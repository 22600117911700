import { useEffect, useMemo, useState } from "react";
import { AddSubscriptionPassport, UpdateCollectionData, getActiveReservation, removeTransferedPassport } from "../../slices/PassportSlice";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../hooks";
import { CollectionFormContainerErrorTypes } from "../../types";
import { Entity__factory } from "../../typechain";
import { ICollectibleDetail } from "../../interfaces/ICollection.interface";
import { CatalogTypes } from "../../enums/catalog.enum";
import { APP_STORAGE_GET_COLLECTIBLEDETAILS, ZERO_ADDRESS, getAppConfiguration } from "../../constants";
import { removeTransferedBillboards, removeTransferedPremiumChats } from "../../slices/OfferSlice";
import { removeData } from "../../helpers/AppStorage";
import { PassportType } from "../../enums/offers.enum";
import { ethers } from "ethers";
import { getDefaultCollection } from "../../services/collection.service";

type CollectionTransferHookProps = {
    resetError:()=>void;
    setErrorStates:({...args})=>void;
    errorStates:CollectionFormContainerErrorTypes
    formInput:ICollectibleDetail;
    setSelectedCard:(item:any)=>void,
    setLoading:({...args})=>void
    type:any;
    onNewCollection?:()=>void;
}

export const useCollectionTransfer = (props:CollectionTransferHookProps) =>{

  const {resetError,setErrorStates,errorStates,formInput,setSelectedCard,setLoading,type} = props;
  const {address,staticProvider,wallet,networkId} = useWeb3Context()

  const [isTransferCollection, setIsTransferCollection] = useState<boolean>(false);
  const [whiteListedAddresses,setWhiteListedAddresses] = useState([]);
  const allEntityAddresses = useAppSelector(state => state?.Entity?.allEntityAddresses);
  const entityAddress = useAppSelector(state => state.Entity.EntityAddress);
  const defaultCollection = getDefaultCollection(type)
  const dispatch = useAppDispatch();

  const loadWhiteListedAdmins = async() => {
    getAppConfiguration().then((res)=>{
      setWhiteListedAddresses(res?.transferEntityCollectionWhitelistedWallet)
    })
  }

  useEffect(()=>{
    loadWhiteListedAdmins()
  },[])

  const isAddressWhiteListed = useMemo(()=>{
    return Boolean(whiteListedAddresses?.find(i=>i?.toLowerCase()==address?.toLowerCase()))
  },[whiteListedAddresses,address])


  const [transferDetails, setTransferDetails] = useState<{ newEntityAddress: string, entityAdminAddress: string }>({
    newEntityAddress: "",
    entityAdminAddress: ""
  });

  const toggleTransfer = () =>{
    setIsTransferCollection(!isTransferCollection)
  }


  const handleTransferFields = (event: any) => {
    const { target } = event;
    resetError();
    setTransferDetails({ ...transferDetails, [target.id]: target?.value })
  }


  const isTransferCollectionDisabled = useMemo(() => {
    const { newEntityAddress, entityAdminAddress } = transferDetails;
    if (((entityAddress?.toLowerCase() == newEntityAddress?.toLowerCase()) ||
      (!entityAdminAddress || !newEntityAddress))) {
      if (entityAddress?.toLowerCase() == newEntityAddress?.toLowerCase()) {
        setErrorStates({ ...errorStates, entityAddress: "Entity Address should not be the same as existing entity" })
      } else if ((!entityAdminAddress || !newEntityAddress) || entityAddress?.toLowerCase() != entityAdminAddress?.toLowerCase()) {
        resetError()


      }
      return true
    } else if (entityAddress?.toLowerCase() != newEntityAddress?.toLowerCase() && entityAdminAddress?.toLowerCase() == address?.toLowerCase()) {
      resetError()

      return false;
    }
    return false
  }, [transferDetails, entityAddress])

  const handleEntityTransfer = async () => {
    const { newEntityAddress, entityAdminAddress } = transferDetails;
    if (allEntityAddresses?.find(i => i?.toLowerCase() == newEntityAddress?.toLowerCase())) {
      setLoading({ open: true, message: "Fetching Entity Details" })
      const entity = Entity__factory.connect(newEntityAddress, staticProvider);
      const entityAdmins = await entity.getAllEntityAdmins(true);
      if (entityAdmins && entityAdmins?.length && entityAdmins?.find(i => i?.toLowerCase() == entityAdminAddress?.toLowerCase())) {
        const activeReservations = await getActiveReservation({ networkID: networkId, provider: staticProvider, entity: entityAddress })
        if (formInput?.linkCollectible?.length == 0 && activeReservations?.length == 0) {
          setLoading({ open: true, message: "Transfering Collection" })
          let createdCollection: any = ZERO_ADDRESS;
          let udpatedCollection: any = ZERO_ADDRESS;
          const floorPrice = ethers.utils.parseUnits(formInput?.price?.toString(), 18).toString();  
          if (formInput.passportType === PassportType.SUBSCRIPTON) {
            // Adding subscrition
            createdCollection = await dispatch(AddSubscriptionPassport({
              networkID: networkId,
              provider: staticProvider,
              address: entityAdminAddress,
              collectibleAddress: formInput?.address,
              wallet, floorPrice: floorPrice,priceRate:Number(formInput?.priceRate)
            }));
          }
          if ((createdCollection?.payload != ZERO_ADDRESS) || formInput.passportType != PassportType.SUBSCRIPTON) {        
            udpatedCollection = await dispatch(UpdateCollectionData({
              networkID: networkId,
              provider: staticProvider,
              address: address,
              isPremium: formInput?.isPremium ? true : false, 
              category: formInput?.category ? formInput?.category : 0,
              collectibleAddress: formInput?.address,
              EntityAddress: newEntityAddress,
              wallet: wallet,
              collectibleData: formInput
            }))


            
            if (udpatedCollection?.payload != ZERO_ADDRESS) {
              if (type == CatalogTypes?.PREMIUM_CHAT) {
                dispatch(removeTransferedPremiumChats(formInput));
              } else if (type == CatalogTypes?.EXPASSES) {
                dispatch(removeTransferedPassport(formInput));
              } else if (type ==CatalogTypes?.ANNOUNCEMENT){
                dispatch(removeTransferedBillboards(formInput));
              }
              removeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(formInput?.address))
              
            } else {
              setLoading({ open: true, message: "Error in updating collection data. Reverting ownership" });
            }
          }
          if (udpatedCollection?.payload == ZERO_ADDRESS &&
            formInput.passportType === PassportType.SUBSCRIPTON) {
            await dispatch(AddSubscriptionPassport({
              networkID: networkId,
              provider: staticProvider,
              address: address,
              collectibleAddress: formInput?.address,
              wallet, floorPrice: floorPrice
            }));
          }

          if (udpatedCollection?.payload != ZERO_ADDRESS && (createdCollection?.payload != ZERO_ADDRESS || type==CatalogTypes?.ANNOUNCEMENT)) {
            setIsTransferCollection(false)
            if(type==CatalogTypes?.ANNOUNCEMENT){
              props?.onNewCollection()
            }else{
              setSelectedCard(defaultCollection);
            }
          }
          setLoading({ open: false, message: "" })
        } else {
          setErrorStates({ ...errorStates, linkedCollectionError: "You have linked Collections.Please de-link them to proceed." })
          setLoading({ open: false, message: "" })
        }

      } else {
        setErrorStates({ ...errorStates, entityAdminAddress: "Entity Admin Address not found" })
        setLoading({ open: false, message: "" })
      }
    } else {
      setErrorStates({ ...errorStates, entityAddress: "Entity Address not found" })
      setLoading({ open: false, message: "" })
    }

  }


    return {
    toggleTransfer,
    handleEntityTransfer,
    handleTransferFields,
    transferDetails,
    address,
    isTransferCollectionDisabled,
    isAddressWhiteListed,
    errorStates,
    whiteListedAddresses,
    isTransferCollection
    }
}