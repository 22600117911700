import React, { useEffect, useMemo, useState } from 'react';
import "./BillboardsPage.scss"
import { BillBoardsRightPanel } from './layouts/BillboardRightPanel';
import parse, { DOMNode } from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import PreviewTemplate from './components/PreviewModal';
import { HtmlIpfsUpload, fileIpfsUpload, jsonIpfsUpload } from '../../services/ipfsUpload.service';
import { useAppSelector, useWeb3Context } from '../../hooks';
import { getIPFSLink, wait } from '../../helpers/ipfs';
import { useDispatch } from 'react-redux';
import { AddCollection, AddSubscriptionPassport, CreateCollection, UpdateCollectiblesdataURI, UpdateCollectionData, delinkCollection, getActiveReservation, getCollectibleDetails, linkCollection, retireCollection } from '../../slices/PassportSlice';
import { AppDispatch } from '../../store';
import { LogToConsoleError } from '../../helpers/Logger';
import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { LoaderModal } from '../../components/ModalComponents/LoaderModal';
import { removeTransferedBillboards, updateDigitalCollectionData } from '../../slices/OfferSlice';
import { useAlert } from '../../hooks/Alert';
import { APP_STORAGE_GET_COLLECTIBLEDETAILS, BILLBOARD_SCRIPT, NETWORKS, ZERO_ADDRESS } from '../../constants';
import { Template1 } from "../../assets/templates/Template1";
import { Template2 } from "../../assets/templates/Template2";
import { Template3 } from "../../assets/templates/Template3";
import validator from 'validator';
import { defaultTheme } from '../../styles';
import { CatalogTypes } from '../../enums/catalog.enum';
import { getDefaultCollection } from '../../services/collection.service';
import { ICollectibleDetail } from '../../interfaces/ICollection.interface';
import { BillboardTopPanel } from './layouts/BillboardTopPanel';
import { loadAllDigitalCollectibles } from '../../slices/OfferSlice';
import { ConfirmModal } from '../../components/ModalComponents/ConfirmModal';
import { CustomButton } from '../../components/Button/CustomButton';
import dayjs from 'dayjs';
import { PassportType } from '../../enums/offers.enum';
import { WarningRounded } from '@mui/icons-material';
import { Entity__factory } from '../../typechain';
import { ethers } from 'ethers';
import { BasicTextInput } from '../../components/TextInput/BasicTextInput/BasicTextInput';
import { removeData } from '../../helpers/AppStorage';
import { CollectionTransfer } from '../../common/collectionTransfer';
import { useCollectionTransfer } from '../../common/collectionTransfer/useCollectionTransfer';
import { rgbStringToHex } from '../../slices/helpers';
import CopyIcon from '../../components/CopyIcon/CopyIcon';
import { ErrorText } from '../../components/Error/ErrorText';

export const Templates = [Template1, Template2, Template3]
const initialState = {
  name: "",
  title: '',
  isActive: false,
  address: "",
  subtext: '',
  description: '',
  timestamp: null,
  bgImage: null,
  passport: [],
  htmlTemplate: null,
  chain: null,
}


const BillBoardsPage = () => {

  const { address, wallet, networkId, staticProvider } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();
  const { setShowToastError } = useAlert();

  const collectionsLoading = useAppSelector(state => state.Offers.DigitalCollectibleloading);
  const allCollectionData = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails);
  const passports = useAppSelector(state => state?.Passports?.AllEntityPassportDetails);
  const defaultCollection: any = getDefaultCollection(CatalogTypes.COLLECTIBLES);
  const entityAddress = useAppSelector(state => state.Entity.EntityAddress);
  const [initialCardSelected, setInitialCardSelected] = useState(defaultCollection);
  const [selectedCard, setSelectedCard] = useState<ICollectibleDetail>(initialCardSelected);
  const [collectionData, setCollectionData] = useState(initialState);
  const [loading, setLoading] = useState({ open: false, message: "" });
  const [updatedHtmlContent, setTemplate] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState(Templates[updatedHtmlContent]);
  const [showConfirmPopup, setShowConfirmPopup] = useState({ confirm: () => { }, state: false, message: "" });
  const [selectedTime, setSelectedTime] = useState(null);
  const [isInitialDescVisible, setInitialDescVisible] = useState<boolean>(true);
  const [searchText, setSearchText] = useState('');
  const [showActive, setShowActive] = useState(true);
  const isSuperAdmin = useAppSelector(state=>state.Entity.isSuperAdmin);

  const [customStyle, setCustomStyle] = useState({
    name: {
      fontWeight: "",
      fontStyle: "",
      color: "",
      textDecoration: "",
      fontSize: 24,
    },
    title: {
      fontWeight: "",
      fontStyle: "",
      color: "",
      textDecoration: "",
      fontSize: 24,
    },
    subtext: {
      fontWeight: "",
      fontStyle: "",
      color: "",
      textDecoration: "",
      fontSize: 24,
    },
    description: {
      fontWeight: "",
      fontStyle: "",
      color: "",
      textDecoration: "",
      fontSize: 24,
    },
  })

  const [errorStates, setErrorStates] = useState<{ entityAdminAddress: string, entityAddress: string, linkedCollectionError: string, disableError: string }>({
    entityAddress: "",
    entityAdminAddress: "",
    linkedCollectionError: "",
    disableError: "",
  });


  const resetError = () => {
    setErrorStates({
      disableError: "",
      entityAddress: "",
      entityAdminAddress: "",
      linkedCollectionError: ""
    })

  }

  const onNewCollection = () => {
    setInitialDescVisible(false)
    setTemplate(1);
    setSelectedCard(defaultCollection);
    setSelectedTime(null)
    setSelectedTemplate(Templates[1]);
    setFormData(initialState);

  }

  const { ...collectionTransferProps } = useCollectionTransfer({
    type: CatalogTypes?.ANNOUNCEMENT,
    setErrorStates,
    formInput: selectedCard,
    onNewCollection,
    errorStates,
    resetError, setLoading,
    setSelectedCard,
  })

  let [bgImage, setBgImage] = useState("");

  const [formData, setFormData] = useState(initialState);

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (selectedCard?.address != "" && String(selectedCard?.htmlTemplate) != "") {
      setSelectedTemplate(Templates[selectedCard?.htmlTemplate])
      setTemplate(Number(selectedCard?.htmlTemplate))
    } else {
      setSelectedTemplate(Templates[1])
      setTemplate(1)
    }
  }, [selectedCard?.address])

  // useEffect(()=>{
  //     if(updatedHtmlContent!=null){
  //       setSelectedTemplate(Templates[updatedHtmlContent])
  //     }
  // },[updatedHtmlContent])

  const handleTemplate = (template: number) => {
    setTemplate(template);
    setSelectedTemplate(Templates[template])
    setFormData({ ...formData, htmlTemplate: template })
  }


  useEffect(() => {
    if (initialCardSelected)
      setSelectedCard(initialCardSelected)
  }, [initialCardSelected])


  const onSelectCollection = (data: ICollectibleDetail) => {
    setCustomStyle({
      name: { textDecoration: "", fontStyle: "", fontWeight: "", color: "", fontSize: 24 },
      title: { textDecoration: "", fontStyle: "", fontWeight: "", color: "", fontSize: 24 },
      subtext: { textDecoration: "", fontStyle: "", fontWeight: "", color: "", fontSize: 24 },
      description: { textDecoration: "", fontStyle: "", fontWeight: "", color: "", fontSize: 24 }
    })
    setInitialDescVisible(false);
    setTemplate(null);
    setSelectedCard(data);
    setSelectedTemplate(data?.animationUrlHtml);
  }

  const onShowPreview = () => {
    setShowPreview(true);
  };
  const onClosePreview = () => {
    setShowPreview(false);
  };

  // loading state
  useEffect(() => {
    if (collectionsLoading) {
      setLoading({ open: true, message: `Fetching Announcements` });
    }
    else {
      setLoading({ open: false, message: "" });
    }

  }, [collectionsLoading]);

  useEffect(() => {
    if (formData?.bgImage && typeof formData.bgImage === 'object') {
      setBgImage(URL.createObjectURL(formData?.bgImage));
    }
    else {
      setBgImage(formData?.bgImage);
    }
  }, [formData?.bgImage]);

  useEffect(() => {

    if (selectedCard && selectedCard.address !== "") {
      const collectionData = {
        name: selectedCard?.name !== "" ? selectedCard.name : "",
        isActive: selectedCard?.isActive ? selectedCard.isActive : false,
        title: selectedCard?.title !== "" ? selectedCard.title : "",
        subtext: selectedCard?.subTitle !== "" ? selectedCard.subTitle : "",
        description: selectedCard?.details !== "" ? selectedCard.details : "",
        timestamp: selectedCard?.timestamp ? selectedCard.timestamp : "",
        bgImage: selectedCard?.imageProps?.image !== "" ? selectedCard.imageProps?.image : "",
        passport: selectedCard?.linkCollectible,
        htmlTemplate: selectedCard?.htmlTemplate,
        address: selectedCard?.address,
        chain: selectedCard?.chain
      }
      setCollectionData(collectionData);
      setFormData(collectionData);
    }

  }, [selectedCard?.address]);

  const RenderTemplate: any = (props: { isPreview?: boolean, isMobilePreview?: boolean, forIpfs?: boolean }) => {
    if (selectedTemplate) {
      const replace = (domNode: DOMNode | any) => {
        if (domNode?.attribs) {
          if (domNode?.attribs.id === `body-container`) {
            if (formData.bgImage) {
              return (
                <div id={`body-container`} style={{
                  ...(updatedHtmlContent == 0 ? {
                    backgroundColor: "#000"
                  } : {
                    backgroundImage: `url(${bgImage})`,
                    ...(props?.isMobilePreview && !props?.forIpfs ? {
                      maxHeight: "48vh"
                    } : {})

                  })
                }} ></div>
              );
            }
          }
          if (domNode.attribs.id === 'name' && formData.name !== "") {
            return (
              <h4 id="name" style={{
                ...customStyle?.name, ...(props?.isPreview ? {
                  marginTop: props?.isMobilePreview ? '1rem' : '4rem',
                  ...(props?.isMobilePreview ? { display: 'none' } : {})
                } : {})
              }}>{formData?.name?.split('\n').flatMap((line, i) => [line, <br key={`line-${i}`} />])}</h4>
            );
          }

          if (domNode.attribs.id === 'title' && formData.title !== "") {
            return (
              <h4 id="title" style={{ ...customStyle?.title, ...(props?.isMobilePreview ? { fontSize: customStyle?.title?.fontSize ?? "1.2rem" } : {}) }} >{formData.title.split('\n').flatMap((line, i) => [line, <br key={`line-${i}`} />])}</h4>
            );
          }
          if (domNode.attribs.id === 'subtext' && formData.subtext != "") {
            return (
              <h5 id="subtext" style={{ ...customStyle?.subtext, ...(props?.isMobilePreview ? { fontSize: customStyle?.subtext?.fontSize ?? "1.2rem" } : {}) }}>{formData.subtext.split('\n').flatMap((line, i) => [line, <br key={`line-${i}`} />])}</h5>
            );
          }
          if (domNode.attribs.id === 'description' && formData?.description !== "") {
            return (
              <p id="description" style={{ ...customStyle?.description, ...(props?.isMobilePreview ? { fontSize: customStyle?.description?.fontSize ?? "1rem" } : {}) }}>{formData?.description.split('\n').flatMap((line, i) => [line, <br key={`line-${i}`} />])}</p>
            );
          }
          if (domNode.attribs.id === 'streamyard' && updatedHtmlContent === 0 && formData?.description !== "" && validator.isURL(formData?.description)) {
            return (
              <iframe id="streamyard" src={formData?.description} referrerPolicy="no-referrer"
                width="100%" height="100%" frameBorder="0" allowFullScreen={true}
              />
            );
          }
          if (domNode.attribs.id === 'timestamp') {
            return (
              <h5 id="timestamp" style={props?.isMobilePreview ? { fontSize: "1rem", ...((formData?.timestamp != "" && formData?.timestamp != null) ? {} : { display: 'none' }) } : {}}>
                {formData?.timestamp != "" && formData?.timestamp != null ?
                  `${new Date(formData?.timestamp).toDateString()} ${selectedTime ? dayjs(selectedTime).format("hh:mm a") : ""}`
                  : "Your Date & Time Goes Here"}
              </h5>
            );
          }

          if (props?.isMobilePreview) {
            if (domNode?.attribs?.class == "streamYardLink") {
              domNode.attribs.style = "font-size:2rem;"
            }

            if (domNode?.attribs?.class == "live") {
              domNode.attribs.style = "font-size:1.2rem;"
            }

            if (domNode?.attribs?.class == "fas fa-circle circle") {
              domNode.attribs.style = "font-size:1rem;margin-right:10px;"
            }

            if (domNode.attribs?.class == 'rec') {
              domNode.attribs.style = "font-size:1.5rem;font-weight:600;padding:10px;border-radius:10px;"
            }
          }

          if (domNode.type === 'script') {
            return (
              <script src={BILLBOARD_SCRIPT} type="text/javascript"></script>
            );
          }
        }
      };

      return parse(selectedTemplate, { replace });
    }
  }

  useEffect(() => {
    if (selectedCard) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(selectedCard?.animationUrlHtml, "text/html");
      const title = doc.getElementById("title");
      const subtext = doc.getElementById("subtext")
      const description = doc.getElementById("description")
      setCustomStyle({
        name: { textDecoration: "", fontStyle: "", fontWeight: "", color: "", fontSize: 24 },
        title: {
          textDecoration: title?.style?.textDecoration,
          fontStyle: title?.style?.fontStyle, fontWeight: title?.style?.fontWeight,
          color: rgbStringToHex(title?.style?.color), fontSize: 30
        },
        subtext: {
          textDecoration: subtext?.style?.textDecoration,
          fontStyle: subtext?.style?.fontStyle, fontWeight: subtext?.style?.fontWeight,
          color: rgbStringToHex(subtext?.style?.color), fontSize: 24
        },
        description: {
          textDecoration: description?.style?.textDecoration,
          fontStyle: description?.style?.fontStyle,
          fontWeight: description?.style?.fontStyle,
          color: rgbStringToHex(description?.style?.color),
          fontSize: 22
        }
      })
    }
  }, [selectedCard])

  // console.log(ReactDOMServer.renderToString(<RenderTemplate isMobilePreview={true} isPreview={true} forIpfs={true} />));

  const ipfsDataGeneration = async () => {
    const backgroundImageIPFS = await fileIpfsUpload(formData.bgImage, selectedCard.imageProps.image, wallet);
    bgImage = getIPFSLink(backgroundImageIPFS);

    // upload HTML
    const htmlBlobData = ReactDOMServer.renderToString(<RenderTemplate isMobilePreview={true} isPreview={true} forIpfs={true} />);
    const HtmlIPFSUri = await HtmlIpfsUpload(htmlBlobData, wallet);

    let collectionObj =
    {
      "name": formData.title.trim(),
      "description": formData.description.trim(),
      "image": backgroundImageIPFS,
      "subtitle": formData.subtext.trim(),
      "animation_url": HtmlIPFSUri,
      "timestamp": formData?.timestamp,
      "htmlTemplate": updatedHtmlContent,
    }

    const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet);
    return ipfsDataURI;

  }

  const onFormCreation = async (ipfsDataURI) => {
    setLoading({ open: true, message: `Creating Announcement` });

    const formInput: ICollectibleDetail = {
      ...selectedCard,
      name: formData.name.trim(),
      details: formData.description.trim(),
      dataURI: ipfsDataURI

    }

    const data = (await dispatch(CreateCollection({
      networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleData: formInput, dataURI: ipfsDataURI, wallet, chainID: networkId
    })));
    if (data.payload && data.payload !== ZERO_ADDRESS) {
      let collectionAddress = data.payload;
      // setLoading({ open: true, message: `Adding ${type} to manager` });

      await dispatch(AddCollection({ networkID: networkId,category:0, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleAddress: collectionAddress, collectibleData: formInput, wallet, chainID: networkId }));

      return collectionAddress;
    }
    else {
      setLoading({ open: false, message: "" });
      LogToConsoleError("creation Error");
      setShowToastError({ open: true, message: `Announcement creation error` });
    }
    return "";
  }

  const resetForm =()=>{
    setTemplate(1);
    setSelectedTime(null)
    setFormData(initialState);
    setInitialDescVisible(true)
    setSelectedTemplate(Templates[1]);
    setSelectedCard(defaultCollection);
  };

  const onSubmitHandler = async (event) => {
    event?.preventDefault();
    // if (JSON.stringify(collectionData) === JSON.stringify(formData)) return;

    setLoading({ open: true, message: selectedCard?.address ? "Updating Announcement" : "Creating Announcement" });
    try {
      // upload image
      let collectionAddress = selectedCard?.address;

      if (collectionAddress === "") {
        let ipfsDataURI = await ipfsDataGeneration();

        if (ipfsDataURI && ipfsDataURI !== "") {
          collectionAddress = await onFormCreation(ipfsDataURI);
          if (collectionAddress === "") return;
        }
        else {
          setLoading({ open: false, message: "" });
          setShowToastError({ open: true, message: "Image upload error" });
          LogToConsoleError("IPFS Upload error");
          return;
        }

      }
      else {


        let ipfsDataURI = await ipfsDataGeneration();
        await dispatch(UpdateCollectiblesdataURI({
          networkID: networkId, provider: staticProvider,
          address, collectibleAddress: collectionAddress,
          wallet, dataURI: ipfsDataURI,
          chainID: networkId
        }));

      }

      if (selectedCard?.linkCollectible !== formData?.passport) {
        setLoading({ open: true, message: `Linking/Delinking collections` });

        let oldLinks = selectedCard?.linkCollectible;
        let newLinks = formData?.passport;

        let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
        if (delinks && delinks.length > 0) {
          for (const collectible of delinks) {
            try {
              await dispatch(delinkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet }));
            } catch (error) {
              console.error('Error with collectible:', collectible, error);
            }
          }
        }

        let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);

        if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
          await dispatch(linkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, linkingAddresses: addlinks, wallet }));
        }
      }
      await wait(100);
      await loadDetails(collectionAddress);
      // reset form after creation is successful
      resetForm();
    } catch (error) {
      LogToConsoleError(`Error in ${selectedCard.address !== "" ? "updating" : "creating"} Billboard`, error);
      setShowToastError({ open: true, message: "Error in creating Announcement" });

    }
    setLoading({ open: false, message: "" });

  }

  const loadDetails = async (collectionAddress) => {
    const collectibleDetails = (await getCollectibleDetails({
      networkID: networkId, provider: staticProvider,
      userAddress:wallet?.address,
      collectibleData: { source: collectionAddress, chainId: networkId },
      index: selectedCard.address === "" ? allCollectionData.length : selectedCard.index, entityAddress,
      isSuperAdmin:isSuperAdmin
    }, { isCache: false, fetchLatest: true }));
    dispatch(updateDigitalCollectionData(collectibleDetails));

  }
  
  const disableCollection = async () => {
    setShowConfirmPopup({ state: false, message: "", confirm: () => { } });
    const activePassports = passports?.filter(i => i.isActive);

    const currentAnnouncementLinkedTo = activePassports?.filter(i => i?.linkCollectible?.find(i => i?.toLowerCase() == formData?.address?.toLowerCase()))

    // if (formData?.passport?.length) {
    //   setErrorStates({ ...errorStates, disableError: "Please unlink collections to proceed." })
    //   return
    // }

    // if (currentAnnouncementLinkedTo && currentAnnouncementLinkedTo?.length) {
    //   setErrorStates({ ...errorStates, disableError: `This announcement is linked to ${currentAnnouncementLinkedTo?.map(i => i?.name)?.join(',')}. Please unlink from the collections to proceed.`})
    //   return
    // }

   

    setLoading({ open: true, message: `Retiring Announcement` });

    await dispatch(retireCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddress: selectedCard.address, wallet }));

    await loadDetails(selectedCard.address);
    setLoading({ open: false, message: "" });
    onNewCollection()
  }

  useEffect(() => {
    let timeOut = setTimeout(() => {
      resetError()
    }, 3000)

    return () => { clearTimeout(timeOut) }
  }, [errorStates])


  const onModalCancel = () => {
    setShowConfirmPopup({ confirm: () => { }, state: false, message: "" });
  }


  const handleDisable = () => {
    if (formData.isActive) {

      setShowConfirmPopup({ message: `Are you sure, you want to permanently disable Announcement, "${formData.name}"`, confirm: () => { disableCollection() }, state: true, });
    }
  }

  const shouldUpdate = useMemo(() => {
    return false
  }, [collectionData, formData])

  const [selectedField, setSelectedField] = useState<"title-field" | "subtext-field" | "description-field" | "">("");

  const applyStyle = ({ property, value }) => {
    const fieldToStyle = selectedField.split('-')[0];
    // const selection = window.getSelection()
    // const range = selection.getRangeAt(0);
    // const selectedElement = range.commonAncestorContainer;
    // const element: any = selectedElement.nodeType === 3 ? selectedElement.parentNode : selectedElement;
    // console.log({ selectedElement: element?.id });
    if (customStyle[fieldToStyle][property] == value) {
      setCustomStyle(prevState => ({
        ...prevState,
        [fieldToStyle]: {
          ...prevState[fieldToStyle],
          [property]: "" // Your new value for fontWeight
        }
      }));
    } else {
      setCustomStyle(prevState => ({
        ...prevState,
        [fieldToStyle]: {
          ...prevState[fieldToStyle],
          [property]: value // Your new value for fontWeight
        }
      }));
    }
  }

  return (
    <div className='billboards-container'>
      <BillboardTopPanel setShowActive={setShowActive} showActive={showActive} searchText={searchText} setSearchText={setSearchText} isInitialDescVisible={isInitialDescVisible} setInitialDescVisible={setInitialDescVisible} setInitialCardSelected={setInitialCardSelected} defaultCollection={defaultCollection} onNewCollection={onNewCollection} selectedCard={selectedCard} onSelectCollection={onSelectCollection} />
      {
        formData?.address !== "" && collectionTransferProps?.isTransferCollection &&
        <span className="address">
          <a href={NETWORKS[Number(formData?.chain)]?.blockExplorerUrl + "address/" + formData?.address} target='_blank' rel="noopener noreferrer">{formData?.address}</a>
          <CopyIcon text={formData?.address} />
        </span>
      }
      {collectionTransferProps?.isTransferCollection &&
        <CollectionTransfer
          type={CatalogTypes?.ANNOUNCEMENT}
          {...collectionTransferProps}
          selectedCard={selectedCard}
        />}
      {!isInitialDescVisible && !collectionTransferProps?.isTransferCollection ?
        <>
          <Divider orientation='horizontal' variant='fullWidth' sx={{ width: '100%', alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />

          <Box sx={{
            display:'flex',
            width:'100%',
            '@media (max-width: 961px)': {
              flexWrap:'wrap'
            },
          }}>
            <BillBoardsRightPanel customStyle={customStyle[selectedField.split('-')[0]]} setSelectedField={setSelectedField} applyStyle={applyStyle} shouldUpdate={shouldUpdate} selectedTime={selectedTime} setSelectedTime={setSelectedTime} handleDisable={handleDisable} setLoading={setLoading} formData={formData} collectionAddress={selectedCard?.address} setFormData={setFormData} handleClickOpen={onShowPreview} onSubmitHandler={onSubmitHandler} handleTemplate={handleTemplate} Template={updatedHtmlContent} />
            <Box sx={{ display: 'flex', flexDirection: 'column',
            '@media (max-width:961px)':{
              minWidth:'100%',
              maxWidth:'100%',
            },
            minWidth: '58%', maxWidth: '58%' }}>
              <div className="left-panel-container" >
                {RenderTemplate()}
              </div>
              <CustomButton
                className={"col-12 col-sm-12 col-lg-12 mt-2"}
                onClick={onShowPreview}
                color={defaultTheme.buttonLightTextColor}
                backgroundColor={defaultTheme.buttonRedColor}
                disabled={(selectedCard?.address && formData?.isActive) || !selectedCard?.address ? false : true}

                type={"button"}
              >
                PREVIEW ANNOUNCEMENT
              </CustomButton>

              {collectionTransferProps?.isAddressWhiteListed && formData?.address != "" && showActive ?
                <CustomButton
                  className={"col-12 col-sm-12 col-lg-12 mt-2"}
                  onClick={() => {
                    setInitialDescVisible(false)
                    collectionTransferProps?.toggleTransfer()
                  }}
                  color={defaultTheme.buttonDarkTextColor}
                  backgroundColor={defaultTheme.yellowColor}
                  disabled={false}

                  type={"button"}
                >
                  Transfer Collection
                </CustomButton> : <></>}
              {errorStates?.disableError &&
                <Box p={2}>
                  <ErrorText message={errorStates?.disableError} />
                </Box>}
            </Box>


          </Box>

        </> : <></>}
      {
        showConfirmPopup.state && <ConfirmModal
          cancelText='No'
          confirmText='Yes'
          modalOpen={showConfirmPopup.state} text={showConfirmPopup.message} onModalCancel={onModalCancel} onModalSubmit={showConfirmPopup.confirm} />
      }

      {showPreview && <PreviewTemplate
        customStyle={customStyle[selectedField.split('-')[0]]}
        setSelectedField={setSelectedField}
        applyStyle={applyStyle}
        shouldUpdate={shouldUpdate} selectedTime={selectedTime} setSelectedTime={setSelectedTime} handleDisable={handleDisable} setLoading={setLoading} formData={formData} collectionAddress={selectedCard.address} setFormData={setFormData} handleClickOpen={onShowPreview} onSubmitHandler={onSubmitHandler} Template={updatedHtmlContent}
        open={showPreview} handleClose={onClosePreview} render={RenderTemplate} backgroundImage={formData?.bgImage} />}

      {loading.open && <LoaderModal open={loading.open} message={loading.message} />}
    </div>

  );
}

export default BillBoardsPage;