import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { CustomButton } from '../../../components/Button/CustomButton';
import { defaultTheme } from '../../../styles';
import backArrow from '../../../assets/svg/backArrow.svg'
import { BillBoardsFormContainer } from './BillboardFormContainer';
import bluePattern from '../../../assets/images/background/blueTexture.png'
import { MobilePreview } from './MobilePreview';
import "../BillboardsPage.scss"
import { ToolBar } from './ToolBar';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
}));

export default function PreviewTemplate({ open, handleClose,customStyle,applyStyle,setSelectedField, render, backgroundImage, shouldUpdate, selectedTime,
  handleDisable,
  collectionAddress,
  onSubmitHandler,
  setLoading,
  Template,
  handleClickOpen,
  formData,
  setFormData,
  setSelectedTime }) {


  const [isPreviewForMobile, setIsPreviewForMobile] = React.useState<boolean>(false);
  const [isEditView,setIsEditView] = React.useState<boolean>();

  return (
    <div className='preview-modal-container'>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: 'unset',
            width: '100vw',
            height: '100vh'
          },
        }}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} fontFamily={"Poppins-SemiBold"} id="customized-dialog-title">
          Preview
        </DialogTitle> */}
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent dividers sx={{ padding: 0 }}>
          <Box display={'flex'} alignItems={'center'} position={'absolute'} justifyContent={'space-between'} top={'2px'} width={"100%"} zIndex={9999} px={'2rem'} py={'1rem'}>
            {/* <Box display={'flex'} flexDirection={'column'} width={'100%'}> */}
            <CustomButton
              disabled={false}
              onClick={()=>setIsEditView(!isEditView)}
              className={'col-lg-2 col-2 col-md-2'}
              backgroundColor={defaultTheme.yellowColor}
              color={defaultTheme.buttonDarkTextColor}
              type='button'
            >
              {isEditView ? 'DONE EDITING' : 'EDIT ANNOUNCEMENT'}
            </CustomButton>

            {/* </Box> */}
            <CustomButton
              disabled={false}
              onClick={() => setIsPreviewForMobile(!isPreviewForMobile)}
              className={'col-lg-2 col-2 col-md-2'}
              backgroundColor={defaultTheme.yellowColor}
              color={defaultTheme.buttonDarkTextColor}
              type='button'
            >
              {!isPreviewForMobile ? "PATRON APP VIEW" : "WEB VIEW"}
            </CustomButton>
          </Box>
          {!isEditView ?
            <Box display={'flex'} alignItems={'center'} position={'absolute'} top={'4rem'} px={'2rem'} py={'1rem'} width={"100%"} zIndex={9999}>
              <IconButton onClick={handleClose}>
                <img src={backArrow} style={{ height: '20px', width: "20px" }} /> <Typography ml={2} color={'#fff'} fontFamily={'Poppins-Medium'} fontSize={22}>Back</Typography>
              </IconButton>

            </Box> : <></>}
            <Grid className='preview-modal-container' sx={{ backgroundImage: `url(${bluePattern})` }} container xl={12} lg={12} display={'flex'}>
              {isEditView ? 
              <Grid  sx={{backgroundColor:"#fff",...(!isPreviewForMobile ? {
                maxHeight:"100vh",
                overflowY:"scroll"
              }:{})}} className='form-container' item xl={4} lg={4} p={4}>
                <Box mt={4}>
                <ToolBar customStyle={customStyle} applyStyle={applyStyle}/>
                  
                  <BillBoardsFormContainer
                    setSelectedField={setSelectedField}
                    mobilePreview={true}
                    shouldUpdate={shouldUpdate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    handleDisable={handleDisable} setLoading={setLoading} formData={formData} collectionAddress={collectionAddress} setFormData={setFormData} handleClickOpen={handleClickOpen} onSubmitHandler={onSubmitHandler} Template={Template}
                  />
                </Box>
              </Grid> : <></>}
              {!isPreviewForMobile ? 
              <Grid item xl={isEditView ?  8 : 12} lg={isEditView ?  8 : 12}>
                {render({isPreview:true})}
              </Grid> : 
              <Grid justifyContent={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'} item xl={isEditView ?  7 : 12} lg={isEditView ?  7 : 12} mt={3}>
              <Typography
              color={"#fff"}
              fontFamily={'Poppins-Medium'}
              fontSize={'1rem'}
              mb={2}
              >
            This is how your billboard will show on mobile user application
            </Typography>
                <MobilePreview render={render} template={Template} formData={formData}/>
              </Grid>}
            </Grid>

        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}