import React from "react";
import { defaultTheme } from "../../../styles";
import { Box, IconButton, Typography } from "@mui/material";
import { BillboardTemplate } from "../components/BillboardTemplates";
import { BillBoardsFormContainer } from "../components/BillboardFormContainer";
import { FormatBold, FormatItalic, FormatUnderlined } from "@mui/icons-material";
import { ToolBar } from "../components/ToolBar";
import { NETWORKS } from "../../../constants";
import CopyIcon from "../../../components/CopyIcon/CopyIcon";

export const BillBoardsRightPanel = ({selectedTime,customStyle,applyStyle,setSelectedField,setSelectedTime,formData,handleDisable,setLoading, collectionAddress,shouldUpdate, setFormData, handleClickOpen, onSubmitHandler, handleTemplate, Template}) => {
    
  return (
    <div className="right-panel-container">

      <Typography
      sx={{
        fontSize:{
          lg:'1.25rem',
          md:'1.25rem',
          xl:'1.25rem',
          sm:'1rem',
          xs:'1rem'
        }
      }}
      color={defaultTheme.buttonDarkTextColor} fontWeight={"500"} fontFamily={'Poppins-Medium'} >
        {"Your Announcement Board awaits - simply input the necessary details and bring it to life now!"}
      </Typography>
      {/* <BillboardTemplate/> */}
      {formData?.address !== "" &&
      <Box 
                      maxWidth={"100%"}
                      justifyContent={'center'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Typography
                      sx={{
                        fontFamily: 'Poppins-Medium',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: {
                          xs: '80%', // Full width for extra-small and small screens
                          md: '100%', // Default width for medium and larger screens
                          lg:'100%',
                          sm:'80%',
                          xl:'100%'
                        },
                      }}
                      >
                        <a href={NETWORKS[Number(formData?.chain)]?.blockExplorerUrl + "address/" + formData?.address} target='_blank' rel="noopener noreferrer">{formData?.address}</a>

                 
                      </Typography>
                 
                      <CopyIcon text={formData?.address} />
                      </Box>}
      {/* {
            formData?.address !== "" &&
            <span className="address">
              <a href={NETWORKS[Number(formData?.chain)]?.blockExplorerUrl + "address/" + formData?.address} target='_blank' rel="noopener noreferrer">{formData?.address}</a>
              <CopyIcon text={formData?.address} />
            </span>
          } */}
      <BillboardTemplate handleTemplate={handleTemplate} selectedTemplate={Template}/>
      {/* form container */}
      <ToolBar customStyle={customStyle} applyStyle={applyStyle}/>
      <BillBoardsFormContainer 
      setSelectedField={setSelectedField}
      shouldUpdate={shouldUpdate}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      handleDisable={handleDisable} setLoading={setLoading} formData={formData} collectionAddress={collectionAddress} setFormData={setFormData} handleClickOpen={handleClickOpen} onSubmitHandler={onSubmitHandler} Template={Template} />

    </div>
  );
} 