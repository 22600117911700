import { CatalogTypes } from "../enums/catalog.enum";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";
import { IPOAPCollectibleDetail } from "../interfaces/IPOAPCollection.interface";
import { DEFAULTCOLLECTIBLE } from "../utils/defaultCollectible";
import { DEFAULTEVENT } from "../utils/defaultEvent";
import { DEFAULTFEATUREDOFFER } from "../utils/defaultFeaturedOffer";
import { DEFAULTOFFERS } from "../utils/defaultOffer";
import { DEFAULTPASSPORT, DEFAULTSUBSCRIPTIONPASSPORT } from "../utils/defaultPassport";
import { DEFAULTPOAPCOLLECTIBLE } from "../utils/defaultPoapCollectible";
import { DEFAULTPREMIUM_CHAT_ROOM } from "../utils/defaultPremium";
import { DEFAULTPRIVATE_CHAT } from "../utils/defaultPrivateChat";

export const getDefaultCollection = (type) : ICollectibleDetail | IPOAPCollectibleDetail =>  {
  switch (type) {
    case CatalogTypes.PASSPORT:
      return DEFAULTPASSPORT;
      break;
    case CatalogTypes.FEATUREDOFFERS:
      return DEFAULTFEATUREDOFFER;
      break;
    // case CatalogTypes.OFFERS:
    //   return DEFAULTOFFERS;
    //   break;
    case CatalogTypes.EVENT:
      return DEFAULTEVENT;
      break;
    case CatalogTypes.COLLECTIBLES:
      return DEFAULTCOLLECTIBLE;
      break;
    case CatalogTypes.EXTERNAL:
      return DEFAULTPOAPCOLLECTIBLE;
      break;
    case CatalogTypes.PREMIUM_CHAT:
      return DEFAULTPREMIUM_CHAT_ROOM;
      break;
    case CatalogTypes.EXPASSES:
      return DEFAULTSUBSCRIPTIONPASSPORT;
      case CatalogTypes.PRIVATE_CHAT:
        return DEFAULTPRIVATE_CHAT;
  
      break;

  }
}

export const checkCollectionDataModified = (formData : ICollectibleDetail, collectionData: ICollectibleDetail) => {

  const modified = (
      // formData.checkInNeeded !== collectionData.checkInNeeded ||
      formData.start !== collectionData.start ||
      formData.end !== collectionData.end ||
      formData.maxBalance !== collectionData.maxBalance ||
      formData.maxMint !== collectionData.maxMint ||
      formData.maxPurchase !== collectionData.maxPurchase ||
      formData.mintWithLinked !== collectionData.mintWithLinked ||
      formData.mintWithLinkedOnly !== collectionData.mintWithLinkedOnly ||
      formData.price !== collectionData.price ||
      formData.area !== collectionData.area || formData?.isPremium!=collectionData?.isPremium || formData?.category!=collectionData?.category
    );

    return modified;
  
}