export enum SOCIAL_ACCOUNT {
    FACEBOOK = "facebook",
    TWITTER = "twitter",
    TIKTOK = "tiktok",
    INSTAGRAM = "instagram",
    NONE = "NONE"    
}

export enum VERIFICATION_STATUS  {
    SUCCESS = "success",
    FAILED = "failed"
}