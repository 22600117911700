import { useEffect, useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getIPFSData, getIPFSLink } from '../../helpers/ipfs';
import QRGenerator from '../QRGenerator/QRGenerator';
import { PDFComponent } from '../PDFComponent/PDFComponent';
import { getHSLRandomColor } from '../../helpers/colorProperties';
import { CustomToast } from '../CustomToast/CustomToast';
import { CustomButton } from '../Button/CustomButton';
import { defaultTheme } from '../../styles';
import { LogToConsoleError } from '../../helpers/Logger';
import { useAlert } from '../../hooks/Alert';

export const CsvUploadComponent = ({CollectibleData}) => {
    const [ csvFile, setCsvFile ]= useState<File | undefined>(null);
    const [ generated, setGenerated ] = useState(false);
    const [ QRSplit, setQRSplit ] = useState([]);
    const [ csvData, setCsvData ] = useState(null);
    const { setShowToastError } = useAlert();
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
      setCsvFile(null);
      setGenerated(false);
      setQRSplit([]);
      setCsvData(null);
      setShowLoading(false);

    },[CollectibleData])

    const fileReader = new FileReader();

    const fileChangeHandler = (event: any) => {
        setGenerated(false);
        setShowLoading(true);
        const { files } = event.target;
        if(files && files[0]) {
            setCsvFile(files[0]);

            fileReader.onload = async (event) => {
                const text = event.target.result;
                let csvData = await csvFileToArray(text);
                if(csvData) {
                    setCsvData(csvData);
                    setShowLoading(false);
                }
                else{
                    setCsvData(null);
                    setCsvFile(null);
                    setShowLoading(false);
                    return;
                }
                
            };
            fileReader.readAsText(files[0]);
            
        }
        else {
            setCsvFile(null);
            setShowLoading(false);
        }

    }

    const csvFileToArray = async (string) => {
        
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        if(csvRows.length > 50){
            setShowToastError({ open:true, message: "Too large data"});
            return;
        }

        const array = await Promise.all(csvRows.map(async i => {
            const values = i.split(",");
            if(values.length !== 2 || isNaN(values[0]) || !(new RegExp(/^ipfs:\/\/\S+/).test(values[1])) ){
                setShowToastError({ open:true, message: "Invalid CSV data"});
                return;
            }
            const ipfsURI = values[1];
            let ipfsData;
            try {
                let response = await getIPFSData(ipfsURI);
                ipfsData = response && await response.json();
            }
            catch(e){
                LogToConsoleError(e);
            }
            let collectibleImage = ipfsData && ipfsData?.image && getIPFSLink(ipfsData.image);
            return { tokenID: Number(values[0]),  ipfsURI: ipfsURI, name: ipfsData?.name, image: collectibleImage }
            
        }));
        return array;

    };

    const onGenerate = async (event?) => {
        event?.preventDefault();
        let split = [];

        await Promise.all(csvData && csvData.map(async (data, index) => {
            let qrCode = (document.getElementById(CollectibleData.address + data?.tokenID + data?.ipfsURI ) as HTMLCanvasElement)?.toDataURL();
            let dataImage = data?.image

            // dataImage = await getBase64Image(data?.image);

            let result =  { qrUrl: qrCode, tokenID: data?.tokenID, dataImage : dataImage, name: data?.name };

            split.push([result]);

        }));

        split[0]?.sort((a, b) =>  a.tokenID - b.tokenID);
        setQRSplit(split);
                   
        setGenerated(true);
    }

  return (
      <>
        <div className="csv-upload-container mt-2">
          <Typography variant="body2" color={'white'} fontFamily={'Poppins-Medium'} >
            { "Generate using CSV" }
          </Typography>
          <div>
              {   
              csvData && csvData.map((data, index) => {
                  return (
                      <div style={{ display: 'none' }} key={CollectibleData.address+index}>
                          <QRGenerator collectiblesData={CollectibleData} index={data?.tokenID} dataURI={data?.ipfsURI} withToken={true} />
                      </div>
                  )})
              }
          </div>
              
          <Box 
            sx={{
              display:'flex',
              rowGap:{md:1,sm:1,xs:1},
              columnGap:{xl:2,lg:2},
              flexDirection:{lg:'row',xl:'row',sm:'column',xs:'column',md:'column'}
            }}
          
          >

            <CustomButton
              className={" col-sm-12 col-xs-12 col-lg-5 p-0 mt-1"} 
              onClick={() => {}} 
              color={defaultTheme.buttonDarkTextColor} 
              backgroundColor={defaultTheme.buttonGreyColor} 
              disabled={false} 
              type={"button"} 
            >
            <Button variant="contained" component="label" className='h-100 w-100 py-0' sx={{ fontFamily: "Poppins" }}  >

              {csvFile ? csvFile?.name : "Upload CSV"}
                <input
                    accept={".csv"}
                    type="file"
                    onChange={fileChangeHandler}
                    style={{ opacity: 0, width: 1 }}
                />
              </Button>
            </CustomButton>
            { generated ?
              <PDFDownloadLink document={
                  <PDFComponent 
                      dataSplit={QRSplit}
                      usercolors={{ 
                          textColor: '#000000', 
                          bgcolor: '#FFFFFF',
                          borderColor: getHSLRandomColor() 
                      }} 
                      withToken={true}
                      dimension={['4.5','5.5']}
                  />} 
                    fileName="POAP_CSV_QR.pdf">

                  {({ blob, url, loading, error }) => {
                      return (
                        <CustomButton
                          className={"mt-3"} 
                          onClick={()=>{}} 
                          color={defaultTheme.buttonDarkTextColor} 
                          backgroundColor={defaultTheme.buttonGreyColor} 
                          disabled={false} 
                          type={"button"} 
                        >
                          {loading ? "Loading": "Download" }
                        </CustomButton>
                      );
                  }}
              </PDFDownloadLink>
              :
              <CustomButton
                className={"col-sm-12 col-xs-12 col-lg-5 mt-1 px-0"} 
                onClick={onGenerate} 
                color={defaultTheme.buttonDarkTextColor} 
                backgroundColor={defaultTheme.buttonGreyColor} 
                disabled={!csvFile || showLoading} 
                type={"button"} 
              >
                { showLoading? "Fetching": "Generate PDF" }
              </CustomButton>
            }
          </Box>  
          {/* { ShowToastError.open && <CustomToast open={ShowToastError.open} setOpen={setShowToastError} messageText={ShowToastError.message}/> } */}
        </div>
      </>
    );
}