import { Wallet } from "ethers";
import { SMALL_IMAGE_SIZE_LIMIT } from "../constants";
import { getIpfsCID, getUploadIPFSData, uploadImageToIPFSData } from "../helpers/ipfs";

export const fileIpfsUpload = async (file : any , data : string, wallet: Wallet, fileName: string = 'collectibles.txt') => {
  let IPFSURI = "";
  if(file && file !== data ) {
    let arrayBuffer = await file.arrayBuffer();
    IPFSURI = file.size > SMALL_IMAGE_SIZE_LIMIT ? await uploadImageToIPFSData(arrayBuffer, wallet, fileName) : await getUploadIPFSData(arrayBuffer, wallet, fileName) ;
  } else {
    IPFSURI = getIpfsCID(file);
  }

  return IPFSURI;

}


export const blobIpfsUpload = async (blob: any, data: string, wallet: Wallet, fileName: string = 'collectibles.txt') => {
  let IPFSURI = ""
  if( blob && blob !== data) {
      var fileReader = new FileReader();
      fileReader.readAsArrayBuffer(blob);
      IPFSURI  = await new Promise((resolve , reject) => {
          fileReader.onload = async function(event) {
              let arrayBuffer = event.target.result;
              let data = await uploadImageToIPFSData(arrayBuffer, wallet, fileName);
              resolve(data);
          };
      })
  }
  else {
      IPFSURI =  getIpfsCID(blob);
  }
  return IPFSURI;
}

export const jsonIpfsUpload = async (dataObj : any, wallet: Wallet, fileName: string = 'collectibles.txt') => {

  const val = new Uint8Array(Buffer.from(JSON.stringify(dataObj))).buffer;
  const ipfsURI = await getUploadIPFSData(val, wallet, fileName);

  return ipfsURI;
}

export const HtmlIpfsUpload = async (dataObj : any, wallet: Wallet, fileName: string = "index.html") => {

  const val = new Blob([dataObj], { type: 'text/html' });
  const ipfsURI = await blobIpfsUpload(val, "", wallet, fileName);

  return ipfsURI;
}