import { Box, Paper, Skeleton, makeStyles, styled } from "@mui/material";
import { defaultTheme } from "../../../styles";
import shadows from "@mui/material/styles/shadows";


const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center'

}));


const TextBox = styled(Box)(({ theme }) => ({

  fontFamily: "Poppins-Medium",
  fontSize: '1.2rem',

}));

interface Props {
  name: string;
  count: number;
  icon: string;
  link?: boolean;
}

const StyledPaper = styled(Paper)(({ theme }) => ({

  background: defaultTheme.cardBackground,
  width: '100%',
  minHeight: '9rem',
  maxHeight: '12rem',
  aspectRatio: '2/1.1',
  border: '1px solid #4D4D6F',
  borderRadius: '16px',
  maxWidth: '21rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  transition: 'box-shadow 0.4s',
  "& .bottom-icon": {
    filter: 'invert(1)'
  },

}));

export const DataCard = ({ name, count, icon, link = true }: Props) => {

  return (
    <StyledBox  >
      <StyledPaper color={defaultTheme.buttonDarkTextColor} sx={{ 
        ":hover": 
        link ? {
          background: defaultTheme.CardSubtitle,
          color: defaultTheme.lightColor,
          boxShadow: shadows[9],
          "& .bottom-icon": {
            filter: 'none'
          }
        } : {},
      }} >
        <TextBox className="title">{name}</TextBox>
        <Box className="bottom" sx={{
          display: 'flex',
          alignItems: 'flex-end',
          height: '100%',
          justifyContent: 'space-between',
        }} >
          { count === null || undefined ?
            <Skeleton variant="rounded" width={'3rem'} height={'3rem'} />
            :
            <TextBox className="count fs-2 fw-regular " >{count}</TextBox>
          }

          <div >
            {icon &&
              <img src={icon} alt="#" height={40} className="bottom-icon" style={{
                objectFit: 'contain',
              }} />
            }
          </div>
        </Box>
      </StyledPaper>
    </StyledBox>
  );
}