import { Grid, Typography } from "@mui/material"


type Props = {
    heading?: string,
    description?: string,
    advantageHeading?: string,
    advantages?: { title?: string, description?: string }[]
}

export const CollectionDescription = (props: Props) => {

    return (
        <Grid container>
            <Grid container my={'1.5rem'}  display={'flex'} justifyContent={'center'}>
            <Grid item xl={9} lg={9} >
                <Typography textAlign={'center'} fontFamily={'Poppins-Bold'} color={'black'} sx={{
                    fontSize:{
                        xs:'1.4rem',
                        sm:'1.4rem',
                        md:'2rem',
                        lg:'2rem',
                        xl:'2rem'
                    }
                }}>
                    {props?.heading}
                </Typography>
                <Typography 
                sx={{
                    fontSize:{
                        xs:'14px',
                        sm:'14px',
                        md:'14px',
                        lg:'1rem',
                        xl:'1rem'
                    }}}
                textAlign={'center'} fontFamily={'Poppins-Medium'} color={'black'} fontSize={'1rem'} >
                    {props?.description}
                </Typography>
            </Grid>
            </Grid>
            <Grid item xl={12} lg={12} mx={'1.5rem'} >
                <Typography 
                  sx={{
                    fontSize:{
                        xs:'1rem',
                        sm:'1rem',
                        md:'1rem',
                        lg:'1.2rem',
                        xl:'1.2rem'
                    }}}
                fontFamily={'Poppins-Bold'} color={'black'}  >
                    {props?.advantageHeading}
                </Typography>
                <ul style={{padding:'2px 1rem'}}>
                    {props?.advantages?.map((i,index) =>
                        <div key={index}>
                            <Typography 
                             sx={{
                                fontSize:{
                                    xs:'1rem',
                                    sm:'1rem',
                                    md:'1rem',
                                    lg:'1.2rem',
                                    xl:'1.2rem'
                                }}}
                            fontFamily={'Poppins-Bold'} color={'black'}>
                                <li>{i?.title}</li>
                            </Typography>
                            <Typography 
                             sx={{
                                fontSize:{
                                    xs:'0.9rem',
                                    sm:'0.9rem',
                                    md:'0.9rem',
                                    lg:'1rem',
                                    xl:'1rem'
                                }}}
                            fontFamily={'Poppins-Medium'} color={'black'} >
                                {i?.description}
                            </Typography>
                        </div>)
                    }

                </ul>
            </Grid>
        </Grid>
    )
}