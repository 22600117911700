import "./SidebarComponent.scss"
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import Loot8Logo from "../../assets/images/logos/loot8-logo.png";
import HomeIcon from "../../assets/images/icons/dashboard.png";
import OfferIcon from "../../assets/images/icons/offers.png";
import FeaturedIcon from "../../assets/images/icons/featured-offers.png";
import CollectionIcon from "../../assets/images/icons/collections.png";
import EventIcon from "../../assets/images/icons/events.png";
import PassportIcon from "../../assets/images/icons/passports.png";
import SettingIcon from "../../assets/images/icons/settings.png";
import LogoutIcon from "../../assets/images/icons/logout.png";
import PoapIcon from "../../assets/images/icons/poaps-icon.png";
import ThirdPartyIcon from "../../assets/images/icons/thirdparty-icon.png";
import SocialIcon from "../../assets/images/icons/social.png";
import PremiumChatIcon from "../../assets/images/icons/premium.png";
import PrivateChatIcon from "../../assets/images/icons/private-chat.svg";
import LockIcon from "../../assets/images/logos/Icon-lock.png";
import Billboard from "../../assets/images/icons/billboard.png";
import { NavItem } from "../NavItem/NavItem";
import { NavLink, useRouteMatch,useHistory } from "react-router-dom";
import { useAppSelector, useWeb3Context } from "../../hooks";
import { CustomButton } from "../Button/CustomButton";
import { defaultTheme } from "../../styles";
import SwitchIcon from "../../assets/images/icons/switchEntity.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { setSelectedEntity } from "../../slices/EntitySlice";
import { Divider} from "@mui/material";
import { NavigationRoutes } from "../../enums/routes.enum";
import { clearAllPassportData } from "../../slices/PassportSlice";
import { clearAllCollectionData } from "../../slices/OfferSlice";
import { clearAllPOAPData } from "../../slices/collectibleSlice";
import { Walkthrough } from "../walkthrough";
import { useEffect, useState } from "react";


export const SidebarComponent = ({ menuOpen, handleToggle, setInviteCodeOpen,isLoading }) => {
  const { disconnect,wallet } = useWeb3Context();
  const userDetails = useAppSelector(state => state.AppUser.UserData);
  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const isSuperAdmin = useAppSelector((state) => state.Entity.isSuperAdmin)
  const allEntityDetails = useAppSelector(state => state.Entity.AllEntityData);
  const inviteCodeSent = useAppSelector(state => state.AppUser.InvitesSent);
  const maxInviteCodes = useAppSelector(state => state.AppUser.maxInvites);
  const isUserNew = useAppSelector(state=>state?.AppUser?.isNewUser);
  const dispatch = useDispatch<AppDispatch>();
  const location = useRouteMatch();
  const history = useHistory();
  
  const handleLogout = async() => {
    await switchEntity();
    await disconnect()
  }
  const switchEntity = async () => {
    const selectedEntity = { EntityData: null, EntityAddress: "", AuthorizedEntityAdmin: false };
    
    dispatch(setSelectedEntity(selectedEntity));
    dispatch(clearAllPassportData());
    dispatch(clearAllCollectionData());
    dispatch(clearAllPOAPData());

  }

  const [userPanelWalkthroughRun,setUserPanelWalkthroughRun] = useState<boolean>(false);

  useEffect(()=>{
    if (!isLoading && isUserNew) {
      setUserPanelWalkthroughRun(true)
    }
  },[isUserNew,isLoading])


  const onboardedEntitySteps = [
    {
      target: '#walkthrough-dashboard',
      title: "/dashboard",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 1,

      content: <p className='mt-2' style={{ maxWidth: "28rem" }}><b>What do we have in the dashboard?</b>
      <p className='mt-1'> The Dashboard offers concise, real-time analytics for your ExPass on LOOT8, tracking key metrics like engagement, growth, and revenue. It's an essential tool for quick insights and strategic decision-making, helping you optimize your digital presence.</p></p>
    },
    {
      target: '#walkthrough-expass',
      title: "/expasses",
      disableBeacon: true,
      spotlightPadding: 1,
      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}><b>What is ExPass?</b>
      <p>ExPasses are the starting point for engagement on LOOT8, offering personalized profiles for creators to connect with their fans. These profiles are more than just a digital presence; they're interactive spaces for sharing content, building a community, and beginning your unique journey with your audience.</p>
      </p>
    },
    {
      target: '#walkthrough-announcement',
      title: "/billboards",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Annoucements?</b>  
      <p>Keep your fans up-to-date with 'Announcements' . Whether it’s revealing new projects, sharing event details, or celebrating achievements, this feature ensures your audience never misses out on the important moments and news from your ExPass.</p>
      </p>,
    },
    {
      target: '#walkthrough-chat-room',
      title: "/premium-chat-room",
      disableBeacon: true,
      spotlightPadding: 1,
      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Premium Chat Room?</b>  
      <p>Create an exclusive space for your most dedicated fans with Premium Chat. It's where you can offer intimate conversations and special insights, reserved just for those who truly support your journey.</p>
      </p>,
    },
  ]

  const adminSteps = [
    {
      target: '#walkthrough-dashboard',
      title: "/dashboard",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 1,

      content: <p className='mt-2' style={{ maxWidth: "28rem" }}><b>What do we have in the dashboard?</b>
      <p className='mt-1'> The Dashboard offers concise, real-time analytics for your ExPass on LOOT8, tracking key metrics like engagement, growth, and revenue. It's an essential tool for quick insights and strategic decision-making, helping you optimize your digital presence.</p></p>
    },
    {
      target: '#walkthrough-expass',
      title: "/expasses",
      disableBeacon: true,
      spotlightPadding: 1,
      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}><b>What is ExPass?</b>
      <p>ExPasses are the starting point for engagement on LOOT8, offering personalized profiles for creators to connect with their fans. These profiles are more than just a digital presence; they're interactive spaces for sharing content, building a community, and beginning your unique journey with your audience.</p>
      </p>
    },
    {
      target: '#walkthrough-announcement',
      title: "/billboards",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Annoucements?</b>  
      <p>Keep your fans up-to-date with 'Announcements' . Whether it’s revealing new projects, sharing event details, or celebrating achievements, this feature ensures your audience never misses out on the important moments and news from your ExPass.</p>
      </p>,
    },
    {
      target: '#walkthrough-chat-room',
      title: "/premium-chat-room",
      disableBeacon: true,
      spotlightPadding: 1,
      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Premium Chat Room?</b>  
      <p>Create an exclusive space for your most dedicated fans with Premium Chat. It's where you can offer intimate conversations and special insights, reserved just for those who truly support your journey.</p>
      </p>,
    },
    {
      target: '#walkthrough-featured-offers',
      title: "/featured-offers",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Feature Offers?</b>  
      <p>On LOOT8, 'Featured Offers' are your opportunity to upsell unique experiences, items, or memorabilia directly to fans. Tailor these offers to enhance fan engagement, whether it’s exclusive content, personalized merchandise, or special experiences, providing fans with something they can't find anywhere else.</p>
      </p>,
    },
    {
      target: '#walkthrough-events',
      title: "/events",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 1,

      content: 
      <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Events?</b>  
      <p>LOOT8's 'Events' feature enables creators to host and promote engaging experiences, from live streams to special meet-ups. Whether it's a virtual concert, a Q&A session, or an exclusive event, use this platform to create moments that strengthen community bonds and enhance fan engagement.</p>
      </p>
      ,
    },
    {
      target: '#walkthrough-collectibles',
      title: "/collectibles",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Collectibles?</b>  
      <p>LOOT8's 'Collectibles' are more than just digital assets; they're tradable pieces of memorabilia that embody your unique creative spirit. As fans buy, collect, and trade these items, you benefit from every transaction, creating a continuous stream of revenue while enhancing fan engagement.</p>
      </p>,
    },
    {
      target: '#walkthrough-phygital',
      title: "/phygitals",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>What is Phygital?</b>  
      <p>'Phygitals,' are an innovative fusion of physical items with digital elements.These special items merge physical memorabilia with digital enhancements, offering fans a unique ownership experience. As fans engage with and trade these phygitals, creators enjoy the benefits of recurring revenue from each transaction.</p>
      </p>,
    },
    {
      target: '#walkthrough-third-party',
      title: "/third-party-collections",
      disableBeacon: true,
      spotlightPadding: 1,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b>  What is Third Party Collection?</b>  
      <p>Upload an image to represent your ExPass. Various file sizes can be uploaded to provide the quality images across the platform</p>
      </p>,
    },
    {
      target: '#walkthrough-social-media',
      title: "/social-media",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 1,

      content: <p className='mt-1' style={{ maxWidth: "28rem" }}>
      <b> Why do we need social media manager?</b>  
      <p>Upload an image to represent your ExPass. Various file sizes can be uploaded to provide the quality images across the platform</p>
      </p>,
    },
  ]

  const handleTour = (data: any) => {    
    if (data?.status == "finished" || data?.status == "skipped") {
      setUserPanelWalkthroughRun(false)
    }else{
      if(data?.step?.title){
        history.push(data?.step?.title)
      }
    }
  }

  return (
    <div className="side-bar-main-component">
      <Walkthrough 
      steps={!isSuperAdmin ? onboardedEntitySteps : adminSteps}
      externalCondition={isUserNew && !isLoading}
      run={userPanelWalkthroughRun} 
      buttonContainer={{"justifyContent":'flex-end'}}
      handleTour={handleTour}
      contentClass={"walkthrough-main-content"}
      containerClass={`walkthrough-container`}
      />
      <div className="sidebar-container" style={!menuOpen ? { width: '5rem',alignItems: 'center' }: {}} >
        <div className="sidebar" >
          <div className="top-bar">

            <div className="logo-image" style={{ display: menuOpen ? 'flex' : 'none' }}>
              <img src={Loot8Logo} alt="loot8_logo" width={'100%'} height={'auto'} />
            </div>
            <div className="menu-icon" onClick={handleToggle} style={{ alignSelf: !menuOpen && 'center' }}>
              {
                menuOpen ? <MenuOpenRoundedIcon sx={{ color: defaultTheme.yellowColor }} /> : <TocRoundedIcon sx={{ color: defaultTheme.yellowColor }} />
              }
            </div>

          </div>
          <Divider orientation='horizontal' sx={{ width: '90%', alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.newFieldColor }} />

          <div className="routes">
            <NavLink id="walkthrough-dashboard"  to={NavigationRoutes.DASHBOARD} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={HomeIcon} name={"Dashboard"} menuOpen={menuOpen} /></NavLink>

            <NavLink id="walkthrough-expass" to={NavigationRoutes.EXPASSES} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PassportIcon} name={"ExPasses"} menuOpen={menuOpen}/></NavLink>
            {/* <NavLink id="walkthrough-featured-offers" to={NavigationRoutes.FEATUREDOFFER} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={FeaturedIcon} name={"Featured Offers"} menuOpen={menuOpen}/></NavLink> */}
            <NavLink id="walkthrough-announcement" to={NavigationRoutes.BILLBOARDS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={Billboard} name={"Announcements"} menuOpen={menuOpen} /></NavLink>
            
            {!isSuperAdmin ?
            <>
              <NavItem icon={FeaturedIcon} name={"Game Boards"} menuOpen={menuOpen} disabled={true} />
              <NavLink id="walkthrough-chat-room" to={NavigationRoutes.PREMIUM_CHAT} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PremiumChatIcon} name={"Premium Chat Room"} menuOpen={menuOpen}/></NavLink>
              <NavLink id="walkthrough-collectibles" to={NavigationRoutes.COLLECTIBLES} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={CollectionIcon} name={"Collectibles"} menuOpen={menuOpen} /></NavLink>
              <NavLink to={NavigationRoutes.PROFILE} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={SettingIcon} name={"Profile"} menuOpen={menuOpen} /></NavLink>
            <NavLink id="walkthrough-featured-offers" to={NavigationRoutes.FEATUREDOFFER} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={FeaturedIcon} name={"Featured Offers"} menuOpen={menuOpen}/></NavLink>
            <NavLink id="walkthrough-events" to={NavigationRoutes.EVENTS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={EventIcon} name={"Events"} menuOpen={menuOpen} /></NavLink>
            
            <NavLink id="walkthrough-phygital" to={NavigationRoutes.POAPS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PoapIcon} name={"Phygitals"} menuOpen={menuOpen} /></NavLink>
              
              {/* <NavLink id="walkthrough-phygital" to={NavigationRoutes.POAPS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PoapIcon} name={"Phygitals"} menuOpen={menuOpen} /></NavLink> */}
            
            </>
            :
            <>
                {/* <NavLink to={NavigationRoutes.EXPASSES} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PassportIcon} name={"ExPasses"} menuOpen={menuOpen}/></NavLink> */}

                <NavLink id="walkthrough-chat-room" to={NavigationRoutes.PREMIUM_CHAT} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PremiumChatIcon} name={"Premium Chat Room"} menuOpen={menuOpen}/></NavLink>
                {/* <NavLink
                style={{opacity:0.5}}
                onClick={(e)=>e.preventDefault()} id="walkthrough-private-chat" to={NavigationRoutes.PRIVATE_CHAT} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PrivateChatIcon} name={"Private Chat  (coming soon)"} menuOpen={menuOpen}/></NavLink>  */}

                <NavLink id="walkthrough-featured-offers" to={NavigationRoutes.FEATUREDOFFER} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={FeaturedIcon} name={"Featured Offers"} menuOpen={menuOpen}/></NavLink>

                {/* <NavLink to={NavigationRoutes.OFFERS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={OfferIcon} name={"Offers"} menuOpen={menuOpen} /></NavLink> */}

                <NavLink id="walkthrough-events" to={NavigationRoutes.EVENTS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={EventIcon} name={"Events"} menuOpen={menuOpen} /></NavLink>

                <NavLink id="walkthrough-collectibles" to={NavigationRoutes.COLLECTIBLES} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={CollectionIcon} name={"Collectibles"} menuOpen={menuOpen} /></NavLink>

                <NavLink id="walkthrough-phygital" to={NavigationRoutes.POAPS} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={PoapIcon} name={"Phygitals"} menuOpen={menuOpen} /></NavLink>

                <NavLink id="walkthrough-third-party" to={NavigationRoutes.THIRDPARTYCOLLECTION} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={ThirdPartyIcon} name={"Third Party Collections"} menuOpen={menuOpen} /></NavLink>

                <NavLink id="walkthrough-social-media" to={NavigationRoutes.SOCIALMEDIA} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={SocialIcon} name={"Social Media Manager"} menuOpen={menuOpen} /></NavLink>
                              
                {/* <NavLink to={"/settings"} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={SettingIcon} name={"Settings"} menuOpen={menuOpen} /></NavLink> */}
                <NavLink to={NavigationRoutes.PROFILE} activeClassName="nav-link-active" className={"nav-link"}><NavItem icon={SettingIcon} name={"Profile"} menuOpen={menuOpen} /></NavLink>

              </>
            }
            {allEntityDetails && allEntityDetails.length > 1 && <div className={"nav-link"} onClick={switchEntity}><NavItem icon={SwitchIcon} name={"Switch Entity"} menuOpen={menuOpen}/></div> }
            {userDetails?.isOnboarded && !isSuperAdmin && Number(inviteCodeSent) < Math.min(Number(maxInviteCodes), 5) && 
              <div className="invite-code-container" style={{ display: menuOpen ? 'flex': 'none' }}>
                <img src={LockIcon} className="lock-icon" alt="" />
                <div className="w-100 text-center">
                  <span className="subtext">Yay! You have access to Invite Codes!</span>
               
                  <CustomButton  
                    className={'mt-3 w-75 rounded-4'} 
                    onClick={() => setInviteCodeOpen(true)} color={defaultTheme.buttonDarkTextColor} 
                    backgroundColor={defaultTheme.yellowColor}  
                    disabled={false} 
                    type={'submit'}
                  >
                    Get Codes
                  </CustomButton>
                </div>
              </div>
            }

          </div>

        </div>
        <div className="logout" onClick={handleLogout}>
          <NavItem icon={LogoutIcon} name={"Logout"} menuOpen={menuOpen}/>
        </div>
      </div>
    </div>
  );
}