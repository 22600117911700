import React from 'react';
import { TextField, TextFieldProps, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';

export const PriceInput = ({label, price, priceChangeHandler, required, disabled, adornment, placeholder="Enter a Price" }) => {

  const handleChange = (event) => {
    const { value } = event.target;
    const pattern = /^-?\d*\.?\d{0,2}$/;
    if(value === "") {
      priceChangeHandler(value);
    }
    else if (pattern.test(value)) {
      priceChangeHandler(value);
    }

  };

  return (
    <div id='price-container' className='mt-2'>
       <div id='price-heading-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {label}
        </Typography>
        { required &&  
          <Typography marginBottom={-1} variant="caption" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
            *REQUIRED
          </Typography>
        }
      </div>
      <TextField
        id='price-field'
        value={price}
        onBlur={({target})=>{
          const validDecimalFormatPattern = /^-?\d+(\.\d{1,2})?$/;
          if(!validDecimalFormatPattern.test(target.value)){
            if(target.value!==""){
            const valid = target.value.split('.')
            if(valid && valid.length &&  valid[0]==""){
              priceChangeHandler(`0.${valid[1]}`)
            }else if(valid && valid.length && valid[0]!=""){
              priceChangeHandler(valid[0])
            }}
          }
        }}
        onChange={handleChange}
        placeholder={placeholder}
        error={false}
        FormHelperTextProps={{
          error: true
        }}
        required={required}
        disabled={disabled}
        inputProps={{
          maxLength: 18,
        }}
        type='text'
        InputProps={{
          startAdornment: adornment,
          sx: {
            backgroundColor: "#F0ECEC",
            
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        variant='outlined'
        fullWidth
        size='small'
        sx={{
          mt: 1
        }}
      />
    </div>
  );
}
