export const CollectionDescriptionTemplate = {
    'ExPasses': {
        heading: "Build Your ExPass, Build Your Brand",
        description: "Transform your engagement on LOOT8. An ExPass isn't just a profile; it's a personal stage for interaction and innovation.",
        advantageHeading: "Experience Passport Advantages:",
        advantages: [
            { title: "Deep Connection:", description: "Host exclusive events and content for your audience." },
            { title: "Direct Monetization:", description: "Unlock new revenue streams through fan engagement." },
            { title: "Creative Freedom: ", description: "Control and curate your digital presence your way." }
        ]
    },
    'Premium Chat Room': {
        heading: "Engage Deeper with Premium Chat Experiences",
        description: "Premium Chat Rooms on LOOT8 are designed to elevate your interaction with your most loyal fans. More than just a chat space, this is your opportunity to foster deeper connections and share unique experiences that resonate with your audience. It's your dedicated environment for impactful engagement.",
        advantageHeading: "Premium Chat Advantages:",
        advantages: [
            { title: "Exclusive Access:", description: "Provide a private forum for in-depth conversations and unique content sharing." },
            { title: "Monetization Opportunities:", description: "Leverage this platform to generate revenue through member-only access and specialized content." },
            { title: "Enhanced Interaction:", description: "Use advanced features to facilitate engaging discussions, Q&A sessions, and interactive events, enriching the user experience." }
        ]
    },
    'Featured Offer': {
        heading: "Exclusive Offers, Unmatched Value",
        description: "Featured Offers on LOOT8 are made to bring exclusive deals and unique opportunities to your fans fingertips. This feature enables a way to offer unique experiences that elevate your engagement and bring exceptional value to your followers. Make the most of LOOT8's dynamic platform by creating offers that align with your brand and resonate with your audience.",
        advantageHeading: "Feature Offers Benefits:",
        advantages: [
            { title: "Tailored Deals:", description: "Present carefully curated offers that reflect your brand’s unique identity and appeal directly to your audience's interests." },
            { title: "Revenue Boost:", description: "Capitalize on these exclusive offers to drive additional revenue streams, enhancing your overall monetization strategy." },
            { title: "Audience Engagement:", description: "Use Featured Offers to keep your followers excited and engaged, offering them special deals that incentivize interaction and loyalty." }
        ]
    },
    'Announcements': {
        heading: "Exclusive News Collectibles Beyond Just Updates",
        description: "Transform your updates into treasured experiences with Announcement Collectibles on LOOT8. Perfect for announcing upcoming events, special releases, or important milestones, these collectibles turn your news into unique, engaging digital items. Make every announcement a memorable moment that your followers can collect and cherish.",
        advantageHeading: "Key Benefits:",
        advantages: [
            { title: "Innovative Announcements:", description: "Elevate event news and important updates into collectible digital experiences." },
            { title: "Engage and Excite:", description: "Capture your audience's attention with unique, collectible versions of your upcoming events or special releases." },
            { title: "Value Beyond News:", description: "Turn every announcement into a digital asset, adding an extra layer of value to your communication." }
        ]
    },
    'Collectible': {
        heading: "Crafting Digital Narratives",
        description: "Explore the world of collectibles on LOOT8, where each item is a chapter in a digital story and every item is not just a piece of memorabilia but a living narrative. These collectibles range from widely accessible to exceptionally rare, each embedded with a private message group that allows for continuous interaction between the host and the community. This feature transforms every collectible into a dynamic and personal experience, deepening the connection between creators and their audience. Engage in meaningful exchanges, cultivate your community, and watch as each collectible becomes a unique and evolving digital artifact of shared experiences.",
        advantageHeading: "Key Benefits:",
        advantages: [
            { title: "Rich Storytelling:", description: "Each collectible is an opportunity to weave unique narratives and share them with your audience." },
            { title: "Community Engagement:", description: "Leverage the private message groups within each collectible to build stronger connections and engage fans in a meaningful way." },
            { title: "Versatile Interaction:", description: "The platform facilitates collecting, trading, and immersing in the distinctive journey each collectible offers, enriching the fan experience." }
        ]
    },
    'Event': {
        heading: "More Than Just Moments",
        description: "Events go beyond the moment, transforming into lasting digital experiences. Attendees can collect virtual tickets as unique, interactive memorabilia, each capturing the essence of the event. These collectibles become more than just tickets; they're gateways to relive and connect over shared experiences, long after the event has ended.",
        advantageHeading: "Highlights of LOOT8 Events:",
        advantages: [
            { title: "Collectible Tickets:", description: "Every event ticket transforms into a collectible, capturing the spirit of the experience." },
            { title: "Continued Engagement:", description: "Post-event, these collectibles serve as a gateway for ongoing interaction and community building among attendees." },
            { title: "Dynamic Souvenirs:", description: "These virtual tickets evolve into cherished digital keepsakes, holding stories, highlights, and exclusive content related to the event." }
        ]
    },
    "Phygital":{
        heading: "The Convergence of Physical and Digital Collectibles",
        description: "Phygitals on LOOT8 are when physical items are paired with digital collectibles, offering a unique two-fold ownership experience. This innovative approach bridges the gap between tangible memorabilia and digital assets, enriching the collector's experience with added interactivity and value.",
        advantageHeading: "Key Benefits:",
        advantages: [
            { title: "Dual Ownership:", description: "Secure a physical item and its digital counterpart, enhancing your collection's diversity." },
            { title: "Interactive Collecting:", description: "Unlock new levels of engagement with content that extends beyond the physical." },
            { title: "Unique Experiences:", description: "Access special content and experiences linked to your Phygital items." }
        ]
    }
} 
