import { IsLoggerEnabled } from "../constants";

// make sure to use this function in case you want to see the logs in console.
export const LogToConsole = (message?: any, ...optionalParams: any[]) => {
    if(IsLoggerEnabled()) {
        console.log(message, ...optionalParams);
    }
}

export const LogToConsoleError = (message?: any, ...optionalParams: any[]) => {
  console.error(message, ...optionalParams);
}