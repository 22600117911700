import { FormLabel, styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { MouseEventHandler, useState } from "react";
import { defaultTheme } from "../../styles";
import { CustomToast } from "../CustomToast/CustomToast";
import { IMAGE_REGEX, IMAGE_UPLOAD_LIMIT, VIDEO_REGEX } from "../../constants";
import { useAlert } from "../../hooks/Alert";


const ColorButton = styled(FormLabel)<ButtonProps>(({ theme }) => ({
  fontFamily: "Poppins-SemiBold",
  padding: '0.25rem',
  backgroundColor:  defaultTheme.buttonGreyColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: '6px',
  '&:hover': {
    backgroundColor: defaultTheme.buttonGreyColor+'e0',
    boxShadow: "0 0 11px rgba(33,33,33,.2)"
  },
  '&:disabled': {
    opacity: 1,
    cursor: 'not-allowed',
    backgroundColor:  defaultTheme.buttonGreyColor,
  },
  position: 'absolute',
  bottom: 0,
  right: 0
}));

interface FileUploadButtonProps {
  children?: any;
  className: string;
  required: boolean;
  disabled: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fileTypes: string;
  fileChangeHandler: (event: any)=> void;
}
export const FileUploadButton = ({className, required=true, disabled, onClick, fileTypes, fileChangeHandler }: FileUploadButtonProps) => {
  
 const { setShowToastError } = useAlert();

  const filehandler = (event) => {
    const { files } = event.target;
    if(files && files[0]) {
      if(files[0].size >= IMAGE_UPLOAD_LIMIT){
        setShowToastError({ open:true, message: "Max file size allowed is 30 MB"});
        return;
      }
      if(!IMAGE_REGEX.test(files[0].type.toString()) && !VIDEO_REGEX.test(files[0].type.toString())) {
          setShowToastError({ open:true, message: "Invalid file type"});
          return;
      }
      fileChangeHandler(event);
    }
  }

  return (
  <div id="img-upload-input-container">
    <ColorButton variant="contained" size="small"  className={className}>
    <EditTwoToneIcon sx={{ color: defaultTheme.darkColor }} />
    <input
      id="image-upload-input"
        accept={fileTypes}
        type="file"
        onChange={filehandler}
        required={required}
        style={{ opacity: 0, width: 1 }}
        disabled={disabled}
        onClick={(event) => { event.currentTarget.value = null }}
    />
    </ColorButton>
  </div>
  );
}