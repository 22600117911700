export enum UICustomOfferType {
    EMPTY = "",
    REGULAR = "Regular",
    FEATURED_OFFER = 'Featured',
    EVENT = 'Event',
    
} 

export enum OfferType {
    NOTANYOFFER,
    FEATURED,
    REGULAR,
}

export enum PassportType {
    REGULAR,
    SUBSCRIPTON,
}


export enum CATEGORIES {
        OTHER,
        SPORTS,
        MUSIC,
        CELEBRITIES,
        EDUCATORS,
        BUSINESS,
        GAMING,
        ARTIST,
        FOUNDATIONS
}

export enum PrivateChatType {
    PRIVATE_CHAT
}

