import { Divider, FormControl, Grid, RadioGroup, Typography } from "@mui/material";
import { AddressInput } from "../../../../components/TextInput/AddressInput/AddressInput";
import { SelectInput } from "../../../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { defaultTheme } from "../../../../styles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../../../hooks";
import { CustomRadioButton } from "../../../../components/RadioButton/CustomRadioButton";
import { getCollectionSocialAccess, toggleUserSocialAccess } from "../../../../slices/AppUserSlice";
import { LogToConsoleError } from "../../../../helpers/Logger";


export const AdministerUsers = ({ setShowLoader, title, lookupCollection }) => {

  const { wallet } = useWeb3Context();
  const dispatch = useAppDispatch();

  const allPassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails)?? [];
  const allCollectibleDetails = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails) ?? [];
  const allPoapsDetails = useAppSelector(state => state.Collectibles.AllPOAPCollectibleDetails) ?? [];
  const allExternalCollectionDetails = useAppSelector(state => state.Collectibles.AllExternalCollectibleDetails) ?? [];


  const [inputAddress, setInputAddress] = useState<string>("");
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [listValue, setListValue] = useState('Passports');

  const allCollections = [...allCollectibleDetails, ...allExternalCollectionDetails, ...allPoapsDetails];
  const menuData = listValue === "Passports" ? 
                    allPassportDetails 
                    : 
                    allCollections.filter((item, index, self) => index === self.findIndex((t) => (t.address?.toLowerCase() === item.address?.toLowerCase()))); 

  useEffect(() => {
    setSelectedCollections([]);
  },[listValue]);

  const collectionChangedHandler = (event) => {
    let values = event.target.value;

    setSelectedCollections([...values]);
  }

  
  const handleRadioValueChange = (event) => {
    setListValue((event.target).value);
  };

  const submitHandler = async (event) => {
    event?.preventDefault();
    setShowLoader({ open: true, message: "Muting User"});
    try {
      if( selectedCollections && selectedCollections.length > 0) {
        await Promise.all(selectedCollections.map( async (collectible) => {
          let collectibleDetail = null;
          let passport = null;
          if(listValue === "Passports") {
            collectibleDetail = allPassportDetails.find(obj => obj.address?.toLowerCase() === collectible?.toLowerCase());
          }
          else { 
            collectibleDetail = allCollections.find(obj => obj.address?.toLowerCase() === collectible?.toLowerCase());
            if(allExternalCollectionDetails?.findIndex(obj => obj.address?.toLowerCase() === collectible?.toLowerCase()) > -1) {
              passport = allExternalCollectionDetails?.find(obj => obj.address?.toLowerCase() === collectible?.toLowerCase())?.linkPassports?.at(0);
            }
          }
          await dispatch(toggleUserSocialAccess({ wallet: wallet, userAccount: inputAddress, collectionAddress: collectible, chainId: collectibleDetail?.chain, status: true, passport: passport }));
         
          // if selected collection has lookup collection update the lookup details
          if(lookupCollection !== "" && lookupCollection?.toLowerCase() === collectible?.toLowerCase()) {
            await dispatch(getCollectionSocialAccess({ wallet: wallet, collectionAddress: collectible, chainId: collectibleDetail?.chain, passport: passport }))
          }
        }));

      }
      setInputAddress("");
      setSelectedCollections([]);
    } catch (error) {
      LogToConsoleError("Error while muting", error);
    }

    setShowLoader({ open: false, message: ""});
    return;
  }

  return (
    <form onSubmit={submitHandler} >
      <Grid container spacing={1} columnGap={2} marginY={'2rem'} >
      {/* First Row */}
      <Grid columnGap={4} item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} >
        <Typography variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {title}
        </Typography>
        <Divider orientation='horizontal' variant="fullWidth" sx={{ flex: 1, alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />
      </Grid>

      <Grid item xs={12} >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioValueChange}
            value={listValue}
          >
            <CustomRadioButton label={"Passports"} value={"Passports"} />
            <CustomRadioButton label={"Collectibles"} value={"Collectibles"} />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/* Second Row */}
      <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
        <AddressInput label={"User Wallet Address"} value={inputAddress} setTextValue={setInputAddress} required={true} disabled={false}/>
      </Grid>


      <Grid item xs={12} sm={10} md={4} lg={3} xl={3}>
        <SelectInput label={listValue} menuData={menuData} handleOptionChange={collectionChangedHandler} selectedOptions={selectedCollections} disabled={false}/>
      </Grid>
      
      <Grid item xs={3} sm={3} md={3} xl={1} lg={1}  alignSelf={'flex-end'} justifyContent={"center"} display={'flex'} alignItems={'flex-start'} marginTop={'1.5rem'} >
        <CustomButton children={"Mute"} className={"w-100 "} onClick={() => {}} color={defaultTheme.buttonDarkTextColor} backgroundColor={defaultTheme.yellowColor} disabled={false} type={"submit"} />
      </Grid>
    </Grid>
  </form>
  );
}