export const Template1 = `<!DOCTYPE html>
<html lang="en">
<head>
  <title>Loot8 For You</title>

  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;600;700&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <script
    src="https://loot8-cdn-dev.loot8.io/ipns/k51qzi5uqu5dlqhlias6mtzusn62o2wnvmuuooi2248zna4mhcbz5ymvsad6sk/script.js"
    type="text/javascript">
  </script>

  <style>
    #body {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      color: #fff;
        object-fit: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 100%;
      text-align: center;
      position: relative;
    }
    .streamyard {
      width:100%;
      height:55vh;
    aspect-ratio:1.5/3.5;
    }
    #body-container {
      background: black;
      width: 100%;
      height: max(100vh, 100%) ;
      position: absolute;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

     #name{
      font-size: clamp(1rem, 4vmin, 1.5rem);
      font-weight: 400;
      word-wrap: break-word;
     } 
      
    #title {
      font-size: clamp(10px, 4vmin, 2rem);
        margin-top:2rem;
      font-weight: 400px;
      word-wrap: break-word;
    }
    #subtext {
      font-size: clamp(10px, 4vmin, 1.5rem);
      font-weight: 400px;
      word-wrap: break-word;

    }
    #timestamp {
      font-size: clamp(10px, 3.5vmin, 1.3rem);
      background-color: #F4B540;
      font-weight: 600;

      padding: 0.5rem;
      color: #252546;
      border-radius: 6px;
    }

    .live{
    font-size:4rem;
    color:#EE6161;
    font-weight:600;
    letter-spacing:2px
    }

     .streamYardLink{
     font-size: 4rem;
    font-weight:700;
     color: #000;
     text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, 0.5px -0.5px 0.5px #fff, -0.5px 0.5px 0.5px #fff;
    }

    @media only screen and (max-width: 600px){
      .streamyard {
        width:100%;
      height:50vh;
    aspect-ratio:1.3/2.5;
      }

      .rec{
        font-size:1.5rem !important;
        font-weight:600 !important;
        padding:10px !important;
        border-radius:10px !important;

      }

      .circle{
      font-size:1rem !important;
      margin-right:10px;
      

      }
      
      .live{
      font-size:1.2rem;
      }
    
      .streamYardLink{
      font-size: 2rem;
      }
}
       .rec{
     font-size: 4rem;
    font-weight:700;
           align-items:center;
           display:flex
           ;
     color:#fff;
               padding:1px 10px;
           border-radius:20px;
    background:#EE6161
    }

      .circle{
              font-size:1.6rem;
          margin-right:10px;
      }
  
  </style>
</head>

<body id="body">
    <div id="body-container" ></div>

    <div class="container position-relative  p-4">
      <div
          
          class="d-flex flex-column justify-content-center align-items-center gap-2 text-content ">
        <h4 id="name" >Announcement Header Name Goes Here</h4>
        <h4 id="title" >Title goes here</h4>
        <h5 id="subtext">Subtitle Goes here</h5>
        <h5 id="timestamp">Date & Time goes here</h5>
      </div>
      <div class="bill-board-content d-flex flex-column align-items-center mt-4">

            <div class="streamyard">
          <iframe id="streamyard"
            src="https://streamyard.com/watch/iSJxmC4e68Qc?embed=true" 
            width="100%" 
            height="100%"
            frameborder="0" 
            allowfullscreen
          >
          </iframe>
        </div>

        <div class="mt-5">
            <h3 style="" class="live">Live</h3>
            <h3 style="" class="streamYardLink">STREAM YARD</h3>
            <div class="d-flex 
            align-items-center flex-row justify-content-center ">
              <h3 style="" class="rec">
                  
            <i style="" class="fas fa-circle circle"></i>   
                 REC</h3>
        <div>
       
        
      </div>
    </div>

</body>
</html>`