import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAddCollaboratorsMessage, GetAddGateKeeperMessage, GetCreateTicketCollection, GetRemoveCollaboratorsMessage, GetRemoveEventMessage, GetRemoveGateKeeperMessage, GetTicketCreatedLogData } from "../helpers/MsgHelper";
import { NetworkId, ZERO_ADDRESS, addresses, getAnynetStaticProvider,  } from "../constants";
import { IMessageMetaData } from "./interfaces";
import { LogToConsole } from "../helpers/Logger";
import { SendMetaTX } from "./AppSlice";
import { IEventDetails } from "../interfaces/IEvent.interface";



export const CreateTicket = createAsyncThunk("Tickets/CreateTicket", async ({ networkID, ticketData,eventData, address, wallet, chainID = networkID,ipfsDataUri }: { networkID, ticketData: IEventDetails | any,eventData, address, wallet, chainID?: NetworkId | "",ipfsDataUri }, { dispatch }): Promise<any> => {
    let name = ticketData?.name?.trim() ?? "";
    let symbol = ticketData?.symbol !== "" ? ticketData?.symbol : name;
    let contractURI = ipfsDataUri;

    // const _verifierAddress = addresses[chainID].Verifier;
    // const _userContract = addresses[chainID].User;
    const CollectionManager = addresses[networkID].CollectionManager;
    const CollectionHelper = addresses[networkID].CollectionHelper;
    const LayerZeroEndpoint = addresses[networkID].LayerZeroEndPoint;
    //_eventAddress, _ticketName,_symbol,_contractURI,_transferable,_manager,_helper,_layerZeroAddress
    const data = GetCreateTicketCollection(
        eventData?.address,eventData?.name,symbol,contractURI,true,CollectionManager,CollectionHelper,LayerZeroEndpoint);

    

    if (address) {
        let msg: IMessageMetaData = {
            to: addresses[chainID].TicketFactory,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('ticketCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            const createdCollection = GetTicketCreatedLogData(res.payload?.eventLogs);
            
            if (createdCollection === ZERO_ADDRESS) {
                LogToConsole('Collection Creation New: transaction failed...')
            }
            return createdCollection;
        }
    }
    return ZERO_ADDRESS;
});



export const AddCollaborators = createAsyncThunk("Events/AddCollaborators", async ({ networkID,contractAddress, addresses, address, wallet, chainID = networkID }: { networkID,contractAddress:string, addresses: string[], address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetAddCollaboratorsMessage(addresses);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})

export const RemoveCollaborator = createAsyncThunk("Events/RemoveCollaborator", async ({ networkID,contractAddress, collabAddress, address, wallet, chainID = networkID }: { networkID,contractAddress:string, collabAddress: string, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetRemoveCollaboratorsMessage(collabAddress,false);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})


export const AddGatekeepers = createAsyncThunk("Events/AddGatekeepers", async ({ networkID,contractAddress, addresses, address, wallet, chainID = networkID }: { networkID,contractAddress:string, addresses: string[], address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetAddGateKeeperMessage(addresses);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})

export const RemoveGateKeepers= createAsyncThunk("Events/RemoveGateKeepers", async ({ networkID,contractAddress, getKeeperAddress, address, wallet, chainID = networkID }: { networkID,contractAddress:string, getKeeperAddress: string, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetRemoveGateKeeperMessage(getKeeperAddress,false);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})


export const RemoveEvent = createAsyncThunk("Events/RemoveEvent",async ({ networkID,eventAddress,EntityAddress, address, wallet, chainID = networkID }: { networkID,eventAddress, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {

    const data = GetRemoveEventMessage(eventAddress,EntityAddress);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: addresses[chainID].EventRegistry,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('removedEventMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})
