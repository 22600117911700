import imageCompression, { Options } from "browser-image-compression";
// import gifFrames from "gif-frames"
// import GIF from "gif.js";
// import _ from "lodash";
export const compressImage = async (imageFile: any, width = 400, iteration = 5) : Promise<File> => {

  const options: Options = {
    maxSizeMB: 2,
    maxWidthOrHeight: width,
    useWebWorker: true,
    preserveExif: true,
    alwaysKeepResolution: true,
    maxIteration: iteration,
    fileType: "image/jpeg"
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);

    return compressedFile;
  } catch (error) {
    console.log(error);
  }
  return null;

}


// export const compressGifImage =async (gifFile: File) => {
  
//   const fileBuffer = await gifFile.arrayBuffer();
//   const height= 200, width = 200
//   // await getDimensions(gifFile);

//   const frames = await getFrames(gifFile);
//   console.log(frames);

//   const resizedFrames = await Promise.all(
//     frames.map(({ image }) => resizeFrame(image, width, height))
//   ).then(images =>
//     images.map((image, index) => ({
//       image,
//       delay: frames[index].delay * 10
//     }))
//   );
//   console.log(resizedFrames)
//   const blob: any = await renderGif(resizedFrames);
//   console.log(blob, URL.createObjectURL(blob));
//   return blob

// }

// function getDimensions (image): Promise<any>  {
//   return new Promise((resolve, reject) => {
//     var url = URL.createObjectURL(image);
//     var img = new Image();

//     img.onload = function() {
//       resolve({ height: img.height, width: img.width });
//         URL.revokeObjectURL(img.src);
//     };

//     img.src = url;
//   });
// }

// function fixEdgeSmoothing(image ) {
//   const  transparentThreshold = 127;

//   const canvas = document.createElement("canvas");
//   canvas.width = image.width;
//   canvas.height = image.height;
//   const ctx = canvas.getContext("2d", { willReadFrequently: true });


//     const data = image
//       .getContext("2d", { willReadFrequently: true })
//       .getImageData(0, 0, canvas.width, canvas.height)
//       .data.map((data, index, dataArr) => {
//         if (index % 4 === 3) {
//           if (_.some(dataArr.slice(index - 3, index), data => data !== 0)) {
//             return data <= transparentThreshold ? 0 : 255;
//           }
//         }

//         return data;
//       });

//     ctx.putImageData(new ImageData(data, canvas.width, canvas.height), 0, 0);
  

//   return canvas;
// }

// async function renderGif(frames) {
//   // const workerScript = await loadWorkerScript();

//   var workerStr =  "../../dist/"; // worker code as a string.
//   const res = await fetch(workerStr);
  
//   console.log(await res.text());
//   var workerBlob = new Blob([workerStr], {
//       type: 'application/javascript'
//   });
//   console.log(workerBlob);

//   const gif = new GIF({
//     workers: 2,
//     quality: 1,
//     workerScript: URL.createObjectURL(  workerBlob),
//   });

//   frames.forEach(({ image, delay }) =>
//     gif.addFrame(image, {
//       delay: delay
//     })
//   );

//   return new Promise((resolve, reject) => {
//     gif.on("finished", resolve);

//     try {
//       gif.render();
//     } catch (e) {
//       reject(e);
//     }
//   });
// }

// async function getFrames(file) {
//   const url = URL.createObjectURL(file);
//   const frames = await gifFrames({
//     url,
//     frames: "all",
//     outputType: "canvas",
//     quality: 50
//   });

//   return frames.map(frame => ({
//     image: frame.getImage(),
//     delay: frame.frameInfo.delay
//   }));
// }

// function resizeFrame(frame, width, height) {
  
//   // if (
//   //   typeof window.createImageBitmap !== "undefined" &&
//   //   typeof window.chrome !== "undefined"
//   // ) {
//   //   return imageBitmapResize(frame, width, height);
//   // }

//   return canvasResize(frame, width, height);
// }

// async function canvasResize(src, width, height) {
//   const canvas = newCanvas(width, height);

//   const ctx = canvas.getContext("2d", { willReadFrequently: true });
//   ctx.drawImage(src, 0, 0, src.width, src.height, 0, 0, width, height);

//   return Promise.resolve(canvas);
// }

// function newCanvas(width, height) {

//   const canvas = document.createElement("canvas");
//   canvas.width = width;
//   canvas.height = height;

//   return canvas;
// }
