import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";

import './index.scss';
import App from './App';
import { Web3ContextProvider } from './hooks/web3Context';
import { Provider } from "react-redux";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
 // <React.StrictMode>
     
  // </React.StrictMode>
root.render(
 
  <Web3ContextProvider>
  <Provider store={store}>
    <BrowserRouter basename={"/#"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </BrowserRouter>      
  </Provider>
</Web3ContextProvider>  
);
