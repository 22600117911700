export enum CollectionType {
        ANY,
           PASSPORT,
           OFFER,
           COLLECTION,
           BADGE,
           TICKET,
           PREMIUM_ACCESS,
           GAMEBOARD,
           PRIVATE_CHAT
           
   }