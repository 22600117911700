export enum CatalogTypes {
    PASSPORT = "Passport",
    EVENT = 'Event',
    COLLECTIBLES = 'Collectible',
    FEATUREDOFFERS = 'Featured Offer',
    OFFERS = 'Offer',
    EXTERNAL = 'Phygital',
    PREMIUM_CHAT = 'Premium Chat Room',
    EXPASSES = 'ExPasses',
    ANNOUNCEMENT = 'Announcements',
    PRIVATE_CHAT="Private Chat"
} 