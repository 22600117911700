import { Box, BoxProps, Typography, styled } from "@mui/material";
import { FileUploadButton } from "../../../../components/Button/FileUploadButton";

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '6px',
  overflow: 'hidden'
}))

export const ImageUploadCard = ({ background, backgroundImageChangeHandler, fileTypes, isVideo, isDisabled, label = "" }) => {

  return (
    <div className='mt-2 w-100'>
      <Typography variant="body2" color={'white'} fontFamily={'Poppins-Medium'} marginBottom={1} >
        {label}
      </Typography>
      <CustomBox>

        {background === "" ?
          <Box p={'1rem'} paddingRight={'2rem'}>
            <Typography fontFamily={"Poppins"} fontSize={'1.5rem'} color={"white"} textAlign={'center'}>
              {fileTypes.includes("video") ? "No Image/video selected" : "No Image selected"}
            </Typography>

          </Box>
          :
          !isVideo ?
            <img src={background}
              id="img-src-input"
              className="background-image"
              alt=""
              loading="lazy"
              height={'90%'}
              width={'100%'}
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                maxHeight: '20rem'
              }}
            />
            :
            <video id="video-src-input" className="previewHeight"
              controls
              height={'100%'}
              width={'100%'}
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                maxHeight: '20rem'
              }}>
              <source src={background} type="video/mp4" />
            </video>
        }
        {
          !isDisabled &&
          <FileUploadButton
            className={""}
            disabled={isDisabled}
            required={background === "" ? true : false}
            fileTypes={fileTypes}
            fileChangeHandler={backgroundImageChangeHandler}
          />
        }


      </CustomBox>
    </div>
  );
}