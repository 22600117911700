import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { defaultTheme } from '../../styles';

export const CustomCheckbox = ({ label, checked, handleCheckChange , disabled, customColor = defaultTheme.buttonRedColor }) => {

const PinkCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: customColor,
  },
  '&.Mui-disabled': {
    color: defaultTheme.buttonRedColor+'a0',
    cursor: 'not-allowed',
    pointerEvents: 'all'
    
  },
  // '&:not(.Mui-checked)': {
  //   color: defaultTheme.buttonRedColor+'a0',
  // },
  color: defaultTheme.buttonRedColor,
}));

  return (
    <FormControlLabel id='checkbox-controlled-form' className='mt-2'
      control={<PinkCheckbox id='checkbox-controlled' checked={checked} onChange={handleCheckChange} />}
      label={label}
      sx={{
        color: defaultTheme.buttonDarkTextColor,
        '& .MuiFormControlLabel-label.Mui-disabled':{
            color: defaultTheme.buttonDarkTextColor
        },
        '& .MuiFormControlLabel-label':{
          fontFamily: 'Poppins-Medium',
          fontSize: '0.875rem',
        },

      }}
      disabled={disabled}      
    />
  );
}

