import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, Typography, outlinedInputClasses } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { defaultTheme } from '../../../styles';

export const SearchTextInput = ({ searchText , setSearchText, placeholder }) => {

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleClearSearchText = () => {
    setSearchText('');
  };

  return (
    <div>
      <TextField
        value={searchText}
        onChange={handleChange}
        placeholder={placeholder}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchText && (
                <IconButton size='small' onClick={handleClearSearchText}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        fullWidth
        variant="outlined"
        size="small"
      />
    </div>
  );
}

