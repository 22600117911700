import styled from "styled-components";
import { defaultTheme } from "../../../../styles";

export const Container = styled.div`
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  min-height: 3rem; 
`;

export const SignUpContainer = styled.div`
  font-family: "Poppins-Bold";
  background-color: ${defaultTheme.yellowColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  ${(props) =>
    props.signingIn !== true
      ? `
  transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	`
      : null}
`;

export const SignInContainer = styled.div`
  font-family: "Poppins-Bold";
  background-color: ${defaultTheme.yellowColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(100%);` : null}
`;

export const Title = styled.h1`
  margin: 0;
  font-size: clamp(0.9rem, 1vw, 1.2rem);
  padding: 0.5rem;
  text-align: center;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 9;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(-100%);` : null}
`;

export const Overlay = styled.div`
  background-color: #c0136f;
  background: -webkit-linear-gradient(to right, #c0136f, #c0136f);
  background: linear-gradient(to right, #c0136f, #c0136f);
  color: #ffffff;
  position: relative;
  left: -100%;
  width: 200%;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(50%);` : null}
`;  

export const OverlayPanel = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  width: 50%;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  font-family: "Poppins-Medium";
`;

export const LeftOverlayPanel = styled(OverlayPanel)`
  transform: translateX(-20%);
  ${(props) => (props.signingIn !== true ? `transform: translateX(0);` : null)}
`;

export const RightOverlayPanel = styled(OverlayPanel)`
  right: 0;
  transform: translateX(0);
  ${(props) =>
    props.signingIn !== true ? `transform: translateX(20%);` : null}
`;
