import { Box, Grid, Typography } from "@mui/material"
import { BasicTextInput } from "../../../../components/TextInput/BasicTextInput/BasicTextInput"
import { NameTextInput } from "../../../../components/TextInput/NameTextInput/NameTextInput"
import { ImageCard } from "../../components/FileUploadCard/ImageCard"
import { CatalogTypes } from "../../../../enums/catalog.enum"
import { useState } from "react"
import { IMAGE_REGEX } from "../../../../constants"
import { CustomCheckbox } from "../../../../components/CheckBox/CheckBox"
import { CountInput } from "../../../../components/TextInput/NumberInput/CountInput"
import { CustomButton } from "../../../../components/Button/CustomButton"
import { defaultTheme } from "../../../../styles"
import { IEventDetails } from "../../../../interfaces/IEvent.interface"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../../store"
import { CreateTicket } from "../../../../slices/TicketSlice"
import { getIpfsCID } from "../../../../helpers/ipfs"
import { fileIpfsUpload, jsonIpfsUpload } from "../../../../services/ipfsUpload.service"
import { PassportType } from "../../../../enums/offers.enum"
import { PriceInput } from "../../../../components/TextInput/NumberInput/PriceInput"
import { AddCollection, linkCollection } from "../../../../slices/PassportSlice"
import { DEFAULTEVENT } from "../../../../utils/defaultEvent"
import { ConfirmationModal } from "./Confirmation"

type Props = {
    setShowTicketCreation: (item: boolean) => void
    eventData:IEventDetails;
    networkId:any
    loadEvents:()=>void
    provider:any;
    entityAddress:any;
    address:any;
    setLoading:any
    wallet:any
}

export const EventTickets = (props: Props) => {

    const {networkId,wallet,address,provider,entityAddress} = props;

    const [formInput, setFormInput] = useState(DEFAULTEVENT);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [limitedCountChecked, setLimitedCountChecked] = useState<boolean>(false);
    const [confirmTicket,setConfirmTicket] = useState<boolean>(false);
    const [confirmTicketAction,setConfirmTicketAction] =  useState({onClick:()=>{}});
    const dispatch = useDispatch<AppDispatch>();


    const ipfsDataGeneration = async () => {
        const originalImage: any = formInput?.imageProps?.image ? formInput?.imageProps?.image : ""
        const originalImagePath = originalImage?.size ? "" : originalImage;
        let collectionOriginalImage = { uri: getIpfsCID(originalImagePath), imageSize: formInput.imageProps?.imageSize ?? 0 }
    

          const ImageFile: any = formInput.imageProps?.image;
    
          if (formInput.imageProps?.image !== "" &&  ImageFile?.type) {
            // const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);
            collectionOriginalImage.uri = await fileIpfsUpload(formInput.imageProps?.image, "", wallet);
            collectionOriginalImage.imageSize = formInput.imageProps?.imageSize;
          }
        
        props?.setLoading({ open: true, message: "Updating  details" });
    
        let collectionObj = 
          {
            "name": props?.eventData?.name?.trim(),
            "description": props?.eventData?.details?.trim(),
            "image": collectionOriginalImage?.uri,
            "subtitle": "",
            "imageSize": formInput?.imageProps?.imageSize,
            "thumbnailImage": "",
            "thumbnailImageSize": "",
            "optimizedImage": "",
            "optimizedImageSize": ""
    
          }
        const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet);
        return ipfsDataURI;
    
      }

    const handleSubmit = async (event) => {
        event?.preventDefault();
        props?.setLoading({ open: true, message: `Creating Ticket`});

        const ipfsDataUri = await ipfsDataGeneration();
        const createdTicketCollection = await dispatch(CreateTicket({networkID:networkId,address,ticketData:formInput,wallet,eventData:props?.eventData,ipfsDataUri}));
        let temp = {...formInput};
        temp.area = props?.eventData?.area
        await dispatch(AddCollection({networkID:networkId,address,collectibleData:temp,wallet,provider,isPremium:false,category:0,EntityAddress:entityAddress,collectibleAddress:createdTicketCollection?.payload}));
        props?.setLoading({ open: true, message: `Linking Ticket to Event`});
        await dispatch(linkCollection({ networkID: networkId, provider: provider, address, collectibleAddressA: props?.eventData?.address, linkingAddresses: [createdTicketCollection?.payload], wallet }));
        setConfirmTicket(false)
        // props?.setShowTicketCreation(false)
        props?.loadEvents()
    }


    

    const priceChangeHandler = (price) => {
        const formData = { ...formInput };
        formData.price = price;
        setFormInput({ ...formInput, ...formData });
    
      }

    const limitedCountChangeHandler = (value) => {
        setFormInput({ ...formInput, maxMint: Number(value), maxPurchase: Number(value) });
    }


    const clearImage = () => {
        setBackgroundImage("")
    }

    const handleImage = (event: any) => {
        let { files } = event.target;
        let gifThumbnail = null
        if (!files) {
            files = event.dataTransfer.files;
        }
        if (files && files[0]) {
            setBackgroundImage(URL.createObjectURL(files[0]));
            setFormInput({
                ...formInput, imageProps: { ...formInput?.imageProps,image: files[0], imageSize: files[0].size}})
              }
        }

    const handleConfirmation = (event) => {
        setConfirmTicket(true)
        setConfirmTicketAction({onClick:()=>handleSubmit(event)})
    }


    return (
        confirmTicket ? 
             
        <ConfirmationModal
        onClickCancel={()=>setConfirmTicket(false)}
        eventData={props?.eventData} ticketData={formInput} buttonText="Confirm" onClick={confirmTicketAction?.onClick}  title="Create/Generate Ticket" /> :
        <form onSubmit={formInput?.address=="" ? handleConfirmation  : handleSubmit}>
            
            <Grid container lg={12} xl={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} >
                <Typography fontFamily={'Poppins-Bold'} color={"#000"} fontSize={'1.7rem'} >Create/Generate Ticket</Typography>
                <Grid container xl={12} lg={12} display={'flex'} columnGap={1} justifyContent={'center'}>
                    <Grid lg={4} xl={4}  >
                        <NameTextInput
                            label={`Event Name ( pre-selected not editable)`}
                            name={props?.eventData?.name}
                            nameChangeHandler={null}
                            required={false} disabled={true} />
                    </Grid>
                    <Grid lg={4} xl={4} sm={12} xs={12} >
                    <PriceInput
                          label={'Price'}
                          placeholder={"Fees"}
                          price={formInput.price}
                          priceChangeHandler={priceChangeHandler}
                          required={true}
                          disabled={false}
                          adornment={null} />
                    </Grid>
                </Grid>
                <Grid container xl={12} lg={12} display={'flex'} columnGap={1} justifyContent={'center'}>
                    <Grid lg={4} xl={4}  >
                        <ImageCard label={'Upload Image'}
                            clear={clearImage}
                            isPassport
                            catalogType={CatalogTypes.EVENT}
                            subHeading='Max file size - 30 MB. This image/gif will be visible in the details screen in the user app.'
                            imageType={'original'}
                            background={backgroundImage}
                            backgroundImageChangeHandler={handleImage}
                            fileTypes={"image/jpg, image/jpeg, image/png"}
                            isVideo={false}
                            isDisabled={false} />
                    </Grid>
                    <Grid lg={4} xl={4} sm={12} xs={12}>
                        <CustomCheckbox label={`Ticket Count `} checked={limitedCountChecked} handleCheckChange={() => setLimitedCountChecked(!limitedCountChecked)} disabled={false} />
                        {limitedCountChecked && <CountInput label="Ticket Count" count={formInput.maxMint} countChangeHandler={limitedCountChangeHandler} required={limitedCountChecked} disabled={false} />}
                    </Grid>
                </Grid>
                <Grid container rowGap={1} order={{ xs: 3, sm: 2 }}xl={8} lg={8} sm={12} xs={12} display={'flex'} columnGap={1} justifyContent={'flex-end'} mt={1}>
                    <Grid lg={3} xl={3} sm={12} xs={12} order={{ xs: 2, sm: 1 }} >
                        <CustomButton
                            className={"col-12 col-sm-12 col-lg-12"}
                            onClick={() => props?.setShowTicketCreation(false)}
                            color={defaultTheme.buttonLightTextColor}
                            backgroundColor={defaultTheme.buttonRedColor}
                            disabled={false}
                            type={"button"}
                        >
                            CANCEL
                        </CustomButton>
                    </Grid>
                    <Grid lg={3} xl={3} sm={12} xs={12}  order={{ xs: 1, sm: 2 }}>
                        <CustomButton
                            className={"col-12 col-sm-12 col-lg-12"}
                            color={defaultTheme.buttonDarkTextColor}
                            backgroundColor={defaultTheme.yellowColor}
                            disabled={false}
                            type={"submit"}
                        >
                            GENERATE TICKET
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>



        </form>
    )
}   