import { styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { MouseEventHandler } from "react";

interface CustomButtonProps {
  children: any;
  className: string;
  color: string;
  backgroundColor: string;
  disabled: boolean;
  type: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CustomButton = ({children, className, color, backgroundColor, onClick, disabled=false, type }: CustomButtonProps) => {

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: color,
    fontFamily: "Poppins-SemiBold",
    paddingBlock: '0.5rem',
    backgroundColor:  backgroundColor,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: backgroundColor+'e0',
      boxShadow: "0 0 11px rgba(33,33,33,.2)"
    },
    '&:disabled': {
      opacity: 1,
      cursor: 'not-allowed',
      backgroundColor:  backgroundColor+'50',
      color: color+'d0'
    }
  }));
  return (
  <>
    <ColorButton id="custom-button-color" type={type} variant="contained"   className={className} onClick={onClick} disabled={disabled} >{children}</ColorButton>
  </>
  );
}