import { Box, Paper, Typography, styled, } from "@mui/material";
import "./PassportCard.scss"
import { defaultTheme } from "../../../../styles";
import { ICollectibleDetail } from "../../../../interfaces/ICollection.interface";
import { useState } from "react";
import { Circle } from "@mui/icons-material";
import { CollectionType } from "../../../../enums/collection.enum";
import free from '../../../../assets/svg/freeProfile.svg';

export const BoxContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // maxHeight: '13rem',
  // minHeight: '10rem',
  // width: '100%',
  // aspectRatio: '2/1.1',

  // minWidth: '28rem',

  borderRadius: 6,
  backgroundColor: defaultTheme.darkColor,
  // overflow: "hidden",
  // [theme.breakpoints.down('lg')]: {
  // maxHeight: '12rem',
  width: '20rem',
  minWidth: '20rem',
  height: '12rem',
  [theme.breakpoints.down('xs')]: {
    margin:'0rem 1rem',
    width: '17rem',
    minWidth: '17rem',
    height: '12rem',
   },
  [theme.breakpoints.down('lg')]: {
    width: '20rem',
    minWidth: '20rem',
    height: '12rem',
  },
  [theme.breakpoints.down('md')]: {
    width: '20rem',
    minWidth: '20rem',
    height: '12rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '16rem',
    minWidth: '16rem',
    margin:'0rem 1rem',

    height: '12rem',
  },
  
  // },
  position: 'relative',
  cursor: 'pointer'
}));

const BoxInnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'right',
  padding: '0.8rem 0.5rem',
  background: defaultTheme.lightColor,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  position: 'absolute',
  right: 0,

}));

interface Props {
  data: ICollectibleDetail;
  index: number;
  type: string,
  onPress: (data: ICollectibleDetail) => void
  isSelected?: boolean,
  isStreamYard?: any,
}

export const PassportCard = ({ isStreamYard, data, index, type, onPress, isSelected }: Props) => {

  return (
    <Box className="passport-card m-3"  >
      <BoxContainer 
      sx={{
        ...(String(isStreamYard) == '0'  && isSelected  ? { border: "3px dashed #F4B540" } : {})
      }}
      elevation={6} onClick={() => { onPress(data) }} >
        {String(isStreamYard) == '0' ?
          <Box  display={'flex'} textAlign={'center'} flexDirection={'column'} justifyContent={'center'}>
            <Typography sx={{
              color: "#EE6161",
              fontSize: '1.5rem',
              fontFamily: 'Poppins-Bold',
              textShadow: '0 0 4px #000000, 0px 0px 4px #000000',
            }}> Live </Typography>
            <Typography
              fontFamily={'Poppins-Bold'}
              sx={{
                fontSize: '2.2em',
                color: '#000', // Black text color
                textShadow: '0.5px 0.5px 0.5px #fff, -0.5px -0.5px 1px #fff, 0.5px -0.5px 0.5px #fff, -0.5px 0.5px 0.5px #fff',

              }}>
              STREAM YARD
            </Typography>
            <Typography
              fontFamily={'Poppins-Bold'}
              sx={{
                fontSize: '2em',
                marginTop: '2px',
                alignSelf: 'center',
                padding: '1px 7px',
                borderRadius: 1,
                background: "#EE6161",
                color: '#fff', // Black text color
              }}>
              <Circle sx={{ fontSize: '1rem' }} /> REC
            </Typography>
          </Box> : 
          <>
          <img src={data?.imageProps?.image} className={`passport-image ${isSelected ? 'selected' : ''}`} alt="" loading="eager" />
          {data?.collectionType==CollectionType.PASSPORT && !data?.isPremium && 
          <img src={free} style={{height:'65px',width:"65px",position:'absolute',right:-8,top:-22}}/>
          }
          </>
          }
        <BoxInnerContainer width={'50%'} >
          <Typography variant="h6" color={'#4E4E93'} sx={{
            width: '100%',
            fontFamily: 'Poppins-Bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}>
            {data.name}
          </Typography>
          <Typography color={'#EB3797'} sx={{
            fontFamily: 'Poppins-Medium',
            fontSize: '0.75rem',
            paddingTop: '0.5rem'
          }}>
            {type + " #" + data.index}
          </Typography>
        </BoxInnerContainer>
      </BoxContainer>
      {
        isSelected &&
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <div style={{ clipPath: 'polygon(50% 100%, 0 0, 100% 0)', background: defaultTheme.yellowColor, height: '1em', width: '1.5rem' }} />
        </Box>
      }


    </Box>
  );
}