import React from 'react';
import { TextField, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';

export const DescriptionTextInput = (
{label = "Description", 
description, 
id="",
onClickField=null,
descriptionChangeHandler, 
required, disabled, rows = 4, placeholder="Enter Description", maxLength = null,edit=false }) => {

  const handleChange = (event) => {
    descriptionChangeHandler(event);
  };

  return (
    <div className='mt-2'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {label}
      </Typography>
      { required &&  
          <Typography variant="caption" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
            *required
          </Typography>
        }
        </div>
      <TextField
        id={id??""}
        multiline
        onClick={onClickField}
        rows={rows}
        value={description}
        onChange={handleChange}
        placeholder={placeholder}
        FormHelperTextProps={{
          error: true
        }}
        required={required}
        disabled={disabled}
        inputProps={{
          style: { 
            maxHeight: '10rem',
            overflow: 'overlay',
            paddingRight: 8
          },
          maxLength: maxLength
        }}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor:edit ? "#FAFAFA" : defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: edit ? "1px solid #DBE2ED" : 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          mt: 1
        }}
      />
        {maxLength ?  
      <Typography display={'flex'} marginBottom={-2} justifyContent={'flex-end'} fontSize={10}>{description?.length}/{maxLength}</Typography> : <></>}
    </div>
  );
}