export const latRegex = "^-?([1-8]?[0-9](\\.[0-9]+)?|90(\\.0+)?)$";

export const lonRegex = "^-?((1[0-7][0-9]|[1-9]?[0-9])(\\.[0-9]+)?|180(\\.0+)?)$";

export const AddressRegex = "^(0x)?[0-9a-fA-F]{40}$";

export const DiscordRegex = "^(https:\/\/(?:www\.)?discord\.gg\/[a-zA-Z0-9]+|https:\/\/(?:www\.)?discord\.com\/(?:channels|invite)\/[a-zA-Z0-9]+\/?)$"

export const TelegramRegex = "^https:\/\/t\.me\/\+[a-zA-Z0-9\\-\\+]+$"

export const FacebookRegex = "^https:\/\/(?:www\.)?facebook\.com\/(?:groups|pages)\/[a-zA-Z0-9.\\-]+\/?|https:\/\/(?:www\.)?facebook\.com\/[a-zA-Z0-9.\\-]+\/?"

export const TikTokRegex = "^https:\/\/(?:www\.)?tiktok\.com\/@([a-zA-Z0-9.+_%\\-]+)?"

// "^https:\/\/(?:www\.)?tiktok\.com\/@([\w\.\-]+)(?:\?_t=[\w\.\-]+&_r=\d+)?"
                           
export const InstagramRegex = "^https:\/\/www\.instagram\.com\/(?:[a-zA-Z0-9_.]+|invites\/contact\/\?i=[a-zA-Z0-9_\\-]+)+\/?|https:\/\/(?:www\.)?instagram\.com\/[a-zA-Z0-9.\\-]+\/?"

export const TwitterRegex = "^(https:\/\/twitter\.com\/[a-zA-Z0-9_]+|https:\/\/t\.co\/[a-zA-Z0-9]+)$"

export const MailAddress = '^[a-zA-Z0-9.+_%\\-]+@([a-zA-Z0-9.\\-]+).[a-zA-Z]{2,}\\s*$';