// import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import { SOCIAL_ACCOUNT } from '../enums/socialAccount.enum';
// import { APP_STORAGE_ALL_PASSPORTDETAILS, APP_STORAGE_USER_KEY, APP_STORAGE_USER_NONCE } from '../constants';

/**
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
export const minutesAgo = (x: number) => {
  const now = new Date().getTime();
  return new Date(now - x * 60000).getTime();
};

// export const clearPassportAllHomeDetails = async () => {
//   const allkeys = await AsyncStorage.getAllKeys();
//   const alloffer = allkeys.filter(x => x.indexOf("@offer_") != -1);
//   const allevent = allkeys.filter(x => x.indexOf("@event_") != -1);
//   const allcollectiable = allkeys.filter(x => x.indexOf("@collectiable_") != -1);
//   const allPassport = allkeys.filter(x => x.indexOf("@passportdetails_") != -1);
//   const todeleteKeys = [alloffer, allevent, allcollectiable, allPassport];
//   await Promise.all(todeleteKeys.map(async keylist => {
//     await Promise.all(keylist.map(async item => {
//       await AsyncStorage.removeItem(item);
//     }));
//   }));
//   await AsyncStorage.removeItem(APP_STORAGE_ALL_PASSPORTDETAILS);
//   await AsyncStorage.removeItem(APP_STORAGE_USER_NONCE);
//   // await AsyncStorage.clear();
// }

export const identifySocialWebsiteFromURL = (url) => {
  if(url?.indexOf(SOCIAL_ACCOUNT.FACEBOOK) > -1) {
      return SOCIAL_ACCOUNT.FACEBOOK;
  } else if(url?.indexOf(SOCIAL_ACCOUNT.INSTAGRAM) > -1) {
      return SOCIAL_ACCOUNT.INSTAGRAM;
  } else if(url?.indexOf(SOCIAL_ACCOUNT.TWITTER) > -1) {
      return SOCIAL_ACCOUNT.TWITTER;
  } else if(url?.indexOf(SOCIAL_ACCOUNT.TIKTOK) > -1) {
      return SOCIAL_ACCOUNT.TIKTOK;
  } else {
      return SOCIAL_ACCOUNT.TIKTOK;
  }
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
