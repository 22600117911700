import { Divider, Grid, Typography } from "@mui/material"
import React from "react"
import { defaultTheme } from "../../../../../styles"
import Loot8 from "../../../../../assets/images/logos/Loot8Token.png"



type RowProps = {
    label:string,
    value?:string,
    showDivider?:boolean,
    labelCol?:number
    valWidth?:string
    valueCol?:number
    price?:boolean
    forSelector?:boolean,
}

export const Row = ({ label,valWidth, value,showDivider,forSelector,labelCol,valueCol,price }:RowProps) => <Grid justifyContent={'space-between'} alignItems={'center'} container className="mt-3" lg={12} xl={12} md={12} sm={12}>
    <Grid item lg={ labelCol ?  labelCol : 5} xl={ labelCol ? labelCol : 5}>
      <Typography fontFamily={'Poppins-Medium'} fontSize={'1rem'}>{label}</Typography>
    </Grid>
    {value ? 
    <Grid item lg={valueCol ? valueCol : 7} xl={valueCol ? valueCol : 7} alignItems={'flex-end'} textAlign={'right'} >
   
      <Typography
       sx={{
        maxWidth:'100%',
        overflow: 'hidden',
        '@media (max-width: 600px)': {
          maxWidth:valWidth? valWidth : '100%',
        },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }}
      fontFamily={'Poppins-Medium'} fontSize={'1rem'}>
        {price &&
           <img src={Loot8} height={20} width={20}></img> }
        {value}</Typography>
    </Grid> : <>----</>}
    {showDivider ? 
    <Grid item lg={12} xl={12}>
    <Divider orientation='horizontal' variant="fullWidth" sx={{ flex: 1, alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.buttonDarkTextColor }} />
    </Grid>
    : <> </>}
  </Grid>