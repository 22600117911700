import { Box, Paper, Typography, styled } from "@mui/material";
import React from "react";
import { defaultTheme } from "../../../styles";
import PlayIcon from '@mui/icons-material/PlayCircleOutlineTwoTone';
import { grey } from "@mui/material/colors";
import { Circle } from "@mui/icons-material";


const TemplateCard = styled(Paper)(({ theme }) => ({
  height: '8rem',
  borderRadius: '16px',
  color: defaultTheme.buttonDarkTextColor,
  width: '11rem',
  '@media (max-width: 961px)': {
    width:'100%'
  },
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[5],
  ":hover":  {
    boxShadow: theme.shadows[9],
    cursor: 'pointer'
  },
  position: 'relative',
  overflow: 'hidden'

}));

const TemplateCard1 = styled(Paper)(({ theme }) => ({

  padding: 10,
  background: "black",
  height: '100%',
  width: '100%',
  objectFit:'contain',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

}));

const TemplateCard2 = styled(Paper)(({ theme }) => ({
  padding: 10,
  background: "#96589A",
  backgroundImage: `url('https://ipfs-prod-01.loot8-services.dev:443/ipfs/QmR6Q9VYVvamDEiXicQcXYDxh9LmxBdA4H6fdzGi1eb5H1/bg.png')`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  width: '100%',
  objectFit:'contain',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',

}));

const TemplateCard3 = styled(Paper)(({ theme }) => ({
  padding: 5,

  background: "#96589A",
  backgroundImage:`url('https://ipfs-prod-01.loot8-services.dev:443/ipfs/QmUwZwnr49JSfsA5mWoBnS2jozMoQGrCNTLs5sqwerpHfS')`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  width: '100%',
  objectFit:'contain',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

}));

const Title = styled(Typography)(({theme}) =>({
  
  fontSize: 15,
  color: '#FFFFFF',
  fontFamily: 'Poppins-Bold',
  textShadow: '0 0 4px #000000, 0px 0px 4px #000000',
  marginBottom: 2
}));

const SubTitle = styled(Typography)(({theme}) =>({
  
  fontSize: 12,
  // color: '#FFFFFF',
  fontFamily: 'Poppins-SemiBold'
}));

const Description = styled(Typography)(({theme}) =>({
  
  fontSize: 10,
  // color: '#FFFFFF',
  fontFamily: 'Poppins'
}));

const DateTime = styled(Typography)(({theme}) =>({
  
  fontSize: 9,
  color: defaultTheme.buttonDarkTextColor,
  fontFamily: 'Poppins-Medium',
  marginBlock: 2,
  paddingInline: 2,
  paddingBlock: 1,
  borderRadius: 4,
  backgroundColor: defaultTheme.yellowColor
}));

const VideoBox = styled(Box)(({theme}) => ({
  marginTop: 2,
  height: '2rem',
  aspectRatio: '3.5/1.5',
  backgroundColor: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBlockWidth: 2,
  borderBlockStyle: 'solid',
  borderBlockColor: '#8D2A80',

}))

export const BillboardTemplate = ({ handleTemplate,selectedTemplate }) => {

  return (
    <Box marginTop={'1rem'}>
      <Typography variant="body2" marginY={1} color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        Change Template
      </Typography>
      <Box 
      sx={{
        '@media (max-width: 961px)': {
          flexDirection:'column',
          alignItems:'center'
        },
      }}
      display={'flex'} gap={2}>
        {
         <>
            <TemplateCard sx={{...(selectedTemplate==1 ? {border:'2px solid #F4B540',borderStyle:'dashed'}:{})}} onClick={() => handleTemplate(1)} >
            
              <TemplateCard3>
                <Title> Title </Title>
                <SubTitle color={defaultTheme.buttonDarkTextColor}> subtitle  </SubTitle>
                <Description color={defaultTheme.buttonDarkTextColor}> your descrition  </Description>
                <DateTime > Date </DateTime>
              </TemplateCard3>
            </TemplateCard>
            <TemplateCard sx={{...(selectedTemplate==2 ? {border:'2px solid #F4B540',borderStyle:'dashed'}:{})}}  onClick={() => handleTemplate(2)} >
            <TemplateCard2>
                <Title> Title </Title>
                <SubTitle color={defaultTheme.buttonLightTextColor}> subtitle  </SubTitle>
                <Description color={defaultTheme.buttonLightTextColor}> your descrition  </Description>
                <DateTime > Date  </DateTime>
              </TemplateCard2>
            </TemplateCard>
            <TemplateCard sx={{...(selectedTemplate==0 ? {border:'2px solid #F4B540',borderStyle:'dashed'}:{})}}   onClick={() => handleTemplate(0)} >
              <TemplateCard1>
                <Title sx={{color:"#EE6161"}}> Live </Title>
                <Typography 
                fontFamily={'Poppins-Bold'}
                sx={{
                 fontSize: '1em',
                 color: '#000', // Black text color
                 textShadow: '0.5px 0.5px 0.5px #fff, -0.5px -0.5px 1px #fff, 0.5px -0.5px 0.5px #fff, -0.5px 0.5px 0.5px #fff',

                }}>
                  STREAM YARD
                </Typography>
                <Typography 
                fontFamily={'Poppins-Bold'}
                sx={{
                 fontSize: '1em',
                 marginTop:'2px',
                 padding:'1px 7px',
                 borderRadius:1,
                 background:"#EE6161",
                 color: '#fff', // Black text color
                }}>
                 <Circle sx={{fontSize:'7px'}} /> REC
                </Typography>
                
              </TemplateCard1>
            </TemplateCard>
         </>
        }
      </Box>
    </Box>
  );
}