import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { alpha, fontFamily, styled } from '@mui/system';
import { defaultTheme } from '../../styles';
import { pink } from '@mui/material/colors';

// Custom styles for the Switch component
const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#f5f5f5',
    '&:hover': {
      backgroundColor: alpha('#f5f5f5', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: defaultTheme.buttonRedColor,
    opacity: 1,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 14,
    height: 14,
    margin: 2,
    color: '#f5f5f5',
  },
  width: 56,
  height: 36,
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,
    },
  },

}));

export const CustomSwitch = ({ disabled, checked, handleToggle, label }) => {

  const handleChange = (event) => {
    const checked = event.target.checked;
    handleToggle(checked)
  }

  return (
    <FormControlLabel id='switch-input-form' className='mt-2'
      control={
        <StyledSwitch
          id='switch-input'
          checked={checked}
          onChange={handleChange}
          
        />
      }
      sx={{
        color:  defaultTheme.buttonDarkTextColor,
        '& .MuiFormControlLabel-label.Mui-disabled':{
            color: defaultTheme.buttonDarkTextColor+'a0'
        },
        '& .MuiFormControlLabel-label':{
          fontFamily: 'Poppins-Medium'
        }

      }}
      disabled={disabled}
      label={label}
    />
  );
};