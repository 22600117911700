import React from "react";
import { Box, BoxProps, Container, ContainerProps, Typography, TypographyProps, styled } from "@mui/material";
import "./EntitySelect.scss";
import { defaultTheme } from "../../styles";
import Loot8Logo from "../../assets/images/logos/loot8-logo.png"
import { useAppSelector } from "../../hooks";
import { EntityCard } from "./components/EntityCard";
import { IEntity } from "../../interfaces/IEntity.interface";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { setSelectedEntity } from "../../slices/EntitySlice";


const StyledContainer = styled(Container)<ContainerProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  marginTop: '5rem',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  borderRadius: '0.25rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
}));

const ContentContainer = styled(Box)<BoxProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '4rem'

}));

const Title = styled(Typography)<TypographyProps>(({theme}) => ({
  margin: 0,
  fontSize: 'clamp(1rem, 1.75vw, 1.5rem)',
  padding: '0.5rem',
  textAlign: 'center',
  fontFamily: 'Poppins-SemiBold',
  color: defaultTheme.lightColor,
}));

export const EntitySelectPage = () => {
  const allEntityDetails = useAppSelector(state => state.Entity.AllEntityData);

  const dispatch = useDispatch<AppDispatch>();

  const onSelectEntity = async (item : IEntity) => {
    
    const selectedEntity = { 
      EntityData: item , EntityAddress: item?.address, AuthorizedEntityAdmin: true };
    dispatch(setSelectedEntity(selectedEntity));

  }

  return (
    <>
      <div className="entity-select-container">
        <StyledContainer maxWidth='lg'>
            <div className="loot8-logo-image">
              <img src={Loot8Logo} alt="" className="loot8-logo-image"/>
            </div>
            <ContentContainer>
              <Title>{"Select the entity to log into the establishment portal"}</Title>
              <Box display={'flex'} gap={2} marginTop={4} flexWrap={'wrap'} justifyContent={'center'}>
              {
                allEntityDetails.map(( entity, index ) => <EntityCard key={index} item={entity} onSelectEntity={onSelectEntity}/> )
              }
              </Box>

            </ContentContainer>

        </StyledContainer>
        </div>
    </>
  );
}