import { gql } from "urql";

export const entityDataQuery = gql`
  query ($isActive: Boolean!, $skip: Int!, $take: Int!) {
    entitiesData: entityItems(skip: $skip, first: $take, where: { isActive: $isActive }) {
      address: id
      name
      dataURI
      isActive
      walletAddress
      entityOnboarded
    }
  }
`;
