import '../Profile.scss'
import warning from '../../../assets/svg/warning_black.svg'
import { Button, Grid } from '@mui/material'

type Props = {
    account: any,
    removeLink: () => void
}

export const ConnectedAccount = (props: Props) => {

    return (
        props.account ?
            <Grid item xl={12} lg={12} style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                <p className='social-text' >Connected Account</p>
                <Grid item lg={12} xl={12}>
                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-row'>
                            <img className='selected-account-icon' src={props?.account?.icon} alt='_blank' />
                            <div style={{ flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                <p className='connected-account-text' style={{color: props?.account?.color}} >{props?.account?.text}</p>
                                {   
                                    !props?.account?.text &&
                                    <Button onClick={props?.removeLink} className='unlink-text'>Unlink account ?</Button> 
                                }
                            </div>
                        </div>
                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                            <p className='social-link'>{props?.account?.url}</p>
                            <div style={{ width: '7rem' }} />
                            <div className='position-relative'>
                                {
                                    !props?.account?.text &&
                                    <div className='flex-row d-flex position-absolute unlink-container' >
                                        <img className='warning-icon' src={warning} alt='_blank' />
                                        <p className='unlink-desc'>{"If you want to add and connect other social account you have to unlink this account first"}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </ Grid> : null
    )
}