export enum NavigationRoutes {
  DASHBOARD = "/dashboard",
  PASSPORT = "/passports",
  EXPASSES = "/expasses",
  FEATUREDOFFER = "/featured-offers",
  OFFERS = "/offers",
  EVENTS = "/events",
  COLLECTIBLES = "/collectibles",
  POAPS = "/phygitals",
  THIRDPARTYCOLLECTION = "/third-party-collections",
  SOCIALMEDIA = "/social-media",
  BILLBOARDS = "/billboards",
  PREMIUM_CHAT = "/premium-chat-room",
  PRIVATE_CHAT = "/private-chat",
  // SETTINGS = "/settings",
  PROFILE = "/profile",
  PROFILE_CREATION = "/profile-creation",

}

export enum OnboardingNavigationRoutes {
  DASHBOARD = "/dashboard",
  EXPASSES = "/expasses",
  BILLBOARDS = "/billboards",
  PREMIUM_CHAT = "/premium-chat-room",
  POAPS = "/phygitals",
  EVENTS = "/events",
  PROFILE = "/profile",
  PROFILE_CREATION = "/profile-creation",
  FEATUREDOFFER = "/featured-offers",
  COLLECTIBLES = "/collectibles",


}

export enum ExternalRoutes {
  VERIFY_USER = "/verify-user",
}