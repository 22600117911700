// TODO: add Offers slice data
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addresses } from "../constants";
import { GetAddBartenderMessage, GetToggleBartenderMessage } from "../helpers/MsgHelper";
import { IBartender } from "../interfaces/IBartender.interface";
import { RootState } from "../store";
import { Entity__factory, User__factory } from "../typechain/factories";
import { SendMetaTX } from "./AppSlice";
import { getUserAvatar, setAll } from "./helpers";
import { IbartenderToggleAsyncThunk, IMessageMetaData, ISettingsOnlyAsyncThunk } from "./interfaces";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";


export interface ISettingSliceData {
  readonly BartendersList: any[];
  readonly entityWallet?: string;
  readonly loading: boolean;
}

const initialState = {
    BartendersList: [],
    entityWallet: null,
    loading: false
  };
  
  export const getBartenderDetails =async ({networkID,provider, bartenderAddress} : {networkID, provider, bartenderAddress}): Promise<any> => {
    const UserEntity = User__factory.connect(addresses[networkID].User, provider);
    let bartendersData = await UserEntity.userAttributes(bartenderAddress);
    return bartendersData;
  }
  export const getAllBartenders = async ({ networkID, provider,entityAddress }: { networkID, provider,entityAddress }): Promise<any[]> => {
    //todo:load all entity details check geocoding filters
    const entity = Entity__factory.connect(entityAddress, provider);
    const bartendersData = await entity.getAllBartenders(false); 
    return bartendersData;
  }
  export const getBartenderStatus = async ({ networkID, provider, bartenderAddress, entityAddress }: { networkID, provider, bartenderAddress, entityAddress }): Promise<any> => {
    //todo:load all entity details check geocoding filters
    const entity = Entity__factory.connect(entityAddress, provider);
    const bartendersStatus = await entity.bartenderDetails(bartenderAddress); 
    return bartendersStatus;
  }
  export const loadAllBartenderList = createAsyncThunk("bartender/loadAllbartenders", async ({ networkID, provider, entityAddress, address, wallet }: ISettingsOnlyAsyncThunk ): Promise<any> => {
    let allbartenderDetails:any = [];
    let allBartenders = (await getAllBartenders({ networkID, provider, entityAddress }));
    await Promise.all(allBartenders.map(async bartenderAddress => {
    let bartenders : IBartender = await getBartenderDetails({networkID, provider, bartenderAddress});
    let bartenderStatus = await getBartenderStatus({networkID, provider, bartenderAddress, entityAddress})
    let bartenderAvatar = await getUserAvatar(bartenders.avatarURI)
    bartenders = { ...bartenders, avatarURI: bartenderAvatar, isActive: bartenderStatus?.isActive }

      if(bartenders.name !== "") allbartenderDetails.push(bartenders); 
    }))
    allbartenderDetails.sort((a, b) => { return (b.isActive - a.isActive || a.name.localeCompare(b.name))});
    LogToConsole("bartenders ",allbartenderDetails);
     return { BartendersList: allbartenderDetails };
  });

  export const toggleBartender = createAsyncThunk("bartender/toggleBartender", async ({ networkID, provider, entityAddress, bartenderAddress, wallet}: IbartenderToggleAsyncThunk, { dispatch }): Promise<any> => {
    const data = GetToggleBartenderMessage(bartenderAddress);
      let msg: IMessageMetaData = {
        to: entityAddress,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider
      }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  });

  export const addBartender = createAsyncThunk("bartender/AddBartender", async ({ networkID, provider, entityAddress, bartenderAddress, wallet}: IbartenderToggleAsyncThunk, { dispatch }): Promise<any> => {
    const data = GetAddBartenderMessage(bartenderAddress);
      let msg: IMessageMetaData = {
        to: entityAddress,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider
      }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  });

const SettingSlice = createSlice({
  name: "SettingDetails",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAllBartenderList.fulfilled, (state, action) => {
        state.BartendersList = action.payload.BartendersList;
        state.loading = false;
      })
      .addCase(loadAllBartenderList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAllBartenderList.rejected, (state, { error } : any) => {
        state.loading = false;
        LogToConsoleError("loadAllBartenderList", error.name, error.message, error.stack);
      })

  }

});

export const SettingSliceReducer = SettingSlice.reducer;

const baseInfo = (state: RootState) => state.Settings;

export const { fetchAppSuccess } = SettingSlice.actions;

export const getSettingState = createSelector(baseInfo, SettingSlice => SettingSlice);