import "./NavItem.scss"

export const NavItem = ({icon, name, menuOpen, disabled = false }) => {

  return (
    <div className={"nav-item "+ (disabled && "disabled-item") } style={{ }} >
      <div>
        <img src={icon} className="icon" alt="" />
      </div>
      { menuOpen &&
        <span className="item-text fade-in-text ms-3">
          {name}
        </span>
      }
    </div>
  );
}