import '../Profile.scss'
import { IconButton } from "@mui/material"
import { SocialLinksColors } from '../../../helpers/SocialLinksColors'
import { SocialLinksData } from '../Profile'
import { useAppSelector } from '../../../hooks'

type Props = {
    handleLinkAccount:(item: any)=>void
    account:any
}

export const SocialLinks = (props:Props) => {
    const userDetails = useAppSelector((state) => state.AppUser.UserData);
    
    return(
        <div >
            <div className="d-flex flex-row">
            {SocialLinksData?.length && SocialLinksData?.map(i => i.id !== props?.account?.id ? 
            <IconButton disabled={(!userDetails?.isExist && !props?.account?.id)} onClick={()=> userDetails?.isExist && !props?.account?.id  && props?.handleLinkAccount(i) } key={i.id}  className="social-btn">
                <img className='social-icons' src={i.icon} alt='_blank'/>
                <p className='social-icon-text' 
                style={{ color:SocialLinksColors(i.text) }}>
                    {i.text}
                </p>
            </IconButton> : null)}
            </div>
        </div>
    )
}