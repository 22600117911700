import { SOCIAL_ACCOUNT } from "../enums/socialAccount.enum"

export const SocialLinksColors = (link) => {
    switch(link){
        case SOCIAL_ACCOUNT.FACEBOOK:
            return "#3A559F"
        case SOCIAL_ACCOUNT.TWITTER:
            return "#03A9F4"
        case SOCIAL_ACCOUNT.INSTAGRAM:
            return "#FE5364"
        default:
            return "#212121"
    }
}