import React, { useState } from "react";
import WhitePattern from "../../../components/Layout/whitePattern/WhitePattern";
import { Box, Divider, Grid, IconButton } from "@mui/material";

import BluePattern from "../../../components/Layout/bluePattern/BluePattern";
import Google from "../../../assets/images/social/google.png";
import Facebook from "../../../assets/images/social/facebook.png";
import Reddit from "../../../assets/images/social/reddit.png";
import Discord from "../../../assets/images/social/discord.png";
import Twitch from "../../../assets/images/social/twitch.png";
import Twitter from "../../../assets/images/social/twitter.png";
import Apple from "../../../assets/images/social/apple.png";
import LinkedIn from "../../../assets/images/social/linkedin.png";
import Github from "../../../assets/images/social/github.png";

import { useWeb3Context } from "../../../hooks";
import { LOGIN_PROVIDER, LOGIN_PROVIDER_TYPE } from "@web3auth/openlogin-adapter";
import { useAlert } from "../../../hooks/Alert";
import "./loginView.scss";
import { defaultTheme } from "../../../styles";
import { FormBox } from "../../AdminAccessPage/AdminAccessPage";
import { CustomButton } from "../../../components/Button/CustomButton";
import { BasicTextInput } from "../../../components/TextInput/BasicTextInput/BasicTextInput";

const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
const validEmail = (text: string) => emailReg.test(text);

const LoginView = ({ setLoading }) => {
  const { connect } = useWeb3Context();
  const { setShowToastError } = useAlert();

  const [loginEmail, setLoginEmail] = useState("");

  const socialData = [
    {
      image: Google,
      type: LOGIN_PROVIDER.GOOGLE,
    },
    {
      image: Facebook,
      type: LOGIN_PROVIDER.FACEBOOK,
    },
    {
      image: Reddit,
      type: LOGIN_PROVIDER.REDDIT,
    },
    {
      image: Discord,
      type: LOGIN_PROVIDER.DISCORD,
    },
    {
      image: Twitch,
      type: LOGIN_PROVIDER.TWITCH,
    },
    {
      image: Twitter,
      type: LOGIN_PROVIDER.TWITTER,
    },
    {
      image: Apple,
      type: LOGIN_PROVIDER.APPLE,
    },
    {
      image: Github,
      type: LOGIN_PROVIDER.GITHUB,
    },
    {
      image: LinkedIn,
      type: LOGIN_PROVIDER.LINKEDIN,
    },
  ];

  const loginContent = [
    {
      id: 1,
      title: "Create An Experience Passport (ExPass)",
      description: `At the heart of this evolution is the Experience Passport (ExPass), 
    a personalized space carved out exclusively for you. 
    It’s more than a profile; it’s a dynamic platform where creators, 
    artists, athletes, and influencers converge to redefine fan engagement. 
    Here, each ExPass is a gateway, not just to exclusive content, but to a world where every like, share, 
    and comment is an intimate interaction, a personal touchpoint.`,
    },
    {
      id: 2,
      title: "Create Unique Collections",
      description: `
    Navigate through intuitive interfaces to create collections that resonate with your unique brand. 
    Every post, picture, video, or piece of content isn’t just viewed—it’s experienced, 
    it’s owned, and it’s an integral piece of the exclusive journey you offer to your fans.`,
    },
    {
      id: 3,
      title: "Welcome to the Future",
      description: `
    So, step in, explore, and create. Every feature, every tool at the Loot8 Establishment Portal is crafted, 
    keeping YOU at the core. Your content, your rules, 
    your ExPass – where every interaction is an exclusive ticket to a world crafted by you, 
    for your fans. Let’s redefine digital engagement. Together.`,
    },
  ];

  const handleLogin = async (type: LOGIN_PROVIDER_TYPE) => {
    try {
      const conn = await connect(type, type == LOGIN_PROVIDER.EMAIL_PASSWORDLESS && loginEmail);
      if (!conn) {
        setShowToastError({ open: true, message: "Something went wrong. Please try again!" });
      }
      setLoading(conn);
    } catch (e) {
      setShowToastError({ open: true, message: "Something went wrong. Please try again!" });
    } finally {
      setLoading(false);
    }
  };

  const Content = ({ title, description, id, descriptionStyle }) => (
    <div key={id}>
      <h5 className="sub-heading">{title}</h5>
      <p className="short-description-text" style={descriptionStyle}>
        {description}
      </p>
    </div>
  );

  const emailChangeHandler = (event: any) => {
    let text = event.target.value;
    setLoginEmail(text);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validEmail(loginEmail)) {
      handleLogin(LOGIN_PROVIDER.EMAIL_PASSWORDLESS);
    } else {
      setShowToastError({ open: true, message: "Invalid email address!" });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <WhitePattern>
          <div className="main-content">
            <div className="heading-text pt-0">
              <span className="">Login</span>
            </div>

            <div className="social-text pt-0">
              <span className="">
                <b>Login</b> with your legacy email address
              </span>
            </div>
            <FormBox>
              <form onSubmit={onSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", margin: "7px 0" }}>
                  <BasicTextInput value={loginEmail} label={""} placeholder={"Email"} valueChangeHandler={emailChangeHandler} required disabled={false} />
                </Box>
                <div className="form-buttons d-flex justify-content-around my-3 mt-4">
                  <CustomButton className={"col-5 col-sm-5 col-lg-12"} onClick={() => {}} color={defaultTheme.buttonDarkTextColor} backgroundColor={defaultTheme.yellowColor} type={"submit"} disabled={false}>
                    {"Continue"}
                  </CustomButton>
                </div>
              </form>
            </FormBox>
            <div className="social-text-wrap mt-4">
              <span className="line" />
              <span style={{ marginLeft: 6, marginRight: 6 }}>OR</span>
              <span className="line" />
            </div>
            <div className="social-text">
              <span>
                <b>Click</b> on a social icon below to log in and get started.
              </span>
            </div>
            <div className="social-container">
              {socialData.map((item, index) => (
                <IconButton key={index} className="button" onClick={() => handleLogin(item.type)}>
                  <img src={item.image} alt="" style={{ width: "45px", height: "45px" }} />
                </IconButton>
              ))}
            </div>
          </div>
        </WhitePattern>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ overflowY: "scroll", maxHeight: "100vh" }}>
          <BluePattern
          // shortDescription="Login to access your account"
          >
            <Box sx={{ marginTop: "10px" }}>
              <div>
                <p className="short-description-text">We're excited to have you here, where the world of digital interaction and fan engagement takes an innovative leap. You're not just stepping into a platform; you're entering a experience where every connection is tailored, intimate, and rewarding.</p>
                <Divider sx={{ color: "#F0ECEC", borderColor: "#F0ECEC", my: "1rem", borderWidth: "1px" }} />
                <Box display={"flex"} justifyContent={"space-between"}>
                  {<Content descriptionStyle={{ maxWidth: "85%" }} description={loginContent[0]?.description} title={loginContent[0]?.title} id={loginContent[0].id} />}
                  <Divider sx={{ color: "#F0ECEC", borderColor: "#F0ECEC", mr: "3rem", borderWidth: "1px", height: "14rem" }} />
                  {<Content descriptionStyle={{ maxWidth: "97%" }} description={loginContent[1]?.description} title={loginContent[1]?.title} id={loginContent[1].id} />}
                </Box>
                <Divider sx={{ color: "#F0ECEC", borderColor: "#F0ECEC", my: "1rem", borderWidth: "1px" }} />
                {<Content descriptionStyle={{ maxWidth: "100%" }} description={loginContent[2]?.description} title={loginContent[2]?.title} id={loginContent[2].id} />}
              </div>
            </Box>
          </BluePattern>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(LoginView);
