import React, { useState } from "react";
import { Box, CircularProgress, DialogContent, DialogTitle, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { useAppSelector, useWeb3Context } from "../../../../hooks";
import { BasicTextInput } from "../../../../components/TextInput/BasicTextInput/BasicTextInput";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { defaultTheme } from "../../../../styles";
import { checkInviteCodeValid, loadEntityDetails, onboardEntity } from "../../../../slices/EntitySlice";
import { jsonIpfsUpload } from "../../../../services/ipfsUpload.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FormBox } from "../../AdminAccessPage";
import { getUserOnboarded, setUserOnboarded } from "../../../../slices/AppUserSlice";
import { LogToConsoleError } from "../../../../helpers/Logger";
import { wait } from "../../../../helpers/ipfs";
import { useHistory } from "react-router-dom";


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#ff6060',
    fontSize: 13,
    fontFamily: "Poppins-Medium",
    padding: 0,
  },
}));

export const OnboardingDialog = ({ loading, setLoading }) => {
  const { address, wallet, networkId, staticProvider, disconnect } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();

  const [inviteCode, setInviteCode] = useState("");
  const [entityName, setEntityName] = useState("");
  const [showToastError, setShowToastError] = useState(false);
  const history = useHistory();


  const userDetails = useAppSelector(state => state.AppUser.UserData);

  const inviteCodeChangeHandler = (event) => {
    let text = event.target.value;
    if(showToastError) setShowToastError(false);
    setInviteCode(text);
  }

  const entityNameChangeHandler = (event) => {
    let text = event.target.value;
    setEntityName(text);
  }

  const onSubmit = async (event) => {
    event?.preventDefault();
    setLoading(true);
    setShowToastError(false);
    try {

      const isValidCode = await checkInviteCodeValid({ networkID: networkId, provider: staticProvider, inviteCode: inviteCode.trim(), isOnboarding: true });

      if (isValidCode) {
        const entity_obj = {
          // "name": "",
          "description": "",
        }

        const ipfsURI = await jsonIpfsUpload(entity_obj, wallet, "Entity.txt");

        const data = await dispatch(onboardEntity({ networkID: networkId, provider: staticProvider, address, wallet, inviteCode: inviteCode.trim(), entityName : entityName.trim(), dataURI: ipfsURI }));
        await wait(100);
        if (data && data.payload) {
          const IsUserOnboarded = await getUserOnboarded({ networkID: networkId, provider: staticProvider, address });

          dispatch(setUserOnboarded(IsUserOnboarded));

          await dispatch(loadEntityDetails({ networkID: networkId, provider: staticProvider, address, wallet: wallet }));
          history.replace({ pathname: '/profile-creation', search: '', state:{isActive: true}})
          // window.history.replaceState(null, null, '/profile-creation');

        }

      }
      else {
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
      }
    }
    catch (e) {
      LogToConsoleError("Onboarding Error", e);
    }
    setLoading(false);
   

  }


  return (
    <>
      <DialogTitle id="alert-dialog-title" className="AdminAccessTitle" >
        Hi, {userDetails?.name}
      </DialogTitle>
      <DialogContent id="alert-dialog-description" className="AdminAccessDescription col-12" >
        <p className="lh-md w-75"><span>Give yourself a unique name! Get onboarded using an invite code and create your own ExPass.</span></p>
      </DialogContent>
      <FormBox>
        <form onSubmit={onSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', margin: '7px 0' }}>
            <BasicTextInput value={inviteCode} label={"Invitation code"} valueChangeHandler={inviteCodeChangeHandler} required={true} disabled={false} placeholder={"Paste your invite code"} />
            {showToastError &&
              <LightTooltip open leaveDelay={1000} title="Invalid invite code" placement="right-end">
                <InfoRoundedIcon color="error" />
              </LightTooltip>
            }
          </Box>
          {/* <BasicTextInput 
          infoText="Your brand name serves as your portfolio, allowing you to develop various collections such as experience passes, billboards, premium chat rooms, and more." 
          value={entityName} label={"Brand name"} valueChangeHandler={entityNameChangeHandler} required={true} disabled={false} placeholder={"Give yourself a unique name"} maxLength={45} />
          <Typography width={'100%'} display={'flex'} justifyContent={'flex-end'} variant="caption" color={defaultTheme.greyWhite} fontFamily={'Poppins-Medium'} >
            {`(${entityName.length}/45)`}
          </Typography> */}
          <div className="form-buttons d-flex justify-content-around my-3 ">
            <CustomButton
              className={"col-5 col-sm-5 col-lg-12"}
              onClick={() => { }}
              color={defaultTheme.buttonDarkTextColor}
              backgroundColor={defaultTheme.yellowColor}
              type={"submit"}
              disabled={false}
            >
              {
                 loading ?
                 <CircularProgress size={'1.2em'} sx={{ margin: 1, color: defaultTheme.buttonDarkTextColor }} />
                 :
                 "Submit"
              }
            </CustomButton>
          </div>
        </form>
      </FormBox>
    </>
  );
} 