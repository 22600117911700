// import { MobileD } from '@mui/x-date-pickers/MobileDateTimePicker';
import {MobileDatePicker,MobileTimePicker} from '@mui/x-date-pickers'
import dayjs from 'dayjs';
import { TextField, Typography } from '@mui/material';
import { defaultTheme } from '../../styles';
import IconButton from '@mui/material/IconButton';
import calenderIcon from '../../assets/images/icons/calendar.png'

type CustomDatePicker = {
  label:string
  selectedDate:any,
  datechangedHandler:any ,
  disabled:boolean,
}

export const CustomDatePicker = (props:CustomDatePicker) => {

  const { label, selectedDate, datechangedHandler, disabled } = props;

    return (
      <div className="mt-2">
        <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {label}
        </Typography>
        <MobileDatePicker
          slotProps={{
            actionBar: {
              actions: ['clear','cancel','accept'],
            },
          }}
          disabled={disabled}
          value={dayjs(selectedDate)}
          onChange={(value) => { datechangedHandler(value); }}
          closeOnSelect
          minDate={dayjs(new Date())}

          sx={{
              backgroundColor: defaultTheme.newFieldColor,
              mt: 1,
              borderColor: defaultTheme.buttonGreyColor,
              borderRadius: '4px',
              width: '100%',
              "& .MuiInputBase-input": {
                  padding: '8.5px',
                  color: defaultTheme.darkColor,
                },
              "& .MuiOutlinedInput-notchedOutline" : {
                  border: 0,
              }
              
          }}
          slots={{
            textField: (params) => (
              <div style={{display:"flex",position:'relative',justifyContent:'center',alignItems:'center'}}>
                
                <TextField {...params} value={selectedDate ? params.value : ""} placeholder='Enter a date'
                  InputProps={{
                    endAdornment: (
                      <IconButton
                  
                        aria-label="calendar"
                        // sx={{position:'absolute',right:'10px',marginTop:'5px'}}
                        edge="end" 
                      >
                      <img src={calenderIcon} style={{width:20,height:20}}/>
                      </IconButton> 
                    )}}
                  /> 
                </div>)
                }}
        />
      </div>
    );

}