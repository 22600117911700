import React from "react";
import useTheme from "../../hooks/useTheme";
import darkBackground from "../../assets/images/background/backgroundImage.png";

const MaintenanceMode = ({ maintenanceText }) => {
  const [theme] = useTheme();

  return (
    <div
      className={`app container-fluid m-0 p-0 ${theme} bg-gray-100 flex items-center justify-center min-h-screen`}
      style={{
        backgroundImage: `url(${darkBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="bg-opacity-80 rounded-lg p-8 max-w-lg w-full text-center">
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
          We'll be back soon!
        </h1>
        <p className="text-white mb-6">{maintenanceText}</p>
      </div>
    </div>
  );
};

export default React.memo(MaintenanceMode);
