import { Box, CircularProgress, Divider, FormControl, Grid, Paper, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { SelectInput } from "../../../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { defaultTheme } from "../../../../styles";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../../../hooks";
import { useEffect, useState } from "react";
import { NETWORKS, ZERO_ADDRESS } from "../../../../constants";
import { CustomRadioButton } from "../../../../components/RadioButton/CustomRadioButton";
import { getCollectionSocialAccess, toggleUserSocialAccess } from "../../../../slices/AppUserSlice";
import { LogToConsoleError } from "../../../../helpers/Logger";

export const AdministrationLookup = ({ setShowLoader, title, selectedCollection, setSelectedCollections }) => {

  const { wallet } = useWeb3Context();
  const dispatch = useAppDispatch();

  const allPassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails)?? [];
  const allCollectibleDetails = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails) ?? [];
  const allPoapsDetails = useAppSelector(state => state.Collectibles.AllPOAPCollectibleDetails) ?? [];
  const allExternalCollectionDetails = useAppSelector(state => state.Collectibles.AllExternalCollectibleDetails) ?? [];

  const mutedUsers =  useAppSelector(state => state.AppUser.MutedUsers);
  const loadingMutedUsers = useAppSelector(state => state.AppUser.SocialAccessLoading);


  const [listValue, setListValue] = useState('Passport');

  const allCollections = [...allCollectibleDetails, ...allExternalCollectionDetails, ...allPoapsDetails];
  const menuData = listValue === "Passport" ? 
                    allPassportDetails 
                    : 
                    allCollections.filter((item, index, self) => index === self.findIndex((t) => (t.address?.toLowerCase() === item.address?.toLowerCase())));
  

  
  const handleRadioValueChange = (event) => {
    setSelectedCollections("");
    setListValue((event.target).value);
  };
  
  const collectionChangedHandler = async (event) => {
    let value = event.target.value;
    const collection = menuData.find(item => item.address?.toLowerCase() === value?.toLowerCase());
    setSelectedCollections(value);
    let passport = "";
    if(listValue !== "Passport" && allExternalCollectionDetails?.findIndex(obj => obj.address === value) > -1) {
      passport = allExternalCollectionDetails?.find(obj => obj.address?.toLowerCase() === value?.toLowerCase())?.linkPassports?.at(0);
    }
    await dispatch(getCollectionSocialAccess({ wallet: wallet, collectionAddress: collection.address, chainId: collection.chain, passport: passport }))
  }

  const unmuteUserFromCollectible = async (userData) => {

    setShowLoader({ open: true, message: "Unmuting User"});
    try {
      if( selectedCollection && selectedCollection !== "") {
          let collectibleDetail = null;
          let passport = null;
          if(listValue === "Passport") {
            collectibleDetail = allPassportDetails.find(obj => obj.address?.toLowerCase() === selectedCollection?.toLowerCase());
          }
          else {
            collectibleDetail = allCollectibleDetails.find(obj => obj.address?.toLowerCase() === selectedCollection);
            if(allExternalCollectionDetails?.findIndex(obj => obj.address?.toLowerCase() === selectedCollection?.toLowerCase()) > -1) {
              passport = allExternalCollectionDetails?.find(obj => obj.address?.toLowerCase() === selectedCollection?.toLowerCase())?.linkPassports?.at(0);
            }
          }
          await dispatch(toggleUserSocialAccess({ wallet: wallet, userAccount: userData.wallet, collectionAddress: selectedCollection, chainId: collectibleDetail?.chain, status: false, passport: passport }));
          await dispatch(getCollectionSocialAccess({ wallet: wallet, collectionAddress: selectedCollection, chainId: collectibleDetail?.chain, passport: passport }))

      }
    }
    catch (error) {
      LogToConsoleError("Error while unmuting", error);

    }
              
    setShowLoader({ open: false, message: ''});
    return;
  }
  
  return (

  <Grid container spacing={1} rowSpacing={2} columnGap={2} marginY={'2rem'} >
    {/* First Row */}
    <Grid columnGap={4} item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Typography
      fontSize={3}
      variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {title}
      </Typography>
      <Divider orientation='horizontal' variant="fullWidth" sx={{ flex: 1, alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />
    </Grid>
    <Grid item xs={12} >
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioValueChange}
          value={listValue}
        >
          <CustomRadioButton label={"Passport"} value={"Passport"} />
          <CustomRadioButton label={"Collectibles"} value={"Collectibles"} />

        </RadioGroup>
      </FormControl>
    </Grid>
 
    {/* Row */}
      <Grid item xs={12}  sm={12} md={6} lg={4} xl={4}>
        <SelectInput label={listValue} multiple={false} menuData={menuData} handleOptionChange={collectionChangedHandler} selectedOptions={selectedCollection} disabled={false}/>
      </Grid>

    {/* Row */}
    <Grid item xs={12}  sm={12} md={12} lg={12} xl={12}>
    { 
      (selectedCollection) &&
      <TableContainer component={Paper} sx={{ background: '#ffffff07', color: defaultTheme.lightColor }} elevation={5}>
        {
           !loadingMutedUsers && mutedUsers && mutedUsers?.length > 0 ?
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>User</TableCell>
                <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>Wallet</TableCell>
                <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>Unmute</TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              { mutedUsers?.map((user, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>{(index+1) + ". "+ user?.name}</TableCell>
                      <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>{user?.wallet}</TableCell>
                      <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>
                        <CustomButton 
                          children={"Unmute"}
                          className={"w-100  mt-3"}
                          onClick={() => unmuteUserFromCollectible(user)}
                          color={defaultTheme.buttonLightTextColor}
                          backgroundColor={defaultTheme.buttonRedColor}
                          disabled={false}
                          type={"button"} />
                      </TableCell>
                      
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
          : loadingMutedUsers ? 
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} margin={2}>
            <CircularProgress color="secondary" />
          </Box>
          :
          <Box p={3}>
            <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
              {`No users muted in ${menuData.find(obj => obj.address === selectedCollection)?.name}`}
            </Typography>
          </Box>
        }
      </TableContainer>
      }
    </Grid>
  </Grid>
  );
}