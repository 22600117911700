import { gql } from "urql";

export const collectionDetailsCombinedQuery = gql`
  query ($address: Bytes!) {
    collectionData: collections(where: { collectionAddress: $address }) {
      collection: collectionAddress
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport: passportId
      minRewardBalance
      minVisits
      minFriendVisits
    }
    collectionMetadata: collections(where: { collectionAddress: $address }) {
      collection: collectionAddress
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      collectionType
    }
    collectionDataAdditional: collections(where: { collectionAddress: $address }) {
      collection: collectionAddress
      maxBalance
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const whitelistedCollectionsQuery = gql`
  query ($address: Bytes!) {
    whitelistedCollectionsForPassport: collections(where: { passportId: $address, isWhitelisted: true }) {
      source: collectionAddress
      chainId
    }
  }
`;

export const fetchPOAPCollectionsQuery = gql`
  query ($entity: Bytes!, $skip: Int!, $take: Int!) {
    poapCreatedCollections: collections(
      skip: $skip
      first: $take
      where: { entity: $entity, isPOAP: true }
    ) {
      blockNumber: addedAtBlockNumber
      entity
      collection: collectionAddress
      # transactionHash
    }
  }
`;

export const collectibleTransfersQuery = gql`
  query collectibleTransfers($skip: Int, $first: Int, $user: Bytes!, $collectible: Bytes!) {
    collectibleTransfers(skip: $skip, first: $first, where: { collectionAddress: $collectible, from: $user }) {
      collection: collectionAddress
      blockNumber
      tokenId
      transactionIndex
      from
      to
      transactionHash
      chainId
    }
  }
`;
