import { ethers } from "ethers";
import { MASTER_KEY, REACT_APP_SOCIAL_ACCOUNT_VERIFY_API } from "../constants"
import { SOCIAL_ACCOUNT } from "../enums/socialAccount.enum";
import { retryFetch } from "../helpers/ipfs";
import { LogToConsoleError } from "../helpers/Logger";


export const validateSocialLogin = async (socialAccount) => {
    try {

        let requestURL = REACT_APP_SOCIAL_ACCOUNT_VERIFY_API + `auth/${socialAccount}/login`;
        const response = await retryFetch(() => fetch(requestURL, { method: 'GET'}));
        
        if(response?.status != 200) {
            
        }
        
        return response?.json();  
    } catch(err) {
        LogToConsoleError('validateSocialLogin', err.name, err.message, err.stack)
        return null;
    }

}

export const verifySocialLogin = async (wallet, verificationCode) => {
    try {        
        const signature = await wallet.signMessage(Buffer.from(wallet.address, "utf-8"));

        const masterWallet = new ethers.Wallet(MASTER_KEY);
        const masterSignature = await masterWallet.signMessage(signature);

        let requestURL = REACT_APP_SOCIAL_ACCOUNT_VERIFY_API + `auth/verify/${wallet.address}`;
        const response = await fetch(requestURL, 
            { method: 'POST', 
            headers:{
                "X-Loot8-3PVRF": verificationCode,
                "X-Loot8-Signature": signature,
                "X-Loot8-MasterSignature": masterSignature
            },
            body:null
        });

        if(response?.status != 201) {
            return null;
        }
        
        return response?.json();  
    } catch(err) {
        return null;
    }

}