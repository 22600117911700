import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { GetAddCollaboratorsMessage, GetAddGateKeeperMessage, GetCreateEventDataMessage, GetEventCreatedLogData, GetRemoveCollaboratorsMessage, GetRemoveEventMessage, GetRemoveGateKeeperMessage, GetUpdateEventDataMessage } from "../helpers/MsgHelper";
import { APP_STORAGE_GET_COLLECTIBLEDETAILS, NetworkId, ZERO_ADDRESS, addresses, getAnynetStaticProvider, getNetwork } from "../constants";
import { ICollectionBaseAsyncThunk, IEventAsyncThunk, IMessageMetaData } from "./interfaces";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { SendMetaTX } from "./AppSlice";
import { IEventDetails } from "../interfaces/IEvent.interface";
import { convertAreaData, getZeroArray, setAll } from "./helpers";
import { getIPFSLink } from "../helpers/ipfs";
import { CATEGORIES, OfferType, PassportType } from "../enums/offers.enum";
import { CollectionType } from "../enums/collection.enum";
import { CollectionHelper__factory, CollectionManager__factory, EventRegistry__factory, Event__factory } from "../typechain";
import { storeData } from "../helpers/AppStorage";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";
import { RootState } from "../store";


export const loadAllEvents = createAsyncThunk("offers/loadAllEventist", async ({ networkID, provider, wallet, entityAddress, isCache = true }: ICollectionBaseAsyncThunk, { dispatch }): Promise<any> => {

  let allEventDetails = [];
  let eventAddressList = [];

  const EventRegistryContract = EventRegistry__factory.connect(addresses[networkID].EventRegistry, provider);
  const collectionHelper = CollectionHelper__factory.connect(addresses[networkID].CollectionHelper, provider);
  const CollectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
  eventAddressList = await EventRegistryContract.getAllEvents(entityAddress);


  if (eventAddressList?.length) {
    eventAddressList.map(async (address, index) => {
      const EventFactory = Event__factory.connect(address, provider);
      const details = await EventFactory.eventData();
      const area = await EventFactory.getEventArea();
      const radius = parseInt(area?.radius?._hex, 16);
      let coordinates;
      if (area?.points?.length == 2) {
        coordinates = area?.points
      } else {
        coordinates = [area?.points[0]?.split(',')[0], area?.points[0]?.split(',')[1]]
      }


      const linkedCollections = await collectionHelper.getLinkedCollections(address);
      const areaCoordinates = convertAreaData(coordinates, Number(radius));

      const allCollaborators = await EventFactory.getAllCollaborators(true);
      const allGatekeepers = await EventFactory.getAllGatekeepers(true);
      const owner = await EventFactory.owner();
      const startDate = parseInt(details?.start?._hex, 16);
      const endDate = parseInt(details?.end?._hex, 16);

      let linkedTickets = [];
      let linkedTicketCollectibles = [];
      let linkedTicketFeaturedOffers = [];


      for (const i of linkedCollections) {
        const collectionType = await CollectionManager.collectionType(i);

        if (collectionType == CollectionType.TICKET) {
          linkedTickets.push(i);
        }
      }

      if (linkedTickets?.length) {
        for (const i of linkedTickets) {
          const linkedCollectiblesForTickets = await collectionHelper.getLinkedCollections(i);
          if (linkedCollectiblesForTickets?.length) {
            for (const x of linkedCollectiblesForTickets) {
              const collectionData = await CollectionManager.collectionData(x);
              if (collectionData.offerType == OfferType.NOTANYOFFER) {
                linkedTicketCollectibles.push(x)
              } else if (collectionData.offerType == OfferType.FEATURED) {
                linkedTicketFeaturedOffers.push(x)
              }
            }
          }
        }
      }



      let event = {
        index: index,
        owner: owner,
        dataURI: "",
        collaborators: allCollaborators,
        inviteCollaborators: allCollaborators.length ? true : false,
        eventTickets: linkedTickets,
        linkedTicketCollectibles: linkedTicketCollectibles,
        linkedTicketFeaturedOffers: linkedTicketFeaturedOffers,

        getKeepers: allGatekeepers,
        imageProps: {
          image: "",
          imageSize: 0,
          thumbnailImage: "",
          thumbnailImageSize: 0,
          optimizedImage: "",
          optimizedImageSize: 0,
        },
        details: "",
        name: "",
        address: address,
        symbol: "",
        isActive: true,
        entityAddress: "",
        price: '0.00',
        priceRate: 0,
        start: null,
        end: null,
        category: CATEGORIES.OTHER,
        offerType: OfferType.NOTANYOFFER,
        collectionType: CollectionType.ANY,
        linkCollectible: linkedCollections,
        subTitle: "",
        whitelistedCollections: [],
        totalSupply: 0,
        tokensEarned: 0,
        privateMessageCap: 0,
        maxMint: 0,
        maxPurchase: 0,
        chain: getNetwork(),
        htmlTemplate: null,
        mintWithLinked: false,
        isPremium: false,
        isVideoCollectible: false,
        video: "",
        isCoupon: false,
        mintWithLinkedOnly: false,
        area: areaCoordinates,
        maxBalance: 0,
        socialMedia: true,
        socialLinks: null,
        passportType: PassportType.REGULAR
      };
      event.name = details?.name;
      event.dataURI = details?.dataURI;
      event.start = (new Date(Number(startDate ?? startDate) * 1000))
      event.end = (new Date(Number(endDate ?? endDate) * 1000));
      if (details?.dataURI) {
        let parsedData;
        if (details?.dataURI?.includes("http")) {
          parsedData = await fetch(details?.dataURI);
        } else {
          const temp = getIPFSLink(details?.dataURI);
          parsedData = await fetch(temp);
        }
        const ipfsData = await parsedData.json();

        if (ipfsData) {
          if (ipfsData?.description) {
            event.details = ipfsData?.description;
          }
          if (ipfsData?.image) {
            event.imageProps.image = getIPFSLink(ipfsData?.image);
            event.imageProps.imageSize = ipfsData?.imageSize;
            event.imageProps.optimizedImage = getIPFSLink(ipfsData?.optimizedImage);
            event.imageProps.thumbnailImage = getIPFSLink(ipfsData?.thumbnailImage);
            event.imageProps.thumbnailImageSize = ipfsData?.thumbnailImageSize;
            event.imageProps.optimizedImageSize = ipfsData?.optimizedImageSize;

          }
        }
      }

      dispatch(updateEventsData(event));
      allEventDetails.push(event);
      await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(address), details);
    })
  }

});

// export const getCollectibleDetails = async ({ networkID, provider, userAddress, collectibleData, index, entityAddress, isSuperAdmin }: ICollectibleAsyncThunk, { isCache = true, fetchLatest = false }): Promise<ICollectibleDetail> => {

export const loadEventDetails = async ({ networkID, provider, eventAddress, index }: IEventAsyncThunk): Promise<any> => {

  // if (eventAddressList?.length) {

  const EventFactory = Event__factory.connect(eventAddress, provider);
  const collectionHelper = CollectionHelper__factory.connect(addresses[networkID].CollectionHelper, provider);
  const CollectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);

  const linkedCollections = await collectionHelper.getLinkedCollections(eventAddress);

  const details = await EventFactory.eventData();
  const area = await EventFactory.getEventArea();
  const radius = parseInt(area?.radius?._hex, 16);
  let coordinates;
  if (area?.points?.length == 2) {
    coordinates = area?.points
  } else {
    coordinates = [area?.points[0]?.split(',')[0], area?.points[0]?.split(',')[1]]
  }

  const areaCoordinates = convertAreaData(coordinates, Number(radius));

  const allCollaborators = await EventFactory.getAllCollaborators(true);
  const allGatekeepers = await EventFactory.getAllGatekeepers(true);
  const owner = await EventFactory.owner();
  const startDate = parseInt(details?.start?._hex, 16);
  const endDate = parseInt(details?.end?._hex, 16);
  let linkedTickets = [];


  for (const i of linkedCollections) {
    const collectionType = await CollectionManager.collectionType(i);

    if (collectionType == CollectionType.TICKET) {
      linkedTickets.push(i);
    }
  }

  let linkedTicketCollectibles = [];
  let linkedTicketFeaturedOffers = [];

  if (linkedTickets?.length) {
    for (const i of linkedTickets) {
      const linkedCollectiblesForTickets = await collectionHelper.getLinkedCollections(i);
      if (linkedCollectiblesForTickets?.length) {
        for (const x of linkedCollectiblesForTickets) {
          const collectionData = await CollectionManager.collectionData(x);
          if (collectionData.offerType == OfferType.NOTANYOFFER) {
            linkedTicketCollectibles.push(x)
          } else if (collectionData.offerType == OfferType.FEATURED) {
            linkedTicketFeaturedOffers.push(x)
          }
        }
      }
    }
  }


  let event = {
    index: index,
    owner: owner,
    dataURI: "",
    inviteCollaborators: allCollaborators.length ? true : false,
    collaborators: allCollaborators,
    eventTickets: linkedTickets,
    linkedTicketCollectibles: linkedTicketCollectibles,
    linkedTicketFeaturedOffers: linkedTicketFeaturedOffers,
    getKeepers: allGatekeepers,
    imageProps: {
      image: "",
      imageSize: 0,
      thumbnailImage: "",
      thumbnailImageSize: 0,
      optimizedImage: "",
      optimizedImageSize: 0,
    },
    details: "",
    name: "",
    address: eventAddress,
    symbol: "",
    isActive: true,
    entityAddress: "",
    price: '0.00',
    priceRate: 0,
    start: null,
    end: null,
    category: CATEGORIES.OTHER,
    offerType: OfferType.NOTANYOFFER,
    collectionType: CollectionType.ANY,
    linkCollectible: linkedCollections,
    subTitle: "",
    whitelistedCollections: [],
    totalSupply: 0,
    tokensEarned: 0,
    privateMessageCap: 0,
    maxMint: 0,
    maxPurchase: 0,
    chain: getNetwork(),
    htmlTemplate: null,
    mintWithLinked: false,
    isPremium: false,
    isVideoCollectible: false,
    video: "",
    isCoupon: false,
    mintWithLinkedOnly: false,
    area: areaCoordinates,
    maxBalance: 0,
    socialMedia: true,
    socialLinks: null,
    passportType: PassportType.REGULAR
  };
  event.name = details?.name;
  event.dataURI = details?.dataURI;
  event.start = (new Date(Number(startDate ?? startDate) * 1000))
  event.end = (new Date(Number(endDate ?? endDate) * 1000));
  if (details?.dataURI) {
    let parsedData;
    if (details?.dataURI?.includes("http")) {
      parsedData = await fetch(details?.dataURI);
    } else {
      const temp = getIPFSLink(details?.dataURI);
      parsedData = await fetch(temp);
    }
    const ipfsData = await parsedData.json();

    if (ipfsData) {
      if (ipfsData?.description) {
        event.details = ipfsData?.description;
      }
      if (ipfsData?.image) {
        event.imageProps.image = getIPFSLink(ipfsData?.image);
        event.imageProps.imageSize = ipfsData?.imageSize;
        event.imageProps.optimizedImage = getIPFSLink(ipfsData?.optimizedImage);
        event.imageProps.thumbnailImage = getIPFSLink(ipfsData?.thumbnailImage);
        event.imageProps.thumbnailImageSize = ipfsData?.thumbnailImageSize;
        event.imageProps.optimizedImageSize = ipfsData?.optimizedImageSize;

      }
    }
  }

  // dispatch(updateEventsData(event));
  // allEventDetails.push(event);
  await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(eventAddress), details);
  return event
}

  

export const CreateEvent = createAsyncThunk("Events/CreateEvent", async ({ networkID,dataURI, eventData, address, EntityAddress, wallet, chainID = networkID }: { networkID,dataURI, eventData: IEventDetails | any, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    let name = eventData?.name?.trim() ?? "";
    // const _verifierAddress = addresses[chainID].Verifier;
    // const _userContract = addresses[chainID].User;
    let startDate = eventData?.start ? Math.floor(new Date(eventData?.start).getTime() / 1000) : 0;
    let endDate = eventData?.end ? Math.floor(new Date(eventData?.end).getTime() / 1000) : 0;
    let _eventData = [
        name, EntityAddress, dataURI, startDate, endDate, getZeroArray(20)
    ]
    let area = [[eventData?.area.latitude, eventData?.area.longitude], Number(eventData?.area.radius)]

    const data = GetCreateEventDataMessage(
        _eventData,
        area
    );

    

    if (address) {
        let msg: IMessageMetaData = {
            to: addresses[chainID].EventFactory,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            const createdCollection = GetEventCreatedLogData(res.payload?.eventLogs);
            
            if (createdCollection === ZERO_ADDRESS) {
                LogToConsole('Collection Creation New: transaction failed...')
            }
            return createdCollection;
        }
    }
    return ZERO_ADDRESS;
});

export const UpdateEvent = createAsyncThunk("Events/UpdateEvent", async ({ networkID,dataURI, eventData, address, EntityAddress, wallet, chainID = networkID }: { networkID,dataURI, eventData: IEventDetails | any, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
  let name = eventData?.name?.trim() ?? "";
  // const _verifierAddress = addresses[chainID].Verifier;
  // const _userContract = addresses[chainID].User;
  let startDate = eventData?.start ? Math.floor(new Date(eventData?.start).getTime() / 1000) : 0;
  let endDate = eventData?.end ? Math.floor(new Date(eventData?.end).getTime() / 1000) : 0;
  let _eventData = [
      name, EntityAddress, dataURI, startDate, endDate, getZeroArray(20)
  ]
  let area = [[eventData?.area.latitude, eventData?.area.longitude], Number(eventData?.area.radius)]
  
  const data = GetUpdateEventDataMessage(
      _eventData,
      area
  );

  if (address) {
      let msg: IMessageMetaData = {
          to: eventData?.address,
          wallet: wallet,
          data: data,
          networkID: chainID,
          provider: getAnynetStaticProvider(chainID)
      }
      LogToConsole('eventUpdationMessage', msg);

      let res = await dispatch(SendMetaTX(msg));
      
      if (res && res.payload?.eventLogs) {

          const createdCollection = GetEventCreatedLogData(res.payload?.eventLogs);
          
          if (createdCollection === ZERO_ADDRESS) {
              LogToConsole('Collection Creation New: transaction failed...')
          }
          return createdCollection;
      }
  }
  return ZERO_ADDRESS;
});



export const AddCollaborators = createAsyncThunk("Events/AddCollaborators", async ({ networkID,contractAddress, addresses, address, wallet, chainID = networkID }: { networkID,contractAddress:string, addresses: string[], address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetAddCollaboratorsMessage(addresses);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})

export const RemoveCollaborator = createAsyncThunk("Events/RemoveCollaborator", async ({ networkID,contractAddress, collabAddress, address, wallet, chainID = networkID }: { networkID,contractAddress:string, collabAddress: string, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetRemoveCollaboratorsMessage(collabAddress,false);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));

        if (res && res.payload?.eventLogs) {


        }
    }
    return ZERO_ADDRESS;

})


export const AddGatekeepers = createAsyncThunk("Events/AddGatekeepers", async ({ networkID,contractAddress, addresses, address, wallet, chainID = networkID }: { networkID,contractAddress:string, addresses: string[], address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetAddGateKeeperMessage(addresses);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})

export const RemoveGateKeepers= createAsyncThunk("Events/RemoveGateKeepers", async ({ networkID,contractAddress, getKeeperAddress, address, wallet, chainID = networkID }: { networkID,contractAddress:string, getKeeperAddress: string, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
    const data = GetRemoveGateKeeperMessage(getKeeperAddress,false);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: contractAddress,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('eventCreationMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})


export const RemoveEvent = createAsyncThunk("Events/RemoveEvent",async ({ networkID,eventAddress,EntityAddress, address, wallet, chainID = networkID }: { networkID,eventAddress, address, EntityAddress, wallet, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {

    const data = GetRemoveEventMessage(eventAddress,EntityAddress);
    
    if (address) {
        let msg: IMessageMetaData = {
            to: addresses[chainID].EventRegistry,
            wallet: wallet,
            data: data,
            networkID: chainID,
            provider: getAnynetStaticProvider(chainID)
        }
        LogToConsole('removedEventMessage', msg);

        let res = await dispatch(SendMetaTX(msg));
        
        if (res && res.payload?.eventLogs) {

            
        }
    }
    return ZERO_ADDRESS;

})

export interface IEventSliceData {

  readonly AllEntityEventsDetails: ICollectibleDetail[];
  readonly Eventsloading: boolean;
}

const initialState: IEventSliceData = {
  AllEntityEventsDetails: null,
  Eventsloading: false,
};

const EventSlice = createSlice({
  name: "OfferDetails",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateRemovedEvent(state, action) {
      let addressOfEventToBeRemoved = action?.payload?.address;
      const allEvents = state?.AllEntityEventsDetails ?? [];
      state.AllEntityEventsDetails = allEvents?.filter(i => i?.address?.toLowerCase() != addressOfEventToBeRemoved?.toLowerCase());
      //  = filteredPassports?.sort((a,b) => Number(b.index) - Number(a.index));
    },
  
  
    updateEventsData(state, action) {

      let allCollectibleData = state.AllEntityEventsDetails ?? [];
      if (action.payload) {
        let collectionData = allCollectibleData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
        if (collectionData) {
          allCollectibleData = allCollectibleData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else {
          collectionData = action.payload;
        }
        allCollectibleData.push(collectionData);
      }
      state.AllEntityEventsDetails = allCollectibleData.sort((a, b) => Number(b.index) - Number(a.index));
    },
  
 
    setEventsLoading(state, action) {
      state.Eventsloading = action.payload;
    },
  
    clearEventData(state) {
      state.AllEntityEventsDetails = [];
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadAllEvents.pending, (state, action) => {
        state.Eventsloading = true;
      })
      .addCase(loadAllEvents.fulfilled, (state, action) => {
        state.Eventsloading = false;
      })
      .addCase(loadAllEvents.rejected, (state: { Eventsloading: boolean; }, { error }: any) => {
        state.Eventsloading = false;
        LogToConsoleError("loadAllEvents", error.name, error.message, error.stack);
      })
  }

});

export const EventSliceReducer = EventSlice.reducer;

const baseInfo = (state: RootState) => state.Events;

export const { fetchAppSuccess, updateEventsData, updateRemovedEvent, 
  setEventsLoading, clearEventData } = EventSlice.actions;

export const getEventState = createSelector(baseInfo, EventSlice => EventSlice);

