import { Box, Divider, FormControl, Grid, Paper, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { SelectInput } from "../../../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { defaultTheme } from "../../../../styles";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../../../hooks";
import { useState } from "react";
import { NETWORKS, ZERO_ADDRESS } from "../../../../constants";
import { CustomRadioButton } from "../../../../components/RadioButton/CustomRadioButton";
import { wait } from "../../../../helpers/ipfs";
import { delistCollection } from "../../../../slices/collectibleSlice";
import { refreshPassportWhitelistedCollections } from "../../../../slices/PassportSlice";

export const CollectionLookup = ({ setShowLoader, title }) => {

  const { wallet, networkId, staticProvider, address } = useWeb3Context();
  const dispatch = useAppDispatch();

  const [listValue, setListValue] = useState('Passport');
  const [selectedPassport, setSelectedPassport] = useState<string>("");


  const handleRadioValueChange = (event) => {
    setListValue((event.target).value);
  };

  const allPassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails);
  const allIndividualCollections = useAppSelector(state => state.Passports.IndividualWhitelistedCollections);

  const whitelistedCollections = listValue === "Passport" ?
                                  allPassportDetails?.find(collection => collection.address?.toLowerCase() === selectedPassport?.toLowerCase())?.whitelistedCollections
                                  : allIndividualCollections;

  const WhitelistName = listValue === "Passport" ?
                          allPassportDetails?.find(collection => collection.address?.toLowerCase() === selectedPassport?.toLowerCase())?.name 
                          : "LOOT8";

  

  const passportChangedHandler = (event) => {
    let value = event.target.value;
    setSelectedPassport(value);
  }

  const delistCollectionFromPassport = async (collectible) => {

    setShowLoader({ open: true, message: "Delisting under process"});
    let passportAddress = listValue === "Passport" ? selectedPassport : ZERO_ADDRESS;

    if( collectible ) {
      await dispatch(delistCollection({ networkID : networkId, provider: staticProvider, address, collectibleAddressA : collectible.source , collectibleAddressB : passportAddress, wallet, chainID: collectible.chainId}));
      await wait(500);
      await dispatch(refreshPassportWhitelistedCollections({  networkID: networkId, provider: staticProvider }));
    }
              
    setShowLoader({ open: false, message: ''});
    return;
  }
  
  return (

    <Grid container spacing={1} rowSpacing={2} columnGap={2} marginY={'2rem'} >
    {/* First Row */}
    <Grid columnGap={4} item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}  >
      <Typography fontSize={2} variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {title}
      </Typography>
      <Divider orientation='horizontal' variant="fullWidth" sx={{ flex: 1, alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />
    </Grid>
    <Grid item xs={12} >
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioValueChange}
          value={listValue}
        >
          <CustomRadioButton label={"Passport"} value={"Passport"} />
          <CustomRadioButton label={"LOOT8"} value={"LOOT8"} />

        </RadioGroup>
      </FormControl>
    </Grid>
 
    {/* Row */}
    { listValue === "Passport" &&
      <Grid item xs={12}  sm={12} md={6} lg={4} xl={4}>
        <SelectInput label={"Passports"} multiple={false} menuData={allPassportDetails} handleOptionChange={passportChangedHandler} selectedOptions={selectedPassport} disabled={false}/>
      </Grid>
    }

    {/* Row */}
    <Grid item xs={12}  sm={12} md={12} lg={12} xl={12}>
    { 
      ((listValue === "Passport" && allPassportDetails && selectedPassport) || ( listValue === "LOOT8" && allIndividualCollections ))&&
      <TableContainer component={Paper} sx={{ background: '#ffffff07', color: defaultTheme.lightColor }} elevation={5}>
        {
           whitelistedCollections?.length > 0 ?
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>Source</TableCell>
                <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>Network</TableCell>
                { listValue === "Passport" &&
                  <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins-SemiBold'}}>Delist</TableCell>
                }

              </TableRow>
            </TableHead>
            
            <TableBody>
              { whitelistedCollections?.map((collectible, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>{(index+1) + ". "+ collectible.source}</TableCell>
                      <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>{NETWORKS[collectible.chainId].chainName}</TableCell>
                      { listValue === "Passport" &&
                        <TableCell sx={{ color:defaultTheme.buttonDarkTextColor, fontFamily: 'Poppins',  border: 'none', whiteSpace: 'nowrap'}}>
                          <CustomButton 
                            children={"Delist"}
                            className={"w-100  mt-3"}
                            onClick={() => delistCollectionFromPassport(collectible)}
                            color={defaultTheme.buttonLightTextColor}
                            backgroundColor={defaultTheme.buttonRedColor}
                            disabled={false}
                            type={"button"} />
                        </TableCell>
                      }
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
          :
          <Box p={3}>
            <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
              {`No collections whitelisted in ${WhitelistName}`}
            </Typography>
          </Box>
        }
      </TableContainer>
      }
    </Grid>
  </Grid>
  );
}