export const defaultTheme = {
  darkColor : '#000000',
  lightColor: '#FFFFFF',
  buttonRedColor: '#C0136F',
  greyWhite: '#969696',
  buttonGreyColor: '#DBE2ED',
  buttonDarkTextColor: '#252546',
  buttonLightTextColor: '#FCFCFC',
  CardTitle: '#4E4E93',
  CardSubtitle: '#EB3797',
  IconDark: '#252546',
  yellowColor: '#F4B540',
  cardBackground:'#F8F8F8',
  newFieldColor:'#F0ECEC',
  placeHolder: '#0000004F',
  cream:"#F0ECEC",
  pink: '#EB3797',
  
}