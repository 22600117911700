import React from 'react';
import { TextField, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';
import { AddressRegex } from '../../../utils/validators';

export const AddressInput = ({ label, required , disabled , value, setTextValue, placeholder="Enter an address" }) => {

  const [error, setError] = React.useState(false);

  const handleChange = (event) => {
    const inputAddress = event.target.value;
    setTextValue(inputAddress);

    // Validate the input address
    const isValidAddress = validateBlockAddress(inputAddress);
    setError(!isValidAddress);
  };

  const validateBlockAddress = (address) => {
    // regex to check if the input text is an address
    const walletAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return walletAddressRegex.test(address);
  };

  return (
    <div className='mt-2 w-100'>
      <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {label}
      </Typography>
      <TextField
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        FormHelperTextProps={{
          error: true
        }}
        helperText={error ? 'Invalid contract address' : ''}
        required={required}
        disabled={disabled}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false          
        }}
        inputProps={{
          pattern: AddressRegex,
        }}
        variant='outlined'
        fullWidth
        size='small'
        sx={{
          mt: 1
        }}

      />
    </div>
  );
}
