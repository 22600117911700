import React, { useEffect, useMemo, useState } from "react";
import { defaultTheme } from "../../../styles";
import { Box, Button, Grid, IconButton, Select, Typography, styled } from "@mui/material";
import { BillboardTemplate } from "../components/BillboardTemplates";
import { BasicTextInput } from "../../../components/TextInput/BasicTextInput/BasicTextInput";
import { DescriptionTextInput } from "../../../components/TextInput/DescriptionTextInput/DescriptionTextInput";
import { SelectInput } from "../../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { useAppSelector } from "../../../hooks";
import { ImageCard } from "../../CollectionDetails/components/FileUploadCard/ImageCard";
import { CustomButton } from "../../../components/Button/CustomButton";
import { IMAGE_REGEX } from "../../../constants";
import dayjs from "dayjs";
import { CustomDatePicker } from "../../../components/DatePicker/CustomDatePicker";
import { CustomTimePicker } from "../../../components/DatePicker/CustomTimePicker";
import { CatalogTypes } from "../../../enums/catalog.enum";
import { FormatBold, FormatItalic, FormatUnderlined } from "@mui/icons-material";

const ColorPicker = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent', 
  borderRadius: '6px', 
  padding: '0.5rem', 
  minWidth: 'unset',
  ['&:hover']: {
  backgroundColor: 'transparent', 

  }
}));

const ColorBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#d039', 
  width: '1rem', 
  height: '0.75rem', 
  padding: '0.75rem', 
  borderRadius: '4px'
}));

export const BillBoardsFormContainer = ({selectedTime,setSelectedField,mobilePreview=false,shouldUpdate,setSelectedTime,formData,setLoading,handleDisable, collectionAddress, setFormData, handleClickOpen, onSubmitHandler, Template}) => {
  
  const availablePassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails ) ?? [];
  const onNameChangeHandler = (event) => {
    let text = event.target.value;
    setFormData({ ...formData, name: text });
  }

  const onTitleChangeHandler = (event) => {
    let text = event.target.value;
    setFormData({ ...formData, title: text });
  }

  const onSubtextChangeHandler = (event) => {
    let text = event.target.value;
    setFormData({ ...formData, subtext: text });
  }

  const onDescriptionChangeHandler = (event, isStreamyardLink = false) => {
    let text = event.target.value;
    const needsAppend = isStreamyardLink && text.includes("https://streamyard.com/") && !text.includes("?embed=true");

    if (needsAppend) {
      setFormData({ ...formData, description: text + '?embed=true' });
    } else {
      setFormData({ ...formData, description: text });
    }
  }

  const timeHandler = (time) => {
  
    let selectedDate = null;
    if (time) {
      const hours = new Date(time).getHours();
      const minutes = new Date(time).getMinutes();
      const seconds = new Date(time).getSeconds();
      selectedDate = new Date(formData.timestamp)
      selectedDate.setHours(hours, minutes, seconds);
      setFormData({ ...formData, timestamp: selectedDate });
    }else{
      setSelectedTime(null)
      let changedDate = new Date(formData?.timestamp);
      changedDate.setHours(0, 0, 0, 0);
      setFormData({...formData,timestamp:changedDate})
    }
  }

  useEffect(()=>{
    if(formData?.timestamp){
      const temp = dayjs(new Date(formData?.timestamp)).format('HH:mm:ss');
      if(temp != "00:00:00"){
        setSelectedTime(formData?.timestamp)
      }else{
        setSelectedTime(null)
      }
      
    }
  },[formData])
  
  const startDateChangeHandler = (date) => {
    let changedDate = null;
    if (date) {
      changedDate = new Date(date);
      changedDate.setHours(0, 0, 0, 0);
      setFormData({ ...formData, timestamp: changedDate});
    }else{
      setSelectedTime(null)
      setFormData({ ...formData, timestamp: null});
    }
  }

  const linkcollectionChangedHandler = (events: any) => {
    let passports = events.target.value;
    setFormData({ ...formData, passport: passports });
  }

  const imageChangeHandler = async (event: any) => {
    let { files } = event.target;
    if(!files)
    {
      files = event.dataTransfer.files;
    }
    if (files && files[0]) {
      if (IMAGE_REGEX.test(files[0].type.toString())) {

        setFormData({ ...formData, bgImage: files[0]});
      }
    }
  }
  const onCancelImage = async () => {
   
    setFormData({ ...formData, bgImage: ""});
  }

  const isPastTimeAllowed = useMemo(()=>{
    if(formData.timestamp){
      const selectedDate = new Date(formData?.timestamp);
      const todayDate = new Date();

      if(selectedDate > todayDate){
        return false
      }else{
        return true
      }
    }else{
      return true;
    }
  },[formData])

 

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container columnGap={1} rowGap={1} mt={2}>
       
        <Grid item xl={12} lg={12} sm={12} xs={12}>
        <BasicTextInput
        maxLength={collectionAddress == "" ? 45 : null}
        value={formData.name} 
        label={"Announcement Header"} valueChangeHandler={onNameChangeHandler} required={true} 
        disabled={collectionAddress !== ""} 
        placeholder={"Give your announcement a unique name"}/>
        </Grid>

        <Grid item xl={12} lg={12} sm={12} xs={12}>
        <BasicTextInput 
        onClickField={(e)=>setSelectedField(e?.target?.id)}   
        id="title-field"
        maxLength={45}
        value={formData.title} label={"Title"} valueChangeHandler={onTitleChangeHandler} required={true} disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true } placeholder={"Give your announcement a title"}/>
        </Grid>

        <Grid item xl={12} lg={12} sm={12} xs={12}>
        <BasicTextInput
        maxLength={45}
        onClickField={(e)=>setSelectedField(e?.target?.id)}   
        id="subtext-field"
        value={formData.subtext} label={"Subtitle"} valueChangeHandler={onSubtextChangeHandler} required={true} disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true } placeholder={"Give your announcement a subtitle"}/>

        </Grid>

        
        <Grid item xl={12} lg={12} sm={12} xs={12}>
        { Template === 0 ? 
            <BasicTextInput
            infoText="Enter your Streamyard URL here to connect your live streaming setup. This link will integrate your broadcasts directly with your ExPass, enhancing your audience's interactive experience." 
            value={formData.description} label={"Streamyard Link"} valueChangeHandler={(e) => onDescriptionChangeHandler(e, true)} required={true} disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true } placeholder={"Please paste the video streamyard link here"}/>
            :
            <DescriptionTextInput 
            onClickField={(e)=>setSelectedField(e?.target?.id)}   
            id="description-field"
              maxLength={280}
              description={formData.description} 
              label={"Description"} 
              descriptionChangeHandler={onDescriptionChangeHandler} 
              required={true} 
              disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true }
              placeholder={"Give brief description of your announcement"} 
              rows={3} 
            />
            }
        </Grid>

        <Grid columnGap={1} display={'flex'} item xl={12} lg={12} sm={12} xs={12}>

        <Grid item lg={6} xl={6} sm={12} xs={12}>
        <CustomDatePicker 
              label={"Date"} 
              selectedDate={formData.timestamp} 
              datechangedHandler={startDateChangeHandler} 
              disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true }
        />
        </Grid>

        <Grid item lg={6} xl={6} sm={12} xs={12}>
              <CustomTimePicker
              disablePast = {isPastTimeAllowed}
              date={formData?.timestamp} 
              label={"Time"} 
              selectedDate={selectedTime}
              datechangedHandler={timeHandler} 
              disabled={((collectionAddress && formData?.isActive && formData?.timestamp) || (!collectionAddress && formData?.timestamp)) &&
                (() => {
                  const selectedDate = new Date(formData?.timestamp);
                  const todayDate = new Date();
                  
                  // Reset the time part to 00:00:00 for both dates to compare only the date
                  todayDate.setHours(0, 0, 0, 0);
                  selectedDate.setHours(0, 0, 0, 0);

                  // Return false if the selected date is in the future, otherwise true
                  return new Date(formData?.timestamp).getDate() >= new Date().getDate() || selectedDate > todayDate ? false : true;
                })()
              }
            />
              </Grid>
        </Grid>
        {!mobilePreview ? 
        <Grid item lg={12} xl={12} sm={12} xs={12}>
        <SelectInput 
              label={"Associated Passport"} 
              menuData={availablePassportDetails.filter(obj => obj.isActive) } 
              handleOptionChange={linkcollectionChangedHandler} 
              selectedOptions={formData.passport} 
              disabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true }
              multiple={true}
              required={formData?.address=="" ? true : false}
            />
        </Grid> : <></>}
        {Template!=0 ? 
        <Grid item lg={12} xl={12} sm={12} xs={12}>
        <ImageCard 
          catalogType={Template==0 ?  CatalogTypes.EXPASSES : CatalogTypes.ANNOUNCEMENT}
          infoText={Template==0 ? "This image would act as a thumbnail for your announcement listing on Establishment Portal" :""}
          imageType={Template==0 ? "thumbnail" : "original"}
          label={Template==0 ? "Add Thumbnail Image" : "Add Background Image"} 
          clear={onCancelImage} 
          background={ formData?.bgImage ? typeof formData?.bgImage === "string" ? formData?.bgImage : URL.createObjectURL(formData.bgImage) : ""} 
          backgroundImageChangeHandler={imageChangeHandler} 
          fileTypes={"image/jpg, image/jpeg, image/png"} 
          isVideo={false} 
          isDisabled={(collectionAddress && formData?.isActive ) || !collectionAddress  ? false : true }
        />
        </Grid> : <></>}
      </Grid>  
      {!mobilePreview ? 
      <Grid sm={12} xs={12}>
        <Grid container >
      <Grid item sx={{
        display:'flex',
        '@media (max-width:961px)':{
          flexDirection:'column',
          rowGap:1
        }
      }} mt={2} sm={12} xs={12} columnGap={1} lg={12} xl={12}>
            <Grid lg={6} xl={6} sm={12} xs={12}  item>
            <CustomButton
            className={"col-12 col-sm-12 col-lg-12 col-xs-12"}
            onClick={handleDisable}
            color={defaultTheme.buttonLightTextColor}
            backgroundColor={defaultTheme.buttonRedColor}
            type={"button"}
            disabled={(collectionAddress && formData?.isActive ) ? false : true}
          >
            DISABLE
          </CustomButton> 
            </Grid>
            <Grid lg={6} xl={6} sm={12} xs={12} item>
            <CustomButton
            className={"col-12 col-sm-12 col-lg-12 col-xs-12"}
            // onClick={() => { }}
            color={defaultTheme.buttonDarkTextColor}
            backgroundColor={defaultTheme.yellowColor}
            type={"submit"}
            disabled={((collectionAddress && formData?.isActive ) || !collectionAddress) && !shouldUpdate  ? false : true }
            
          >
            SAVE & UPDATE
          </CustomButton>
            </Grid>
        </Grid>
        </Grid>
        </Grid> : <></>}
    </form>
  );
} 
