import { createAsyncThunk, createSelector, createSlice, current } from "@reduxjs/toolkit";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { CollectionManager__factory } from "../typechain/factories";
import { ICollectibleSliceData } from "./collectibleSlice";
import { ZERO_ADDRESS, addresses } from "../constants";
import { IExternalCollectionManager } from "../typechain/CollectionManager";
import { IPrivateChat } from "../interfaces/IPrivateChat.interface";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";
import { getAllCollectibleList, getCollectibleDetails } from "./PassportSlice";
import { CollectionType } from "../enums/collection.enum";
import { ICollectionBaseAsyncThunk } from "./interfaces";
import { RootState } from "../store";

export interface IPrivateChats {
    readonly AllPrivateChats: IPrivateChat[];
    readonly privateChatLoading: boolean;

}

const initialState: IPrivateChats = {
    AllPrivateChats: null,
    privateChatLoading: false,
};







export const loadAllPrivateChats = createAsyncThunk("privateChat/loadAllPrivateChats", async ({ networkID, provider, entityAddress,wallet, isCache = true,isSuperAdmin }: ICollectionBaseAsyncThunk, { dispatch }): Promise<any> => {

    let allPrivateChatsData = [];
    let allPremiumList = (await getAllCollectibleList({ networkID, provider, collectibleType: CollectionType.PRIVATE_CHAT, entity: entityAddress }));

    if (allPremiumList && allPremiumList.length > 0) {
        await Promise.all(allPremiumList.filter(collectibleAddress => collectibleAddress?.source !== ZERO_ADDRESS).map(async (collectibleAddress, _index) => {
            let privateChatDetails: ICollectibleDetail = (await getCollectibleDetails(
                { networkID, provider,userAddress:wallet?.address,isSuperAdmin, collectibleData: collectibleAddress, index: _index, entityAddress },
                { isCache }
            ));

            if (privateChatDetails && privateChatDetails.name !== "") {
                dispatch(updatePrivateChats(privateChatDetails));
                allPrivateChatsData.push(privateChatDetails);
            }
        }));
    }
    if (allPrivateChatsData && allPrivateChatsData.length === 0) {
        dispatch(updatePrivateChats(null));
    }

    LogToConsole("all private chats", allPrivateChatsData);

});




const PrivateChatsSlice = createSlice({
    name: "PrivateChats",
    initialState,
    reducers: {

        updatePrivateChats(state, action) {
            let allPrivateChats = state.AllPrivateChats ?? [];
            if (action.payload) {
                let privateChat = allPrivateChats.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
                if (privateChat) {
                    allPrivateChats = allPrivateChats.filter(obj => obj.address?.toLowerCase() !== privateChat.address?.toLowerCase());
                    privateChat = { ...privateChat, ...action.payload };
                }
                else {
                    privateChat = action.payload;
                }
                allPrivateChats.push(privateChat);
            }
            state.AllPrivateChats = allPrivateChats.sort((a, b) => Number(b.index) - Number(a.index));
        },
        setPrivateChatLoading(state, action) {
            state.privateChatLoading = action.payload;
          },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAllPrivateChats.pending, (state, action) => {
                // state.PremiumChatsLoading = true
            })
            .addCase(loadAllPrivateChats.fulfilled, (state, action) => {
                state.privateChatLoading = false
            })
    },
});


export const PrivateChatReducer = PrivateChatsSlice.reducer;

export const { updatePrivateChats,setPrivateChatLoading} = PrivateChatsSlice.actions;