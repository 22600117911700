import QRCode from 'qrcode.react';
import { WEB_URL } from '../../constants';

const QRGenerator = ({ collectiblesData, index, size=512, dataURI, withToken = false}) => {

const QR_URL = WEB_URL + "scan.html?contract="+collectiblesData.address+"&function=mintNext&chain="+ collectiblesData.chain +"&types=address,string&args=to," + dataURI ;
const QR_URL_TOKEN = WEB_URL + "scan.html?contract="+collectiblesData.address+"&function=mint&chain="+ collectiblesData.chain +"&types=address,uint256,string&args=to," + index +","+ dataURI ;

  return (
    <div>
      <QRCode
        id={withToken? collectiblesData.address+index+dataURI : collectiblesData.address+dataURI}
        value={withToken? QR_URL_TOKEN : QR_URL}
        size={size}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={true}
      />
    </div>
  );
};

export default QRGenerator;