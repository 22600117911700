import { Box, Grid } from "@mui/material";
import { CustomButton } from "../../components/Button/CustomButton";
import { PassportType } from "../../enums/offers.enum";
import { ICollectibleDetail } from "../../interfaces/ICollection.interface";
import { defaultTheme } from "../../styles";
import { BasicTextInput } from "../../components/TextInput/BasicTextInput/BasicTextInput";
import { CollectionFormContainerErrorTypes } from "../../types";
import { CatalogTypes } from "../../enums/catalog.enum";
import { ErrorText } from "../../components/Error/ErrorText";

type CollectionTransferProps = {
    whiteListedAddresses: string[];
    selectedCard: ICollectibleDetail;
    isTransferCollection: boolean;
    address: string;
    toggleTransfer: () => void;
    isTransferCollectionDisabled: boolean;
    errorStates: CollectionFormContainerErrorTypes;
    handleEntityTransfer: () => void;
    transferDetails: { newEntityAddress: string, entityAdminAddress: string }
    handleTransferFields:(e:any)=>void;
    type?:any;
}

export const CollectionTransfer = (props: CollectionTransferProps) => {

    const { whiteListedAddresses,
        isTransferCollection,
        isTransferCollectionDisabled,
        address,
        selectedCard,
        handleEntityTransfer,
        transferDetails,
        handleTransferFields,
        errorStates,
        toggleTransfer,
        type,
    } = props;

    return (
         (selectedCard.passportType === PassportType.SUBSCRIPTON || type==CatalogTypes?.ANNOUNCEMENT) ? !isTransferCollection ?
            <Box sx={{flexDirection:'row',flex:1,justifyContent:{lg:'flex-end',xl:'flex-end',sm:'center',xs:'center',md:'flex-end'},display:'flex'}}>
                <CustomButton
                    type={'button'}
                    disabled={false}
                    color={defaultTheme.buttonDarkTextColor}
                    backgroundColor={defaultTheme.yellowColor}
                    className={""} onClick={toggleTransfer}>
                    Transfer Collection
                </CustomButton>
                </Box>
                 :
                <Grid container display={'flex'} justifyContent={'center'} rowGap={2} columnGap={1}>
                    <Grid item xl={6} lg={6} xs={12} sm={12}>
                        <BasicTextInput id='newEntityAddress' placeholder='Entity Address' disabled={false} value={transferDetails?.newEntityAddress} required={isTransferCollection} label='Entity Address' valueChangeHandler={handleTransferFields} />
                        {errorStates?.entityAddress != "" &&
                            <ErrorText message={errorStates?.entityAddress} />
                        }
                    </Grid>
                    <Grid item xl={6} lg={6} xs={12} sm={12}>
                        <BasicTextInput id='entityAdminAddress' placeholder='Entity Admin Address' disabled={false} value={transferDetails?.entityAdminAddress} required={isTransferCollection} label='Entity Admin Address' valueChangeHandler={handleTransferFields} />
                        {errorStates?.entityAdminAddress != "" &&
                            <ErrorText message={errorStates?.entityAdminAddress} />
                        }
                    </Grid>
                    <Grid item xl={12} lg={12} xs={12} sm={12} />
                    <CustomButton
                        type={'button'}
                        disabled={false}
                        color={defaultTheme.lightColor}
                        backgroundColor={defaultTheme.buttonRedColor}
                        className={"col-sm-5 col-lg-3 col-10"} onClick={toggleTransfer}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                        type={'button'}
                        disabled={isTransferCollectionDisabled}
                        color={defaultTheme.buttonDarkTextColor}
                        backgroundColor={defaultTheme.yellowColor}
                        className={"col-sm-5 col-lg-3 col-10"} onClick={handleEntityTransfer}>
                        Transfer Collection
                    </CustomButton>
                    {errorStates?.linkedCollectionError != "" &&
                        <Grid item xl={12} lg={12} display={'flex'} justifyContent={'center'}>
                            <ErrorText message={errorStates?.linkedCollectionError} />
                    
                        </Grid>
                    }
                </Grid>
                : <></>
        )
}