import { useEffect, useState } from "react";
import "./ThirdpartyCollection.scss";
import { CollectionLookup } from "./components/CollectionLookup/CollectionLookup";
import { useAppSelector } from "../../hooks";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { WhiteListingCollection } from "./components/WhiteListingCollection/WhiteListingCollection";

export const ThirdpartyCollectionDetails = () => {

  const [loading, setLoading] = useState({ open: false, message: "" });
  const passportLoading = useAppSelector(state => state.Passports.loading);


  useEffect(() => {
    if(passportLoading) {
      setLoading({open: true, message: "Fetching passport details"});
    }
    else {
      setLoading({open: false, message: ""});

    }
  },[passportLoading]);
  return (
    <>
     { loading.open && <LoaderModal open={true} message={loading.message}/> }

      <div className="thirdparty-collection-container">
        <div className="thirdparty-collection-container-title">
          Third Party Collections - Management
        </div>
        <div className="my-3 ms-3">
          <WhiteListingCollection setShowLoader={setLoading} title={"1. Passport - Whitelist Collection"}  isPassport={true} />
          {/* <DeListingCollection setShowLoader={setLoading} /> */}
          {/* <WhiteListingCollection setShowLoader={setLoading} title={"2. Whitelist Collection - LOOT8"} isPassport={false} /> */}

          <CollectionLookup setShowLoader={setLoading} title={"2. Whitelisted Lookup"}/>
        </div>
      </div>
    </>
  );
}