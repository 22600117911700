import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IAlert {
  open: boolean;
  message: string;

}
interface IAlertContext {  
  showToastError: IAlert,
  setShowToastError: (value: IAlert) => void
}

export const AlertContext = createContext<IAlertContext | null>(null);

export const useAlert = () => {
  const alertContext = useContext(AlertContext) as IAlertContext;
  const initialState: IAlert = {
    open: false,
    message: ""
  } 
  let defaultState: IAlertContext  = {
    showToastError: initialState,
    setShowToastError: (value: IAlert) : void => {}
  }
  
  if(alertContext) {
    defaultState = alertContext;
  }

  return useMemo<IAlertContext>(() => defaultState, [defaultState]);
}

export const AlertProvider = ({ children }) => {

  const [showToastError, setShowToastError] = useState<IAlert>({ open: false, message: "" }); 

  const preferences = useMemo(
    () => ({
      showToastError,
      setShowToastError
    }),
    [
      showToastError,
      setShowToastError
    ]
  );
  return (
    <AlertContext.Provider value={preferences}>
      {children}
    </AlertContext.Provider>
  );
}