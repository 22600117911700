import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import CssBaseline from '@mui/material/CssBaseline';
import "./App.scss";
import { useAppSelector } from "./hooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";
import { loadUserDetail, setMailStatus } from "./slices/AppUserSlice";
import { loadEntityDetails } from "./slices/EntitySlice";
import { useWeb3Context } from "./hooks/web3Context";
import { getData } from "./helpers/AppStorage";
import { APP_STORAGE_USER_KEY, getAppConfiguration, getMaintenanceInfo } from "./constants";

import { dark as darkTheme } from "./themes/dark.js";
import useTheme from "./hooks/useTheme";
import { ThemeProvider } from "@emotion/react";
import { AdminAccessPage } from "./views/AdminAccessPage/AdminAccessPage";
import LoginPage from "./views/LoginPage/LoginPage";
import Preloader from "./components/Preloader/Preloader";
import { UserPanelView } from "./views/UserPanel/UserPanel";
import { getRequestMailStatus } from "./services/Message.service";
import { EntitySelectPage } from "./views/EntitySelect/EntitySelect";
import { LogToConsoleError } from "./helpers/Logger";
import { AlertProvider } from "./hooks/Alert";
import { CustomToast } from "./components/CustomToast/CustomToast";
import { Route } from "react-router-dom";
import VerifyUser from "./views/VerifyUserPage/VerifyUser";
import OnboardingProfilePage from "./views/OnboardingProfilePage";
import ProfileCreation from "./views/ProfileCreation/ProfileCreation";
import { LoaderModal } from "./components/ModalComponents/LoaderModal";
import MaintenanceMode from "./components/MaintenanceMode/MaintenanceMode";


const App = () => {
  const [theme, toggleTheme] = useTheme();
  const { connected, connect, address , wallet, networkId, staticProvider, userInfo, connectLoading} = useWeb3Context();
  const [loading , setLoading] = useState(true);
  const EntityDetails = useAppSelector(state => state.Entity);
  const isSuperAdmin = useAppSelector((state) => state.Entity.isSuperAdmin)
  const userLoading = useAppSelector(state => state.AppUser.loading);
  const userDetails = useAppSelector(state => state.AppUser.UserData);
  const [profileLoader,setProfileLoader] = useState<boolean>(false);
  const [freeProfileLoading,setFreeProfileLoading] = useState({open:false,message:""});
  const passportLoading = useAppSelector(state=>state.Passports.loading)
  const [inMaintenanceMode, setInMaintenanceMode] = useState(false)
  const [maintenanceText, setMaintenanceText] = useState("")

  useEffect(() => {
    const isSessionExists = async () => {
      setLoading(true);
      let isUserExists = await getData(APP_STORAGE_USER_KEY);
      if (isUserExists) {
        await connect();
        setLoading(true);
      }
      else {
        setLoading(false);
      }
    };
    isSessionExists();
  }, [])

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const loadDetails = async () => {
      setLoading(true)

      const maintenanceInfo = await getMaintenanceInfo()
      const config = await getAppConfiguration()

      let isUnderLocalMaintenance =
        config?.UnderMaintenance?.platform?.estPortal || false
      if (isUnderLocalMaintenance || maintenanceInfo?.inMaintenance) {
        setMaintenanceText(
          isUnderLocalMaintenance
            ? config?.UnderMaintenance?.message
            : maintenanceInfo?.maintenanceInfo
        )
        setInMaintenanceMode(true)
        setLoading(false)
      } else if (connected && wallet) {
        setMaintenanceText("")
        setInMaintenanceMode(false)

        try {
          await dispatch(
            loadUserDetail({
              networkID: networkId,
              provider: staticProvider,
              address,
              userInfo,
              wallet
            })
          )
          await dispatch(
            loadEntityDetails({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet: wallet
            })
          )

          const status = await getRequestMailStatus(wallet)

          await dispatch(setMailStatus(status))
        } catch (e) {
          LogToConsoleError(e)
        }
        setLoading(false)
      }
    }
    loadDetails()
  }, [connected, wallet])

  
  const passports = useAppSelector(state=>state.Passports.AllEntityPassportDetails);

  return (
  <AlertProvider>
    <ThemeProvider theme={darkTheme}>
      {inMaintenanceMode ? <MaintenanceMode maintenanceText={maintenanceText}/> : 
        loading || userLoading || connectLoading || profileLoader ? <Preloader/> : 
        connected ? freeProfileLoading.open ? <LoaderModal open={true} message={freeProfileLoading.message}/> :
        <>
          <CssBaseline/>
          <div className={`app container-fluid m-0 p-0 ${theme}`}>
            {userDetails && userDetails?.isExist ? 
              EntityDetails.AllEntityData && EntityDetails.AllEntityData.length > 0  ? 
              // userDetails?.isOnboarded && !userDetails?.isExist ? <ProfileCreation/> : 
              EntityDetails.EntityAddress !== "" ?
              !isSuperAdmin && !passportLoading && passports?.filter(i=>i.isActive && !i.isPremium).length==0 ? 
              <ProfileCreation connected={connected} 
              address={address}  
              wallet={wallet} 
              networkId={networkId} staticProvider={staticProvider} 
              setFreeProfileLoading={setFreeProfileLoading}
              /> :
              <UserPanelView setLoading={setLoading} /> :
              <EntitySelectPage/> :  <AdminAccessPage /> : 
              <OnboardingProfilePage setProfileLoader={setProfileLoader} isOnboarding={true} />
            }
          </div>
        </>
        :
        <LoginPage setLoading={setLoading}/>
      }
     

      <Route path={'/verify-user'} exact component={(props) => <VerifyUser props={props} />}/>

      <CustomToast/>
    </ThemeProvider>
  </AlertProvider>

  );
}

export default App;