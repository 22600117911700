import { Wallet, ethers } from "ethers";
import { IPNS_UPLOAD_LAMBDA_URL, MASTER_KEY, SOCIAL_MEDIA_API } from "../constants";
import { fetchWithTimeout, retryFetch } from "../helpers/ipfs";

const separator: Buffer = Buffer.from([0x01]);

// muting/unmuting user from social media [<feed>] [<account-address>] [true|false]
export async function muteAccount(account: string, feed: string, status: boolean, wallet: Wallet, passportAddress: string = ""): Promise<any> {

  // const masterWallet = new ethers.Wallet(MASTER_KEY);
  let sstatus: string = status.toString().toLowerCase();

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(account, "utf-8"));
  chunks.push(separator);
  chunks.push(Buffer.from(feed, "utf-8"));
  chunks.push(separator);
  chunks.push(Buffer.from(sstatus, "utf-8"));

  const signature = await wallet.signMessage(Buffer.concat(chunks));

  let body = null;

  // POST /admin/account/:account/mute/:feed/:status/:signature?passport=<passportAddress>

  let requestURL = SOCIAL_MEDIA_API + `admin/account/${account}/mute/${feed}/${sstatus}/${signature}`;

  if(passportAddress && passportAddress !== "") {
    requestURL += `?passport=${passportAddress}`
  }

  const response = await retryFetch(() => fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }));

  return response;
}

export async function getMutedUsers(feed: string, wallet: Wallet, passportAddress: string = ""): Promise<any> {

  // POST /admin/account/:feed/:signature

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(feed, "utf-8"));

  const signature = await wallet.signMessage(Buffer.concat(chunks));

  let requestURL = SOCIAL_MEDIA_API + `admin/account/${feed}/${signature}`;

  if(passportAddress && passportAddress !== "") {
    requestURL += `?passport=${passportAddress}`
  }

  const response = await retryFetch(() => fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: null }));

  return response;  
}

export async function getCollectionFeedSettings(feed: string) {

  // POST /admin/feed/:feed/settings/:signature
  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(feed, "utf-8"));

  const masterWallet = new ethers.Wallet(MASTER_KEY);

  const signature = await masterWallet.signMessage(Buffer.concat(chunks));

  let requestURL = SOCIAL_MEDIA_API + `admin/feed/${feed}/settings/${signature}`;

  const response = await retryFetch(() => fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: null }));

  return response;  
}

export async function updateCollectionFeedSettings(feed: string, wallet: Wallet, status: boolean, passportAddress: string) {

  // POST /admin/feed/:feed/settings/update/:signature
  const body =  JSON.stringify({ messagingDisabled : status });

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(feed, "utf-8"));

  const signature = await wallet.signMessage(Buffer.concat(chunks));

  let requestURL = SOCIAL_MEDIA_API + `admin/feed/${feed}/settings/update/${signature}`;

  if(passportAddress && passportAddress !== "") {
    requestURL += `?passport=${passportAddress}`
  }

  const response = await retryFetch(() => fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }));

  return response;  

  
}

export async function sendRequestEmail(message : any, wallet: Wallet,  ) {

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  
  const data = Buffer.from(JSON.stringify(message), "utf-8");

  // sign data
  const signature = await wallet.signMessage(data);
  // sign signature with master key
  const masterSignature = await masterWallet.signMessage(signature);

  const requestURL = IPNS_UPLOAD_LAMBDA_URL + `user/${wallet.address}/mail/${signature}/${masterSignature}`;

  const response = await fetchWithTimeout(requestURL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(message)
  });

  return response;
  
}

export async function getRequestMailStatus( wallet: Wallet) {

  const masterWallet = new ethers.Wallet(MASTER_KEY);

  const signature = await wallet.signMessage(Buffer.from(wallet.address, "utf-8"));

  const masterSignature = await masterWallet.signMessage(signature);

  const requestURL = IPNS_UPLOAD_LAMBDA_URL + `user/${wallet.address}/mail/status/${signature}/${masterSignature}`;

  const response = await fetchWithTimeout(requestURL, {
    method: 'POST',
    body: null
  });
  const result = await response.json();

  return result.timestamp ?? 0; 
  
}