import { FormLabel, styled } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { MouseEventHandler } from "react";
import edit_icon_new from '../../../assets/svg/edit_icon_new.svg'
import { useAlert } from "../../../hooks/Alert";
import { IMAGE_REGEX, IMAGE_UPLOAD_LIMIT } from "../../../constants";


const ColorButton = styled(FormLabel)<ButtonProps>(({ theme }) => ({
  fontFamily: "Poppins-SemiBold",
  padding: '0.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: '6px',
  '&:disabled': {
    opacity: 1,
    cursor: 'not-allowed',
  },
  position: 'absolute',
  bottom: 0,
  right: 0
}));

interface ProfilePicUploadProps {
  children?: any;
  className: string;
  required: boolean;
  disabled: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fileTypes: string;
  fileChangeHandler: (event: any)=> void;
}
export const ProfilePicUpload = ({className, required=true, disabled, onClick, fileTypes, fileChangeHandler }: ProfilePicUploadProps) => {
  
 const { setShowToastError } = useAlert();

  const filehandler = (event) => {
    const { files } = event.target;
    if(files && files[0]) {
      if(files[0].size >= IMAGE_UPLOAD_LIMIT){
        setShowToastError({ open:true, message: "Max file size allowed is 30 MB"});
        return;
      }
      if(!IMAGE_REGEX.test(files[0].type.toString())) {
          setShowToastError({ open:true, message: "Invalid file type"});
          return;
      }
      fileChangeHandler(event);
    }
  }

  return (
  <div>
    <ColorButton variant="contained" size="small"  className={className}>
    {/* <EditTwoToneIcon sx={{ color: defaultTheme.darkColor }} /> */}
    <img className='edit-container' src={edit_icon_new} alt="_blank" />
    <input
        accept={fileTypes}
        type="file"
        onChange={filehandler}
        required={required}
        style={{ opacity: 0, width: 1 }}
        disabled={disabled}
        onClick={(event) => { event.currentTarget.value = null }}
    />
    </ColorButton>
  </div>
  );
}