import '../Profile.scss'
import { Grid, IconButton } from "@mui/material"
import { useEffect, useState } from 'react'
import pink_close from '../../../assets/images/icons/pinkClose.png'
import { EditTextInput } from '../../../components/TextInput/EditTextInput/EditTextInput'

export const Form = ({userName, userBio, onSave, isOnboarding=false, isRequired = false, setUserName, setUserBio, isLoadingName = false, isLoadingStatus= false}) => {
    
    const STATUS_MAX_LENGTH = 70;
    const NAME_MIN_LENGTH = 3;
    const NAME_MAX_LENGTH = 35;

    const [name, setName] = useState(userName);
    const [bio, setBio] = useState(userBio ? userBio : '');
    const [enableNameUpdate, setEnableNameUpdate] = useState(false);
    const [enableStatusUpdate, setEnableStatusUpdate] = useState(false);

    const onUpdateName = () => {
         onSave(name, null)
         setEnableNameUpdate(false);
    }

    const onUpdateStatus = () => {
        onSave(null, bio)
        setEnableStatusUpdate(false)
    }

    const nameCloseIcon = <IconButton
    onClick={() => {
        setName('')
    }}>
        <img className='close-icon-input' src={pink_close} alt='_blank'/>
    </IconButton>

    const statusCloseIcon = <IconButton
    onClick={() => {
        setBio('')
    }}>
        <img className='close-icon-input' src={pink_close} alt='_blank'/>
    </IconButton>


    useEffect(() => {
        setUserName(name);
        setUserBio(bio);
    }, [name,bio])

    return (
        <>
            <Grid item sm={12} xs={12} lg={12} xl={12}>
                <EditTextInput
                    variant='outlined' placeholder={"Your name"}
                    onChange={(val) => setName(val)}
                    label={"Your Name"}
                    value={name}
                    enableUpdate={enableNameUpdate}
                    onUpdate={onUpdateName}
                    closeIcon={nameCloseIcon}
                    normalTextField={true}
                    required={isRequired}
                    minLength={NAME_MIN_LENGTH}
                    maxLength={NAME_MAX_LENGTH}
                />                             
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12} xl={9} />
            <Grid item sm={12} xs={12} lg={12} xl={12}>
                <EditTextInput
                    variant='outlined' placeholder={"e.g Hi there ! "}
                    onChange={(val) => setBio(val)} 
                    label={"Enter Tagline"} 
                    value={bio}
                    enableUpdate={enableStatusUpdate}
                    onUpdate={onUpdateStatus}
                    closeIcon={statusCloseIcon}
                    normalTextField={true}
                    maxLength={STATUS_MAX_LENGTH}
                    />
            </Grid>
        </>
    )
}