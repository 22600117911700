import React from 'react';
import { Checkbox, IconButton, MenuItem, Select, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';
import { RemoveCircle } from '@mui/icons-material';

type Props = {
  alreadyLinkedPassport?:any[],
  handleRemove?:any
  label:string
  multiple?:boolean 
  menuData:any[],
  handleOptionChange:(item:any)=>void
  selectedOptions:any
  disabled:boolean
  placeholder?:string, 
  required?:boolean,
  formContainer?:boolean
  allowRemove?:boolean
}

export const SelectInput = (props:Props) => {

  const {alreadyLinkedPassport=[],handleRemove,label, multiple = true, menuData, handleOptionChange,formContainer , selectedOptions, disabled, placeholder = "Select passport", required=false,allowRemove=false} = props;
  
  return (
    <div className='mt-2'>
      <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        { label }
      </Typography>
      <Select 
        className='input-select-passport-value w-100' 
        value={selectedOptions}
        onChange={(event, child) => handleOptionChange(event)}
        required={required}
        multiple={multiple}
        disabled={disabled}
        renderValue={(value : any) => {
          if (value.length === 0) {
            return (
              <Typography variant="body2" color={defaultTheme.placeHolder} fontFamily={'Poppins-Medium'} >
                { placeholder }
              </Typography>
            )
          }
          else return (
            
              multiple ? 
              value?.map((obj) => menuData?.find(collection => collection.address?.toLowerCase() === obj?.toLowerCase())?.name ?? "").filter(e => e !== "" ).join(', ') 
              : 
              menuData?.find(collection => collection.address?.toLowerCase() === value?.toLowerCase())?.name
            )}
          }
        fullWidth
        sx={{
          mt: 1,
          backgroundColor: defaultTheme.newFieldColor,
          color: defaultTheme.buttonDarkTextColor,
          fontFamily: 'Poppins-Medium',
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: 'none',
          },
          [`& .${outlinedInputClasses.disabled}`]: {
            WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
            cursor: 'not-allowed'
          }
        }}
        SelectDisplayProps={{
          style: {
            paddingBlock: 8.5,
            maxWidth: formContainer ?  "24rem" : "100%"

          }
        }}
        MenuProps={{
          MenuListProps: {
            sx: {
              padding: 0 , 
              '& .Mui-selected': {
                backgroundColor: '#a8189030', // Replace with your desired color
                ':hover': {
                  backgroundColor: '#a818901f'
                }
              },
            },
          },
          PaperProps: {
            sx: {
              maxWidth: '5rem',
              maxHeight: '18rem'
            }
          },
        }}
        displayEmpty
        defaultValue={multiple? []: ''}
      >
        {menuData && menuData.map(( data, index) => {
          let checked = multiple && selectedOptions?.indexOf(data.address) > -1 ;
          return (
            <MenuItem 
            disabled={alreadyLinkedPassport && alreadyLinkedPassport?.length ?  
            alreadyLinkedPassport.find(i=>i?.address?.toLowerCase() == data?.address?.toLowerCase()) ? true : false : false}
            style={{
                whiteSpace: 'break-spaces',
                paddingInline: 5,
                fontFamily: 'Poppins-Medium',
            }}
            value={data.address}
            key={data.address}

            >
            {allowRemove && selectedOptions && selectedOptions==data?.address ?  <IconButton onClick={handleRemove}><RemoveCircle/></IconButton> : <></>}
            { multiple && <Checkbox sx={{ padding: 0.75, '&.Mui-checked': { color: '#C0136F', }}} checked={checked} />} 
            { data.name }
            </MenuItem>
        )})}
      </Select>
    </div>
  );
}