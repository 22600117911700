import { LogToConsoleError } from "./Logger";
import { fetchUsersData } from './GraphQLHelperSubgraph';

const getPagedUserData = async (skip: number, pageSize: number) => {
  const response = await fetchUsersData(skip, pageSize);
  try {
    if (response?.records?.length > 0) {
      return response;
    } else {
      return null;
    }
  } catch (ex) {
    LogToConsoleError(
      'Error occurred: getPagedUserData sub query',
      null,
      ex.message,
      ex.stack,
      null,
    );
  }
};

export const getAllUsersDataFromQuery = async (pageSize: number = 50) => {
    try {
      let allUserData = [];
      let skip = 0;
      let morePages = true;
  
      while (morePages) {
        let userData = await getPagedUserData(skip, pageSize);
        if (userData) {
          let data = userData?.records;
          if (data && data?.length > 0) {
            data.forEach(u => {
              let user = {
                id: Number(u?.userId),
                name: u?.name,
                wallet: u?.wallet,
                avatarURI: u?.avatarURI,
                dataURI: u?.dataURI ?? '',
              };
              allUserData.push(user);
            });
  
            // If the data length is less than the page size, it means it's the last page
            if (data?.length < pageSize) {
              morePages = false;
            }
  
            // Increment skip for the next iteration
            skip += pageSize;
          } else {
            // Stop if no more data is returned
            morePages = false;
          }
        }
      }
  
      return allUserData;
    } catch (ex) {
      LogToConsoleError(
        'Error occurred: getAllUsersData query',
        null,
        ex.message,
        ex.stack,
        null,
      );
    }
  };