import React, { useState } from 'react';
import { Box, BoxProps, Divider, Modal, ModalProps, Paper, PaperProps, Typography, styled } from "@mui/material";

import { CircularProgress } from '@mui/material';
import { AddressInput } from '../TextInput/AddressInput/AddressInput';
import { defaultTheme } from '../../styles';
import { CustomButton } from '../Button/CustomButton';
import { useDispatch } from 'react-redux';
import { useAppSelector, useWeb3Context } from '../../hooks';
import { addBartender, loadAllBartenderList } from '../../slices/SettingSlice';
import { AppDispatch } from '../../store';

const StyledModal = styled(Modal)<ModalProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  zIndex:99999,
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: '#0000004a'

}));

const Content = styled(Paper)<PaperProps>(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: 0,
  outline: 'none',
  position: 'relative',
  '@media (max-width: 961px)': {
    maxWidth: '100%',

  },
  maxWidth: '30vw',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden'

}));

type Props = {
  cancelText?:string,
  confirmText?:string,
  modalOpen:boolean, 
  text?:any, 
  onModalCancel:()=>void, 
  onModalSubmit :()=>void,
  children?:React.ReactNode
}

export const ConfirmModal = (props:Props) => {

  const {cancelText="No",confirmText="Yes",modalOpen, text, onModalCancel, onModalSubmit } = props

  return (
    <StyledModal open={modalOpen} onClose={onModalCancel} >
      <Content>
        <div className='w-100 h-100'>
          <Typography fontFamily={'Poppins-Medium'} fontSize={'1rem'} 
            sx={{ 
              backgroundColor: '#c63fb4',
              color: defaultTheme.lightColor,
              padding: 1
            }} 
            textAlign={'center'}>{"Confirm Action"}</Typography>
        </div>
        <div className='w-100'>
          <Divider orientation='horizontal' variant='fullWidth'  sx={{ borderColor: defaultTheme.darkColor }}/>
            {props?.children}
            <Typography fontFamily={'Poppins-Medium'} fontSize={'1rem'} 
              sx={{ 
               
                color: '#5e5eaa',
                padding: 1,
                marginInline: '1rem'
              }} 
              textAlign={'center'}>{text}</Typography>

            <Box width={'100%'}  display={'flex'} justifyContent={'space-evenly'}   >
              <CustomButton  className={'my-3 col-4'} onClick={onModalCancel} color={defaultTheme.buttonDarkTextColor} backgroundColor={defaultTheme.buttonGreyColor}  disabled={false} type={'button'}>{cancelText}</CustomButton>

              <CustomButton  className={'my-3 col-4'} onClick={onModalSubmit} color={defaultTheme.buttonLightTextColor} backgroundColor={defaultTheme.buttonRedColor}  disabled={false} type={'button'}>{confirmText}</CustomButton>
            </Box>
          
        </div>

      </Content>
    </StyledModal>
  );
};
