import { WarningRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { defaultTheme } from "../../styles"

export const ErrorText = ({message}) => {
    return(
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <WarningRounded fontSize={'small'} color={'error'} />
      <Typography ml={'5px'} mt={'2px'} fontFamily={'Poppins-Medium'} fontSize={'12px'} color={defaultTheme?.buttonRedColor}>{message}</Typography>
    </Box>
    )
}