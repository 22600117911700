import { Box, BoxProps, Button, Typography, styled } from "@mui/material";
import { FileUploadButton } from "../../../../components/Button/FileUploadButton";
// import closeIcon from '../../../../assets/images/icons/pinkClose.png'
import closePink from '../../../../assets/svg/closePink.svg'
import editIcon from '../../../../assets/svg/edit_icon_pink.svg'
import { FileUploader } from "./FileUploader";
import "./ImageCard.scss"
import { defaultTheme } from "../../../../styles";
import { useEffect, useRef } from "react";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { LightTooltip } from "../../views/FormContainer.tsx/CollectionFormContainer";

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '10rem',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
  backgroundColor: 'transparent',
  position: 'relative',
  borderRadius: '6px',
  border: '1px dashed #C0136F',
  flexDirection: 'column', // To center content vertically
  textAlign: 'center', // To center text horizontally

}))

type imageType = "thumbnail" | "optimised" | "original"

type Props = {
  background: any,
  catalogType?: string,
  backgroundImageChangeHandler: (event: any, isThumbnail?: imageType) => void
  imageType?: imageType,
  fileTypes: any,
  isVideo: boolean
  isDisabled: boolean,
  infoText?: string,
  label: string,
  subHeading?: string,
  isPassport?:boolean,
  clear: () => void
}

export const ImageCard = (props: Props) => {
  const { background,isPassport, backgroundImageChangeHandler, imageType, fileTypes, isVideo, isDisabled, label = "", clear, infoText } = props
  const dropImageDiv = useRef(null);

  const dragFileHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      backgroundImageChangeHandler(event, imageType);
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }


  useEffect(() => {
    dropImageDiv.current.addEventListener('dragover', handleDragOver);
    dropImageDiv.current.addEventListener('drop', dragFileHandler);

    return () => {
      dropImageDiv?.current?.removeEventListener('dragover', handleDragOver);
      dropImageDiv?.current?.removeEventListener('drop', dragFileHandler);
    };
  }, []);

  return (
    <div className='mt-2 w-100' style={{ position: 'relative' }}>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} mb={1}>
        <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {label}
        </Typography>
        {props?.infoText ?
          <LightTooltip title={props?.infoText} placement="bottom" arrow>
            <InfoRoundedIcon sx={{ fontSize: '1rem', color: defaultTheme.yellowColor, marginLeft: 1 }} />
          </LightTooltip> : <></>}
      </Box>
      {props?.subHeading ?
        <Typography fontSize={10} color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} marginBottom={1} textAlign={'start'}>
          {props?.subHeading}
        </Typography> : <></>}
      <div ref={dropImageDiv}>
        <CustomBox sx={isPassport ? {maxWidth:"100%"}:{maxWidth:"70%"}}>
          {
            !isDisabled && background != "" &&
            <Button onClick={clear} sx={{ padding: 0, minWidth: 'unset', position: 'absolute', top: -20, right: -15, zIndex: 1, }}>
              <img src={closePink} style={{ height: 40, width: 40 }} alt="Close" />
            </Button>
          }
          {background === "" ?
            <Box p={'1rem'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <FileUploader
                catalogType={props?.catalogType}
                imageType={imageType}
                className="uploadButton"
                disabled={isDisabled}
                required={background === "" ? true : false}
                fileTypes={fileTypes}
                fileChangeHandler={backgroundImageChangeHandler}
              />
            </Box>
            :
            !isVideo ?
              <img src={background}
                className="background-image"
                alt=""
                loading="lazy"
                height={'90%'}
                width={'100%'}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                  maxHeight: '10rem'
                }}
              />
              :
              <video className="previewHeight"
                controls
                height={'100%'}
                width={'100%'}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                  maxHeight: '10rem'
                }}>
                <source src={background} type="video/mp4" />
              </video>
          }
        </CustomBox>
      </div>
    </div>
  );
}