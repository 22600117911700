import { useState } from 'react';
import "./PDFGenerator.scss"
import { PDFDownloadLink  } from '@react-pdf/renderer';
import { Box, BoxProps, CircularProgress, MenuItem, Modal, Select, Typography, styled } from '@mui/material';
import { MuiColorInput } from "mui-color-input";
import { getDarkHSL, getHSLRandomColor, getLightHSL } from '../../helpers/colorProperties';
import QRGenerator from '../QRGenerator/QRGenerator';
import { PDFComponent } from '../PDFComponent/PDFComponent';
import Loot8Logo from "./../../assets/images/logos/loot8-logo-black.png";
// import TCFLogo from "./../../assets/images/logos/TCFLogo.png";
// import SYNLogo from "./../../assets/images/logos/Synthopia.png";
import { CustomButton } from '../Button/CustomButton';
import { defaultTheme } from '../../styles';

const LoaderContainer = styled(Box)<BoxProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(2)
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 1
  };
  let POAP_PER_PAGE = 4;
  const A4_SIZE = [ 8.27, 11.69 ]; 

export const PDFGenerator = ({ collectiblesData, showModal, handleClose, image, imageLoading, tokenCount } ) => {
    // const [dataImage, setDataImage] = useState(image);
    const [QRSplit, setQRSplit] = useState([]);
    const [bgcolor, setBgColor] = useState('#FFFFFF');
    const [textColor, setTextColor] = useState('#000000');
    const [randomColor, setborderColor] = useState(getHSLRandomColor());
    const [generated, setGenerated] = useState(false);
    const [dimension, setDimension] = useState(['4','4']);


    const getPoapsPerPage = (dimensions) => {
        
        let row = Math.floor(A4_SIZE[0] / Number(dimensions[0]));
        let col = Math.floor(A4_SIZE[1] / Number(dimensions[1]));

        return row * col;
    }

    const onGenerate = async (event?) => {
        event?.preventDefault();
        POAP_PER_PAGE = getPoapsPerPage(dimension);
        let countFrom = tokenCount.fromCount;
        let countTo = tokenCount.toCount;
        let split = [];
        let dataImage = image;
        // dataImage = await getBase64Image(image);
        if(tokenCount?.countChecked) {

            for(let i=0; i < countTo - countFrom + 1; i+=POAP_PER_PAGE){
                let cluster = [];
                for(let index = countFrom + i ; index < countFrom + i + POAP_PER_PAGE && index <= countTo ; index++){

                    let qrCode = (document.getElementById(collectiblesData.address+index+collectiblesData.dataURI ) as HTMLCanvasElement)?.toDataURL();
                    let result =  { qrUrl: qrCode, tokenID: index, dataImage : dataImage, name: collectiblesData.name };

                    cluster.push(result);
                }
                split.push(cluster);
            }
                
        }
        else {
            let qrCode = (document.getElementById(collectiblesData.address+collectiblesData.dataURI ) as HTMLCanvasElement)?.toDataURL();
            let cluster = Array(POAP_PER_PAGE).fill({ qrUrl: qrCode, tokenID: 0, dataImage : dataImage, name: collectiblesData.name });
            split.push(cluster);
        }

        setQRSplit(split);        
        setGenerated(true);
    }

    const onBorderColorChange = (color, colors) => {
        let hsl = colors?.hsl
        let lightColor = getLightHSL(hsl);
        let darkColor = getDarkHSL(hsl)

        setborderColor({
            hsl: hsl,
            lightColor,
            darkColor: darkColor
        });
        setGenerated(false);
    }

    const onBgColorChange = (color : string) => {
        setBgColor(color);
        let rgb = color.slice(color.indexOf('(')+1, color.lastIndexOf(')')).split(',');
        var r = parseInt(rgb[0]);
        var g = parseInt(rgb[1]);
        var b = parseInt(rgb[2]);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        setTextColor((yiq >= 128) ? '#000000' : '#FFFFFF');
        setGenerated(false);
    }
    
    return (
        <>
            <div>
                {   tokenCount?.countChecked ?
                    [...Array(Number(tokenCount.toCount)+1).keys()].slice(Number(tokenCount.fromCount),Number(tokenCount.toCount)+1).map((idx) =>  { 
                    return (
                        <div style={{ display: 'none' }} key={collectiblesData.address+idx}>
                            <QRGenerator collectiblesData={collectiblesData} index={idx} dataURI={collectiblesData.dataURI} withToken={tokenCount?.countChecked} />
                        </div>
                    )})
                    :
                    <div style={{ display: 'none' }} key={collectiblesData.address}>
                        <QRGenerator collectiblesData={collectiblesData} index={0} dataURI={collectiblesData.dataURI} withToken={tokenCount?.countChecked} />
                    </div>
                }
            </div>

            <Modal 
                open={showModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-container'
            >
              <Box sx={style}>
                { !imageLoading ?
                    <div className='modalBody w-100'>
                        <div className='header-container'>
                            <span >Generate POAP</span>
                        </div>
                        <div><span>Preview</span></div>
                        <div className='preview'>
                            <div className='preview-front' style={{ height: `calc(54px * ${dimension[1]})`, width: `calc(54px * ${dimension[0]})` }}>
                                <div className='section' style={{ 
                                    background: bgcolor,
                                  }}>
                                    <img src={image} className='cardImage' ></img>
                                </div>
                            </div>
                            <div className='preview-back' style={{ height: `calc(54px * ${dimension[1]})`, width: `calc(54px * ${dimension[0]})` }}>
                                <div className='section'>
                                    <div className='qr-section'>
                                        <div className='cardTitle' style={{ fontSize: `calc(4px * ${dimension[0]})` }} ><span>{collectiblesData.name}</span></div>

                                        <div className='cardTitle' style={{ fontSize: `calc(3px * ${dimension[0]})`, marginTop: 5 }} ><span>Scan here to collect</span></div>

                                        <QRGenerator collectiblesData={collectiblesData} index={tokenCount?.fromCount} size={ Number(dimension[1]) * 20 } dataURI={collectiblesData.dataURI} withToken={tokenCount?.countChecked} />
                                        { tokenCount?.countChecked && <div className='cardTitle' style={{ fontSize: `calc(4px * ${dimension[0]})` }} ><span> #{tokenCount?.fromCount} </span></div> }
                                    </div>

                                </div>
                                <div className='logoSection'>
                                    <img className='logoImage' src={Loot8Logo} style={{height: `calc(6px * ${dimension[0]})`}} />
                                    {/* <img className='TcflogoImage mx-1' src={SYNLogo} style={{height: `calc(6px * ${dimension[0]})`}}/> */}
                                </div>
                            </div>
                        </div>
                        <div className='configuration' >
                            <div className='mt-2 px-2 backgroundColorSection'>
                                <label className="input-label pr-2"  htmlFor="">Select Background Color</label>
                                <MuiColorInput value={bgcolor} variant='filled' onChange={(color) => onBgColorChange(color) } className='color-picker'  />

                            </div>
                            <div className='mt-2'>
                            <Select
                                labelId="demo-simple-select-label"
                                className="input-select-chain-value"
                                value={dimension.join(" x ")}
                                onChange={(event) => { let value = event.target.value; setDimension(value.split(" x ")); setGenerated(false); } }
                                required
                            >
                                <MenuItem  value={'3 x 4'}>3 x 4</MenuItem>
                                <MenuItem  value={'4 x 4'}>4 x 4</MenuItem>
                                <MenuItem  value={'4 x 4.5'}>4 x 4.5</MenuItem>
                                <MenuItem  value={'4 x 5'}>4 x 5</MenuItem>
                                <MenuItem  value={'4.25 x 5.5'}>4.25 x 5.5</MenuItem>
                                <MenuItem  value={'4.5 x 5'}>4.5 x 5</MenuItem>
                                <MenuItem  value={'4.5 x 5.5'}>4.5 x 5.5</MenuItem>
                                <MenuItem  value={'4.5 x 6'}>4.5 x 6</MenuItem>
                                <MenuItem  value={'5 x 6'}>5 x 6</MenuItem>
                            </Select>
                            </div>

                        </div>
                        <div className='my-3 submit-section'>
                            <Box sx={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                                <CustomButton
                                  className={"fs-6"} 
                                  onClick={onGenerate} 
                                  color={defaultTheme.darkColor} 
                                  backgroundColor={defaultTheme.buttonGreyColor} 
                                  disabled={collectiblesData?.linkCollectible?.length ? false : true} 
                                  type={"button"} 
                                >
                                  Generate
                                </CustomButton>
                                {!collectiblesData?.linkCollectible?.length ?
                                <Typography color={'red'} mt={1} fontSize={'13px'} fontFamily={'Poppins-Medium'}>Please link a passport to generate QR</Typography> : <></>}
                            </Box>

                         {   generated &&
                            <PDFDownloadLink document={
                                <PDFComponent 
                                    dataSplit={QRSplit} 
                                    usercolors={{ 
                                        textColor: textColor, 
                                        bgcolor: bgcolor,
                                        borderColor: randomColor 
                                    }} 
                                    withToken={tokenCount?.countChecked}
                                    dimension={dimension}
                                    />} 
                                    fileName={`POAP_QR_${collectiblesData?.name?.split(" ").join("_")}.pdf`}>

                                {({ blob, url, loading, error }) => {
                                    return (
                                      <CustomButton
                                        className={"fs-6"}  
                                        color={defaultTheme.darkColor} 
                                        backgroundColor={defaultTheme.buttonGreyColor} 
                                        disabled={false} 
                                        type={"button"} 
                                      >
                                        {loading ? "Loading": "Download" }
                                      </CustomButton>
                                    );
                                }}
                            </PDFDownloadLink>
                        }
                        </div>
                    </div>
                    :
                <div className='loader-center'>
                  <LoaderContainer>
                    <CircularProgress />
                  </LoaderContainer>
                </div>    
                }
                </Box>
            </Modal>
        </>
)};