import { useEffect, useState } from "react";
import "./CollectionDetails.scss"
import { CollectionContainer } from "./views/CollectionContainer";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { CatalogTypes } from "../../enums/catalog.enum";
import { useAppSelector } from "../../hooks";
import { Avatar, Box, Typography } from "@mui/material";
import { defaultTheme } from "../../styles";
import { Wallet } from "ethers";

interface Props {
  collectionData: any[];
  type: CatalogTypes;
  collectionsLoading: boolean;
  setRun?: (item: boolean) => void;
  run?: boolean;
  wallet?: Wallet
}

export const CollectionDetails = ({ type, collectionData, collectionsLoading, setRun, run, wallet }: Props) => {
  const [loading, setLoading] = useState({ open: false, message: "" });
  const entityDetails = useAppSelector(state => state.Entity.EntityData);


  useEffect(() => {
    if (collectionsLoading) {
      setLoading({ open: true, message: `Fetching ${type} Details` });
    }
    else {
      setLoading({ open: false, message: "" });
    }
  }, [type, collectionsLoading]);

  return (
    <>
      {loading.open && <LoaderModal open={true} message={loading.message} />}

      <div className="collection-container" >
        <Typography sx={{
          textAlign: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'left',
          },
          margin: {
            xs: '0 auto',
            sm: '0 auto',
            md: '0 auto',
            lg: 0,
          }
        }}
          variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Bold'} >
          {entityDetails.name}
        </Typography>
        <CollectionContainer wallet={wallet} setRun={setRun} run={run} type={type} collectionData={collectionData ?? []} setLoading={setLoading} loading={loading.open} />
      </div>
    </>

  );
}