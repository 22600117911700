import { Client, createRequest, fetchExchange } from "urql";

import { whitelistedCollectionsQuery, collectionDetailsCombinedQuery, fetchPOAPCollectionsQuery, collectibleTransfersQuery } from "../subgraphQueries/collection";

import { LogToConsoleError } from "./Logger";
import { IUsers } from "../subgraphQueries/IUsers";
import { usersDataQuery } from "../subgraphQueries/users";
import { entityDataQuery } from "../subgraphQueries/entity";
import { getSubgraphConfig, getNetwork } from "../constants";

const fetchData = async (query: any, params: any) => {
  const config = await getSubgraphConfig();
  const nativeChain = getNetwork();

  const endpoint = config.endpoints[nativeChain];

  const client = new Client({
    url: endpoint,
    exchanges: [fetchExchange],
  });

  const request = createRequest(query, {
    ...params,
  });
  try {
    const response = await client.executeQuery(request);
    return response?.data ?? null;
  } catch (e) {
    LogToConsoleError("Error while fetching GraphQL Data", e);
    return null;
  }
};

export const fetchCollectionDetailsCombined = async (
  collectionAddress: string
): Promise<{
  collectionDataAdditional: CollectionDataAdditional;
  collectionMetadata: CollectionMetadata;
  collectionData: CollectionData;
} | null> => {
  const responseData = await fetchData(collectionDetailsCombinedQuery, {
    address: collectionAddress,
  });
  return responseData
    ? {
        collectionDataAdditional: responseData?.collectionDataAdditional?.[0]
          ? {
              ...responseData.collectionDataAdditional[0],
              mintModel: Number(responseData.collectionDataAdditional[0]?.mintModel),
            }
          : null,
        collectionMetadata: responseData?.collectionMetadata?.[0]
          ? {
              ...responseData?.collectionMetadata?.[0],
              name: responseData.collectionMetadata[0]?.name ?? "",
              dataURI: responseData.collectionMetadata[0]?.dataURI ?? "",
              symbol: responseData.collectionMetadata[0]?.symbol ?? "",
              areaPoints: JSON.stringify(responseData.collectionMetadata[0]?.areaPoints),
              linkedCollections: JSON.stringify(responseData.collectionMetadata[0]?.linkedCollections),
              collectionType: Number(responseData.collectionMetadata[0]?.collectionType),
            }
          : null,
        collectionData: responseData?.collectionData?.[0]
          ? {
              ...responseData.collectionData[0],
              offerType: Number(responseData.collectionData[0]?.offerType),
            }
          : null,
      }
    : null;
};

export const fetchWhitelistedCollections = async (passportAddress: string): Promise<WhitelistedCollections[] | null> => {
  const responseData = await fetchData(whitelistedCollectionsQuery, {
    address: passportAddress,
  });
  return responseData?.whitelistedCollectionsForPassport ?? null;
};

export const fetchUsersData = async (skip: number, pageSize: number): Promise<IUsers | null> => {
  const responseData = await fetchData(usersDataQuery, {
    skip,
    first: pageSize,
  });
  return {
    records: responseData?.users ?? null,
  };
};

export const fetchAllEntityDetails = async (skip: number, take: number, isActive: boolean): Promise<EntityDetails[] | null> => {
  const responseData = await fetchData(entityDataQuery, {
    isActive,
    skip,
    take,
  });
  return responseData?.entitiesData
    ? responseData.entitiesData.map((entity: any) => ({
        ...entity,
        name: entity?.name ?? "",
        dataURI: entity?.dataURI ?? "",
      }))
    : null;
};

export const fetchCollectibleTransfers = async (skip: number, pageSize: number, user: any, collectible: any): Promise<ICollectibleTransferEvent[] | null> => {
  const responseData = await fetchData(collectibleTransfersQuery, { skip, first: pageSize, user, collectible });

  return responseData?.collectibleTransfers
    ? responseData.collectibleTransfers.map((ct: any) => ({
        ...ct,
        blockNumber: Number(ct?.blockNumber),
      }))
    : null;
};

export const fetchPOAPCollections = async (skip: number, take: number, entity: string) => {
  const responseData = await fetchData(fetchPOAPCollectionsQuery, { entity, skip, take });

  return responseData?.poapCreatedCollections
    ? responseData.poapCreatedCollections.map((poap: any) => ({
        ...poap,
        blockNumber: Number(poap?.blockNumber),
      }))
    : null;
};
