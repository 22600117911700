import React, { useState } from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { LogToConsole, LogToConsoleError } from '../../helpers/Logger';
import { defaultTheme } from '../../styles';

const CopyIcon = ({ text, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        LogToConsole(text);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
      })
      .catch((error) => {
        LogToConsoleError('Error copying text:', error);
      });
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
    <IconButton onClick={handleCopy} disabled={disabled} onMouseEnter={handlePopoverOpen} >
      <ContentCopyOutlinedIcon sx={{ color: defaultTheme.buttonDarkTextColor }} />
    </IconButton>
    
    <Popover
    open={open && showPopup}
    anchorEl={anchorEl}
    onClose={handlePopoverClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    <Typography sx={{ p: 0.75 }}>Copied</Typography>
  </Popover>
</>
  );
};

export default CopyIcon;
