import { useEffect, useState } from "react";
import "./AdministrationDetails.scss";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { AdministerUsers } from "./components/AdministerUsers/AdministerUsers";
import { AdministrationLookup } from "./components/AdministrationLookup/AdministrationLookup";
import { useAppSelector } from "../../hooks";

export const AdministrationDetails = () => {

  const [loading, setLoading] = useState({ open: false, message: "" });
  const passportLoading = useAppSelector(state => state.Passports.loading);
  const collectiblesLoading = useAppSelector(state => state.Offers.DigitalCollectibleloading);
  const externalCollectiblesLoading = useAppSelector(state => state.Collectibles.loading);
  const [selectedCollection, setSelectedCollections] = useState<string>("");


  useEffect(() => {
    if(passportLoading || collectiblesLoading || externalCollectiblesLoading) {
      setLoading({open: true, message: "Fetching Collectible details"});
    }
    else {
      setLoading({open: false, message: ""});

    }
  },[passportLoading, collectiblesLoading, externalCollectiblesLoading]);
  return (
    <>
     { loading.open && <LoaderModal open={true} message={loading.message}/> }

      <div className="administration-container">
        <div className="administration-container-title">
          Social Media Administration
        </div>
        <div className="my-3 ms-3">
          <AdministerUsers setShowLoader={setLoading} title={"1. Mute Users"}  lookupCollection={selectedCollection} />
          <AdministrationLookup setShowLoader={setLoading} title={"2. Muted Users Lookup"} selectedCollection={selectedCollection} setSelectedCollections={setSelectedCollections}/>
        </div>
      </div>
    </>
  );
}