import { Box, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { BasicTextInput } from "../../../components/TextInput/BasicTextInput/BasicTextInput";
import { useEffect, useState } from "react";
import { CustomButton } from "../../../components/Button/CustomButton";
import { defaultTheme } from "../../../styles";
import { useAppSelector, useWeb3Context } from "../../../hooks";
import { addEntityAdmin } from "../../../slices/EntitySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { Close } from "@mui/icons-material";


type Props = {
  setVisibility: (item: boolean) => void
  isVisible: boolean
}

export const AddAdminModal = (props: Props) => {


  const [address, setAddress] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { networkId, staticProvider, wallet } = useWeb3Context()
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const entityAddress = useAppSelector((state) => state.Entity.EntityAddress)

  const handleAddEntityAdmin = async () => {
    if (address != "") {
      setLoading(true)
      const res = await dispatch(addEntityAdmin({ networkID: networkId, provider: staticProvider, wallet, address, entityAddress }))
      if(res?.payload==undefined){
        setError("Unsupported Address type")
      }
      if(res?.meta?.requestStatus=="fulfilled"){
        props?.setVisibility(false)
        setAddress("")
      }
      setLoading(false)
    }
  }

  const handleClose = () => {
    props?.setVisibility(false)
    setAddress("")
  }

  useEffect(()=>{
    if(error){
    setTimeout(()=>{
      setError("")
    },5000)
    }
  },[error])

  return (
    <Modal
      onClose={handleClose}
      sx={{
        backdropFilter: 'blur(10px)',
        boxShadow: '0 0 10px rgba(255, 255, 255, 0.3)',
      }}
      open={props?.isVisible}>
      {/* <Content> */}
      <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', height: "100%", alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#fff", width:{ lg:"50%",xl:"40%",sm:"100%",xs:"100%", }, padding: '1.5rem' }}>

          <Typography textAlign={'center'} fontFamily={"Poppins-Medium"} color={"#000"} fontSize={'1.2rem'}>Onboard Entity</Typography>

          {/* <Box sx={{ width: "100%" }}> */}

          <Box sx={{ width: { lg:"75%",xl:"75%",sm:"100%",xs:"100%", }}}>

            <BasicTextInput
              value={address}
              label="Enter Address"
              disabled={loading}
              required={true}
              maxLength={100}
              placeholder="Enter Address"
              valueChangeHandler={(event) => setAddress(event.target.value)}
            />
            {error &&
            <Typography mt={'12px'} fontFamily={"Poppins-Medium"} fontSize={"12px"} color={defaultTheme.buttonRedColor}>
            {error}
            </Typography>}

            <Box sx={{ display: 'flex',justifyContent:'center',alignSelf: 'center',gap:'5px',marginTop:'2rem' }}>
              <CustomButton
                className={"col-5 col-sm-5 col-lg-5 my-3 mt-1"}
                onClick={handleAddEntityAdmin}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.yellowColor}
                type={"button"}
                disabled={loading}
              >

                {loading ?
                  <CircularProgress size={"20px"} sx={{color:"#000" }} /> : 
                "Onboard Entity" }
              </CustomButton>
              <CustomButton
                className={"col-5 col-sm-5 col-lg-5 my-3 mt-1"}
                onClick={handleClose}
                color={defaultTheme.lightColor}
                backgroundColor={defaultTheme.pink}
                type={"button"}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </Box>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
      {/* </Content> */}
    </Modal>
  );
}