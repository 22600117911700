import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import "./LoginPage.scss"
import Loot8Logo from "../../assets/images/logos/loot8-logo.png"
import Arrow from "../../assets/images/icons/arrowRight.png"

import { CustomButton } from "../../components/Button/CustomButton";
import { useWeb3Context } from "../../hooks";
import { useState } from "react";
import LoginView from "./view/loginView";
import { defaultTheme } from "../../styles";



const LoginPage = ({ setLoading }) => {
  const [toggle, setToggle] = useState(false);
  const { connectLoading } = useWeb3Context();

  return (
    <section className="main-page h-100 w-100">
      {
        toggle ?
          <LoginView setLoading={setLoading} />
          :
          <section className="login-page-root" >
              <div className="login-page-container">
                <div className="loot8-logo-image">
                  <img src={Loot8Logo} alt="" className="loot8-logo-image" />
                </div>
                <div className="establishment-text pt-4 ">
                  <span className="">ESTABLISHMENT</span>
                </div>
                <span className="portal-text">PORTAL</span>

              </div>
              <div  style={{ marginTop: 30 }}>
                <CustomButton className="custom-button" color="#FCFCFC" backgroundColor={defaultTheme.yellowColor} onClick={() => setToggle(true)} disabled={connectLoading} type={"button"} >
                  {
                    connectLoading ?
                      <CircularProgress size={'1em'} sx={{ margin: 1, color: '#FCFCFC' }} />
                      :
                      <Typography sx={{textTransform:'capitalize'}} color={'#000000'} fontWeight={'600'} fontSize={'1rem'} fontFamily={'Poppins-Medium'}>{"Click To Begin"}</Typography>
                  }
                  <img style={{ height: 15 }} src={Arrow} alt=""/>
                </CustomButton>
              </div>
          </section>
      }
    </section>
  );
}
export default React.memo(LoginPage);
