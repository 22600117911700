import { Box, Typography } from "@mui/material";
import footerMobilePreview from '../../../assets/svg/footerMobilePreview.svg'
import headerMobilePreview from '../../../assets/svg/headerMobilePreview.svg'
import messageMobilePreview from '../../../assets/svg/messagesMobilePreview.svg'
import backArrow from '../../../assets/svg/backArrow.svg'
import imagePlaceHolderAnnouncement from '../../../assets/svg/imagePlaceHolder.svg'
import { defaultTheme } from "../../../styles";
import dayjs from "dayjs";
import { Circle } from "@mui/icons-material";

type FormData = {
    name: string,
    title: string,
    isActive: boolean,
    subtext: string,
    description: string,
    timestamp: null,
    bgImage: null,
    passport: []
}

type MobilePreviewProps = {
    formData: FormData,
    template?: number,
    render?:({isPreview,isMobilePreview}:{isPreview?:boolean,isMobilePreview?:boolean})=>any
}

export const MobilePreview = (props: MobilePreviewProps) => {

    const { formData, template,render } = props;

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', display: 'flex' }}>
            <Box display={'flex'} flexDirection={'column'}>
                <img src={headerMobilePreview} style={{ width: '350px' }} />
                <Box py={1}>
                    <Box display={'flex'} justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                    <Box sx={{position:"relative",left:'1.5rem',bottom:'8px'}} mr={1}>
                    <img src={backArrow} style={{ width: '17px', height: '17px',position:'absolute',left:0}} />
                    </Box>
           
                        
                        <Typography
                            sx={{flex:1}}
                            color={"#fff"}
                            fontFamily={'Poppins-Medium'}
                        >
                            {"My Collectibles"}
                        </Typography>
                        </Box>

                        
                    {/* <Box sx={{
 backgroundImage:`url(${formData?.bgImage})`,
 backgroundRepeat: "no-repeat",
 backgroundSize: "cover",
 height: "385px",

                     }} p={1} mt={1} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                 
                        <Typography
                            color={"#fff"}
                            fontFamily={'Poppins-Bold'}
                            sx={{
                                textDecoration: 'underline'
                            }}
                        >
                            {formData?.title ? formData?.title : "Title goes here "}
                        </Typography>
                        <Typography
                            mt={2}
                            fontSize={'1rem'}
                            color={"#fff"}
                            fontFamily={'Poppins-Medium'}
                        >
                            {formData?.subtext ? formData?.subtext : "Subtitle goes here "}
                        </Typography>
                        <Typography
                            fontSize={'1.2rem'}
                            color={defaultTheme.yellowColor}
                            fontFamily={'Poppins-SemiBold'}
                            sx={{
                                textDecoration: 'underline'
                            }}
                        >
                            {formData.timestamp ? `${dayjs(new Date(formData?.timestamp)).format('MM/DD/YYYY')} ${dayjs(new Date(formData?.timestamp)).format('hh:mm A')}` : "DATE & TIME GOES HERE"}
                        </Typography>
                    </Box> */}
                </Box>

                {/* {template == 0 && formData?.description != "" ?
                    <iframe
                        style={{ height: "30vh", width: '350px' }}
                        src={formData?.description}
                        width="100%"
                        height="100%"
                        allowFullScreen={true}
                    >
                    </iframe> : template == 0 && formData?.description == "" ?
                        <Box
                            sx={{
                                backgroundColor: "#000",
                                height: "30vh",
                                width: '350px'
                            }}
                            display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Typography sx={{
                                color: "#EE6161",
                                fontSize: '1rem',
                                fontFamily: 'Poppins-Bold',
                                textShadow: '0 0 4px #000000, 0px 0px 4px #000000',
                                marginBottom: 2
                            }}> Live </Typography>
                            <Typography
                                fontFamily={'Poppins-Bold'}
                                sx={{
                                    fontSize: '2em',
                                    color: '#000', // Black text color
                                    textShadow: '0.5px 0.5px 0.5px #fff, -0.5px -0.5px 1px #fff, 0.5px -0.5px 0.5px #fff, -0.5px 0.5px 0.5px #fff',

                                }}>
                                STREAM YARD
                            </Typography>
                            <Typography
                                fontFamily={'Poppins-Bold'}
                                sx={{
                                    fontSize: '1.2em',
                                    marginTop: '2px',
                                    padding: '1px 7px',
                                    borderRadius: 1,
                                    background: "#EE6161",
                                    color: '#fff', // Black text color
                                }}>
                                <Circle sx={{ fontSize: '7px' }} /> REC
                            </Typography>
                        </Box>
                        :
                        formData?.bgImage == "" || formData?.bgImage == null ?
                            <Box sx={{backgroundColor:"#000",width:"350px",height:"30vh"}} flexDirection={'column'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <img style={{ width: '30px' }} src={imagePlaceHolderAnnouncement} />
                                <Typography mt={1} fontFamily={'Poppins-Medium'} fontStyle={'italic'} fontSize={'1'} color={"#fff"} >
                                Your Thumbnail Image Goes Here
                                </Typography>
                            </Box>
                            :
                            <img style={{ width: '350px' }} src={formData?.bgImage ? typeof formData?.bgImage === "string" ? formData?.bgImage : URL.createObjectURL(formData.bgImage) : ""} />
                } */}
                   <Box p={'6px'} sx={{backgroundColor:"rgb(54, 38, 79)",maxWidth:"350px",flex:1,borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}}>
                    <Typography maxWidth={"350px"} sx={{wordWrap:'break-word'}} textAlign={'center'} fontFamily={'Poppins-Medium'} fontSize={'1.2rem'} color={'white'}>
                    {formData?.name}
                    </Typography>
                </Box>
                <div className="form-container" style={{width:"350px",height:"30vh",overflowY:'scroll'}}>
             
                {render({isPreview:true,isMobilePreview:true})}
                </div>
                <img src={messageMobilePreview} style={{ width: '350px' }} />
                <img src={footerMobilePreview} style={{ width: '350px' }} />
            </Box>
        </Box>
    )

}