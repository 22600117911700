import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addresses, ZERO_ADDRESS, getSubgraphConfig } from "../constants";
import { getIPFSData, getIPFSLink } from "../helpers/ipfs";
import { GetAddEntityAdminDataMessage, GetCreateEntityDataMessage, GetEncodedHash, GetEntityOnboardedLogData, GetOnboardEntityDataMessage, GetUpdateEntityDataUriMessage } from "../helpers/MsgHelper";
import { IEntity } from "../interfaces/IEntity.interface";
import { RootState } from "../store";
import { EntityRegistry__factory, Entity__factory, Loot8Onboarder__factory, TokenPriceCalculator__factory, User__factory } from "../typechain/factories";
import { SendMetaTX } from "./AppSlice";
import { setAll } from "./helpers";
import { IWalletBaseAsyncThunk, IMessageMetaData, ICollectionBaseAsyncThunk } from "./interfaces";
import { loadAllCollections, loadAllPremiumChats, setCollectiblesLoading, setOffersLoading, setPremiumChatsLoading } from "./OfferSlice";
import { getIndividualWhitelistedCollections, loadAllPassporDetails, setPassportLoading } from "./PassportSlice";
import { loadAllPOAPCollectibleDetails, loadExternalCollectibleDetails, setExternalCollectibleLoading } from "./collectibleSlice";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { fetchAllEntityDetails } from "../helpers/GraphQLHelperSubgraph";
import { loadAllPrivateChats } from "./PrivateChatSlice";
import { setEventsLoading } from "./EventSlice";

export const checkInviteCodeValid = async ({ networkID, provider, inviteCode, isOnboarding }): Promise<boolean> => {
  const loot8Onboarder = Loot8Onboarder__factory.connect(addresses[networkID].Onboarder, provider);

  const encryptedHashCode = GetEncodedHash(inviteCode);
  const isValidHashCode = await loot8Onboarder.inviteHashes(encryptedHashCode);
  const isRedeemed = await loot8Onboarder.redeemed(encryptedHashCode);

  if (isOnboarding) {
    return isValidHashCode && !isRedeemed;
  }
  return isValidHashCode;
};

export const getAllEntityData = async (networkID, provider): Promise<{ allEntityAddresses: string[]; allEntityData: EntityDetails[] }> => {
  // Fetch all entities list from blockchain
  const EntityRegistryContract = EntityRegistry__factory.connect(addresses[networkID].EntityRegistry, provider);
  const allEntityAddresses = await EntityRegistryContract.getAllEntities(true);

  let entityData: EntityDetails[] = [];
  try {
    let subgraphConfig: any;
    try {
      subgraphConfig = await getSubgraphConfig();
    } catch (err) {
      LogToConsoleError("getAllEntityData: Error while reading app config");
    }

    if (subgraphConfig && subgraphConfig.modules && subgraphConfig.modules.entity) {
      let skip = 0;
      const take = 100;
      let hasMoreData = true;

      while (hasMoreData) {
        const result = await fetchAllEntityDetails(skip, take, true);
        entityData = [...entityData, ...result];
        hasMoreData = result.length === take;
        skip += take;
      }
    }
  } catch (err) {
    LogToConsoleError("getAllEntityData", err.name, err.message, err.stack);
  }

  // Return all entities from chain & Data from indexer
  return { allEntityAddresses: allEntityAddresses, allEntityData: entityData };
};

export const checkSuperAdmin = async ({ networkID, provider, walletAddress, entityAddress }: { networkID; provider; walletAddress; entityAddress }): Promise<any> => {
  const entity = Entity__factory.connect(entityAddress, provider);
  const isSuperAdmin = await entity.managers(walletAddress);

  return isSuperAdmin;
};

export const getEntityDetails = async ({ networkID, provider, address, dispatch }: { networkID; provider; address; dispatch }): Promise<any> => {
  let allentityDetails: IEntity[] = [];

  const { allEntityAddresses, allEntityData } = await getAllEntityData(networkID, provider);
  dispatch(setAllEntityAddresses(allEntityAddresses));

  if (allEntityAddresses && allEntityAddresses.length > 0) {
    await Promise.all(
      allEntityAddresses
        .filter((entityAddress) => entityAddress !== ZERO_ADDRESS)
        .map(async (entityAddress, index) => {
          const entity = Entity__factory.connect(entityAddress, provider);
          const entityAdmins = await entity.getAllEntityAdmins(true);
          if (entityAdmins && entityAdmins.findIndex((obj) => obj?.toLowerCase() === address?.toLowerCase()) > -1) {
            let entityData: IEntity | any = {};

            if (allEntityData && allEntityData?.length > 0 && allEntityData.findIndex((item) => item.address?.toLowerCase() === entityAddress?.toLowerCase()) > -1) {
              const data = allEntityData.find((e) => e.address?.toLowerCase() === entityAddress?.toLowerCase());
              entityData = { ...data };
            } else {
              const entity = Entity__factory.connect(entityAddress, provider);
              const blockData = await entity.getEntityData();
              entityData = {
                name: blockData?.name,
                dataURI: blockData?.dataURI,
                address: entityAddress,
                isActive: blockData?.isActive,
                walletAddress: blockData?.walletAddress,
              };
            }
            if (entityData) {
              const entityDataURI = entityData?.dataURI;
              if (entityDataURI && entityDataURI !== "" && entityDataURI !== "ipfs://") {
                let response = await getIPFSData(entityDataURI);
                if (response) {
                  let responseJson = await response.json();
                  if (responseJson) {
                    entityData.description = responseJson?.description ?? "";
                    entityData.image = responseJson?.image ? getIPFSLink(responseJson.image) : "";
                    entityData.backgroundimage = responseJson?.meta?.backgroundimage ? getIPFSLink(responseJson.meta.backgroundimage) : "";
                    entityData.logo = responseJson?.meta?.logo ? getIPFSLink(responseJson.meta.logo) : "";
                  }
                  if (entityData?.entityOnboarded === undefined || entityData?.entityOnboarded === null) {
                    const allentity = EntityRegistry__factory.connect(addresses[networkID].EntityRegistry, provider);
                    const entityOnboarded = await allentity.isOnboardedEntity(entityAddress);
                    entityData.entityOnboarded = entityOnboarded;
                  }
                }
              }
              entityData.index = index;
              allentityDetails.push(entityData);
              dispatch(pushEntityData(entityData));
            }
          }
        })
    );
  }
  return allentityDetails;
};

export const loadEntityDetails = createAsyncThunk("app/loadEntity", async ({ networkID, provider, address, wallet }: IWalletBaseAsyncThunk, { dispatch, getState }): Promise<any> => {
  const allentityDetails: IEntity[] = await getEntityDetails({ networkID, provider, address, dispatch });

  if (allentityDetails && allentityDetails.length > 0) {
    allentityDetails.sort((a, b) => a.index - b.index);
    if (allentityDetails.length === 1) {
      const selectedEntity = { EntityData: allentityDetails[0], EntityAddress: allentityDetails[0]?.address, AuthorizedEntityAdmin: true };
      dispatch(setSelectedEntity(selectedEntity));
    }
  }

  LogToConsole("Entity slice", allentityDetails);

  return { AllEntityData: allentityDetails };
});

export const getLoot8TokenPrice = createAsyncThunk("app/loadTokenPrice", async ({ networkID, provider }: { networkID; provider }): Promise<any> => {
  const tokenPriceCalculator = TokenPriceCalculator__factory.connect(addresses[networkID].TokenPriceCalculator, provider);
  const tokenPrice = Number(await tokenPriceCalculator.pricePerMint());
  return { TokenPrice: tokenPrice };
});

export const setAllDataLoading = createAsyncThunk("app/setLoading", async (_, { dispatch }): Promise<any> => {
  await dispatch(setPassportLoading(true));
  dispatch(setOffersLoading(true));
  dispatch(setEventsLoading(true));
  dispatch(setCollectiblesLoading(true));
  dispatch(setExternalCollectibleLoading(true));
  dispatch(setPremiumChatsLoading(true));
  // dispatch(setPrivateChatLoading(true));
});

export const loadAllDetails = createAsyncThunk("app/loadAllDetails", async ({ networkID, provider, address, wallet, isCache, isSuperAdmin }: ICollectionBaseAsyncThunk, { getState, dispatch }): Promise<any> => {
  const state = getState() as RootState;
  const entityAddress = state.Entity.EntityAddress;
  await dispatch(loadAllPassporDetails({ networkID: networkID, provider: provider, address, wallet, entityAddress: entityAddress, isCache, isSuperAdmin }));

  await dispatch(loadAllPremiumChats({ networkID: networkID, provider: provider, address, wallet, entityAddress: entityAddress, isCache, isSuperAdmin }));
  await dispatch(loadAllPrivateChats({ networkID: networkID, provider: provider, address, wallet, entityAddress: entityAddress, isCache, isSuperAdmin }));

  await dispatch(loadAllCollections({ networkID: networkID, wallet: wallet, provider: provider, entityAddress: entityAddress, isCache, isSuperAdmin }));
  await dispatch(loadAllPOAPCollectibleDetails({ networkID, provider, entityAddress, isCache: true }));
  dispatch(loadExternalCollectibleDetails({ networkID, provider, isCache: true, isSuperAdmin }));

  if (state?.Entity?.isSuperAdmin) {
    // await dispatch(loadAllBartenderList({ networkID: networkID, provider: provider, address, wallet, entityAddress: entityAddress }));
    // dispatch(loadExternalCollectibleDetails({ networkID, provider, isCache: true,isSuperAdmin }));

    dispatch(getIndividualWhitelistedCollections({ networkID: networkID, provider: provider, isCache }));

    // await dispatch(loadAllPOAPCollectibleDetails({ networkID, provider, entityAddress, isCache: true }));
  }
});

export const updateEntityDataUri = createAsyncThunk("Entity/updateDataUri", async ({ networkID, provider, address, entityAddress, wallet, dataURI }: any, { dispatch }): Promise<any> => {
  const data = GetUpdateEntityDataUriMessage(dataURI);
  if (address) {
    let msg: IMessageMetaData = {
      to: entityAddress,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const addEntityAdmin = createAsyncThunk("Entity/addEntity", async ({ networkID, provider, entityAddress, address, wallet }: any, { dispatch }): Promise<any> => {
  // TODO: Add Entity Address
  const entityContract = Entity__factory.connect(entityAddress, provider);
  const isSuperAdmin = await entityContract.managers(wallet?.address);
  if (!isSuperAdmin) {
    return false;
  }
  // * Check if User Accounts Exists
  const userFactory = User__factory.connect(addresses[networkID].User, provider);
  const userExists = await userFactory.isValidPermittedUser(address);

  // TODO: Handle User Account does not exist
  if (!userExists) {
    LogToConsole("addEntityAdmin", "User Account does not exist in Loot8 User Factory");
    return false;
  }

  // * Meta Tx for adding new Admin
  // TODO: Check User Wallet Address
  const addEntityAdminMsgData = GetAddEntityAdminDataMessage(address);

  const addEntityAdminMessage: IMessageMetaData = {
    to: entityAddress,
    wallet: wallet,
    data: addEntityAdminMsgData,
    networkID: networkID,
    provider: provider,
  };

  let isSuccess = false;

  // TODO: Add Try catch and Error Handling

  try {
    let res = await dispatch(SendMetaTX(addEntityAdminMessage));

    // Availability of Event Logs means transaction was successful
    if (res && res.payload?.eventLogs) {
      isSuccess = true;
      return isSuccess;
    }
    return isSuccess;
  } catch (error) {
    LogToConsoleError("addEntityAdmin", error.name, error.message, error.stack);
    return isSuccess;
  }
});

export const onboardEntity = createAsyncThunk("Entity/onboardEntity", async ({ networkID, provider, address, wallet, inviteCode, entityName, dataURI }: any, { dispatch }): Promise<any> => {
  const creationData = GetCreateEntityDataMessage([["", ""], 0], entityName, dataURI, address, addresses[networkID].DAOAuthority, addresses[networkID].User, addresses[networkID].Onboarder);

  const data = GetOnboardEntityDataMessage(inviteCode, creationData);

  if (data) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].Onboarder,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };
    LogToConsole(msg);
    let res = await dispatch(SendMetaTX(msg));
    if (res && res.payload?.eventLogs) {
      const createdEntity = GetEntityOnboardedLogData(res.payload?.eventLogs);
      if (createdEntity === ZERO_ADDRESS) {
        LogToConsole("Entity Onboard: transaction failed...");
      }
      return true;
    }
  }
  return false;
});

export interface IEntitySliceData {
  readonly AllEntityData: IEntity[];
  readonly EntityData: IEntity;
  readonly EntityAddress: string;
  readonly isSuperAdmin: boolean;
  readonly AuthorizedEntityAdmin: boolean;
  readonly loading: boolean;
  readonly TokenPrice: Number;
  readonly allEntityAddresses: string[];
}

const initialState: IEntitySliceData = {
  AllEntityData: null,
  EntityData: null,
  EntityAddress: "",
  isSuperAdmin: false,
  AuthorizedEntityAdmin: false,
  loading: false,
  TokenPrice: 0,
  allEntityAddresses: [],
};

const EntitySlice = createSlice({
  name: "Entity",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    setSuperAdmin(state, action) {
      state.isSuperAdmin = action?.payload;
    },
    setAllEntityAddresses(state, action) {
      state.allEntityAddresses = action?.payload;
    },
    pushEntityData(state, action) {
      let allEntityData = state.AllEntityData ?? [];
      allEntityData.push(action.payload);
      state.AllEntityData = allEntityData.sort((a, b) => Number(a.index) - Number(b.index));
    },
    setSelectedEntity(state, action) {
      state.EntityData = action.payload.EntityData;
      state.EntityAddress = action.payload.EntityAddress;
      state.AuthorizedEntityAdmin = action.payload.AuthorizedEntityAdmin;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEntityDetails.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(loadEntityDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loadEntityDetails.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        LogToConsoleError("loadEntityDetails", error.name, error.message, error.stack);
      })
      .addCase(updateEntityDataUri.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(updateEntityDataUri.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getLoot8TokenPrice.fulfilled, (state, action) => {
        state.TokenPrice = action.payload.TokenPrice;
      });
  },
});

export const EntitySliceReducer = EntitySlice.reducer;

const baseInfo = (state: RootState) => state.Entity;

export const { fetchAppSuccess, setSelectedEntity, setSuperAdmin, pushEntityData, setAllEntityAddresses } = EntitySlice.actions;

export const getEntityState = createSelector(baseInfo, (Entity) => Entity);
