import Joyride from "react-joyride"
import { CatalogTypes } from "../../enums/catalog.enum"
import { CustomButton } from "../Button/CustomButton";
import { defaultTheme } from "../../styles";
import { Box } from "@mui/material";


type Props = {
run:boolean;
handleTour:(data:any)=>void;
type?:any;
steps:any[];
externalCondition?:boolean
walkthroughStepsIndex?:number,
containerClass?:any
contentClass?:any
styles?:any
buttonContainer?:any
}

export const Walkthrough = (props:Props) => {

    const {run,handleTour,steps,type,externalCondition,walkthroughStepsIndex} = props;

    const CustomTooltip = ({ step, continuous, backProps, closeProps, size, index, skipProps, primaryProps }) => {
        return (
          <div id={props?.containerClass}>
            <p id={props?.contentClass}>{step.content}</p>
            <Box sx={{ display: 'flex', columnGap: 1,...props?.buttonContainer}}>
              <CustomButton
                className={"col-4 col-sm-4 col-lg-4"}
                onClick={skipProps}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.newFieldColor}
                type={"button"}
                disabled={false}
              >
                {`SKIP TOUR`}
              </CustomButton>
              <CustomButton
                className={"col-4 col-sm-4 col-lg-4"}
                onClick={(data) => primaryProps(data)}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.yellowColor}
                type={"button"}
                disabled={false}
              >
                {`NEXT ${index + 1}/${size}`}
              </CustomButton>
            </Box>
          </div>
        );
      };

    return (
        externalCondition?
            <Joyride
              styles={{ overlay: { height: '100%',backgroundColor: "rgba(0, 0, 0, 0.8)"}}}
              showSkipButton={true}
              run={run}
              floaterProps={{hideArrow: true,placement:(walkthroughStepsIndex &&  walkthroughStepsIndex+1==3 && type && type==CatalogTypes?.COLLECTIBLES) ? "left" : 'right' }}
              tooltipComponent={({ step, continuous, backProps, closeProps, index, skipProps, primaryProps, size }) => <CustomTooltip
                step={step} continuous={continuous}
                index={index}
                primaryProps={primaryProps.onClick}
                skipProps={skipProps.onClick}
                size={size}
                backProps={backProps} closeProps={closeProps}
              />}
              steps={steps}
              callback={handleTour}
              disableOverlayClose={true}
            /> : <></>
    )
}