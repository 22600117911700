import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, IconButton, MenuItem, Typography, outlinedInputClasses } from "@mui/material";


import "./profile.scss"

import { LOGIN_PROVIDER, LOGIN_PROVIDER_TYPE } from "@web3auth/openlogin-adapter";

import BluePattern from "../../components/Layout/bluePattern/BluePattern";
import WhitePattern from "../../components/Layout/whitePattern/WhitePattern";
import { BasicTextInput } from "../../components/TextInput/BasicTextInput/BasicTextInput";
import { ImageCard } from "../CollectionDetails/components/FileUploadCard/ImageCard";
import { CatalogTypes } from "../../enums/catalog.enum";
import { compressImage } from "../../services/imageHelper.service";
import { Categories, IMAGE_REGEX, ZERO_ADDRESS, getNetwork } from "../../constants";
import { SelectInput } from "../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { CustomButton } from "../../components/Button/CustomButton";
import { defaultTheme } from "../../styles";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../hooks";
import { fileIpfsUpload, jsonIpfsUpload } from "../../services/ipfsUpload.service";
import { getIpfsCID } from "../../helpers/ipfs";
import { getDefaultCollection } from "../../services/collection.service";
import { AddCollection, AddFreeCollection, AddSubscriptionPassport, CreateCollection, SetMarketPlaceTradability, loadAllPassporDetails, setPassportLoading } from "../../slices/PassportSlice";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CATEGORIES, OfferType, PassportType } from "../../enums/offers.enum";
import { CollectionType } from "../../enums/collection.enum";
// import discord from '../../../../assets/images/social/discordColor.png';
import facebook from '../../assets/images/social/facebookColor.png';
import twitter from '../../assets/images/social/twitterColor.png';
// import telegram from '../../../../assets/images/social/telegramColor.png';
import instagram from '../../assets/images/social/instagramColor.png';
import tiktok from '../../assets/images/social/tiktokColor.png';
import { FacebookRegex, InstagramRegex, TikTokRegex, TwitterRegex } from "../../utils/validators";
import SocialLinksComponent from "../../components/SocialLink/SocialLinkComponent";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { useHistory, useLocation } from "react-router-dom";
import { Wallet, ethers } from "ethers";
import { useAlert } from "../../hooks/Alert";
import { LogToConsoleError } from "../../helpers/Logger";
import { checkSuperAdmin, setSuperAdmin } from "../../slices/EntitySlice";
import { DescriptionTextInput } from "../../components/TextInput/DescriptionTextInput/DescriptionTextInput";

type imageType = "thumbnail" | "optimised" | "original"

type Props = {
    address: any
    wallet: Wallet
    networkId: any
    connected: any
    staticProvider: any
    setFreeProfileLoading: any
}

const ProfileCreation = (props: Props) => {

    const dispatch = useAppDispatch();

    const { connected, address, wallet, networkId, staticProvider, setFreeProfileLoading } = props;


    const loginContent = [{
        id: 1,
        title: "What is meant by creating a free profile?",
        description: `
        On LOOT8, every creator begins with a free profile to attract and grow their fanbase. 
        This serves as your public presence, where an infinite amount of followers can engage with your content and stay updated. 
        However, for creators looking to deepen connections and monetize their influence, the Premium ExPass, embedded in the free profile, elevates the experience. 
        It's your exclusive channel for offering unique content, experiences, and direct engagement with your most dedicated fans. 
        The Premium ExPass not only unlocks new revenue streams but also fosters closer community bonds.`
    }]

    //   const handleLogin = async (type: LOGIN_PROVIDER_TYPE) => {
    //     const conn = await connect(type);
    //     if(!conn) {
    //       setShowToastError({open: true, message: "Something went wrong. Please try again!" })
    //     }
    //     setLoading(conn);
    //   }

    // const { address, wallet, networkId, staticProvider } = useWeb3Context();
    const entityAddress = useAppSelector(state => state.Entity.EntityAddress);
    const [category, setCategory] = useState(0);
    //   const [loading, setLoading] = useState({ open: false, message: "" });
    const { setShowToastError } = useAlert();
    const EntityPassports = useAppSelector(state=>state.Passports.AllEntityPassportDetails);
    const loadingPassports = useAppSelector(state=>state.Passports.loading);
    const location = useLocation();


    const history = useHistory();

    const [formInput, setFormInput] = useState({
        name: "",
        category: "",
        chain: getNetwork(),
        dataURI: "",
        details: "",
        address: "",
        symbol: "",
        isActive: true,
        entityAddress: "",
        price: '0.00',
        totalSupply: 0,
        linkCollectible: [],
        socialMedia: true,
        offerType: OfferType.NOTANYOFFER,
        collectionType: CollectionType.PASSPORT,
        passportType: 1,
        subscriptionSet: false,
        marketPlaceConfig: {
            allowMarketplaceOps: true,
            privateTradeAllowed: true,
            publicTradeAllowed: true
        },
        area: {
            latitude: "",
            longitude: "",
            radius: 0
        },
        subTitle: "",
        whitelistedCollections: [],
        tokensEarned: 0,
        maxMint: 0,
        maxPurchase: 0,
        maxBalance: 0,
        mintWithLinked: true,
        mintWithLinkedOnly: false,
        isVideoCollectible: false,
        video: "",
        isCoupon: false,
        priceRate: 0,
        start: null,
        end: null,
        socialLinks: {
            tiktok: "",
            instagram: "",
            twitter: "",
            facebook: "",
        },
        optimised: {
            image: "",
            size: "",
        },
        thumbnail: {
            image: "",
            size: "",
        },
        original: {
            image: "",
            size: "",
        }
    })

    const Content = ({ title, description, id, descriptionStyle }) => <div key={id} >
        <h5 className="sub-heading">{title}</h5>
        <p className="short-description-text" style={descriptionStyle}>
            {description}
        </p>
    </div>


    const urlChangeHandler = (event, account: "facebook" | "telegram" | "discord" | "twitter" | "instagram" | "tiktok") => {
        const { value } = event.target;
        setFormInput({ ...formInput, socialLinks: { ...formInput.socialLinks, [account]: value } })
    }

    const socialLinks = useMemo(() => {
        if (formInput)
            return [
                // {
                //   id: 1,
                //   label: 'Discord',
                //   icon: discord,
                //   handler: (event) => urlChangeHandler(event, "discord"),
                //   disabled: !formInput?.isActive,
                //   pattern: DiscordRegex,
                //   required: formInput?.socialLinks?.discord !== "",
                //   placeHolder: "Add Link",
                //   value: formInput?.socialLinks?.discord ?? "",
                //   key: 'discord'
                // },
                // {
                //   id: 2,
                //   label: 'Telegram',
                //   icon: telegram,
                //   handler: (event) => urlChangeHandler(event, "telegram"),
                //   disabled: !formInput?.isActive,
                //   pattern: TelegramRegex,
                //   required: formInput?.socialLinks?.telegram !== "",
                //   placeHolder: "Add Link",
                //   value: formInput?.socialLinks?.telegram ?? "",
                //   key: 'telegram'

                // },
                {
                    id: 3,
                    label: 'Facebook',
                    icon: facebook,
                    handler: (event) => urlChangeHandler(event, "facebook"),
                    disabled: false,
                    pattern: FacebookRegex,
                    required: formInput?.socialLinks?.facebook !== "",
                    placeHolder: "Add Link",
                    value: formInput?.socialLinks?.facebook ?? "",
                    key: 'facebook'

                },
                {
                    id: 4,
                    label: 'Instagram',
                    icon: instagram,
                    handler: (event) => urlChangeHandler(event, "instagram"),
                    disabled: false,
                    pattern: InstagramRegex,
                    required: formInput?.socialLinks?.instagram !== "",
                    placeHolder: "Add Link",
                    value: formInput?.socialLinks?.instagram ?? "",
                    key: 'instagram'

                },
                {
                    id: 5, label: 'TikTok', icon: tiktok,
                    handler: (event) => urlChangeHandler(event, "tiktok"),
                    disabled: false,
                    pattern: TikTokRegex,
                    required: formInput?.socialLinks?.tiktok !== "",
                    placeHolder: "Add Link",
                    value: formInput?.socialLinks?.tiktok ?? "",
                    key: 'tiktok'

                },
                {
                    id: 6, label: 'Twitter', icon: twitter,
                    handler: (event) => urlChangeHandler(event, "twitter"),
                    disabled: false,
                    pattern: TwitterRegex,
                    required: formInput?.socialLinks?.twitter !== "",
                    placeHolder: "Add Link",
                    value: formInput?.socialLinks?.twitter ?? "",
                    key: 'twitter'

                }
            ]
    }, [formInput])

    const onRemoveSocialLinkHandler = (social) => {
        const formSocialLinks = formInput?.socialLinks;
        delete formSocialLinks[social];
        setFormInput({ ...formInput, socialLinks: formSocialLinks });

    }

    const socialLinkChangeHandler = (events) => {
        let checked = events.target.checked;
        if (checked) {
            setFormInput({ ...formInput, socialLinks: { ...formInput.socialLinks, instagram: "" } });
        }
        else {
            setFormInput({ ...formInput, socialLinks: null });
        }
    }

    const ipfsDataGeneration = async () => {
        setFreeProfileLoading({ open: true, message: "Uploading Image" });

        let collectionImageThumbnail = { uri: getIpfsCID(""), imageSize: formInput?.thumbnail?.size ?? 0 },
            collectionImageOptimized = { uri: getIpfsCID(""), imageSize: formInput?.optimised?.size ?? 0 },
            collectionOriginalImage = { uri: getIpfsCID(""), imageSize: formInput?.original?.size ?? 0 };

        collectionOriginalImage.uri = await fileIpfsUpload(formInput?.original?.image, "", wallet);
        collectionOriginalImage.imageSize = formInput?.original?.size;

        collectionImageOptimized.uri = await fileIpfsUpload(formInput?.optimised?.image, "", wallet);
        collectionImageOptimized.imageSize = formInput?.optimised?.size;

        collectionImageThumbnail.uri = await fileIpfsUpload(formInput?.thumbnail?.image, "", wallet);
        collectionImageThumbnail.imageSize = formInput.thumbnail?.size;



        let collectionObj =
        {
            "name": formInput?.name?.toString(),
            "description": formInput?.details?.trim(),
            "image": collectionOriginalImage?.uri,
            "imageSize": collectionOriginalImage?.imageSize,
            "thumbnailImage": collectionImageThumbnail?.uri,
            "thumbnailImageSize": collectionImageThumbnail?.imageSize,
            "optimizedImage": collectionImageOptimized?.uri,
            "optimizedImageSize": collectionImageOptimized?.imageSize,

        }

        if (formInput?.socialLinks && Object.keys(formInput?.socialLinks).length > 0) {
            if (formInput?.socialLinks?.facebook !== "") {
                collectionObj["facebook"] = formInput?.socialLinks?.facebook;
            }
            if (formInput?.socialLinks?.twitter !== "") {
                collectionObj["twitter"] = formInput?.socialLinks?.twitter;
            }
            if (formInput?.socialLinks?.instagram !== "") {
                collectionObj["instagram"] = formInput?.socialLinks?.instagram;
            }
            if (formInput?.socialLinks?.tiktok !== "") {
                collectionObj["tiktok"] = formInput?.socialLinks?.tiktok;
            }
        }

        // console.log({ ipfsDataURI });
        // if(ipfsDataURI && ipfsDataURI !== "")
        const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet)

        return ipfsDataURI;

    }


    const [originalImage, setOriginalImage] = useState("");
    const [thumbnailImage, setThumbnailImage] = useState("");
    const [optimizedImage, setOptimizedImage] = useState("");

 

    const clearImage = (imageType: "optimised" | "thumbnail" | "original") => {
        setFormInput({ ...formInput, [imageType]: { image: "", size: "" } })
        imageType == "optimised" ? setOptimizedImage("") : imageType == "original" ? setOriginalImage("") : setThumbnailImage("")

    }


    const onFormCreation = async (ipfsDataURI) => {
        // setLoading({ open: true, message: `Creating Free ExPass` });
        setFreeProfileLoading({ open: true, message: "Creating Free ExPass" })
        const data = await dispatch(CreateCollection({
            networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleData: formInput, dataURI: ipfsDataURI, wallet, chainID: formInput?.chain
        }));
        
        if (data.payload && data?.payload !== ZERO_ADDRESS) {
            let collectionAddress = data?.payload;
            //   setLoading({ open: true, message: `Adding ${type} to manager` });

            await dispatch(AddFreeCollection({ networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleAddress: collectionAddress, category: category, isPremium: false, collectibleData: formInput, wallet, chainID: formInput?.chain }));

            //   if (formInput.passportType === PassportType.SUBSCRIPTON) {
                // Adding subscrition
                const floorPrice = ethers.utils.parseUnits(formInput?.price?.toString(), 18).toString();
                await dispatch(AddSubscriptionPassport({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress: collectionAddress, wallet, floorPrice: floorPrice,priceRate:Number(formInput?.priceRate)}));
                // Setting MarketPlace tradability
                await dispatch(SetMarketPlaceTradability({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress : collectionAddress, wallet, marketPlaceConfig: formInput?.marketPlaceConfig }));

            //   }
            setFreeProfileLoading({ message: "", open: false });
            history.replace({ pathname: "/dashboard" })
            dispatch(setPassportLoading(true))
            const result = await checkSuperAdmin({networkID:networkId,provider:staticProvider,walletAddress:wallet?.address,entityAddress:entityAddress})
            dispatch(setSuperAdmin(result))
            await dispatch(loadAllPassporDetails({networkID:networkId,wallet:wallet,provider:staticProvider,address:address,entityAddress:entityAddress,isCache:true,isSuperAdmin:result}))
            return collectionAddress;
        }
        else {
            setFreeProfileLoading({ open: false, message: "" });
            LogToConsoleError("creation Error");
            setShowToastError({ open: true, message: `Free ExPass Creation Error` });
        }
        // return "";
    }


    const onSubmit = async (event) => {

        event?.preventDefault();
        let collectionAddress = "";
        const ipfsDataURI = await ipfsDataGeneration();
        if (ipfsDataURI && ipfsDataURI != "") {

            await onFormCreation(ipfsDataURI)
        }

    }

    const onAddSocialLinkHandler = (social) => {
        setFormInput({ ...formInput, socialLinks: { ...formInput.socialLinks, [social]: "" } });
    }


    // console.log({ formInput });

    const imageChangeHandler = async (event: any, imageType?: imageType) => {

        let { files } = event.target;
        let gifThumbnail = null
        if (!files) {
            files = event.dataTransfer.files;
        }
        
        if (files && files[0]) {
            if (IMAGE_REGEX.test(files[0].type.toString()) && files[0]?.type != "image/gif") {
                if (imageType == "thumbnail") {
                    setThumbnailImage(URL.createObjectURL(files[0]));
                } else if (imageType == "optimised") {
                    setOptimizedImage(URL.createObjectURL(files[0]))
                } else {
                    setOriginalImage(URL.createObjectURL(files[0]));
                }
                setFormInput({ ...formInput, [imageType]: { image: files[0], size: files[0].size } })
            } else if (files[0]?.type == "image/gif" && (imageType == "original" || imageType == "optimised")) {
                if (imageType == "original") {
                    
                    setOriginalImage(URL.createObjectURL(files[0]));
                } else if (imageType == "optimised") {
                    
                    setOptimizedImage(URL.createObjectURL(files[0]))
                }

                const thumbnailImage: any = await compressImage(files[0], 300, 5);
                

                // gifThumbnail = await getFirstFrameDataUrl(URL.createObjectURL(files[0]),canvasRef)
                if (formInput?.thumbnail?.image == "")
                    setThumbnailImage(URL.createObjectURL(thumbnailImage));
                setFormInput({
                    ...formInput, thumbnail: { image: thumbnailImage, size: thumbnailImage.size },
                    ...(imageType == 'optimised' ? {
                        optimized: files[0], size: files[0].size
                    } : {
                        original: files[0], size: files[0].size
                    })
                })
            }
        }
    }


    const isDisabled = useMemo(() => {
        return Boolean(formInput?.name == "" && formInput?.category == "" && formInput?.thumbnail?.image == "" &&
            formInput?.optimised?.image == "" && formInput?.original?.image == "" &&
            (formInput?.socialLinks?.facebook == "" || formInput?.socialLinks?.instagram == "" || formInput?.socialLinks?.tiktok == ""))
    }, [formInput])

    const descriptionChangeHandler = (event) => {
        let text = event.target.value;
        setFormInput({ ...formInput, details: text });
      }
    

      useEffect(()=>{
            if(!loadingPassports && EntityPassports && EntityPassports.length){
                if(EntityPassports?.find(i=>i.isActive && !i.isPremium)){
                    window.location.replace('/')
                }
            }
      },[location,loadingPassports,EntityPassports])
    

    return (
        <>


            <form onSubmit={onSubmit}>


                <Grid container>
                    <Grid item lg={6} xs={12} sm={12} xl={6}>
                        <WhitePattern>
                            <div className="main-content-profile-creation">
                                <div className="heading-text-loot8 p-5">

                                    <span className="heading-text-loot8"> Loot8 - Create A Free Profile/Expass</span>
                                </div>
                                {/* <div className="social-text">
              <span>Loot8- Creates A Free Profile/Expass</span>
            </div> */}
                                {/* <div className="social-text mt-4">
              <span>Please select an option below.</span>
            </div> */}
                                <div className="profile-sub-container">
                                    <div className="p-5 pt-0 pb-1">
                                        <BasicTextInput label="Profile Name" value={formInput?.name} valueChangeHandler={(event) => {
                                            
                                            setFormInput({ ...formInput, name: event?.target?.value })
                                        }} disabled={false} required={true} placeholder="Enter your name" />
                                          <DescriptionTextInput
              description={formInput.details}
              edit={formInput?.address != "" ? true : false}
              descriptionChangeHandler={descriptionChangeHandler}
              required={false}
              disabled={!formInput?.isActive}
            />
                                        <Typography variant="body2" className="mt-3" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
                                            {"Select Category"}
                                        </Typography>
                                        <Select
                                            required={true}
                                            value={formInput?.category}
                                            className='input-select-passport-value w-100 '

                                            placeholder="Select Category"
                                            onChange={(event) => {
                                                
                                                setCategory(CATEGORIES[event?.target?.value])
                                                setFormInput({ ...formInput, category: event?.target?.value })
                                            }}
                                            sx={{
                                                mt: 1,
                                                backgroundColor: defaultTheme.newFieldColor,
                                                color: defaultTheme.buttonDarkTextColor,
                                                fontFamily: 'Poppins-Medium',
                                                [`& .${outlinedInputClasses.notchedOutline}`]: {
                                                    border: 'none',
                                                },
                                                [`& .${outlinedInputClasses.disabled}`]: {
                                                    WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingBlock: 8.5,
                                                    maxWidth: "100%"
                                                }
                                            }}
                                            MenuProps={{
                                                MenuListProps: {
                                                    sx: {
                                                        padding: 0,
                                                        '& .Mui-selected': {
                                                            backgroundColor: '#a8189030', // Replace with your desired color
                                                            ':hover': {
                                                                backgroundColor: '#a818901f'
                                                            }
                                                        },
                                                    },
                                                },
                                                PaperProps: {
                                                    sx: {
                                                        maxWidth: '5rem',
                                                        maxHeight: '18rem'
                                                    }
                                                },
                                            }}
                                        >
                                            {Categories.map(i =>
                                                <MenuItem value={i?.value}>{i?.label}</MenuItem>
                                            )}

                                        </Select>
                                        <Box display={'flex'} maxWidth={"35rem"} columnGap={1} mt={3} 
                                        sx={{flexWrap:{lg:"nowrap",xl:"nowrap",sm:"wrap",xs:"wrap"}}}
                                        >
                                            <ImageCard label={'Original Image'}
                                                clear={() => clearImage("original")}
                                                isPassport
                                                catalogType={CatalogTypes.EXPASSES}
                                                subHeading=''
                                                imageType={'original'}
                                                background={originalImage}
                                                backgroundImageChangeHandler={imageChangeHandler}
                                                fileTypes={"image/jpg, image/jpeg, image/png, image/gif"}
                                                isVideo={false}
                                                isDisabled={false} />
                                            <ImageCard label={'Thumbnail Image'}
                                                clear={() => clearImage("thumbnail")}
                                                isPassport
                                                catalogType={CatalogTypes.EXPASSES}
                                                subHeading=''
                                                imageType={"thumbnail"}
                                                background={thumbnailImage}
                                                backgroundImageChangeHandler={imageChangeHandler}
                                                fileTypes={"image/jpg, image/jpeg, image/png"}
                                                isVideo={false}
                                                isDisabled={false} />
                                            <ImageCard label={'Optimised Image'}
                                                clear={() => clearImage("optimised")}
                                                isPassport
                                                catalogType={CatalogTypes.EXPASSES}
                                                subHeading=''
                                                imageType={"optimised"}
                                                background={optimizedImage}
                                                backgroundImageChangeHandler={imageChangeHandler}
                                                fileTypes={"image/jpg, image/jpeg, image/png"}
                                                isVideo={false}
                                                isDisabled={false} />
                                        </Box>
                                    </div>
                                </div>
                                <Box justifyContent={'center'} display={'flex'} mb={'2rem'} pl={{lg:0,xl:0,sm:'1rem',xs:'1rem'}}>
                                    <Box display={'flex'} justifyContent={'center'} width={'35rem'}>
                                        <SocialLinksComponent formSocialLinks={formInput.socialLinks}
                                            onAddSocialLinkHandler={onAddSocialLinkHandler}
                                            onRemoveSocialLinkHandler={onRemoveSocialLinkHandler}
                                            socialLinks={socialLinks}
                                            checked={formInput.socialLinks ? true : false}
                                            socialLinkChangeHandler={socialLinkChangeHandler} />
                                    </Box>
                                </Box>

                            </div>
                            <Box display={'flex'} justifyContent={'center'} columnGap={1}>
                                {/* <CustomButton
                            disabled={false}
                            onClick={() => { }}
                            className="col-3"
                            type="button"
                            color={defaultTheme.buttonLightTextColor}
                            backgroundColor={defaultTheme.buttonRedColor}
                        >
                            Cancel Process
                        </CustomButton> */}
                                <CustomButton
                                    disabled={isDisabled}
                                    // onClick={onSubmit}
                                    className="col-9"
                                    type="submit"
                                    color={defaultTheme.buttonDarkTextColor}
                                    backgroundColor={defaultTheme.yellowColor}
                                >
                                    Create Profile
                                </CustomButton>
                            </Box>
                        </WhitePattern>
                    </Grid>
                    <Grid item lg={6} xl={6}>
                        <Box sx={{ overflowY: 'scroll', maxHeight: '100vh' }}>
                            <BluePattern
                            // shortDescription="Login to access your account"
                            >
                                <Box sx={{ marginTop: '10px' }}>
                                    <div>
                                        <p className="short-description-text">
                                            You have successfully log into the establishment portal, lets take another step before proceed further
                                        </p>
                                        <Divider sx={{ color: "#F0ECEC", borderColor: "#F0ECEC", my: '1rem', borderWidth: '1px' }} />
                                        <Box >

                                            {<Content descriptionStyle={{ maxWidth: "100%" }} description={loginContent[0]?.description} title={loginContent[0]?.title} id={loginContent[0].id} />}

                                        </Box>


                                    </div>
                                </Box>
                            </BluePattern>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default React.memo(ProfileCreation);
