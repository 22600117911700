import { FormControlLabel, Radio } from '@mui/material';
import { pink } from '@mui/material/colors';
import { defaultTheme } from '../../styles';


export const CustomRadioButton = ({ label, value }) => {

  return (
    <FormControlLabel  sx={{
      color: defaultTheme.buttonDarkTextColor,
      '& .MuiFormControlLabel-label':{
        fontFamily: 'Poppins'
      }
      }} 
      value={value} 
      control={
          <Radio sx={{
          color: pink[800],
          '&.Mui-checked': {
            color: pink[600],
          },
        }} /> }
      label={label} />
  );
};