import React from 'react';
import { Box, BoxProps, Button, DialogContent, Grid, Paper, PaperProps, Typography, styled } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import Loot8 from "../../../../assets/images/logos/Loot8Token.png"

import { CircularProgress } from '@mui/material';

// import closeIcon from '../../../../assets/images/'

import closeIcon from '../../../../assets/images/icons/pinkClose.png'
import { CustomButton } from '../../../../components/Button/CustomButton';
import { defaultTheme } from '../../../../styles';
import { Row } from './components/Row';
import { IEventDetails } from '../../../../interfaces/IEvent.interface';
import { ICollectibleDetail } from '../../../../interfaces/ICollection.interface';

// const StyledModal = styled(Paper)<PaperProps>(({theme}) => ({

//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// //   height: '100%',
//   backgroundColor: '#FDFDFDA0',
//   position: 'absolute',
//   alignSelf:'center',
//   width: 'fill-available',
//   // maxWidth:'50%',
//   zIndex: 100,

// }));

const Content = styled(Box)<BoxProps>(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  outline: 'none',
  position: 'relative',
  minWidth: '40vw',
  maxWidth: '50vw',
  '@media (max-width: 600px)': {
    maxWidth: '100vw',
  maxHeight:'65%',

  },
  '@media (min-width: 600px) and (max-width: 960px)': {
    maxWidth: '100vw',
  maxHeight:'65%',

  },
  '@media (min-width: 1200px) and (max-width: 1200px)': {
  maxHeight:'90%',
  maxWidth: '80vw',


  },
  maxHeight:'60%',
  borderRadius: 4,
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  flexDirection: 'column',
  alignItems: 'center'

}));


type Props = {
  message?:string;
  title?:string;
  buttonText?:string;
  children?:React.ReactNode
  buttonColor?:string
  buttonTextColor?:string
  eventData:ICollectibleDetail
  ticketData:ICollectibleDetail
  onClickCancel:()=>void
  onClick?:()=>void
}

export const ConfirmationModal = ({ title,eventData,onClickCancel,ticketData,message,buttonText,children,onClick,buttonColor,buttonTextColor }:Props) => {

  const {start,end,getKeepers,linkCollectible,collaborators} = eventData;



  return (
    <Dialog
    sx={{
      display: 'flex',
      justifySelf:'center',
      alignItems: 'center',
      justifyContent: 'center',
      
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      
    }}
    fullScreen
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: 'auto',
      },
    }}
    open={true}
    onClose={onClickCancel}
    
  >
            <DialogContent sx={{display:'flex',justifyContent:'center'}}>
    {/* <Box> */}
      <Content>
      <Button onClick={onClickCancel} sx={{ padding: 0, minWidth: 'unset', flex: 1, position: 'absolute', zIndex: 9999, right: -10, top: -15 }}>
        <img src={closeIcon} style={{ height: 30, width: 30 }} />
      </Button>
      <Typography fontFamily={'Poppins-Medium'} color={"#000"} fontSize={'1.5rem'} >Create/Generate Ticket</Typography>
        
        <Row label='Event Name' value={eventData?.name}/>
        <Row label='Associated ExPass' value={linkCollectible?.join(',')}/>
        <Row label='Price' price value={`${ticketData?.price}`}/>
        {start && end && 
        <Row label='Dates' 
        value={String(`${new Date(start)?.toLocaleString('default',{month:'long'})} ${new Date(start)?.getDate()}th, ${new Date(start)?.getFullYear()} - ${new Date(end)?.getDate()}th`)}
        />
        }
        <Row valueCol={1} valWidth='20%' label='Collaborators' value={collaborators?.join(',')}/>
        <Row valueCol={1} valWidth='20%'  label='Gatekeeper scanner name' value={getKeepers?.join(',')}/>
        {message ? 
        <Typography fontFamily={'Poppins-Medium'} fontSize={'1.1rem'} textAlign={'center'} >{message}</Typography> : <></>}
        <Grid container lg={12} xl={12}>
        <Grid item lg={12} xl={12}>
        {children ?? ""}
        </Grid>
        </Grid>
        {buttonText && onClickCancel ? 
        <Grid container mt={3} xl={12} lg={12} sm={12} xs={12} md={12} display={'flex'} columnGap={3} justifyContent={'center'}>
          <Grid item lg={4} xl={4}>
          <CustomButton
        type='button'
        color={buttonTextColor ? buttonTextColor :defaultTheme.lightColor}
        backgroundColor={buttonColor ? buttonColor : defaultTheme.buttonRedColor}
        disabled={false}
        className="col-12 col-lg-12 col-md-6 mt-3 col-sm-6 col-xs-6"
        onClick={onClickCancel}
        >
          Cancel
        </CustomButton>
        </Grid>
        <Grid item lg={4} xl={4}>
        <CustomButton
        type='button'
        color={buttonTextColor ? buttonTextColor :defaultTheme.buttonDarkTextColor}
        backgroundColor={buttonColor ? buttonColor : defaultTheme.yellowColor}
        disabled={false}
        className="col-12 col-lg-12 col-md-6 mt-3  col-sm-6 col-xs-6"
        onClick={onClick}
        >
          {buttonText}
        </CustomButton>
        </Grid>
        </Grid>
         : <></>}
      </Content>
    {/* </Box> */}
    </DialogContent>
    </Dialog>
  );
};

