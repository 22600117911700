import { configureStore } from "@reduxjs/toolkit"; 

import { AppSliceReducer } from './slices/AppSlice';
import { EntitySliceReducer } from "./slices/EntitySlice";
import { OfferSliceReducer } from './slices/OfferSlice';
import { PassportSliceReducer } from "./slices/PassportSlice";
import { SettingSliceReducer } from "./slices/SettingSlice";
import { AppUserSliceReducer } from "./slices/AppUserSlice";
import { CollectibleSliceReducer } from "./slices/collectibleSlice";
import { PrivateChatReducer } from "./slices/PrivateChatSlice";
import { EventSliceReducer } from "./slices/EventSlice";

const store = configureStore({
  reducer: {
    App: AppSliceReducer,
    AppUser:AppUserSliceReducer,
    Entity:EntitySliceReducer,
    Offers: OfferSliceReducer,
    privateChats:PrivateChatReducer,
    Passports: PassportSliceReducer,
    Settings: SettingSliceReducer,
    Collectibles: CollectibleSliceReducer,
    Events:EventSliceReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;