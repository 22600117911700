import { Grid } from "@mui/material";
import WhitePattern from "../../components/Layout/whitePattern/WhitePattern";
import BluePattern from "../../components/Layout/bluePattern/BluePattern";
import { Profile } from "../Profile/Profile";
import './index.scss';

interface OnboardingProfileProps {
  isOnboarding: boolean,
  setProfileLoader?:(item:boolean)=>void
}

const OnboardingProfilePage = ({ isOnboarding,setProfileLoader }:OnboardingProfileProps) => {
    return(
        <Grid container className="onboarding-profile-page">
        <Grid item xs={12} sm={12}>
          <WhitePattern>
            <div className="left-container">
                <div className="heading-text pt-2">
                {
                  <span style={{paddingLeft:10}}>Profile</span>
                }
                </div>              
              <div className="social-container">
                <Profile setProfileLoader={setProfileLoader} isOnboarding={isOnboarding}/>
              </div>
            </div>
          </WhitePattern>
        </Grid>
        <Grid item xs={6}>
          {
            isOnboarding ?
            <BluePattern
              shortDescription=""
              >
              <div className="welcome-container">
              </div>
            </BluePattern>
          :
            <div className="background-container-blue">
              <div>
                <div>
                  <span className="short-description-text">
                    <div className="welcome-container">
                      <div className="description-container">
                        <span className="description-title">Verify your Experience Pass (ExPass)</span>
                        <div>
                            <span className="description">LOOT8 recommends ExPass owners to link their established Web2 social accounts (like Twitter or Instagram) to their LOOT8 profile. Doing so not only integrates your socials into our platform but also awards you a verified checkmark, giving your followers confidence that they're interacting with the authentic, verified personality behind the ExPass.</span>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>          
            </div>
          }
        </Grid>
      </Grid>
    )
}

export default OnboardingProfilePage;