import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font,  } from '@react-pdf/renderer';
import Loot8Logo from "./../../assets/images/logos/loot8-logo-black.png";


export const PDFComponent = ({ dataSplit, usercolors, withToken, dimension } ) => {

    return (
        <>
            <Document>
                <Page size="A4" >
                {   dataSplit?.map((cluster, index) => ( 
                <React.Fragment  key={index}>
                    <View key={index} style={[styles.page, styles.poapPage]}>
                        { cluster?.map((QRData, _idx) => {
                            return (
                                <View key={_idx} style={[styles.section,{ height: Number(dimension[1]) * 72 , width: Number(dimension[0]) * 72 }]} >
                                    <View style={[styles.outerCard , {
                                        borderTopColor: usercolors.borderColor.lightColor,
                                        borderLeftColor: usercolors.borderColor.lightColor,
                                        borderRightColor: usercolors.borderColor.darkColor,
                                        borderBottomColor: usercolors.borderColor.darkColor
                                    }]}>
                                        <View style={[styles.card]}>
                                        
                                            <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' , backgroundColor: usercolors.bgcolor}}>
                                                <Image src={QRData.dataImage} style={styles.cardImage}></Image>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View style={[styles.page, styles.qrPage]}>
                        { cluster?.map((QRData, _idx) => {
                             return (                        
                                <View key={_idx} style={[styles.section ,{ height: Number(dimension[1]) * 72 , width: Number(dimension[0]) * 72 }]}>
                                    <View style={[styles.outerCard]}>
                                        <View style={[styles.card]}>
                                            <View style={[styles.title]}>
                                                <Text>{QRData.name}</Text>
                                            </View>
                                            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                <Text style={{ fontSize: Number(dimension[1]) * 55 * 0.05 , textAlign: 'center'  }}>Scan here to collect</Text>
                                                <Image src={ QRData?.qrUrl} style={[styles.cardQR, { height: Number(dimension[1]) * 65 / 2, width: Number(dimension[0]) * 72/ 2}]} ></Image>
                                            </View>
                                            <View style={[styles.tokenId]}>
                                               { withToken && <Text>#{QRData?.tokenID}</Text> }
                                            </View>
                                            <View style={styles.logoImage}>
                                                <Image style={{ height: 18, marginHorizontal: 7 }} source={Loot8Logo}></Image>
                                               
                                            </View>
                                        </View>
                                    </View>

                                </View>
                            );
                        })}
                    </View>
                    </React.Fragment>
                    ))
                }
                </Page>
            </Document>
        </>
    )};
    
    // Create styles
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-evenly',
            alignItems: "center",
            alignContent: "stretch",
            flexWrap: 'wrap',
            height:'100%',
            width: '100%'
        },
        poapPage:{
            flexDirection: 'row'

        },
        qrPage: {
            flexDirection: 'row-reverse'
        },
        section: {

            justifyContent: 'center',
            alignItems: 'center',
            margin: 1,
            // padding: 0,
            // borderWidth: 0.5,
            // borderColor: '#0000004A',
            // backgroundColor: '#AAFFFF',
            flexDirection: 'column'
        },
        viewer: {
            width: window.innerWidth, 
            height: window.innerHeight,
        },
        outerCard: {

            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
            // backgroundColor: '#000000',
        },
        card: {
            // borderWidth: 5,
            // borderColor: '#000000',
            height: "100%",
            width: "100%",
            alignItems: 'center',
            // justifyContent: 'center'
            // backgroundColor: '#FFFFFF',
            // padding: 10
        },
        cardImage: {
            objectFit: 'contain',
            height: '100%',
            width: '100%'
        },
        cardQR: {
            margin: 10,
            marginBottom: 0,
            objectFit: 'contain',
            // backgroundColor: '#957'
        },
        logoImage: {
            justifyContent: 'flex-end',
            objectFit: 'contain',
            // height: 100,
            // width: 100,
            bottom: 15,
            position: 'absolute',
            flexDirection: 'row',
            alignItems: 'center'
            // backgroundColor: '#395'

        },
        title: {
            fontSize: 10,
            fontWeight: 700,
            alignSelf: 'center',
            justifyContent:'center',
            width:'100%',
            color: '#000000',
            textAlign: 'center',
            marginTop: 15,
            paddingHorizontal: 10
        },
        tokenId: {
            fontSize: 11,
            fontWeight: 700,
            alignSelf: 'center',
            justifyContent:'center',
            width:'100%',
            color: '#000000',
            textAlign: 'center',
            marginVertical: 5

        }
    });