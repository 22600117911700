import React from 'react'
import "./BluePattern.scss"
import Loot8Logo from '../../../assets/images/logos/loot8-logo-new.png'
interface Props {
    children: React.ReactNode;
    shortDescription?:string;
}

const BluePattern = ({ children, shortDescription } : Props ) => {
  return (
      <div className="background-container-blue">
        <div>
          <img src={Loot8Logo} alt="" className='loot8-logo-image'/>
          <div className="welcome-text pt-3">
            <span className="">Welcome to</span>
          </div>
          <span className="establishment-portal-text">
            ESTABLISHMENT PORTAL
          </span>
          <div>
            <span className="short-description-text">
              {shortDescription}
            </span>
          </div>
        </div>
      {children}
    </div>
  )
}

export default BluePattern
