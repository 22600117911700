import React, { useState } from 'react';
import { TextField, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';

export const CountInput = ({ label, count, countChangeHandler, required, disabled,  placeholder="Enter a count" }) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    const pattern = /^\d+$/;
    if (pattern.test(value) || value === '' || !isNaN(value)) {
      countChangeHandler(value);
    }
  };

  return (
    <div className='mt-2'>
      <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {label}
      </Typography>
      <TextField
        value={count}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        FormHelperTextProps={{
          error: true
        }}
        helperText={error && '*required'}

        required={required}
        disabled={disabled}
        type='text'
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        variant='outlined'
        fullWidth
        size='small'
        sx={{
          mt: 1
        }}
        onBlur={(event) => countChangeHandler(Number(event.target.value))}
      />
    </div>
  );
}

