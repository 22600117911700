import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { socialAccountVerifyCode } from "../../slices/AppUserSlice";
import { useWeb3Context } from "../../hooks";
import { storeData } from "../../helpers/AppStorage";
import { VERIFICATION_STATUS } from "../../enums/socialAccount.enum";
import { LogToConsole, LogToConsoleError } from "../../helpers/Logger";
import { wait } from "../../helpers/ipfs";

const VerifyUser = ({props}) => {
    
    const dispatch = useDispatch<AppDispatch>();

    const { address, wallet, networkId, staticProvider, userInfo } = useWeb3Context();

    const searchParams = new URLSearchParams(props?.location?.search);
    const [verificationCode, setVerificationCode] = useState(null);
    const [isVerificationDone, setIsVerificationDone] = useState(false);

    const onSendCode = async (code: string) => {
        LogToConsole("onSendCode",code)
        const responseHash = await dispatch(socialAccountVerifyCode({ networkID: networkId , provider: staticProvider, address: address, wallet: wallet, code, userInfo: userInfo }))
        LogToConsole("responseHash",responseHash)
        
        try {

            if(responseHash) {
                if(responseHash?.payload === VERIFICATION_STATUS.SUCCESS) {
                    await storeData("accountVerificationInProgress", false);
                    await wait(1000)
                    window.close();
                } else if(responseHash?.payload === VERIFICATION_STATUS.FAILED) {
                    await storeData("accountVerificationInProgress", false);
                    await wait(1000);
                    window.close();
                }
                setIsVerificationDone(true);
            }
        } catch(e) {
            await storeData("accountVerificationInProgress", false);
            LogToConsoleError(e)
            window.close();
        }
    }

    const onClearData = async () => {
        await storeData("accountVerificationInProgress", false);          
        setIsVerificationDone(false);
    }

    useEffect(() => {
        if(searchParams) {
            const code = searchParams.get("verificationCode");
            if(code) {
                setVerificationCode(code);                
            }
        }

        return(() => {
            onClearData();
        }) 

    })
    
    useEffect(() => {
        if(verificationCode && !isVerificationDone) {
            onSendCode(verificationCode);
        }
    }, [verificationCode])

    return <div>
            Please wait...
        </div>
}

export default VerifyUser;