import { ethers, Wallet } from "ethers";
import { API_REQUEST_TIMEOUT, IPFS_URL, IPNS_UPLOAD_LAMBDA_URL, MASTER_KEY, FRIENDS_IPNS_FILE_NAME, USERDETAIL_IPNS_FILE_NAME } from "../constants";
import { LogToConsole, LogToConsoleError } from "./Logger";
import { IIPNSUserDetailType } from "../interfaces/IUser.interface";

export const getIPFSLink = (path: string): string => {
  if(path && path !== "") {
    return IPFS_URL() + 'ipfs/' + path.replace(/ipfs:\/\//g, "");
  }
  else return "";
}

export const getIpfsCID = (path: string) : string => {
  if(path && path !== "") {
    return 'ipfs://' + path.split("ipfs/")[1];
  }
  return "";
}

export const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
}

export const getUserAvatarIPFS = async (avatarURI): Promise<Response> => {
    let data;
    if (avatarURI) {
      data = fetchWithTimeout(IPFS_URL() + 'ipfs/' + avatarURI.replace('ipfs://', '') + '/',
        { method: 'GET' })
        .then(async (response) => {
          if (response.status === 404) {
            return "";
          }
          else {
            return await response.text();
          }
        })
        .catch(error => LogToConsoleError(error));
    }
    return data;
  }

  export const fetchWithTimeout = async (resources, options, timeout = 1 * 60 * 1000) => {
    // pull request timout from config
     const apiTimeout = API_REQUEST_TIMEOUT();
     if(apiTimeout) {
       timeout = Number(apiTimeout);
     }  
   
     // initilize abort controller 
     const controller = new AbortController();
   
     // append signal parameter with request
     const params = {...options, signal: controller.signal }
   
     // set timeout 
     const timeoutId = setTimeout(() => controller.abort(), timeout);
   
     // call api to pull the data
     const response = await fetch(resources, params);  
   
     // clear timeout 
     clearTimeout(timeoutId);
   
     // return api response
     return response;  
   }

const fetchPlus = (url, options, retries) => {

    return fetchWithTimeout(url, options)
        .then(async res => {
            if (res.ok) {
                return res
            }
            if (retries > 0) {
                LogToConsole("waiting..");
                await wait(5000);
                LogToConsole("restries", url, retries);
                return fetchPlus(url, options,retries - 1)
            }
            throw new Error(res.status + ":" + JSON.stringify(res))
        })
        .catch(async error => LogToConsoleError(error.message))
    }

export const retryFetch = (fn, retriesLeft = 3, interval = 1000): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retryFetch(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const getIPFSData = async (path: string): Promise<Response> => {
    return await fetchPlus(getIPFSLink(path), { method: "GET" } , 3);
}

export type Signeable = Buffer | string;
const separator: Buffer = Buffer.from([0x01]);

export function getBufferForSigning(...args: Signeable[]): Buffer {
    const chunks: Buffer[] = [];
  
    for (let i = 0; i < args.length; i++) {
        if (i > 0) chunks.push(separator);
  
        if (args[i] instanceof Buffer) {
            chunks.push(args[i] as Buffer);
        }
        else {
            chunks.push(Buffer.from(args[i].toString(), "utf-8"));
        }
    }
    return Buffer.concat(chunks);
}

export const getBufferForStatusAndAvatarSigning = (fileData: Buffer, fileName: string) => {
  const separator: Buffer = Buffer.from([0x01]);
  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(fileName.trim(), "utf-8"));
  chunks.push(separator);
  chunks.push(fileData);

  return Buffer.concat(chunks);
}

export const uploadImageToIPFSData = async  (newData: any, userWallet: Wallet, filename: string) => {

   
    const data = Buffer.from(newData, "utf-8");
    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const pin: boolean = true;
    let userAddress = userWallet.address;

    let signature = await userWallet.signMessage(getBufferForSigning(filename, "/upload/begin"));

    let masterSignature = await masterWallet.signMessage(signature);

    //post new URI to IPFS
    let ipfsImageUri = "";
    const postImageURL = IPNS_UPLOAD_LAMBDA_URL + `user/${userAddress}/upload/begin/${filename}/${signature}/${masterSignature}`;

    let response = await fetchWithTimeout(postImageURL, {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    let responseJson = JSON.parse(await response.text());

    // upload file
    response = await fetch(responseJson.url, {
        method: 'PUT',
        body: data
    });

    const filehash = ethers.utils.keccak256(data).slice(2);
    signature = await userWallet.signMessage(getBufferForSigning(filename, filehash, "/upload/complete"));
    masterSignature = await masterWallet.signMessage(signature);

    const completionUrl = IPNS_UPLOAD_LAMBDA_URL + `user/${userAddress}/upload/complete/${filename}/${filehash}/${signature}/${masterSignature}?ipns=false&pin=${pin}`;

    response = await fetch(completionUrl,{
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    let result = await response.json()

    if (response.status === 200) {
        const cid = result.fileCID;
        LogToConsole("cid-for-upload", cid);
        ipfsImageUri = "ipfs://" + cid;
        return ipfsImageUri;
    }
    return '';
}

export const getUploadIPFSData = async (newData: any, userWallet: Wallet, filename: string) => {

    const data = Buffer.from(newData, "utf-8");
    const pin: boolean = true;
    const canonicalizedData = getBufferForSigning(filename, data);
    const lambda_signature = await userWallet.signMessage(canonicalizedData);

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(lambda_signature);

    //post new URI to IPFS
    let ipfsImageUri = "";
    const postImageURL = IPNS_UPLOAD_LAMBDA_URL + 'user/' + userWallet.address + '/secure-upload/raw/' + filename + '/'
        + lambda_signature + '/' + masterSignature + '?ipns=false&pin='+pin;

    const response = await fetchWithTimeout(postImageURL, {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: newData
    });

    if (response.status === 200) {
        const cid = JSON.parse(await response.text()).fileCID;
        LogToConsole("cid-for-upload", cid);
        ipfsImageUri = "ipfs://" + cid;
        return ipfsImageUri;
    }
    return '';
}

export const uploadUserData = async (newAvatarUri: string, userWallet: Wallet, filename = "avatar.txt") => {

  // const filename = 'avatar.txt';
  const data = Buffer.from(newAvatarUri, "utf-8");
  const canonicalizedData = getBufferForStatusAndAvatarSigning(data, filename);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  //post new avatar URI to IPFS
  let ipnsAvatarUri;
  const postAvatarURL = IPNS_UPLOAD_LAMBDA_URL + 'user/' + userWallet.address + '/secure-upload/raw/' + filename + '/'
    + lambda_signature + '/' + masterSignature + '?ipns=false';

  ipnsAvatarUri = retryFetch(() => fetch(postAvatarURL, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: newAvatarUri
  }).then(async (res) => {
    if (res.status == 200) {
      const cid = JSON.parse(await res.text()).fileCID;
      ipnsAvatarUri = "ipfs://" + cid;
      return ipnsAvatarUri;
    }
  }))
    .catch((err) => {
      LogToConsoleError('fetch error for User Avatar');
      console.error(err)
    });
  return ipnsAvatarUri;
}

export const getUserIPNSURL = async (userWallet: Wallet) => {
  const blankFriendsJSON = { "friends": [] };
  const data = Buffer.from(JSON.stringify(blankFriendsJSON), "utf-8");
  const canonicalizedData = getBufferForSigning(FRIENDS_IPNS_FILE_NAME, data);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  let ipnsURL;
  ipnsURL = retryFetch(() => fetch(IPNS_UPLOAD_LAMBDA_URL + 'user/' + userWallet.address + '/secure-upload/raw/' + FRIENDS_IPNS_FILE_NAME
    + '/' + lambda_signature + '/' + masterSignature, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(blankFriendsJSON)
  }).then(async (response) => {
    if (response.status == 200) {
      const ipns = JSON.parse(await response.text()).folderIPNS;
      return ipns;
    }
    return null
  }))
    .catch((err) => {
      LogToConsole('fetch error for User IPNS');
      console.error(err)
    });
  return ipnsURL;
}

export const uploadUserDetailsToIPNS = async (userDetails: IIPNSUserDetailType, userWallet: Wallet) => {
  
  const data = Buffer.from(JSON.stringify(userDetails), "utf-8");
  const canonicalizedData = getBufferForSigning(USERDETAIL_IPNS_FILE_NAME, data);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  return retryFetch(() => fetch(IPNS_UPLOAD_LAMBDA_URL + 'user/' + userWallet.address + '/secure-upload/raw/' + USERDETAIL_IPNS_FILE_NAME
      + '/' + lambda_signature + '/' + masterSignature, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userDetails)
    }).then(async (response) => {
      if (response.status == 200) {
        const ipns = JSON.parse(await response.text()).folderIPNS;
        return ipns;
      }
      return null
    })).catch((err) => {
        LogToConsole('fetch error for User IPNS');
        console.error(err)
      });
}
