import React, { useState } from 'react';
import { Box, Modal, ModalProps, Paper, PaperProps, Typography, styled } from "@mui/material";

import { CircularProgress } from '@mui/material';
import { defaultTheme } from '../../styles';
import { CustomButton } from '../Button/CustomButton';
import { useDispatch } from 'react-redux';
import { useAppSelector, useWeb3Context } from '../../hooks';
import { AppDispatch } from '../../store';
import CopyIcon from '../CopyIcon/CopyIcon';
import { CustomCheckbox } from '../CheckBox/CheckBox';
import { CreateInviteCodes, getInviteCodesSent, getRandomInviteCode } from '../../slices/AppUserSlice';
import { LogToConsole, LogToConsoleError } from '../../helpers/Logger';

const StyledModal = styled(Modal)<ModalProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: '#0000001a'

}));

const Content = styled(Paper)<PaperProps>(({theme}) => ({
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  outline: 'none',
  position: 'relative',
  minWidth: '35vw',
  maxWidth: "60%",
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backdropFilter: "blur(5em)",
  color: defaultTheme.lightColor,
  fontFamily: "Poppins",
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }

}));


export const InviteCodeModal = ({ modalOpen, setModalOpen }) => {

  const { address, wallet, networkId, staticProvider } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();

  const [showLoader, setShowLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [inviteCodes, setInviteCodes] = useState<string[]>(null);
  const maxInviteCodes = useAppSelector(state => state.AppUser.maxInvites);
  const sentInviteCodes = useAppSelector(state => state.AppUser.InvitesSent);

  const maxCodes = Math.min(Number(maxInviteCodes)-Number(sentInviteCodes), 5)


  const onGetCode = async () => {
    setShowLoader(true);
    try {
      const encodedInvites : string[] = await getRandomInviteCode({ networkID: networkId, provider: staticProvider, maxInvite: maxCodes });

      
      if(encodedInvites && encodedInvites.length === maxCodes) {
        await dispatch(CreateInviteCodes({ networkID: networkId, provider: staticProvider, address, wallet, inviteCodes: encodedInvites }));

        // To be removed later
        LogToConsole("Invite Codes",encodedInvites);
        
        setInviteCodes(encodedInvites);
        await dispatch(getInviteCodesSent({ networkID: networkId, provider: staticProvider, address }));
      }
      else {
        LogToConsoleError("Error: Generating invite Codes");
      }
    } catch (error) {
      LogToConsoleError("Error: Fetching invite Codes", error);
    }
    setShowLoader(false);
  }

  const onCloseModal = () => {
    setModalOpen(false);
  }

  const exportInviteCode = () => {
    const blob = new Blob([inviteCodes.join('\n')], { type: 'text/plain' });

    // Create a download link
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);

    // Set the filename for the downloaded file
    a.download = 'InviteCodes.txt';

    // Trigger a click event on the download link to initiate the download
    a.click();
  }
 
  return (
    <StyledModal open={modalOpen} onClose={() => {}} >

        <Content>
          <div className='w-100'>
            <Typography fontFamily={'Poppins-Medium'} color={defaultTheme.buttonDarkTextColor} fontSize={'1.2rem'} textAlign={'left'}>Invite Codes</Typography>
            <Box width={'100%'} marginTop={'1rem'} color={defaultTheme.buttonDarkTextColor} display={'flex'} flexDirection={'column'} >
              <p className="lh-md"><span>
              Disclaimer: These invite codes cannot be retrieved later. Make sure to copy and save them before sharing with your friends  
              </span></p>
                

              <Box marginY={2} color={defaultTheme.buttonDarkTextColor}>
                { (inviteCodes && inviteCodes.length > 0) ? 
                  <CustomButton 
                    className={'mb-2'} 
                    onClick={exportInviteCode}
                    color={defaultTheme.buttonDarkTextColor} 
                    backgroundColor={defaultTheme.yellowColor}  
                    disabled={showLoader}
                    type={'button'}
                  >
                    <Typography fontSize={'1rem'} fontFamily={'Poppins-SemiBold'}>Download Invite Codes</Typography>
                  </CustomButton>
                  :
                  <CustomButton 
                    className={'mb-2'} 
                    onClick={onGetCode}
                    color={defaultTheme.buttonDarkTextColor} 
                    backgroundColor={defaultTheme.yellowColor}  
                    disabled={showLoader}
                    type={'button'}
                  >
                    {
                      showLoader ? 
                        <CircularProgress size={"1.5rem"} sx={{ color: defaultTheme.lightColor, marginBlock: '0.25rem', marginInline: '2rem' }}/>
                        :
                        <Typography fontSize={'1rem'} fontFamily={'Poppins-SemiBold'}>Show codes</Typography>
                    }
                  </CustomButton>
                }
                <Box display={'flex'}>
                  <div>
                    { inviteCodes && inviteCodes.length > 0 ? 
                      inviteCodes.map( (code, idx) => <div className='d-flex align-items-center' key={idx}><p className="lh-md me-3"><span>{code} </span></p></div>)
                      :
                      Array.from({length: maxCodes}, (e,i) => <div className='d-flex align-items-center' key={i}><p className="lh-md me-2"><span>{"x".repeat(20)} </span></p></div>)
                    }
                  </div>
                  <div>
                    { inviteCodes && inviteCodes.length > 0 ? 
                      inviteCodes.map( (code, idx) => <div className='d-flex align-items-center' key={idx}><CopyIcon text={code} /></div>)
                      :
                      Array.from({length: maxCodes}, (e,i) => <div className='d-flex align-items-center' key={i}><CopyIcon disabled text={""} /></div>)
                    }
                  </div>
                </Box>

                <CustomCheckbox 
                  label={"Yes, I copied and saved all invite codes"}
                  customColor={defaultTheme.yellowColor}
                  checked={checked}
                  handleCheckChange={(events) => setChecked(events.target.checked)}
                  disabled={!inviteCodes}/>
                
                <div className='w-100 d-flex justify-content-end me-3'>
                  <CustomButton 
                    className={'mt-2'} 
                    onClick={() => onCloseModal()}
                    color={defaultTheme.buttonDarkTextColor} 
                    backgroundColor={defaultTheme.yellowColor}  
                    disabled={(!checked && inviteCodes && inviteCodes.length > 0) || showLoader}
                    type={'button'}
                  >
                    <Typography fontSize={'1rem'} fontFamily={'Poppins-SemiBold'}>{"Close"}</Typography>
                  </CustomButton>
                </div>
                
              </Box>
            </Box>
          </div>

        </Content>
    </StyledModal>
  );
};
