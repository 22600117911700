import { useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area, Dot } from 'recharts';
import { defaultTheme } from '../../styles';

// Sample data

type ChartProps = {
price:string|number;
percentage:string|number;
}

const PriceCurveChart = (props:ChartProps) => {

const {price,percentage} = props;
let varyingPrice = price; 

const [data, setData] = useState([
    { name: '25', users: 25, price: null },
    { name: '50', users: 50, price: null },
    { name: '75', users: 75, price: null },
    { name: '100', users: 100, price: null },
    { name: '125', users: 125, price: null },
    { name: '150', users: 150, price: null },
    { name: '175', users: 175, price: null },
    { name: '200', users: 200, price: null },
  ]);

const calculatePercentage = async () => {
    let pricePerUser;    
    const temp = [...data];
    for(let i=0;i<temp.length;i++){  
        if(i==0){
        pricePerUser = price;
        }else{
        pricePerUser = Number(Number(varyingPrice) * Number(Number(percentage)/100)) + Number(varyingPrice);
        }
        varyingPrice = pricePerUser;
        temp[i].price = Number(varyingPrice);
    }
    setData(temp);
}

useEffect(()=>{
    calculatePercentage()
},[price,percentage])

    return (
        <div style={{background:defaultTheme.newFieldColor,borderRadius:8,padding:'5px',margin:"10px 0px"}}>
        <ResponsiveContainer width='100%' aspect={1.2}>
            <AreaChart
                width={300}
                height={50}
                data={data}
                margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="users" label={{ value: "No of users", position: "insideBottom", offset: -10 }} />
                <YAxis dataKey='price' label={{ value: "Price (Loot8)", angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Area type="monotone" dataKey="price" stroke="#000" fill="#ddd"
                    dot={<Dot r={2} stroke="#000" strokeWidth={3} fill="#ddd" />}
                />
            </AreaChart>
        </ResponsiveContainer>
        </div>
    );
}

export default PriceCurveChart;
