import React from 'react';
import { IEntity } from '../../../interfaces/IEntity.interface';
import { Card, CardActionArea, CardContent, CardProps, Paper, PaperProps, Typography, styled } from '@mui/material';
import { defaultTheme } from '../../../styles';

const EntityCardContainer = styled(Paper)<PaperProps>(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#0a2850ad',
  borderRadius: '1rem',
  overflow: "hidden"

}));

const CardBox = styled(Card)<CardProps>(({theme}) => ({
  background: 'transparent',
  color: defaultTheme.lightColor,
  boxShadow: 'none',
  minHeight: '8rem',
  minWidth: '12rem'
}));

type Props = {
  item: IEntity,
  onSelectEntity : (item) => void
}

export const EntityCard = ({ item, onSelectEntity }: Props) => {

  return (
    <EntityCardContainer elevation={4}>
      <CardBox>
        <CardActionArea sx={{ maxWidth: '12rem', height:'100%', padding: 1 }} onClick={() => onSelectEntity(item)}>
          {/* <CardMedia
            component="img"
            image={EntityImage}
            sx={{
              height: '10rem',
              width: '10rem',
              objectFit: 'contain',
              objectPosition: 'center',
              borderTopLeftRadius: '1rem',
              borderTopRightRadius: '1rem',
            }}
            alt="green iguana"
          /> */}
          <CardContent sx={{ padding: '0.5rem 0' }}>
            <Typography gutterBottom={false} variant="h6" fontFamily={'Poppins-Medium'} textAlign={'center'} sx={{ 
              wordBreak: 'break-word'
            }} >
              {item?.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </CardBox>
    </EntityCardContainer>
  );
} 