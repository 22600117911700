import { getNetwork } from "../constants";
import { CollectionType } from "../enums/collection.enum";
import { OfferType, UICustomOfferType } from "../enums/offers.enum";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";

export const DEFAULTFEATUREDOFFER : ICollectibleDetail = {

  index: 0,
  chain: getNetwork(),
  dataURI: "",
  imageProps: {
    image: "",
    imageSize: 0,
    thumbnailImage:"",
    thumbnailImageSize:0,
    optimizedImage:"",
    optimizedImageSize:0,
  },
  details: "",
  name: "",
  address: "",
  symbol: "",
  isActive: true,
  entityAddress: "",
  price: '0.00',
  start: null,
  end: null,
  // checkInNeeded: false,
  offerType: OfferType.FEATURED,
  collectionType: CollectionType.COLLECTION,
  linkCollectible: [],
  subTitle: "",
  whitelistedCollections: [],
  totalSupply : 0,
  tokensEarned: 0,
  maxMint: 0,
  maxPurchase: 0,
  maxBalance: 0,
  mintWithLinked: false,
  mintWithLinkedOnly: false,
  isVideoCollectible: false,
  video: "",
  isCoupon: false,
  area: {
    latitude: "",
    longitude: "",
    radius: 0
  },
  socialMedia: true,
  marketPlaceConfig: {
    allowMarketplaceOps: true,
    privateTradeAllowed: true,
    publicTradeAllowed: true
  }
}