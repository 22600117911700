import { PrivateForwarder } from "../typechain/PrivateForwarder";
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch
} from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  addresses,
  checkNewForwarderCompatibility,
  MASTER_KEY,
  META_TX_DEADLINE,
  NetworkId,
  NETWORKS,
  WEBHOOK_URL
} from "../constants";
import { RootState } from "../store";
import { setAll } from "./helpers";
import {
  IForwardRequest,
  IForwardRequestBuilder,
  IMessageMetaData,
  ITxTypeData,
  IWebhookResponse
} from "./interfaces";
import {
  LOOT8Forwarder__factory,
  PrivateForwarder__factory,
  PrivateOwnableForwarder__factory
} from "../typechain/factories";
import { ethers, Wallet } from "ethers";
import { wait } from "../helpers/ipfs";
import AwaitLock from "../helpers/AwaitLock";
import { LOOT8Forwarder, PrivateOwnableForwarder } from "../typechain";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";

let lock = new AwaitLock();

const buildRequest_Loot8Forwarder = async (
  args: IForwardRequestBuilder,
  prevNonce: string
): Promise<IForwardRequest | null> => {
  const { forwarder, params, networkID, wallet } = args

  let request: IForwardRequest = {
    value: 0,
    gas: undefined,
    nonce: undefined,
    deadline: META_TX_DEADLINE(),
    ...params
  }

  request["nonce"] = Number(await forwarder.nonces(params.from))
  try {
    while (prevNonce && String(prevNonce) === String(request["nonce"])) {
      await wait(500)
      request["nonce"] = Number(await forwarder.nonces(params.from))
    }
  } catch (e) {
    LogToConsole("Failed to fetch nonce", e)
  }

  if (networkID === NetworkId.POLYGON_MAINNET) {
    request["gas"] = 1e6

    return request
  } else {
    let gas = 2e6
    const txSubmit = new Date().getTime()

    try {
      request["gas"] = 15e6

      let { domain, types, message } = buildTypedData_Loot8Forwarder(
        forwarder,
        request,
        networkID
      )
      let signature = await wallet._signTypedData(domain, types, message)

      const payload = { ...request, signature }
      const data = forwarder.interface.encodeFunctionData("execute", [payload])

      gas = (
        await forwarder.provider.estimateGas({
          from: addresses[networkID].RelayerAddress,
          to: addresses[networkID].Loot8Forwarder,
          data: data
        })
      ).toNumber()

      LogToConsole(`gasEstimated: ${gas}`)
    } catch (e) {
      LogToConsole("timeend-error:", new Date().getTime(), e)
      LogToConsole(
        "error",
        { message: "META-TX-GAS-ESTIMATE-ERROR", to: params.to, networkID },
        [
          params,
          txSubmit,
          { txSubmit: String(new Date().getTime() - txSubmit) }
        ]
      )
    }

    request["gas"] = gas

    return request
  }
}

const buildRequest = async (forwarder: PrivateForwarder | PrivateOwnableForwarder, input: any, networkID, wallet: Wallet, prevNonce: string): Promise<any> => {
  let nonce = (await forwarder.getNonce(input.from)).toString();
  try {
    while(prevNonce && prevNonce === nonce){
      await wait(500);
      nonce = (await forwarder.getNonce(input.from)).toString();
    }
  }
  catch(e) {
    LogToConsoleError('Failed to fetch nonce', e);
   }
  let gas = 2e6;
  try {
    //const gasPrice = await forwarder.provider.getGasPrice();
    const request = { value: 0, gas: 15e6, nonce, ...input };

    let toSign = await buildTypedData(forwarder, request);
    let signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);
    const data = forwarder.interface.encodeFunctionData("execute", [request, signature]);

    gas = (await forwarder.provider.estimateGas({
       from: addresses[networkID].RelayerAddress,
       to: addresses[networkID].PrivateForwarder,
       data: data })).toNumber();

    LogToConsole(`gasEstimated: ${gas}`)

  }
  catch (e) {
    LogToConsoleError("timeend-error:", new Date().getTime(), nonce, e);
  }
  return { value: 0, gas: gas, nonce, ...input };
}

const buildTypedData_Loot8Forwarder = (
  forwarder: LOOT8Forwarder,
  request: IForwardRequest,
  networkID: NetworkId | ""
): ITxTypeData => {
  const typeData = getMetaTxTypeData_Loot8Forwarder(
    networkID,
    forwarder.address
  )

  return { ...typeData, message: request }
}

const buildTypedData = async (forwarder: PrivateForwarder | PrivateOwnableForwarder, request: any) => {
  const chainId = (await forwarder.provider.getNetwork()).chainId;
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
}

export interface IAppData {
  readonly msgLoading: boolean;
  readonly nonce: string;
}

const initialState: IAppData = {
  msgLoading: false,
  nonce: ""
};

const getMetaTxTypeData_Loot8Forwarder = (
  chainId: NetworkId | "",
  verifyingContract: string
): Omit<ITxTypeData, "message"> => {
  return {
    types: {
      ForwardRequest: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "gas", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint48" },
        { name: "data", type: "bytes" }
      ]
    },
    domain: {
      name: "LOOT8 Forwarder",
      version: "1",
      chainId,
      verifyingContract
    },
    primaryType: "ForwardRequest"
  }
}

const getMetaTxTypeData = (chainId: number, verifyingContract: string): any => {
  return {
    types: {
      ForwardRequest: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "gas", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "data", type: "bytes" },
      ],
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest'
  }
};

export const SendMetaTX = createAsyncThunk("app/SignMetaTX", async ({ networkID, provider, to, wallet, data }: IMessageMetaData, { dispatch, getState }): Promise<any> => {
  await lock.acquireAsync();
  const startTime = new Date().getTime();
  try {
    LogToConsole("transaction start", startTime)
    let res = await dispatch(SendMetaTXCall({ networkID, provider, to, wallet, data }));
    return res.payload;
  } finally {
    const endTime = new Date().getTime();
    LogToConsole("transaction end", endTime, "Total time took:", (endTime-startTime)/1000, 'seconds');
    lock.release();
  }
});

export const SendMetaTXCall_Loot8Forwarder = async (
  { networkID, provider, to, wallet, data }: IMessageMetaData,
  {
    prevNonce,
    dispatch
  }: {
    prevNonce: string
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>
  }
): Promise<any> => {
  try {
    console.log("Send Meta TX Calls - Loot8 Forwarder")
    const from = await wallet.getAddress()

    let forwarder = LOOT8Forwarder__factory.connect(
      addresses[networkID].Loot8Forwarder,
      provider
    )

    const request = await buildRequest_Loot8Forwarder(
      {
        forwarder,
        params: { from, to, data },
        networkID,
        wallet
      },
      prevNonce
    )
    await dispatch(updateNonce(request.nonce))
    const { domain, types, message } = buildTypedData_Loot8Forwarder(
      forwarder,
      request,
      networkID
    )

    const signature = await wallet._signTypedData(domain, types, message)

    const masterWallet = new ethers.Wallet(MASTER_KEY)
    const masterSignature = await masterWallet.signMessage(signature)

    let responseData
    try {
      responseData = await fetch(WEBHOOK_URL(networkID), {
        method: "POST",
        body: JSON.stringify({
          request: { ...request, signature },
          masterSignature
        }),
        headers: { "Content-Type": "application/json" }
      })
    } catch (e) {
      LogToConsole("WEBHOOK ERROR", e)
      await dispatch(updateNonce(""))
    }

    let response: IWebhookResponse = await responseData.json()
    LogToConsole("relay-response", response)

    let waitResponseLogs: any
    if (response.status == "success") {
      let result = response?.result ? JSON.parse(response.result) : null
      let tx = result?.txHash
        ? result?.txHash
        : JSON.parse(response.result).txHash
      const txStartTime = new Date().getTime()
      const txReceipt = provider.waitForTransaction(tx)
      LogToConsole("txReceipt", txReceipt)

      let waitResponse: any = await Promise.race([txReceipt, wait(20000)])
      waitResponseLogs = waitResponse?.logs
      LogToConsole("waitResponse", waitResponse)

      if (!waitResponse) {
        LogToConsole("calling waitrepsonse again")
        waitResponse = await Promise.race([txReceipt, wait(60000)])
        LogToConsole("waitResponse", waitResponse)
        if (waitResponse) {
          const transactionEnd = new Date().getTime()
          const processing = transactionEnd - txStartTime
          waitResponseLogs = waitResponse?.logs
          LogToConsole(
            "warning",
            { message: "META-TX-TIME-WARNING", to, networkID },
            [response, tx, waitResponseLogs, txStartTime, processing]
          )
        } else {
          LogToConsole("error", { message: "META-TX-TIMEOUT", to, networkID }, [
            response,
            txStartTime,
            tx
          ])

          return {
            status: "Error",
            errMsg: "Something went wrong. Please try again later."
          }
        }
      }

      return {
        status: "Success",
        response: response,
        eventLogs: waitResponseLogs
      }
    } else {
      LogToConsole("error", { message: "META-TX-ERROR", to, networkID }, [
        response
      ])

      // TODO: Add error handling after testing error responses
      return {
        status: "Error",
        response: response,
        eventLogs: waitResponseLogs
      }
    }
  } catch (error) {
    LogToConsole("error", { message: error, to: wallet.address, networkID }, [
      error
    ])
    LogToConsole("SendMetaTXCall", error.name, error.message, error.stack)

    // TODO: Add error handling after testing error responses
    return {
      status: "Error",
      errMsg: "Something went wrong. Please try again later."
    }
  }
}

export const SendMetaTXCall = createAsyncThunk("app/SendMetaTx", async ({ networkID, provider, to, wallet, data }: IMessageMetaData, { dispatch, getState} ) : Promise<any> => {
  try { 
    const state = getState() as RootState;

    let isNewForwarderCompatible = checkNewForwarderCompatibility(networkID)

    if (isNewForwarderCompatible) {
      return await SendMetaTXCall_Loot8Forwarder(
        {
          networkID,
          provider,
          to,
          wallet,
          data
        },
        { dispatch, prevNonce: state.App.nonce }
      )
    }

    const from = await wallet.getAddress();
    const ADDRESS_FORWADER = addresses[networkID].PrivateForwarder;
    let private_forwarder: any;

    if( networkID === NetworkId.POLYGON_MAINNET || networkID === NetworkId.POLYGON_MUMBAI_TESTNET ) {
      private_forwarder = PrivateOwnableForwarder__factory.connect(ADDRESS_FORWADER, provider)
    }
    else {
      private_forwarder = PrivateForwarder__factory.connect(ADDRESS_FORWADER, provider);
    }
    const request = await buildRequest(private_forwarder, { from, to, data }, networkID, wallet, state.App.nonce);
    await dispatch(updateNonce(request.nonce));
    const toSign = await buildTypedData(private_forwarder, request);
    const signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(signature);
    const body = JSON.stringify({ signature, request, masterSignature });
    const submissionTime = new Date().getTime();
    
    let response = null;
    try {
    let responseData = await fetch(WEBHOOK_URL(networkID), {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/json" },
    });
    response = await responseData.json();
    LogToConsole("relay-response", response);
    }
    catch(e) {
      LogToConsoleError("WEBHOOK ERROR", e);
      await dispatch(updateNonce(""));
    }

    let waitResponseLogs;
    if (response && response?.status === 'success') {
      let tx = (JSON.parse(response?.result)).txHash;
      const transactionStart = new Date().getTime();
      const txReceipt = provider.waitForTransaction(tx);
      LogToConsole("txReceipt", txReceipt);
      let waitResponse : any = await Promise.race([txReceipt, wait(20000)]);//timeout in millisecond
      LogToConsole("waitResponse", waitResponse);
      waitResponseLogs = waitResponse?.logs;
      if (!waitResponse) {
        LogToConsole("calling waitrepsonse again");
        waitResponse = await Promise.race([txReceipt, wait(60000)]);//timeout in millisecond    
        LogToConsole("waitResponse", waitResponse);
        if (waitResponse) {
          const transactionEnd = new Date().getTime();
          const timeDiffernt = transactionEnd - transactionStart;
          waitResponseLogs = waitResponse?.logs;
          //transactionHash,autoTaskRunId,blockNumber,networkID,submissionTime
          LogToConsoleError("META-TX-TIME-WARNING", to, networkID, [
            { tag: "autotaskId", value: response.autotaskId },
            { tag: "autotaskRunId", value: response.autotaskRunId },
            { tag: "blockNumber", value: waitResponse?.blockNumber },
            { tag: "processingTime", value: timeDiffernt },
            { tag: "processStartTime", value: transactionStart },
            { tag: "submissionTime", value: submissionTime },
            { tag: "txReceipt", value: tx }]);
        }
        else {
          LogToConsoleError("META-TX-TIMEOUT", to, networkID, [
            { tag: "autotaskId", value: response.autotaskId },
            { tag: "autotaskRunId", value: response.autotaskRunId },
            { tag: "atLog", value: response.autotaskRunId },
            { tag: "submissionTime", value: submissionTime },
            { tag: "processStartTime", value: transactionStart },
            { tag: "txReceipt", value: tx }]);
          return { status: "Error", errMsg: "Something went wrong. Please try again later." }
        }
      }
    }
    else {
      LogToConsoleError("META-TX-ERROR", to, networkID,
        [{ tag: "autotaskId", value: response.autotaskId },
        { tag: "autotaskRunId", value: response.autotaskRunId },
        { tag: "status", value: response.status },
        { tag: "message", value: response.message }]);
    }
    return { status: "Success", response: response, eventLogs: waitResponseLogs };

  }
  catch (e) {
    return { status: "Error", errMsg: "Something went wrong. Please try again later." }
  }
});

const AppSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateNonce(state, action) {
      state.nonce = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SendMetaTX.pending, (state: { msgLoading: boolean; }) => {
        state.msgLoading = true;
      })
      .addCase(SendMetaTX.fulfilled, (state: { msgLoading: boolean; }, action: { payload: any; }) => {
        state.msgLoading = false;
      })
      .addCase(SendMetaTX.rejected, (state: { msgLoading: boolean; }, { error }: any) => {
        state.msgLoading = false;
        LogToConsoleError("SendMetaTX", error.name, error.message, error.stack);
      })
  },
});

export const AppSliceReducer = AppSlice.reducer;

const baseInfo = (state: RootState) => state.App;

export const { fetchAppSuccess, updateNonce } = AppSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
