export const Template3 = `<!DOCTYPE html>
<html lang="en">

<head>
  <title>Loot8 For You</title>

  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;600;700&display=swap" rel="stylesheet">

  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <script
    src="https://loot8-cdn-dev.loot8.io/ipns/k51qzi5uqu5dlqhlias6mtzusn62o2wnvmuuooi2248zna4mhcbz5ymvsad6sk/script.js"
    type="text/javascript">
  </script>

  <style>
  #body {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      color: #fff;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #000000;
      min-height: 100%;
      position: relative;
      text-align: center;

    }

    #name{
      font-size: clamp(1rem, 4vmin, 1.5rem);
font-weight: 400;
font-style: italic;
   
word-wrap: break-word;
} 
    #body-container {
      background-color: #000000;
      background-image: url('https://ipfs-prod-01.loot8-services.dev:443/ipfs/QmUwZwnr49JSfsA5mWoBnS2jozMoQGrCNTLs5sqwerpHfS');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: max(100vh, 100%) ;
      position: absolute;
    }
    #content {
      margin: 8% 0 8% 2%;
    }
    #title {
      font-size: clamp(10px, 6vmin, 1.75rem);
      font-weight: bold;
      margin-top:2rem;
      word-wrap: break-word;
      text-shadow: rgb(37, 37, 70) 2px 0px 0px, rgb(37, 37, 70) 1.75517px 0.958851px 0px, rgb(37, 37, 70) 1.0806px 1.68294px 0px, rgb(37, 37, 70) 0.141474px 1.99499px 0px, rgb(37, 37, 70) -0.832294px 1.81859px 0px, rgb(37, 37, 70) -1.60229px 1.19694px 0px, rgb(37, 37, 70) -1.97998px 0.28224px 0px, rgb(37, 37, 70) -1.87291px -0.701566px 0px, rgb(37, 37, 70) -1.30729px -1.5136px 0px, rgb(37, 37, 70) -0.421592px -1.95506px 0px, rgb(37, 37, 70) 0.567324px -1.91785px 0px, rgb(37, 37, 70) 1.41734px -1.41108px 0px, rgb(37, 37, 70) 1.92034px -0.558831px 0px;
    }
    #subtext {
      font-size: clamp(10px, 4vmin, 1.5rem);
      font-weight: 600;
      word-wrap: break-word;
      color: #252546;

    }
    #description {
      font-size: clamp(10px, 4vmin, 1.5rem);
      font-weight: 600;
      word-wrap: break-word;
      line-height: 1.5;
      width: 85%;
      color: #252546;

    }
    #timestamp {
      font-size: clamp(10px, 3.5vmin, 1.3rem);
      background-color: #F4B540;
      font-weight: 600;
      padding: 0.5rem 0.7rem;
      color: #252546;
      border-radius: 4px;
    }
  

  </style>
</head>
<body id="body">
  <div id="body-container"></div>
  <div class="container position-relative p-4">
    <div id="content" class="d-flex flex-column justify-content-center align-items-center gap-2">
    <h4 id="name" >Announcement Header Name Goes Here</h4>
      
    <h4 id="title" >Your Title goes here</h4>
      <h5 id="subtext">Your Subtext Goes here</h5>
      <p id="description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
  
      <h5 id="timestamp">Your Date & Time goes here</h5>
    </div>
  </div>
</body>
</html>
`
