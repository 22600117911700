import React, { useState } from 'react';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Add, Remove, InfoRounded } from '@mui/icons-material';

import { CustomCheckbox } from '../CheckBox/CheckBox';
import { LightTooltip } from '../../views/CollectionDetails/views/FormContainer.tsx/CollectionFormContainer';
import { BasicTextInput } from '../TextInput/BasicTextInput/BasicTextInput';
import { defaultTheme } from '../../styles';


const SocialLinksComponent = ({ socialLinks, checked, socialLinkChangeHandler, formSocialLinks, onRemoveSocialLinkHandler, onAddSocialLinkHandler }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleSocialClick = (event) => {
    if(socialLinks?.length==Object?.entries(formSocialLinks)?.length) return 
    setAnchorEl(event.currentTarget);
  };

  const handleSocialClose = () => {
    setAnchorEl(null);
  };

  const handleSocialSelect = (social) => {
    onAddSocialLinkHandler(social);
    handleSocialClose();
  };



  return (
    <div className='w-100'>
      <Box>
        <CustomCheckbox label={"Add Social Links"} checked={checked} handleCheckChange={socialLinkChangeHandler} disabled={false} />
        <LightTooltip title={"You have the option to add social media links, and these links will be shown on the passport details page within the mobile application."} placement="bottom" arrow>
          <InfoRounded className='mt-2' fontSize='small' color='disabled' />
        </LightTooltip>
      </Box>


      {checked && (
        <div className='w-100'>


          <Menu sx={{ left: '13rem', top: '-3.5rem' }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleSocialClose}>
            {socialLinks && socialLinks.map((social, index) => !(social?.key?.toLowerCase() in formSocialLinks) && (
              <MenuItem key={index} onClick={() => handleSocialSelect(social?.key)}>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <img src={social?.icon} alt="" style={{ width: "25px", height: "25px" }} />
                    <Typography textTransform={'uppercase'} mx={2} fontSize={'13px'} fontFamily={'Poppins-Medium'} color={'#000'}>{social?.label}</Typography>
                  </Box>
                  {index + 1 != socialLinks?.length ?
                    <Divider sx={{ backgroundColor: 'red', width: '100%', mt: 1.5 }} /> : <></>}
                </Box>
              </MenuItem>

            ))}
          </Menu>

          <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
              
            {socialLinks && socialLinks.map((social, index) => social?.key?.toLowerCase() in formSocialLinks && (
                <Box sx={{width:'100%'}} key={index}>
                <BasicTextInput
                  key={social.id}
                  handleRemove={()=>onRemoveSocialLinkHandler(social?.key)}
                  pattern={social?.pattern}
                  icon={social?.icon}
                  value={social?.value} valueChangeHandler={(event) => social?.handler(event)}
                  label={social?.label} placeholder={social?.placeHolder}
                  required={social?.required} disabled={social?.disabled}
                />
                </Box>
            )
            )}
          </Box>
          <Box mt={2}>
            <IconButton sx={{ opacity:socialLinks?.length==Object?.entries(formSocialLinks)?.length ? 0.5 : 1,border: '2px dashed #C0136F', borderRadius: '6px', width: '12.5rem', padding: '1rem' }} disabled={socialLinks?.length==Object?.entries(formSocialLinks)?.length} onClick={handleSocialClick}>
              <Add sx={{ backgroundColor: '#C0136F', borderRadius: '50%', color: '#FFFFFF', fontSize: '1.25rem' }} />
            </IconButton>
          </Box>
        </div>
      )}


    </div>
  );
};

export default SocialLinksComponent;
