// import { Web3Auth } from "@web3auth/modal";
import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from "@web3auth/base";

import { Web3AuthNoModal } from "@web3auth/no-modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { LOGIN_PROVIDER, LOGIN_PROVIDER_TYPE, OpenloginAdapter } from "@web3auth/openlogin-adapter";

import type { IProvider } from "@web3auth/base";
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import RPC from "./../ethersRPC";
import { APP_STORAGE_USER_KEY, REDIRECT_URL, TWITCH_CLIENT_ID, TWITCH_LOGIN_VERIFIER_NAME, getNetwork, getNetworkName, getNetworkRPC, getNetworkURI, getStaticProvider } from "../constants";
import { EnvHelper } from "../Environment";
import { ethers, Wallet } from "ethers";
import { clearAllData, getData, storeData } from "../helpers/AppStorage";
import { LogToConsoleError } from "../helpers/Logger";

type web3AuthDetailsTypes = {
  connect: (loginType?: LOGIN_PROVIDER_TYPE, loginHint?: string) => Promise<boolean>;
  disconnect: () => void;
  web3auth: Web3AuthNoModal | null;
  address: string;
  connected: boolean;
  networkId: number;
  networkName: string;
  blockExplorerUrl: string;
  providerUri: string;
  provider: IProvider;
  userInfo: any;
  staticProvider: any;
  wallet: any;
  connectLoading: boolean;
};
export type Web3ContextData = {
  web3AuthDetails: web3AuthDetailsTypes;
} | null;

const clientId = EnvHelper.env.REACT_APP_CLIENTID ?? "";
const networkHEX = EnvHelper.env.REACT_APP_NETWORKHEX;
const chainrpc = EnvHelper.env.REACT_APP_CHAINRPC;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error("useWeb3Context() can only be used inside of <Web3ContextProvider />, please declare it at a higher level.");
  }
  const { web3AuthDetails } = web3Context;
  return useMemo<web3AuthDetailsTypes>(() => {
    return { ...web3AuthDetails };
  }, [web3AuthDetails]);
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [connectLoading, setConnectLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [provider, setProvider] = useState<IProvider>();
  const [networkId, setNetworkId] = useState(0);
  const [userInfo, setUserInfo] = useState<Partial<any>>();
  const [networkName, setNetworkName] = useState("");
  const [providerUri, setProviderUri] = useState("");
  const [blockExplorerUrl, setBlockExplorerUrl] = useState("");
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
  const [staticProvider, setStaticProvider] = useState<StaticJsonRpcProvider>(getStaticProvider());
  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        const chainConfig = {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: networkHEX,
          rpcTarget: chainrpc,
          displayName: "Arbitrum",
          blockExplorer: "https://goerli.arbiscan.io/",
          ticker: "ETH",
          tickerName: "Ethereum",
        };

        const web3auth_l = new Web3AuthNoModal({
          clientId,
          chainConfig,
          web3AuthNetwork: EnvHelper.env.REACT_APP_ENV === "development" ? "testnet" : "cyan",
        });

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });

        const openloginAdapter = new OpenloginAdapter({
          loginSettings: {
            mfaLevel: "none",
          },
          privateKeyProvider,
          adapterSettings: {
            //uxMode: 'redirect',
            clientId: clientId,
            loginConfig: {
              twitch: {
                name: "TwitchLogin",
                verifier: TWITCH_LOGIN_VERIFIER_NAME,
                typeOfLogin: "twitch",
                clientId: TWITCH_CLIENT_ID,
              },
            },
          },
        });

        web3auth_l.configureAdapter(openloginAdapter);

        await web3auth_l.init();
        setWeb3auth(web3auth_l);

        if (web3auth_l?.provider) {
          setProvider(web3auth_l.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  // useEffect(() => {
  //   const init = async () => {
  //     if(web3auth && web3auth?.connected) {
  //       await connect();
  //     }
  //   }

  //   init();
  // },[web3auth]);

  const connect = useCallback(
    async (loginType?: LOGIN_PROVIDER_TYPE, loginHint?: string) => {
      setConnectLoading(true);

      let privateKey = await getData("pKey");
      let userinfoAuth = await getData(APP_STORAGE_USER_KEY);

      try {
        if (!userinfoAuth || !privateKey) {
          let web3authProvider = provider;

          if (!web3auth?.connected) {
            web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
              loginProvider: loginType,
              redirectUrl: REDIRECT_URL,
              // to support legacy email login
              extraLoginOptions: {
                login_hint: loginType == LOGIN_PROVIDER.EMAIL_PASSWORDLESS && loginHint,
              },
            });
          }

          setConnectLoading(true);
          setProvider(web3authProvider);

          userinfoAuth = await web3auth.getUserInfo();

          const rpc = new RPC(web3authProvider as IProvider);
          privateKey = await rpc.getPrivateKey();
        }

        const userWallet = new ethers.Wallet(privateKey);
        const address = userWallet.address;

        // to remove email domain from username
        if (userinfoAuth?.typeOfLogin === "jwt" || userinfoAuth?.typeOfLogin === "github") {
          if (userinfoAuth?.name.indexOf("@") > -1) {
            userinfoAuth.name = userinfoAuth.name.substring(0, userinfoAuth.name.lastIndexOf("@"));
          }
        }

        await storeData(APP_STORAGE_USER_KEY, userinfoAuth);
        await storeData("address", address);
        await storeData("pKey", privateKey);

        setAddress(address);
        setNetworkId(getNetwork());
        setProviderUri(getNetworkURI());
        setNetworkName(getNetworkName());
        setBlockExplorerUrl(getNetworkRPC());
        setWallet(userWallet);
        setUserInfo(userinfoAuth);

        setStaticProvider(getStaticProvider());
        setConnected(true);
        setConnectLoading(false);
      } catch (e) {
        LogToConsoleError("Web3auth login error", e);
        setConnectLoading(false);
        return false;
      }
      return true;
    },
    [web3auth, provider]
  );

  const disconnect = useCallback(async () => {
    // setTimeout(() => window.location.reload(), 1000);
    if (web3auth) {
      await web3auth.logout();
    }
    setConnected(false);
    setProvider(undefined);
    setUserInfo(null);
    await clearAllData();
    window.history.replaceState(null, "", "/");
    setTimeout(() => window.location.reload(), 500);
  }, []);

  const web3AuthDetails = useMemo(
    () => ({
      connect,
      disconnect,
      address,
      connected,
      networkId,
      networkName,
      blockExplorerUrl,
      providerUri,
      provider,
      userInfo,
      web3auth,
      staticProvider,
      wallet,
      connectLoading,
    }),
    [connect, disconnect, address, connected, networkId, networkName, blockExplorerUrl, providerUri, provider, userInfo, web3auth, staticProvider, wallet, connectLoading]
  );
  return <Web3Context.Provider value={{ web3AuthDetails }}>{children}</Web3Context.Provider>;
};
