import "./Profile.scss"
import { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { Form } from "./components/Form";
import { ProfileImage } from "./components/ProfileImage";
import { ConnectedAccount } from "./components/ConnectedAccount";
import { SocialLinks } from "./components/SocialLinks";
import { validateSocialLogin } from "../../services/SocialAccount.service";
import { getData, storeData } from "../../helpers/AppStorage";
import { SOCIAL_ACCOUNT } from "../../enums/socialAccount.enum";
import { useAppSelector, useWeb3Context } from "../../hooks";
import profile from '../../assets/images/icons/profileIcon.png'
import { useAlert } from "../../hooks/Alert";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { CreateUserDetail, loadUserDetail, setUserAvatar, setUserName, setUserStatus } from "../../slices/AppUserSlice";
import twitter from '../../assets/images/icons/twitter.png'
import { convertBase64, identifySocialWebsiteFromURL } from "../../helpers/Gadgets";
import { defaultTheme } from "../../styles";
import { LogToConsoleError } from "../../helpers/Logger";
import { SocialLinksColors } from "../../helpers/SocialLinksColors";
import { useHistory } from 'react-router-dom';
import { wait } from "../../helpers/ipfs";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import CopyIcon from "../../components/CopyIcon/CopyIcon";
import profile_placeHolder from '../../assets/svg/profile_placeholder.svg';

export const SocialLinksData = [
    { id: 1, text: SOCIAL_ACCOUNT.TWITTER, icon: twitter, url: '', color: SocialLinksColors(SOCIAL_ACCOUNT.TWITTER) }
]


interface ProfileProps {
    isOnboarding: boolean
    setProfileLoader?: (item: boolean) => void
}

export const Profile = ({ isOnboarding, setProfileLoader }: ProfileProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { disconnect } = useWeb3Context();

    const NAME_MAX_LENGTH = 35;

    const getTruncatedName = (name) => {
        return name.length > NAME_MAX_LENGTH ? name.substring(0, NAME_MAX_LENGTH) : name;
    }

    const { address, wallet, networkId, staticProvider, userInfo } = useWeb3Context();
    const history = useHistory();

    const { setShowToastError } = useAlert();
    const userDetails = useAppSelector((state) => state.AppUser.UserData);
    const userWalletAddress = useAppSelector((state) => state?.AppUser?.UserData?.wallet);
    const [accountLinked, setAccountLinked] = useState<Object>();
    const [accountVerificationInprogress, setAccountVerificationInProgress] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState(null);
    const [newUserName, setNewUserName] = useState(isOnboarding ? userInfo?.name : userDetails?.name);
    const [userBio, setUserBio] = useState(isOnboarding ? '' : userDetails?.status);
    const [avatarURI, setAvatarURI] = useState(userDetails?.avatarURI && (userDetails?.avatarURI === "" || userDetails?.avatarURI === "no-avatar"
        || userDetails?.avatarURI.includes("ipfs://"))
        ? profile : userDetails?.avatarURI);
    const [isSaveInprogress, setIsSaveInprogress] = useState(false);
    const [isSkipInprogress, setIsSkipInprogress] = useState(false);
    const [isSaveName, setIsSaveName] = useState(false);
    const [isSaveStatus, setIsSaveStatus] = useState(false);
    const [showCloseLink, setShowCloseLink] = useState(false);

    const AvatarImage = userDetails?.avatarURI && (userDetails?.avatarURI === "" || userDetails?.avatarURI === "no-avatar"
        || userDetails?.avatarURI.includes("ipfs://"))
        ? profile : userDetails?.avatarURI;

    const removeLink = () => {
        setAccountLinked(null)
    }

    const handleLinkAccount = async (account) => {
        setAccountVerificationInProgress(true);
        const response = await validateSocialLogin(account?.text);
        if (response?.url && window) {
            let socialLoginWindow = window.open(response?.url, '_blank', "popup");
            if (socialLoginWindow == null) {
                return;
            }
            await storeData("accountVerificationInProgress", true);
            async function waitUntil() {
                return await new Promise(resolve => {
                    const verificationInterval = setInterval(async () => {
                        const verificationInProgress = await getData("accountVerificationInProgress");
                        if (!verificationInProgress) {
                            await dispatch(loadUserDetail({ networkID: networkId, provider: staticProvider, address, userInfo, wallet }));
                            clearInterval(verificationInterval);
                        }
                    }, 1000);
                });
            }

            await waitUntil();

        } else {
            setShowToastError({ open: true, message: "Something went wrong, please try after sometime." });
        }
    }

    const onSave = async (nameText, statusText) => {
        if (userDetails?.isExist) {
            if (nameText) {
                if (userDetails?.name !== nameText) {
                    setIsSaveName(true);
                    const uData = await dispatch(setUserName({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newUserName: nameText }));
                    if (uData?.payload?.UserData) {
                        setUserName(uData?.payload?.UserData?.name);
                    }
                    await wait(1000);
                    setIsSaveName(false);
                }
            }
            if (statusText) {
                if (userDetails?.status !== statusText) {
                    setIsSaveStatus(true);
                    const sData = await dispatch(setUserStatus({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newStatus: statusText }));
                    if (sData?.payload?.status) {
                        setUserBio(sData?.payload?.UserData?.status);
                    }
                    await wait(1000);
                    setIsSaveStatus(false);
                }
            }
        }
    }

    const onProfilePicUpload = async (fileData) => {
        const { files } = fileData?.target;
        if (files) {
            try {
                setSelectedAvatarImage(URL.createObjectURL(files[0]));
                const base64 = (await convertBase64(files[0])).toString();
                if (base64 && userDetails?.avatarURI !== base64) {
                    // if(userDetails?.isExist) {
                    //     await dispatch(setUserAvatar({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newAvatarUri: base64 }));
                    // } else {
                    // }
                    setAvatarURI(base64);
                    // }
                }
            } catch (e) {
                LogToConsoleError('onProfilePicUpload', e.name, e.message, e.stack)
            }
        }
    }

    useEffect(() => {
        return (() => {
            onClearData();
        })

    }, [])

    useEffect(() => {

        if (userDetails?.thirdPartyVerifiedURL?.length > 0) {
            let link = userDetails?.thirdPartyVerifiedURL[0];
            let connectedAccount = identifySocialWebsiteFromURL(link);
            if (connectedAccount) {
                switch (connectedAccount) {
                    case SOCIAL_ACCOUNT.TWITTER:
                        let twitterLinks = SocialLinksData.find(x => x.text === SOCIAL_ACCOUNT.TWITTER);
                        twitterLinks = { ...twitterLinks, url: link }
                        setAccountLinked(twitterLinks);
                        break;
                    case SOCIAL_ACCOUNT.INSTAGRAM:
                        let instagramLinks = SocialLinksData.find(x => x.text === SOCIAL_ACCOUNT.INSTAGRAM);
                        instagramLinks = { ...instagramLinks, url: link }
                        setAccountLinked(instagramLinks);
                        break;
                    default:
                        setAccountLinked(null);

                }
            }
        }

        // update username 
        setNewUserName(isOnboarding ? userInfo?.name : userDetails?.name);
        setUserBio(isOnboarding ? '' : userDetails?.status);

    }, [userDetails])
    
    const onUserSave = async () => {
        let refreshUserDetail = true;
        if (newUserName && isOnboarding) {
            if (!userDetails?.isExist) {
                setProfileLoader(true)
            }
            setProfileLoader(true)
            setIsSaveInprogress(true);
            const userAvatarURI = avatarURI ? avatarURI : userInfo?.profileImage ? userInfo.profileImage : profile_placeHolder;
            await dispatch(CreateUserDetail({ networkID: networkId, provider: staticProvider, address, userInfo, wallet, userName: getTruncatedName(newUserName), userStatus: userBio ? userBio : "", userAvatarURI }))
            if (!userDetails?.isExist)
                setTimeout(() => {
                    setProfileLoader(false)
                }, 500)
        } else {
            if (userDetails?.isExist) {
                if (userDetails?.name !== newUserName) {
                    setIsSaveInprogress(true);
                    refreshUserDetail = true;
                    const uData = await dispatch(setUserName({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newUserName: newUserName }));
                    if (uData?.payload?.UserData) {
                        setUserName(uData?.payload?.UserData?.name);
                    }
                }
                if (userDetails?.status !== userBio) {
                    setIsSaveInprogress(true);
                    refreshUserDetail = true;
                    const sData = await dispatch(setUserStatus({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newStatus: userBio }));
                    if (sData?.payload?.status) {
                        setUserBio(sData?.payload?.UserData?.status);
                    }
                }
                if (avatarURI && userDetails?.avatarURI !== avatarURI) {
                    setIsSaveInprogress(true);
                    refreshUserDetail = true;
                    await dispatch(setUserAvatar({ networkID: networkId, provider: staticProvider, address: address, wallet: wallet, newAvatarUri: avatarURI }));
                }
                if (refreshUserDetail) {
                    await dispatch(loadUserDetail({ networkID: networkId, provider: staticProvider, address, userInfo, wallet }));
                }
            }
        }
        setIsSaveInprogress(false);
    }

    const onSkip = async () => {
        if (userDetails?.isExist) {
            history.push('/dashboard')
        } else {
            if (newUserName && isOnboarding) {
                setProfileLoader(true)
            }
            setIsSkipInprogress(true);

            const userAvatarURI = userInfo?.profileImage ? userInfo.profileImage : profile_placeHolder;

            await dispatch(CreateUserDetail({ networkID: networkId, provider: staticProvider, address, userInfo, wallet, userName: getTruncatedName(userInfo?.name), userStatus: '', userAvatarURI }))

            setIsSkipInprogress(false);
            setTimeout(() => {
                setProfileLoader(false)
            }, 500)
        }
    }

    const onClearData = () => {
        setIsSaveName(false);
        setIsSaveStatus(false);
    }

    useEffect(() => {
        if (accountVerificationInprogress) {
            setTimeout(() => {
                setShowCloseLink(true);
            }, 5000);
        } else {
            setShowCloseLink(false);
        }

    }, [accountVerificationInprogress])

    return (
        <Grid className="root-profile" justifyContent={'center'} container>
            <Grid item lg={12} xl={12}>
                <ProfileImage source={selectedAvatarImage ? selectedAvatarImage : AvatarImage} profilePicUpload={onProfilePicUpload} />
            </Grid>
            <Grid item lg={5} xl={5} sm={12} xs={12}>
                <Form userName={newUserName} userBio={userBio} onSave={onSave} isOnboarding={isOnboarding} setUserName={setNewUserName} setUserBio={setUserBio} isLoadingName={isSaveName} isLoadingStatus={isSaveStatus} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CopyIcon text={address || userWalletAddress } />
                <Typography
                    sx={{
                        maxWidth: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                    fontFamily={'Poppins-Medium'} fontSize={'1rem'}>{address || userWalletAddress}</Typography>
            </Grid>
            {
                <>

                    <Grid container item sm={12} xs={12} lg={5} xl={5} className="mt-3">
                        <div className='d-flex flex-row' style={isOnboarding ? { width: '100%', justifyContent: 'space-between' } : { width: '100%', justifyContent: 'flex-end' }}>
                            {
                                isOnboarding &&
                                <Button
                                    onClick={onSkip}
                                    style={{
                                        color: defaultTheme.lightColor,
                                        backgroundColor: defaultTheme.buttonRedColor,
                                        fontFamily: 'Poppins-SemiBold',
                                        width: '45%',
                                        height: '50px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        borderRadius: 6
                                    }}>
                                    {
                                        isSkipInprogress ?
                                            <>
                                                <CircularProgress size={'1.2em'} sx={{ margin: 1, color: defaultTheme.buttonDarkTextColor }} />
                                                <span style={{ color: '#fff' }}>SKIP</span>
                                            </>
                                            :
                                            <span style={{ color: '#fff' }}>SKIP</span>
                                    }

                                </Button>
                            }

                            <Button
                                onClick={onUserSave}
                                disabled={!(newUserName?.length > 0)}
                                style={{
                                    color: defaultTheme.lightColor,
                                    backgroundColor: defaultTheme.yellowColor,
                                    fontFamily: 'Poppins-SemiBold',
                                    width: '45%',
                                    height: '50px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    borderRadius: 6
                                }}
                            >
                                {
                                    isSaveInprogress ?
                                        <>
                                            <CircularProgress size={'1.2em'} sx={{ margin: 1, color: defaultTheme.buttonDarkTextColor }} />
                                            <span>{"SAVE"}</span>
                                        </>
                                        :
                                        "SAVE"
                                }
                            </Button>

                        </div>
                    </Grid>
                    {
                        isOnboarding &&
                        <Grid container item sm={12} xs={12} lg={9} xl={7} className="mt-3">
                            <Link onClick={disconnect} style={{
                                fontFamily: 'Poppins',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                marginBottom: 15
                            }}>
                                <span style={{ color: "blue", fontSize: 12, textDecoration: 'underline' }}>Back to Login</span>
                            </Link>
                        </Grid>
                    }
                </>
            }
            {
                !isOnboarding &&
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item sm={12} xs={12} lg={9} xl={7}>
                        <ConnectedAccount removeLink={removeLink} account={accountLinked} />
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        {
                            !accountLinked &&
                            <Grid item sm={12} xs={12} lg={9} xl={7}>
                                <p className="social-text" style={{ textAlign: 'center' }}>
                                    Connect your social account with loot8 to verify your account
                                </p>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
            {/* </Grid> */}
            {
                !isOnboarding &&
                <Grid container sx={{ justifyContent: 'center' }}>
                    <SocialLinks account={accountLinked} handleLinkAccount={handleLinkAccount} />
                </Grid>
            }
            {
                accountVerificationInprogress &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    onClick={() => { }}
                >
                    <LoaderModal open={true} message={""} >
                        <div>
                            {
                                showCloseLink &&
                                <span style={{ color: "blue", fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setAccountVerificationInProgress(false)}> Click to close </span>
                            }
                        </div>
                    </LoaderModal>
                </Backdrop>
            }
            {
                isSaveInprogress &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    onClick={() => { }}
                >
                    <LoaderModal open={true} message={""} />
                </Backdrop>
            }
        </Grid>
    )
}