import { Divider, Grid, Typography } from "@mui/material";
import { AddressInput } from "../../../../components/TextInput/AddressInput/AddressInput";
import NetworkSelect from "../../../../components/Dropdown/NetworkSelect/NetworkSelect";
import { SelectInput } from "../../../../components/Dropdown/SelectDropdown/SelectInputDropdown";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { defaultTheme } from "../../../../styles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useWeb3Context } from "../../../../hooks";
import { wait } from "../../../../helpers/ipfs";
import { getIndividualWhitelistedCollections, loadAllPassporDetails, refreshPassportWhitelistedCollections } from "../../../../slices/PassportSlice";
import { whitelistCollection } from "../../../../slices/collectibleSlice";
import { ZERO_ADDRESS } from "../../../../constants";
import { LogToConsoleError } from "../../../../helpers/Logger";
import { useAlert } from "../../../../hooks/Alert";

export const WhiteListingCollection = ({ setShowLoader, title, isPassport }) => {


  const { wallet, networkId, staticProvider, address } = useWeb3Context();
  const dispatch = useAppDispatch();

  const allPassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails);

  const EntityAddress = useAppSelector(state => state.Entity?.EntityAddress );
  const allIndividualCollections = useAppSelector(state => state.Passports.IndividualWhitelistedCollections);

  const [selectedChain, setSelectedChain] = useState("");
  const [inputAddress, setInputAddress] = useState<string>("");
  const [selectedPassports, setSelectedPassports] = useState([]);
  const [whitelistedPassports, setWhitelistedPassports ] = useState([]);
 const { setShowToastError } = useAlert();


    useEffect(() => {
      if(isPassport) {
        let whitelistedPassports = (allPassportDetails && allPassportDetails.filter(passport => {
            return passport.whitelistedCollections.filter(collectible => collectible.source?.toLowerCase() === inputAddress?.toLowerCase() && Number(collectible.chainId) === Number(selectedChain)).length > 0 
        }).map((passport, index) => passport.address)) ?? [];
        setWhitelistedPassports(whitelistedPassports);
        setSelectedPassports(whitelistedPassports);
      }

    },[inputAddress, selectedChain, isPassport, allPassportDetails]);

    const networkChainHandler = (event) =>{
        setSelectedChain(event.target.value);
    }

    const passportChangedHandler = (event) => {
      let values = event.target.value;

      setSelectedPassports([...new Set([...whitelistedPassports,...values])]);
    }

    const submitHandler = async (event) => {
      event?.preventDefault();
      if(allIndividualCollections.findIndex(collection => collection.source.toLowerCase() === inputAddress.toLowerCase()) > -1 ){
        setShowToastError({ open: true, message: "Collection already whitelisted universally" });
        return;
      }
      setShowLoader({ open: true, message: "Whitelisting under process"});
      try {
        if(isPassport) {

      
          let oldLinks = whitelistedPassports;
          let newLinks = selectedPassports;
  
          let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a ) === -1);
  
          if( addlinks && addlinks.length > 0) {
            await Promise.all(addlinks.map( async (collectible) => {
              await dispatch(whitelistCollection({ networkID : networkId, provider: staticProvider, address, collectibleAddressA : inputAddress , collectibleAddressB : collectible, wallet, chainID: selectedChain }));
            }));
          
  
            await wait(500);
            await dispatch(refreshPassportWhitelistedCollections({ networkID: networkId, provider: staticProvider }));
          }
        }
        else {
          await dispatch(whitelistCollection({ networkID : networkId, provider: staticProvider, address, collectibleAddressA : inputAddress , collectibleAddressB : ZERO_ADDRESS, wallet, chainID: selectedChain }));
          await wait(500);
          await dispatch(getIndividualWhitelistedCollections({ networkID: networkId, provider: staticProvider, isCache: false }));

        }
      } catch (error) {
        LogToConsoleError("Error while whitelisting", error);
      }

      setShowLoader({ open: false, message: ""});
      return;
    }

  return (
    <form onSubmit={submitHandler} >
      <Grid container spacing={1} columnGap={2} marginY={'2rem'} >
      {/* First Row */}
      <Grid columnGap={4} item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}  >
      <Typography variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {title}
      </Typography>
      <Divider orientation='horizontal' variant="fullWidth" sx={{ flex: 1, alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />
    </Grid>

      {/* Second Row */}
      <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
        <AddressInput label={"Collection Source (Address)"} value={inputAddress} setTextValue={setInputAddress} required={true} disabled={false}/>
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
        <NetworkSelect handleChange={networkChainHandler} selectedChain={selectedChain} disabled={false} required={true} isWhitelist={true}/>
      </Grid>

      {
        isPassport &&
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          <SelectInput label={"Associated Passports"} menuData={allPassportDetails} handleOptionChange={passportChangedHandler} selectedOptions={selectedPassports} disabled={false}/>
        </Grid>
      }
     

      <Grid item xs={3} sm={3} md={3} lg={1} xl={1} alignSelf={'flex-end'} justifyContent={"center"} display={'flex'} alignItems={'flex-start'} marginTop={'1.5rem'} >
        <CustomButton children={"Save"} className={"w-100"} onClick={() => {}} color={defaultTheme.buttonDarkTextColor} backgroundColor={defaultTheme.yellowColor} disabled={false} type={"submit"} />
      </Grid>
    </Grid>
    {/* {showToastError.open &&<CustomToast open={showToastError.open}
        setOpen={setShowToastError}
        messageText={showToastError.message}
      />
    } */}
  </form>
  );
}