import { Box, Button, InputAdornment, TextField, Typography, outlinedInputClasses } from "@mui/material"
import { defaultTheme } from "../../../../styles"
import { SearchOutlined } from "@mui/icons-material"
import { useMemo, useState } from "react"
import { FixedSizeList as List } from "react-window";
import './index.scss'
import { transformText } from "../../../../slices/helpers";
import CopyIcon from "../../../../components/CopyIcon/CopyIcon";

type Props = {
    label?: string,
    placeholder?: string,
    isOwner?: boolean
    selectedCollaborators?: string[];
    handleSelection?: (item: string) => void,
    allUserData: { name: string, wallet: string }[]
}

export const CollaboratorDropDown = (props: Props) => {

   
    const transformAddress = (address) => {
        const firstPart = address.slice(0, 6);
        const lastPart = address.slice(-6);
        return `${firstPart}........${lastPart}`;
      };
      
    const [searchValue, setSearchValue] = useState<string>("");

    const userArray = useMemo(() => {
        if (searchValue != "") {
            return props?.allUserData?.filter(i => (i?.name?.toLowerCase().includes(searchValue?.toLowerCase()) || 
            (i.wallet?.toLowerCase().includes(searchValue?.toLowerCase())) ));
        } else {
            return props?.allUserData
        }
    }, [searchValue, props.allUserData])

    const RenderRow = ({ index, style }) => (
        <div
            style={style}
            className="list-component"
        >
            <Box display={'flex'} flexDirection={'column'}>
                <Typography fontSize={'1rem'} fontFamily={'Poppins-Medium'} color={defaultTheme.buttonDarkTextColor}>
                    {userArray[index]?.name}
                </Typography>
                <Typography
                    sx={{
                        // maxWidth: '80%',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap'
                    }}
                   fontSize={'0.8rem'} fontFamily={'Poppins-Medium'} color={defaultTheme.buttonDarkTextColor}>
                    {transformAddress(userArray[index]?.wallet)}  <CopyIcon text={userArray[index]?.wallet}/>
                </Typography>
              
            </Box>
            <Button
                disabled={props?.isOwner ? false : true}
                onClick={() => props?.handleSelection(userArray[index]?.wallet)}
                sx={{ padding: 0, alignItems: 'center', height: '2rem', backgroundColor: defaultTheme.yellowColor, color: defaultTheme.buttonDarkTextColor, paddingX: 3, paddingY: 0, textTransform: 'capitalize', fontFamily: 'Poppins-Medium' }}
            >
                {props?.selectedCollaborators?.find(x => String(userArray[index]?.wallet?.toLowerCase()) == String(x?.toLowerCase())) ? "Remove" : "Add"}
            </Button>
        </div>
    )

    return (
        <div >
            {props?.label &&
                <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
                    {props?.label}
                </Typography>}
           
            <TextField
                variant="outlined"
                placeholder={props?.placeholder}
                fullWidth
                value={searchValue}
                onChange={(event) => setSearchValue(event?.target?.value)}
                size="small"
                sx={{
                    mt: 1
                }}
                InputLabelProps={{
                    sx: {
                        color: defaultTheme.placeHolder,
                    }
                }}
                FormHelperTextProps={{
                    sx: {
                        fontFamily: 'Poppins',
                        color: '#ff6060',
                        fontSize: '0.8rem',
                        marginLeft: 0
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                    sx: {
                        backgroundColor: defaultTheme.newFieldColor,
                        color: defaultTheme.buttonDarkTextColor,
                        fontFamily: 'Poppins-Medium',
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: 'none',
                        },
                        [`& .${outlinedInputClasses.disabled}`]: {
                            WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
                            cursor: 'not-allowed'
                        }
                    },
                    notched: false
                }}
            />
            {props?.selectedCollaborators?.length>0 &&
             <Box sx={{background:"#fff",padding:'10px'}} display={'flex'} flexWrap={'wrap'}>
            {props.selectedCollaborators?.map((i,index)=>
    
                <Typography
                sx={{
                        maxWidth: '40%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                }}
                fontFamily={'Poppins-Medium'} fontSize={'12px'}>{i}              
                </Typography>
            )} 
            </Box>}
            <List
                width={"100%"}
                height={200}
                itemSize={70}
                itemCount={userArray.length}
            >
                {RenderRow}
            </List>
        </div>
    )
}