import { CollectionType } from "../enums/collection.enum";
import { OfferType, UICustomOfferType } from "../enums/offers.enum";
import { IPOAPCollectibleDetail } from "../interfaces/IPOAPCollection.interface";

export const DEFAULTPOAPCOLLECTIBLE : IPOAPCollectibleDetail = {
  index: 0,
  chain: "",
  dataURI: "",
  imageProps: {
    image: "",
    imageSize: 0,
    // thumbnailImage:"",
    // thumbnailImageSize:0,
    // optimizedImage:"",
    // optimizedImageSize:0,
  },
  area: {
    latitude: "",
    longitude: "",
    radius: 0
  },
  details: "",
  name: "",
  start: null,
  end: null,
  address: "",
  symbol: "",
  price: '0.00',
  priceRate:0,
  isActive: true,
  collectionType: CollectionType.COLLECTION,
  offerType: OfferType.NOTANYOFFER,
  maxMint: 0,
  maxPurchase: 0,
  maxBalance: 0,
  mintWithLinked: false,
  mintWithLinkedOnly: false,
  linkCollectible: [],
  autoStartMint: 1,
  socialMedia: true,
  isCoupon: false,

  marketPlaceConfig: {
    allowMarketplaceOps: false,
    privateTradeAllowed: false,
    publicTradeAllowed: false,
  }
}