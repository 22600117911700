import { Box, IconButton } from "@mui/material"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormatBold, FormatItalic, FormatUnderlined } from "@mui/icons-material";

export const ToolBar = ({ applyStyle, customStyle }) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 5,

        '@media (max-width: 961px)': {
            flexWrap:'wrap'
          },

         }}>
            <Select
                sx={{ marginRight: "25px" }}
                value={customStyle?.fontSize ?? 24}
                onChange={(e) => applyStyle({ property: "fontSize", value: e.target.value })}>
                <MenuItem value={24} sx={{ fontFamily: "Poppins-Medium" }}>Normal Text</MenuItem>
                <MenuItem value={30} sx={{ fontSize: 19, fontFamily: "Poppins-Medium" }}>Heading 1</MenuItem>
                <MenuItem value={22} sx={{ fontSize: 16, fontFamily: "Poppins-Medium" }}>Heading 2</MenuItem>
                <MenuItem value={18} sx={{ fontSize: 13, fontFamily: "Poppins-Medium" }}>Heading 3</MenuItem>
            </Select>
            <input value={customStyle?.color??"#000"} style={{ width: "30px", border: 0, marginRight: '20px' }} type='color' onChange={(e) => applyStyle({ property: 'color', value: e.target.value })} />
            <IconButton sx={{...(customStyle?.fontWeight?{backgroundColor:"#ddd"}:{}) ,marginRight: "10px" }} onClick={() => applyStyle({ property: 'fontWeight', value: 'bold' })}>
                <FormatBold sx={{ color: "#000" }} />
            </IconButton>
            <IconButton sx={{...(customStyle?.fontStyle?{backgroundColor:"#ddd"}:{})  ,marginRight: "10px" }} onClick={() => applyStyle({ property: 'fontStyle', value: 'italic' })} >
                <FormatItalic sx={{ color: "#000" }} />
            </IconButton>
            <IconButton sx={{...(customStyle?.textDecoration?{backgroundColor:"#ddd"}:{})  ,marginRight: "10px" }} onClick={() => applyStyle({ property: 'textDecoration', value: 'underline' })}>
                <FormatUnderlined sx={{ color: "#000" }} />
            </IconButton>
        </Box>
    )
}