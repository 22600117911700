import { getUserAvatarIPFS } from "../helpers/ipfs";
import { ILocation } from "../interfaces/ILocation.interface";

export function setAll(state: any, properties: any) {
    if (properties) {
      const props = Object.keys(properties);
      props.forEach(key => {
        state[key] = properties[key];
      });
    }
  }

export const getUserAvatar = async ( avatarUri : any ): Promise<string> => {
  let userAvatar = avatarUri;
  if (userAvatar && userAvatar.includes('ipfs://') && userAvatar !== "ipfs://") {
    userAvatar = await getUserAvatarIPFS(userAvatar);
  }
  return userAvatar ?? "";
}

export const convertAreaData = (area: string | any[], radius : number ) => {

  if (area !== null && area.length === 2 && radius !== null) {
    let newArea : ILocation = {
      latitude: Number(area[0]).toString() ?? "",
      longitude: Number(area[1]).toString() ?? "",
      radius:  Number(radius)
      // [[Number(area[0]).toString(), Number(area[1]).toString()], Number(radius)]
    }
    return newArea;
  }
  return {
    latitude: "",
    longitude: "",
    radius: 0
  };
}

export const getBase64Image = async (imgUrl ) => {
  var img = new Image();
    // set attributes and src 
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = imgUrl;
    let data = await callFunction(img);
    return data;

}

function callFunction(img) {
  return new Promise((resolve, reject) => {
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    }
  })
}


export const getThumbnailFromVideo = async (videoFile: File) => {

  var video = document.createElement("video");
  video.src = URL.createObjectURL(videoFile);
  video.preload = "metadata";

  video.muted = true;
  video.playsInline = true;
  video.play();

  let thumbnail = await callBackVideoFunction(video);
  return thumbnail;
}

function callBackVideoFunction(video) {
  return new Promise<any>((resolve, reject) => {
    video.addEventListener("loadeddata", function () {

      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height) ;
      canvas.toBlob((blob) => { 
        resolve(blob);
      });

    });
  })
}

export function getZeroArray(length: number) {

  return Array(length).fill(0);
  
}

export const getFirstFrameDataUrl = (gifData,canvasRef) => {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Clear the canvas and draw the first frame
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Capture the first frame as a data URL
      const firstFrameDataUrl = canvas.toDataURL('image/png');
      const imageInfo = dataURLtoBlob(firstFrameDataUrl);

      resolve({firstFrameDataUrl,size:imageInfo?.size,type:imageInfo?.type});
    };

    image.src = gifData
  });
};

export const dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

export const  dataUriToArrayBuffer = (dataUri) => {
  // Extract base64-encoded data from the data URI
  const base64Data = dataUri.split(',')[1];

  // Convert base64-encoded data to binary data
  const binaryData = atob(base64Data);

  // Create an ArrayBuffer from the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  return arrayBuffer;
}

export const isDataUri = (str) => {

  const dataUriPattern = /^data:([a-zA-Z]+\/[a-zA-Z]+)?(;[a-zA-Z-]+=[a-zA-Z0-9-]+)*(;base64)?,[a-zA-Z0-9+/=\r\n]+$/;

  return dataUriPattern.test(str);
}

export const isValidStreamYardLink = (str:string) => {
  const streamYardRegex = /^https:\/\/streamyard\.com\/[a-zA-Z0-9_-]+$/;
  const streamYardEmbededRegex = /^https:\/\/streamyard\.com\/watch\/[a-zA-Z0-9_-]+\?embed=true$/;
  
  if(streamYardRegex?.test(str)){
  return true
  }
  else if(streamYardEmbededRegex?.test(str)){
  return true
  }
  else 
  return false
}

export const rgbStringToHex = (rgbString) => {
  if(rgbString){
  const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
  const result = rgbString.match(regex);
  if (!result) {
    throw new Error('Invalid RGB string');
  }
  const [, r, g, b] = result.map(Number);
  const toHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return '#' + toHex(r) + toHex(g) + toHex(b);
  }
}

export const transformText = (text, firstWordsCount, lastWordsCount) => {
  const words = text.split(' ');

  const firstWords = words.slice(0, firstWordsCount);
  const lastWords = words.slice(-lastWordsCount);


  const transformedWords = [
    ...firstWords,
    '...',
    ...lastWords
  ];

  return transformedWords.join(' ');
};
