import React from "react";
import * as Components from "./MuiComponents";

interface Props {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
}

export const TitleBanner = ({ toggle, setToggle }: Props) => {

  return (
    <Components.Container>
      <Components.SignUpContainer signingIn={toggle}>
      <Components.Title>
            Request Access from Loot8
      </Components.Title>
      </Components.SignUpContainer>
      <Components.SignInContainer signingIn={toggle}>
        <Components.Title>
          Quick Onboarding
        </Components.Title>
      </Components.SignInContainer>
      <Components.OverlayContainer signingIn={toggle}>
        <Components.Overlay signingIn={toggle}>
          <Components.LeftOverlayPanel signingIn={toggle} onClick={() => setToggle(true)}>
            <Components.Title >
              Quick Onboarding
            </Components.Title>
          </Components.LeftOverlayPanel>
          <Components.RightOverlayPanel signingIn={toggle}  onClick={() => setToggle(false)}>
            <Components.Title>
              Request Access from Loot8
            </Components.Title>
          </Components.RightOverlayPanel>
        </Components.Overlay>
      </Components.OverlayContainer>
    </Components.Container>
  );
}
