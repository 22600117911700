import React, { useState } from 'react';
import { Box, BoxProps, Button, Dialog, DialogTitle, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import './../EditCollectibleModal/EditCollectibleModal.scss';
import closeIcon from '../../../../assets/images/icons/pinkClose.png';
import { NameTextInput } from '../../../../components/TextInput/NameTextInput/NameTextInput';
import { DescriptionTextInput } from '../../../../components/TextInput/DescriptionTextInput/DescriptionTextInput';
import { BasicTextInput } from '../../../../components/TextInput/BasicTextInput/BasicTextInput';
import { CustomButton } from '../../../../components/Button/CustomButton';
import { defaultTheme } from '../../../../styles';
import { useAppSelector } from '../../../../hooks';
import { SelectInput } from '../../../../components/Dropdown/SelectDropdown/SelectInputDropdown';
import { ImageCard } from '../../components/FileUploadCard/ImageCard';
import NetworkSelect from '../../../../components/Dropdown/NetworkSelect/NetworkSelect';
import { CountInput } from '../../../../components/TextInput/NumberInput/CountInput';
import { CatalogTypes } from '../../../../enums/catalog.enum';
import { IMAGE_REGEX, VIDEO_REGEX } from '../../../../constants';
import { useAlert } from '../../../../hooks/Alert';
import { ConfirmModal } from '../../../../components/ModalComponents/ConfirmModal';
import { LogToConsoleError } from '../../../../helpers/Logger';
import { getThumbnailFromVideo } from '../../../../slices/helpers';

const Content = styled(Box)<BoxProps>(({ theme }) => ({

  padding: theme.spacing(2),
  outline: 'none',
  position: 'relative',
  borderRadius: 4,
  display: 'flex',
  flexDirection:'row',
  '@media (max-width: 961px)': {
    width: '100%'
  },
  width:"30rem"
 

}));


const EditPhygitalModal = ({ modalOpen, onModalClose, collectionData, formInput, setFormInput, type,  onSubmitHandler }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [backgroundImage, setBackgroundImage] = useState(collectionData.imageProps?.image);
  const [backgroundVideo, setBackgroundVideo] = useState(collectionData?.video);


  const {setShowToastError} = useAlert();

  const [showConfirmPopup, setShowConfirmPopup] = useState({ confirm: () => { }, state: false, message: "" });

  const [traits, setTraits] = useState({file: "", data: null });

  const availablePassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails ) ?? [];



  const imageChangeHandler = async (event: any) => {
    let { files } = event.target;
    if(!files)
    {
      files = event.dataTransfer.files;
    }
    if(files && files[0]) {

      if(IMAGE_REGEX.test(files[0].type.toString())){
        setBackgroundImage(URL.createObjectURL(files[0]));
        setFormInput({ ...formInput, isVideoCollectible: false, video: "",
          imageProps: { ...formInput?.imageProps,
            image: files[0],  imageSize: files[0].size 
          } 
        });
      }
      else if(VIDEO_REGEX.test(files[0].type.toString())) {
        let thumbnail = await getThumbnailFromVideo(files[0]);

        setFormInput({ ...formInput, video: files[0], isVideoCollectible: true,  
          imageProps: { ...formInput?.imageProps,
            image: thumbnail,  imageSize: 0
          }
        });
        setBackgroundVideo(URL.createObjectURL(files[0]));
      }

    }
  }

  // const thumbnailChangeHandler = async (event: any) => {
  //   const { files } = event.target;
  //   if(files && files[0]) {

  //     if(IMAGE_REGEX.test(files[0].type.toString())){
  //       setBackgroundImage(URL.createObjectURL(files[0]));
  //       setFormInput({ ...formInput, image: files[0], imageSize: files[0].size});
  //     }
  //   }
  // }

  const nameChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, name: text });
  }

  const descriptionChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, details: text });
  }

  const linkcollectionChangedHandler = (events: any) => {
    let passports = events.target.value
    setFormInput({ ...formInput, linkCollectible: passports });
  }

  const networkChangeHandler = (event) => {
    setFormInput({ ...formInput, chain: event.target.value });
  }

  const maxTokenChangeHandler = (value) => {
    setFormInput({ ...formInput, autoStartMint: Number(value) });
  }


  const traitsUploadChangeHandler = (event: any) => {
    const { files } = event.target;
    if(files && files[0]) {
        new Response(files[0]).json().then(json => {
            setTraits({ file: files[0].name, data: json })
          }, err => {
            setShowToastError({open: true, message: "Invalid json file"});
            LogToConsoleError("Not a valid json",err);
          })
    }
  }

  const clearImage = () => {
    setBackgroundImage('');
    setBackgroundVideo('');
  }


  const onModalCancel = () => {
    setShowConfirmPopup({ confirm: () => { }, state: false, message: "" });
  }



  return (
    <>
    {/* <Dialog
    open={modalOpen}
    onClose={onModalClose}
    className='editable-container'
    maxWidth="sm"
    fullScreen={fullScreen}
    BackdropProps={{
      style: {
        backgroundColor: '#FFFFFF33',
        backdropFilter: 'blur(6px)',
      }
    }}
    PaperProps={{
      style: {
        borderRadius: '0.25rem',
        alignItems: "center",
        overflowY: 'unset',
        maxWidth: 'unset',
        width: '45%'
      },
    }}
  > */}
   {/* <Button onClick={onModalClose} sx={{ padding: 0, minWidth: 'unset', flex: 1, position: 'absolute', zIndex: 9999, right: -10, top: -10 }}>
        <img src={closeIcon} style={{ height: 30, width: 30 }} />
      </Button>
      <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
        <Typography fontFamily={'Poppins-SemiBold'}
          fontSize={'1.25rem'}
          textAlign={'center'}>{formInput.address === "" ? `Add New ${type}` : `Edit ${type}`}
        </Typography>
      </DialogTitle>
      <Box sx={{ overflowY: 'scroll', width: "100%", justifyContent: 'center', alignSelf: 'center', display: 'flex' }}> */}

      <form onSubmit={ onSubmitHandler }>
      <Grid container display={'flex'} columnGap={4}>

      <Grid  item xl={4} lg={4} sm={12} xs={12}>

          <NameTextInput
            label={"Name"} 
            name={formInput.name} 
            nameChangeHandler={nameChangeHandler} 
            required={true} 
            disabled={!formInput?.isActive}
          />
          
          <DescriptionTextInput
            description={formInput.details} 
            descriptionChangeHandler={descriptionChangeHandler} 
            required={false} 
            disabled={!formInput?.isActive}
          />

            <CustomButton
              className={"col-5 col-sm-6 col-xs-6 col-lg-5 p-0 mt-3"} 
              onClick={() => {}} 
              color={defaultTheme.buttonDarkTextColor} 
              backgroundColor={defaultTheme.buttonGreyColor} 
              disabled={false} 
              type={"button"} 
            >

            <Button variant="contained" component="label" className='h-100 w-100' sx={{ fontFamily: "Poppins", paddingInline: 0 }}  >

              {traits.file ? traits?.file : "Upload Traits"}
                <input
                    accept="application/json"
                    type="file"
                    onChange={traitsUploadChangeHandler}
                    style={{ display: 'none' }}
                />
              </Button>
            </CustomButton>

            <div className='passport-row'>
              <div style={{ width: '48%' }}>
              <BasicTextInput 
                value={type} 
                valueChangeHandler={() => {}} 
                label={'Type'}
                placeholder={""}
                required={false}
                disabled={true}
              />
              
              </div>
              <Grid container display={'flex'} flexDirection={'row'} columnGap={1}>
                  <Grid item xl={5} lg={5} >
                  <NetworkSelect 
                handleChange={networkChangeHandler}
                selectedChain={formInput.chain}
                disabled={formInput.address !== ""}
                required={true}
                />
                  </Grid>
                  <Grid item xl={5} lg={5} >
                  <CountInput 
                  label={"Auto Token Mint Start"} 
                  count={formInput.autoStartMint} 
                  countChangeHandler={maxTokenChangeHandler} 
                  required={true} 
                  disabled={formInput.address !== ""}
                />
                  </Grid>
              </Grid>
             
            </div>
</Grid>
<Grid item xl={4} lg={4} sm={12} xs={12}>
            <SelectInput 
              required={true}
              label={ "Associated Passports" } 
              menuData={ availablePassportDetails.filter(obj => obj.isActive) } 
              handleOptionChange={linkcollectionChangedHandler} 
              selectedOptions={formInput.linkCollectible} 
              disabled={!formInput?.isActive}
            />

            <ImageCard label={'Upload image'} 
              clear={clearImage} 
              background={formInput.isVideoCollectible ? backgroundVideo : backgroundImage} 
              backgroundImageChangeHandler={imageChangeHandler} 
              fileTypes={type === CatalogTypes.COLLECTIBLES ? 
                "image/jpg, image/jpeg, image/png, video/*" : "image/jpg, image/jpeg, image/png"} 
              isVideo={formInput.isVideoCollectible} isDisabled={!formInput?.isActive}/>

</Grid>
<Grid item xl={4} lg={4} sm={12} xs={12}>

            <div className='my-4' style={{ display: 'flex', justifyContent: 'space-between' }} >
              <CustomButton
                className={"col-5 col-sm-6 col-xs-6 col-lg-5"}
                onClick={onModalClose}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.buttonRedColor}
                disabled={false}
                type={"button"}
              >
                CANCEL
              </CustomButton>

              <CustomButton
                className={"col-5 col-lg-5 col-sm-6 col-xs-6 "}
                // onClick={() => { }}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.yellowColor}
                type={"submit"}
                disabled={ formInput.address === "" ? 
                            JSON.stringify(collectionData) === JSON.stringify(formInput) 
                            : 
                            formInput.linkCollectible === collectionData.linkCollectible 
                          }
              >
                SAVE
              </CustomButton>
            </div>
            </Grid>
        </Grid>
      </form>
      {/* </Box> */}
      {
        showConfirmPopup.state && 
          <ConfirmModal 
            modalOpen={showConfirmPopup.state} 
            text={showConfirmPopup.message} 
            onModalCancel={onModalCancel} 
            onModalSubmit={showConfirmPopup.confirm} />
      }
    {/* </Dialog> */}
    </>
  );
};
export default EditPhygitalModal;


