import React, { useState } from 'react';
import { TextField, Typography, outlinedInputClasses } from '@mui/material';
import { defaultTheme } from '../../../styles';

export const CoordinateInput = ({ label, validator, coordinateValue, onCoordinateChange, required, disabled, placeholder="Enter a coordinate" }) => {

  const [error, setError] = useState(false);

  return (
    <div className='mt-2'>
      <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {label}
      </Typography>
      <TextField
        value={coordinateValue}
        onChange={onCoordinateChange}
        placeholder={placeholder}
        error={error}
        FormHelperTextProps={{
          error: true
        }}
        helperText={error && '*required'}
    
        required={required}
        disabled={disabled}
        type='text'
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            borderRadius: '4px',
            "& :invalid": {
              border: "red solid 2px",
              borderRadius: '4px',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        inputProps={{ pattern: validator }}
        variant='outlined'
        fullWidth
        size='small'
        sx={{
          mt: 1
        }}
      />
    </div>
  );
}

