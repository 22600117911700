import React, { useState } from "react";
import { Box, BoxProps, CircularProgress, Container, Grid, Paper, PaperProps, styled } from "@mui/material";
import "./AdminAccessPage.scss";
import { defaultTheme } from "../../styles";
import { TitleBanner } from "./components/TitleBanner/TitleBanner";
import { OnboardingDialog } from "./views/OnboardingDialog/OnboardingDialog";
import { AccessMsgDialog } from "./views/AccessMsgDialog/AccessMsgDialog";
import WhitePattern from "../../components/Layout/whitePattern/WhitePattern";
import BluePattern from "../../components/Layout/bluePattern/BluePattern";

const StyledContainer = styled(Paper)<PaperProps>(({ theme }) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  borderRadius: '0.25rem',
  background: 'transparent',
  boxShadow:'none',
  // minHeight:'100%',
  height:'100%',
  padding: '1rem',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },

}));

export const FormBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '75%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
}));

const shortDescription = `You have successfully logged into the Establishment Portal, let's take another\nstep before proceeding further`

export const AdminAccessPage = () => {

  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const ToggleTitle = (_toggle) => {
    if(!loading) {
      setToggle(_toggle);
    } 
  }

  return (

    <Grid container>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <WhitePattern>
          <div className="AdminAccessMsg-container">
            <div className="banner-container">
              <TitleBanner toggle={toggle} setToggle={ToggleTitle} />
            </div>
              <StyledContainer>
                {
                  toggle ?
                    <OnboardingDialog loading={loading} setLoading={setLoading} />
                    :
                    <AccessMsgDialog loading={loading} setLoading={setLoading} />
                }
              </StyledContainer>
          </div>
        </WhitePattern>
      </Grid>

      <Grid item xs={6} lg={6} xl={6}>
        <BluePattern shortDescription={shortDescription}>
          <div className="description-container mt-4">
            <span className="heading-text-underline" >Quick Onboarding</span>
            <p className="description">
              {`Ever been invited to an exclusive club? With Loot8, it's just as seamless. Upon receiving an invitation
              code, you're immediately granted access to craft your ExPass.
              Dive right into the heart of our platform. Begin curating your community and deepening interactions right away.
              The best part? As an ExPass holder, you hold the key to expanding your circle. Share additional invite codes at your discretion, and watch your community thrive.
           `}</p>
          </div>
          <div className="description-container">
            <span className="heading-text-underline" >Request Access from Loot8</span>
            <p className="description">{`Do you think you could host the next big ExPass? It starts with a simple message to our team.
            Show us your enthusiasm, and you might just be the newest member with access to our exclusive ExPass builder.
             Loot8 is excited to continue growing with your support! However, do note that as we maintain the quality of our platform, not every request might be approved instantly. There could be a brief wait due to demand, but we promise – we're as eager as you are to see you onboard!`}</p>
          </div>
        </BluePattern>
      </Grid>
    </Grid>
  );
}