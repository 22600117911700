import "./Dashboard.scss";
import { AppBar, Avatar, Box, Grid, IconButton, Link, Toolbar, Typography } from "@mui/material";
import { DataCard } from "./components/DataCard";
import PassportIcon from "../../assets/images/icons/passports.png";
import OfferIcon from "../../assets/images/icons/offers.png";
import FeaturedIcon from "../../assets/images/icons/featured-offers.png";
import CollectionIcon from "../../assets/images/icons/collections.png";
import PremiumChatRoomIcon from "../../assets/images/icons/premiumDashboard.png";
import EventIcon from "../../assets/images/icons/events.png";
import { useAppSelector } from "../../hooks";
import profile from '../../assets/images/icons/profileIcon.png'
import verified from '../../assets/images/icons/verifiedIcon.png'
import { defaultTheme } from "../../styles";
import CopyIcon from "../../components/CopyIcon/CopyIcon";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { CustomButton } from "../../components/Button/CustomButton";
import { useState } from "react";
import { AddAdminModal } from "./components/AddEntityModal";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: "6px",
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     width: "400px",
//   },
// }));

type DashboardProps = {
  isLoading: boolean;
}

export const Dashboard = (props: DashboardProps) => {

  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const userDetails = useAppSelector((state) => state.AppUser.UserData);

  const passports = useAppSelector((state) => state.Passports.AllEntityPassportDetails);
  const passportLoading = useAppSelector((state) => state.Passports.loading);
  const chatrooms = useAppSelector((state) => state.Offers.AllEntityPremiumChatDetails);
  const chatroomLoading = useAppSelector((state) => state.Offers.PremiumChatsLoading);
  // const regularOffers = useAppSelector((state) => state.Offers.AllEntityRegularOfferDetails);
  const featuersOffers = useAppSelector((state) => state.Offers.AllEntityFeturedOfferDetails);
  const DigitalCollectibleloading = useAppSelector((state) => state.Offers.DigitalCollectibleloading);
  const events = useAppSelector((state) => state.Events.AllEntityEventsDetails);
  const eventLoading = useAppSelector((state) => state.Events.Eventsloading);
  const collectibles = useAppSelector((state) => state.Offers.AllEntityDigitalCollectiblesDetails);
  const passportMints = useAppSelector((state) => state.Passports.AllPassportMintDetails);
  const isSuperAdmin = useAppSelector((state) => state.Entity.isSuperAdmin)
  const [isAddAdminModalOpen,setIsAddAdminModalOpen] = useState<boolean>(false);
  

  const AvatarImage = userDetails?.avatarURI && (userDetails?.avatarURI === "" || userDetails?.avatarURI === "no-avatar"
    || userDetails?.avatarURI.includes("ipfs://"))
    ? profile : userDetails?.avatarURI;

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });


  const checkCount = (collection, loading) => {
    if (collection) {
      if (collection.length)
        return collection?.filter(obj => obj?.isActive === true)?.length;
      else
        return 0;
    }
    else {
      if (!loading) {
        return 0
      }
      return null
    }
  }


  return (

    <div className="dashboard-container">
      {props?.isLoading ?
        <LoaderModal open={true} message={"Fetching collections"} /> : <></>}
      <Typography variant="h6" textAlign={'center'} color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Bold'} >
        Establishment Portal
        <div className="dashboard-title ">
          <span>Brand Name: {entityDetails?.name}</span>
        </div>

      </Typography>
      <Box 
  display="flex" 
  alignItems="center" 
  justifyContent="center" 
  sx={{
    width: '100%',
    justifyContent: 'center',
    '@media (min-width: 0px) and (max-width: 960px)': {
     
    },
    '@media (min-width: 961px)': {
      // justifyContent: 'flex-end',
    },
  }}
>
  <CopyIcon text={entityDetails?.address} />
  <Typography
    sx={{
      fontSize: '1rem',
      fontFamily: 'Poppins-Medium',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: {
        xs: '100%', // Full width for extra-small and small screens
        md: 'auto', // Default width for medium and larger screens
      },
    }}
  >
    {entityDetails?.address}
  </Typography>
</Box>
      {isSuperAdmin ?
        <div className="d-flex justify-content-center justify-content-lg-end justify-content-md-center">
          <CustomButton
            className={"col-sm-12 col-lg-3 col-xs-12 col-md-12 col-xl-2"}
            onClick={() => setIsAddAdminModalOpen(true)}
            color={defaultTheme.buttonDarkTextColor}
            backgroundColor={defaultTheme.yellowColor}
            type={"button"}
            disabled={false}
          >
            Onboard Entity
          </CustomButton>
          <AddAdminModal isVisible={isAddAdminModalOpen} setVisibility={setIsAddAdminModalOpen}  /> 
        </div> : <></>}
        
      <Box>
        <AppBar sx={{ flexGrow: 1, backgroundColor: 'rgba(255, 0, 0, 0)', elevation: 0, boxShadow: 'none' }} position="static">
          <Toolbar sx={{ flex: 1, alignSelf: 'flex-end' }}>
            {/* <div className="dashboard-title me-4">
              <span>Entity Name: {entityDetails?.name}</span>
            </div> */}
            {/* <Search>
              <SearchTextInput searchText={searchText} setSearchText={setSearchText} placeholder={"Search"}/>
            </Search> */}
            {/* <Box sx={{ flexGrow: 1 }}/> */}

            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center' }}>
              {/* <IconButton sx={{ height: '100%' }}>
                <img className="icon-style" src={notifications} />
              </IconButton>
              <IconButton sx={{ height: '100%' }}>
                <img className="icon-style" src={settings} />
              </IconButton> */}

              <Link href="#/profile">
                <IconButton
                  size="large"
                  edge="end"
                  sx={{ borderRadius: 'unset', padding: 0, marginLeft: 1 }}
                >

                  <Avatar src={AvatarImage} sx={{ width: 32, height: 32, marginLeft: 1 }} />
                  <span className="user-name">{userDetails?.name}</span>
                  {
                    userDetails?.thirdPartyVerifiedURL?.length > 0 &&
                    <img src={verified} alt="_blank" width="14px" height="14px" />
                  }
                </IconButton>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <div className="data-cards p-3">

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
            <Link href="#/expasses" >
              <DataCard
                name={"ExPasses"}
                count={checkCount(passports, passportLoading)}
                icon={PassportIcon}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
            <Link href="#/premium-chat-room" >
              <DataCard
                name={"Premium Chat Rooms"}
                count={checkCount(chatrooms, chatroomLoading)}
                icon={PremiumChatRoomIcon}
              />
            </Link>
          </Grid>
          {isSuperAdmin && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 3, sm: 3, md: 3, lg: 2, xl: 2 }}>
                <Link href="#/featured-offers">
                  <DataCard
                    name={"Featured Offers"}
                    count={checkCount(featuersOffers, DigitalCollectibleloading)}
                    icon={FeaturedIcon}
                  />
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 5, sm: 5, md: 5, lg: 3, xl: 3 }}>
                <Link href="#/events">
                  <DataCard
                    name={"Events"}
                    count={checkCount(events, eventLoading)}
                    icon={EventIcon}
                  />
                </Link>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 4, sm: 4, md: 4, lg: 5, xl: 5 }}>
                <Link href="#/offers">
                  <DataCard 
                    name={"Offers"} 
                    count={checkCount(regularOffers)}
                    icon={OfferIcon}
                  />
                </Link>
              </Grid>  */}

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <Link href="#/collectibles">
                  <DataCard
                    name={"Collectibles"}
                    count={checkCount(collectibles, DigitalCollectibleloading)}
                    icon={CollectionIcon}
                  />
                </Link>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} order={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
            <DataCard
              name={`ExPasses Minted \n(${currentDate})`}
              count={passportMints?.length}
              icon={""}
              link={false}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
