import React, { useState } from "react";
import { DialogTitle, DialogContent, Typography, CircularProgress } from "@mui/material";
import { CustomButton } from "../../../../components/Button/CustomButton";
import { BasicTextInput } from "../../../../components/TextInput/BasicTextInput/BasicTextInput";
import { defaultTheme } from "../../../../styles";
import { useWeb3Context, useAppSelector } from "../../../../hooks";
import { MailAddress } from "../../../../utils/validators";
import { DescriptionTextInput } from "../../../../components/TextInput/DescriptionTextInput/DescriptionTextInput";
import CopyIcon from "../../../../components/CopyIcon/CopyIcon";
import { getRequestMailStatus, sendRequestEmail } from "../../../../services/Message.service";
import { CustomToast } from "../../../../components/CustomToast/CustomToast";
import { FormBox } from "../../AdminAccessPage";
import { AppDispatch } from "../../../../store";
import { useDispatch } from "react-redux";
import { setMailStatus } from "../../../../slices/AppUserSlice";
import { isProduction } from "../../../../constants";
import { wait } from "../../../../helpers/ipfs";
import { useAlert } from "../../../../hooks/Alert";

const NEXT_MAIL_REQUEST_INTERVAL = isProduction ? 7 * 24 * 60 * 60 * 1000 : 60 * 1000;
export const AccessMsgDialog = ({loading, setLoading}) => {

  const { disconnect, wallet, address } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();
  const mailStatus =  useAppSelector(state => state.AppUser.MailStatus);
  const timeSpent = new Date().getTime() - Number(mailStatus);

  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
 const { setShowToastError } = useAlert();

  const userDetails =  useAppSelector(state => state.AppUser.UserData);

  const emailAddressChangeHandler = (event) => {
    let text = event.target.value;
    setEmailAddress(text);
  }

  const messageChangeHandler = (event) => {
    let text = event.target.value;
    setMessage(text);
  }

  const onSubmit = async (event) => {
    event?.preventDefault();

    setLoading(true);
    // remove empty spaces in the text
    setEmailAddress(emailAddress.trim());
    setMessage(message.trim());

    const sendMessage = {
        walletAddress: address,
        replyTo: emailAddress.trim(),
        userName: userDetails.name,
        text: message.trim()
    }


    const response = await sendRequestEmail(sendMessage, wallet);
    
    if(response && response.status === 200) {
      const getMailStatus = await getRequestMailStatus(wallet);
      await dispatch(setMailStatus(getMailStatus));
    }
    else {
      setShowToastError({ open: true, message: "Error: Email not sent" })
    }
    setLoading(false);
  }

  return (
    <>
      <DialogTitle id="alert-dialog-title" className="AdminAccessTitle" >
        Hi, {userDetails?.name}
      </DialogTitle>
      {
        mailStatus !== 0 && Number(timeSpent) <= NEXT_MAIL_REQUEST_INTERVAL?
        <>
          <DialogContent id="alert-dialog-description" className="AdminAccessDescription col-12" >
            <p className="lh-md"><span>Your request has been submitted successfully!</span></p>
            <p className="lh-md m-1"><span>Due to a large volume of requests, our response may be delayed.</span></p>

            <p className="lh-md"><span>Thank you for your interest in the Loot8 platform and your understanding.</span></p>
          </DialogContent>
          <div className="form-buttons d-flex justify-content-around my-3 w-100">
            <CustomButton
              className={"col-5 col-sm-4 col-lg-4"} 
              onClick={disconnect} 
              color={defaultTheme.buttonLightTextColor} 
              backgroundColor={defaultTheme.buttonRedColor} 
              disabled={false} 
              type={"button"} 
            >
              Logout
            </CustomButton>
          </div>
        </>
        :
        <>
          <DialogContent id="alert-dialog-description" className="AdminAccessDescription col-12" >
            <p className="lh-sm"><span>You currently do not have access to the Loot8 portal.</span></p>
            <p className="lh-sm w-75"><span>To request access, please enter in your contact information below and click request access.</span></p>

          </DialogContent> 
          <FormBox>
            <form onSubmit={onSubmit}>
              <BasicTextInput value={emailAddress} label={"Email"} valueChangeHandler={emailAddressChangeHandler} required={true} disabled={false} placeholder={"Enter your email address"} pattern={MailAddress}/>
              <DescriptionTextInput description={message} label={"Message"} descriptionChangeHandler={messageChangeHandler} required={true} disabled={false} placeholder={"Enter your message"} rows={3} maxLength={2048} />
                <Typography width={'100%'} display={'flex'} justifyContent={'flex-end'} marginTop={'-1rem'} variant="caption" color={defaultTheme.greyWhite} fontFamily={'Poppins-Medium'} >
                  {`(${message.length}/2048)`}
                </Typography>
              <div className="form-buttons d-flex justify-content-around my-3 ">
              <CustomButton
                  className={"col-5 col-sm-5 col-lg-5"} 
                  onClick={disconnect} 
                  color={defaultTheme.buttonLightTextColor} 
                  backgroundColor={defaultTheme.buttonRedColor} 
                  disabled={loading} 
                  type={"button"} 
                >
                  Logout
                </CustomButton>
                <CustomButton 
                  className={"col-5 col-sm-5 col-lg-5"}
                  onClick={() => {}}
                  color={defaultTheme.buttonDarkTextColor}
                  backgroundColor={defaultTheme.yellowColor}
                  type={"submit"} 
                  disabled={loading}
                >
                  {
                    loading ?
                    <CircularProgress size={'1.2em'} sx={{ margin: 1, color: defaultTheme.buttonDarkTextColor }} />
                    :
                    "Request Access"
                  }
                </CustomButton>

       
              </div>
            </form>
          </FormBox>
          <DialogContent id="alert-dialog-description" className="AdminAccessDescription col-12" >
            <p className="lh-md m-0"><span>Please note - Your LOOT8 address is</span></p>
            <p className="lh-sm address">
              <span className="fst-italic ">{userDetails?.wallet} <CopyIcon text={userDetails?.wallet} /> </span>
            </p>
          </DialogContent>
        </>
      }
      {/* <CustomToast open={showToastError.open}
        setOpen={setShowToastError}
        messageText={showToastError.message}
      /> */}
    </>
  );
} 