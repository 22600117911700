import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Link, styled, Avatar, IconButton, AppBar, Toolbar } from "@mui/material";
import { defaultTheme } from "../../../styles";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAppSelector } from "../../../hooks";
import { FirstColumnContent } from "../../CollectionDetails/views/CollectionContainer";
import { PassportCard, BoxContainer } from "../../CollectionDetails/components/PassportCard/PassportCard";
import plusIcon from '../../../assets/images/icons/plusIcon.png'
import { CollectionDescription } from "../../CollectionDetails/views/components/CollectionDescription";
import { CollectionDescriptionTemplate } from "../../../CollectionDescConstants";
import { SearchTextInput } from "../../../components/TextInput/SearchInput/SearchInput";
import verified from '../../../assets/images/icons/verifiedIcon.png'
import profile from '../../../assets/images/icons/profileIcon.png'


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "6px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "400px",
  },
}));

export const BillboardTopPanel = ({ isInitialDescVisible, showActive, setShowActive, searchText, setSearchText, setInitialDescVisible, onNewCollection, setInitialCardSelected, defaultCollection, selectedCard, onSelectCollection }) => {


  const collectionData = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails);
  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const userDetails = useAppSelector(state => state?.AppUser?.UserData)
  const sortedCollections = collectionData
    ?.filter(m => 1 === 1)
    .sort((x, y) => (Number(y.isActive) - Number(x.isActive) || Number(y.index) - Number(x.index)));

  const viewCollection = sortedCollections?.filter(obj => (obj.animationUrl && obj.animationUrl != "") && obj.isActive === showActive);
  const totalLength = viewCollection && viewCollection?.length ? viewCollection.length : 0


  const filteredCards: any[] = sortedCollections?.filter((card) =>
    card.name.toLowerCase().includes(searchText.toLowerCase()) || card?.address.toLowerCase().includes(searchText.toLowerCase())
  ) ?? [];

  // useEffect(()=>{
  //   if(viewCollection && viewCollection.length)
  //     setInitialCardSelected(viewCollection[0])
  // },[viewCollection])

  const toggleActiveCollections = () => {
    const selected = sortedCollections && sortedCollections.length ? sortedCollections.find(i => showActive ? !i.isActive : i.isActive) : null
    onSelectCollection(selected ? selected : defaultCollection)
    setShowActive(!showActive)
  }


  const AvatarImage = userDetails?.avatarURI && userDetails?.avatarURI == "" || userDetails.avatarURI == "no-avatar"
    || (userDetails.avatarURI && (userDetails.avatarURI.includes("ipfs://")))
    ? profile : userDetails?.avatarURI;

  return (
    <>
      <div>
        <Typography variant="h6" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Bold'} >
          {entityDetails.name}
        </Typography>
      </div>

      {/* <AppBar sx={{ flexGrow: 1, backgroundColor: 'rgba(255, 0, 0, 0)', elevation: 0, boxShadow: 'none' }} position="static"> */}
      <AppBar  sx={{ flexGrow:1,backgroundColor: 'rgba(255, 0, 0, 0)', elevation: 0, boxShadow: 'none' }} position="static">
      <Toolbar sx={{display:'flex',
          // justifyContent:{
          //   xs:'center',
          //   sm:'center',
          // },
          justifyContent:'center',
          alignItems:'center',
          textAlign:{
            xs:'center',
            sm:'center',
            lg:'left',
            md:'center',
            
          },
          
          flexDirection:{sm:'column',md:'row',lg:'row',xl:'row',xs:'column'}}}>
      {/* <Toolbar sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}> */}
        <Box display={'flex'}
        sx={{
          flexDirection:{sm:'column',md:'row',lg:'row',xl:'row',xs:'column'}
        }}
        alignItems={'center'} columnGap={3}>
          <Typography fontFamily={'Poppins-SemiBold'} color={defaultTheme.buttonDarkTextColor} fontSize={'1.1rem'} >
            {`${showActive ? 'Active' : "Disabled"} Announcements(${totalLength})`}
          </Typography>
          <Search sx={{
                marginTop:{
                  lg:0,
                  md:2,
                  sm:2,
                  xs:2,
                  xl:0,
                }            
            }}>
              <SearchTextInput searchText={searchText} setSearchText={setSearchText} placeholder={"Search by Name or Address"} />
            </Search>

        </Box>
        {/* <Box display={'flex'}  alignItems={'center'} justifyContent={'space-between'} > */}

        <Box sx={{ flex: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center' }}>
          <Link href="#/profile">
            <IconButton
              size="large"
              edge="end"
              sx={{ borderRadius: 'unset', padding: 0, marginLeft: 1 }}
            >
              <Avatar src={AvatarImage} sx={{ width: 32, height: 32, marginLeft: 1 }} />
              <span className="user-name">{userDetails?.name}</span>
              {
                userDetails?.thirdPartyVerifiedURL?.length > 0 &&
                <img src={verified} alt="_blank" width="14px" height="14px" />
              }
            </IconButton>
          </Link>
          {/* </Box> */}
        </Box>
      </Toolbar>
      </AppBar>
      <Box width={'100%'} >
        {isInitialDescVisible ?
          <CollectionDescription {...CollectionDescriptionTemplate['Announcements']} /> : <></>}
        <FirstColumnContent item className=' py-2'>
          {showActive ?
            <BoxContainer onClick={onNewCollection}
              sx={{ background: defaultTheme.buttonLightTextColor, border: '2px dashed  #4b4b79' }}
              elevation={2} className='mt-3'
            >
              <div className='w-100 h-100 d-flex justify-content-center align-items-center '>
                <img src={plusIcon} style={{ height: '2rem', width: '2rem' }} />
              </div>
            </BoxContainer> : <></>
          }


          {
            searchText === "" ?
              (viewCollection && viewCollection.length > 0 ? viewCollection?.map(
                (collection, index) =>
                  <PassportCard isStreamYard={collection?.htmlTemplate} isSelected={collection == selectedCard} key={index} index={index} data={collection} type={"Billboard"} onPress={onSelectCollection} />
              )
                :
                <BoxContainer sx={{ background: "transparent" }} elevation={4} className='m-2'>
                  <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
                    {`No active Announcements`}
                  </Typography>
                </BoxContainer>
              )
              :
              (filteredCards &&
                <>
                  {filteredCards.length > 0 ?
                    (
                      <>
                        {filteredCards?.map((collection, index) => {
                          return (<PassportCard isSelected={collection == selectedCard} key={index} index={index} data={collection} type={"Billboard"} onPress={onSelectCollection} />);
                        })}
                      </>
                    )
                    :
                    <BoxContainer sx={{ background: "transparent" }} elevation={4} className='m-2'>
                      <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
                        {`No results found`}
                      </Typography>
                    </BoxContainer>
                  }
                </>
              )
          }


          {

          }
        </FirstColumnContent>
        {(!showActive || (showActive && sortedCollections?.filter(obj => obj.isActive === !showActive).length > 0)) &&
          <div className="collective-view-link py-2 m-1">
            <Link onClick={toggleActiveCollections}><span>{"View " + (showActive ? "disabled " : "active ") + "Announcements"}</span></Link>
          </div>
        }
      </Box>
    </>
  );
}