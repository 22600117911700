import { gql } from 'urql';

export const usersDataQuery = gql`
  query ($skip: Int, $first: Int) {
    users(
      skip: $skip
      first: $first
      orderDirection: asc
      orderBy: registeredAtTimestamp
    ) {
      wallet: id
      name
      userId
      dataURI
      avatarURI
    }
  }
`;