export const hexToRGB= (h) => {
    let r = "", g = "", b = "";
  
    // 3 digits
    if (h.length === 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length === 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    
    return "rgb("+ +r + "," + +g + "," + +b + ")";
}

export const RGBToHex = (r,g,b) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;

  return "#" + r + g + b;
}

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0, g = 0, b = 0;
  if (H.length === 4) {
    r = Number("0x" + H[1] + H[1]);
    g = Number("0x" + H[2] + H[2]);
    b = Number("0x" + H[3] + H[3]);
  } else if (H.length === 7) {
    r = Number("0x" + H[1] + H[2]);
    g = Number("0x" + H[3] + H[4]);
    b = Number("0x" + H[5] + H[6]);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

  if (delta === 0)
    h = 0;
  else if (cmax === r)
    h = ((g - b) / delta) % 6;
  else if (cmax === g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + l + "%)";
}

export const getLightHSL = (hsl) => {

  let rgb = hsl.slice(hsl.indexOf('(')+1, hsl.lastIndexOf(')')).split(',');
  rgb[2] = "70%";

  return  "hsl("+ rgb.join(",") +")";

}

export const getDarkHSL = (hsl) => {

  let rgb = hsl.slice(hsl.indexOf('(')+1, hsl.lastIndexOf(')')).split(',');
  rgb[2] = "30%";

  return  "hsl("+ rgb.join(",") +")";

}

function rand(min, max) {
  return min + Math.random() * (max - min);
}

export const getHSLRandomColor = () => {
  var h = rand(1, 360);
  var s = rand(0, 100);
  var l = rand(0, 100);
  let hsl = 'hsl(' + h + ',' + s + '%,' + l + '%)'
  let lightColor = getLightHSL(hsl);
  let darkColor = getDarkHSL(hsl);

  return { hsl, lightColor, darkColor };
}