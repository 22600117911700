import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { IPNS_UPLOAD_LAMBDA_URL, IPNS_URL, MASTER_KEY, NetworkId, SOCIAL_MEDIA_API, addresses } from "../constants";
import { GetAddInviteCodeDataMessage, GetCreateUserMessage, GetEncodedHash, GetUserAvatarMessage, GetUserCreatedLogData, GetUserNameMessage, GetUserDataURIMessage } from "../helpers/MsgHelper";
import { RootState } from "../store";
import { Loot8Onboarder__factory, User__factory } from "../typechain/factories";
import { SendMetaTX } from "./AppSlice";
import { getUserAvatar, setAll } from "./helpers";
import { IUser, IIPNSUserDetailType } from "../interfaces/IUser.interface";
import {  IUserBaseAsyncThunk, IMessageMetaData, IUserCreateBaseAsyncThunk, IAddressBaseAsyncThunk, IUserSetUserNamesBaseAsyncThunk, IUserSetStatusBaseAsyncThunk, IUserSetAvatarBaseAsyncThunk } from "./interfaces";
import { getMutedUsers, muteAccount } from "../services/Message.service";
import { BigNumber, Wallet, ethers } from "ethers";
import { checkInviteCodeValid } from "./EntitySlice";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { VERIFICATION_STATUS } from "../enums/socialAccount.enum";
import { getData, storeData } from "../helpers/AppStorage";
import { getBufferForStatusAndAvatarSigning, uploadUserData, wait, getUserIPNSURL, uploadUserDetailsToIPNS, fetchWithTimeout, retryFetch } from "../helpers/ipfs";
import { verifySocialLogin } from "../services/SocialAccount.service";
import { getAllUsersDataFromQuery } from "../helpers/QueryHelper";

export const IsUserExists = async ({ networkID, provider, address }: IAddressBaseAsyncThunk): Promise<{
  userAttributes: {
    id: BigNumber;
    name: string;
    wallet: string;
    avatarURI: string;
    dataURI: string;
}
}> => {
  const userFactory = User__factory.connect(addresses[networkID].User, provider);
  const userAttributes = await userFactory.userAttributes(address);
  return { userAttributes };
}

export const getUserOnboarded = async ({ networkID, provider, address }: IAddressBaseAsyncThunk): Promise<boolean> => {
  const onboarder = Loot8Onboarder__factory.connect(addresses[networkID].Onboarder, provider);

  const isOnboarded = await onboarder.userOnboarded(address);

  return isOnboarded;
}

export const getInvitesSent = async ({ networkID, provider, address }: IAddressBaseAsyncThunk): Promise<Number> => {
  const onboarder = Loot8Onboarder__factory.connect(addresses[networkID].Onboarder, provider);

  const invitesSent = await onboarder.invitesSent(address);

  return Number(invitesSent);
}
export const getMaxInvites = async ({ networkID, provider, address }: IAddressBaseAsyncThunk): Promise<Number> => {
  const onboarder = Loot8Onboarder__factory.connect(addresses[networkID].Onboarder, provider);

  const maxInvites = await onboarder.maxInvites()

  return Number(maxInvites);
}

export const getRandomInviteCode = async ({ networkID, provider, maxInvite}): Promise<string[]>  => {
  const inviteCodes = [];
  while (inviteCodes.length < maxInvite) {
    const inviteBytes = ethers.utils.randomBytes(10);

    const code = [...inviteBytes].map(hex => String.fromCharCode((hex % 26) + (Math.random() < 0.5 ? 65 : 97))).join("");

    const isDuplicate =  await checkInviteCodeValid({ networkID, provider, inviteCode: code, isOnboarding: false });
    if(!isDuplicate) {
      inviteCodes.push(code);
    }
  }
  return inviteCodes;
}

export const storeUserDetailsToMFS = async (userDetails: IIPNSUserDetailType, wallet: Wallet) => {
  uploadUserDetailsToIPNS(userDetails, wallet);
}

export const getUserJSONdata = async (address: string | string[]): Promise<any> => {
  let response;
  if (address) {
    const body = Array.isArray(address) ? address : [address.toLowerCase()];
    const timestamp =  Date.now()
    response = await retryFetch(() => fetchWithTimeout(SOCIAL_MEDIA_API + "api/v1/account/details/",
      { 
        method: 'POST', 
        headers: { 
          "X-Loot8-Timestamp": timestamp?.toString(),
          "Content-Type": "application/json" 
        },
        body: JSON.stringify({ "accounts": body })
      }))
      .then(async (response) => {  return await response.json(); })
      .catch(error => LogToConsoleError(error));
  }

  return Array.isArray(address) ?  response?.data : response?.data[address?.toLowerCase()];
}

export const CreateUserDetail = createAsyncThunk("appuser/CreateUserDetail", async ({ networkID, provider, address, userInfo, wallet, userStatus, userName, userAvatarURI, userLocation }: any, { dispatch }): Promise<any> => {
  await dispatch(CreateUser({ networkID, provider, address, userInfo, wallet, userStatus, userName, userAvatarURI }));
  await wait(1000);
  
  await dispatch(loadUserDetail({ networkID, provider, address, userInfo, wallet }));
});

export const storeUserDetailsToIPNS = async (userDetails: IIPNSUserDetailType, wallet: Wallet) => {
  uploadUserDetailsToIPNS(userDetails, wallet);
};

export const loadUserDetail = createAsyncThunk("appuser/checkUser", async ({ networkID, provider, address, userInfo, wallet }: IUserBaseAsyncThunk, { dispatch }): Promise<IUser> => {
  let userAttributesData = (await IsUserExists({ networkID, provider, address }));
  
  let id = Number(userAttributesData.userAttributes.id);
  
  let userStoredAvatarUri = "";
  let thirdPartyVerifiedURL = []
  let IsUserOnboarded = null;

  if (id != 0) {

    //   await dispatch(CreateUser({ networkID, provider, address, userInfo, wallet, }));
    //   userAttributesData = (await IsUserExists({ networkID, provider, address }));
    //   id = Number(userAttributesData.userAttributes.id);
    // }

    //upload blank friends.json to get dataURI from IPNS - this will execute for new user and in case for any existing user does not have dataURI
    if(userAttributesData && userAttributesData.userAttributes && 
      userAttributesData.userAttributes.wallet)
    {
      const userJsonData = await getUserJSONdata(userAttributesData?.userAttributes?.wallet);
      if(!userJsonData) {
      getUserIPNSURL(wallet).then(async (response) => {
        if (response) {
          storeUserDetailsToMFS({
            publicKey: wallet.publicKey
          }, wallet);
        }
        // if (response) {
          // const dataURI = "ipns://" + ipns;
          
          //Update dataURI into AppUser state
          // dispatch(updateUserDataURI({ dataURI: dataURI }));

          //Update dataURI for user into User contract
          // const dataURIMsg = GetUserDataURIMessage(dataURI);
          // if (address) {
          //   let msg: IMessageMetaData = {
          //     to: addresses[networkID].User,
          //     wallet: wallet,
          //     data: dataURIMsg,
          //     networkID: networkID,
          //     provider: provider
          //   }
            
          //   try {
          //     dispatch(SendMetaTX(msg));
          //   } catch (error) {
          //     LogToConsole("META-TX-USER-DATA-URI-ERROR", wallet.address, networkID, [
          //       { tag: "from", value: wallet.address },
          //       { tag: "to", value: addresses[networkID].User },
          //       { tag: "data", value: dataURIMsg },
          //       { tag: "processingTime", value: Date.now().toString() }]);
          //   }

          //   //store user's public key to ipfs.
          //   storeUserDetailsToIPNS({
          //     publicKey: wallet.publicKey
          //   }, wallet);
            
          // }
        // }
      });
    }
    }
    
    dispatch(getAllUsersData({ networkID, provider}));

    IsUserOnboarded = await getUserOnboarded({ networkID, provider, address });
    userStoredAvatarUri = await getUserAvatar( userAttributesData?.userAttributes?.avatarURI ?? "" );
    
    try {
      thirdPartyVerifiedURL =  (await getThirdPartyVerifiedURL({ networkID, provider, address }));
    } catch(e) {
      LogToConsoleError("loadUserDetail | thirdPartyVerifiedURL", e.name, )
    }
    dispatch(getUserStatus({ networkID, provider, address, userAttributesData }));
    dispatch(setUserOnboarded(IsUserOnboarded));
    dispatch(getInviteCodesSent({ networkID, provider, address }));

  }
  const userData : IUser = { 
    ...userAttributesData.userAttributes, isExist: id !== 0, avatarURI: userStoredAvatarUri, isOnboarded: IsUserOnboarded, id,
    thirdPartyVerifiedURL: thirdPartyVerifiedURL?.length > 0 ? thirdPartyVerifiedURL : null }

  LogToConsole("userDetails ",userData);
  dispatch(setUserData(userData));

  return userData;
});


export const getInviteCodesSent = createAsyncThunk("appuser/InviteCodesSent", async ({ networkID, provider, address }: { networkID, provider, address }, { dispatch }): Promise<any> => {
  const invites = await getInvitesSent({ networkID, provider, address });
  const maxInvites = await getMaxInvites({ networkID, provider, address })
  dispatch(setInvitesSent(invites));
  dispatch(setMaxInvites(maxInvites));

});

export const getAllUsersData = createAsyncThunk("appuser/AllUsersData", async ({ networkID, provider }: { networkID, provider }, { dispatch }): Promise<any> => {

  const userContract = User__factory.connect(addresses[networkID].User, provider);
  
  let usersData = []
  let enableQueryHelper = true;
  try {
    if(enableQueryHelper) {
      usersData = await getAllUsersDataFromQuery();
      const uniqueData = Array.from(new Map(usersData.map(item => [item.wallet, item])).values());
      usersData = uniqueData;
    }
    else {
      usersData = await userContract.getAllUsers(false);
    }

  }
  catch (e) {
    LogToConsoleError(e);
  }
  
  await dispatch(setAllUserData({ AllUsersData : usersData}));
  return usersData;
});

export const toggleUserSocialAccess = createAsyncThunk("appuser/toggleUserSocialAccess", async ({ wallet, userAccount, collectionAddress, chainId, status, passport }: {  wallet: Wallet, userAccount: string, collectionAddress: string, chainId: NetworkId, status: boolean, passport?: string }) => {
  const feed = collectionAddress + ":" + chainId.toString();
  const response = await muteAccount(userAccount ,feed, status, wallet, passport);

  if (response.status !== 200) {
    LogToConsoleError("toggleUserSocialAccess", response?.status?.toString(), response?.statusText, null);
    if(response.status === 500)
    {
      const res = await response.json();
      if(res && res.error)
      {
        LogToConsoleError(res.error);
      }
    }
  } 
  
});

export const getCollectionSocialAccess = createAsyncThunk("appuser/getCollectionSocialAccess", async ({ wallet, collectionAddress, chainId, passport }: { wallet: Wallet, collectionAddress: string, chainId: NetworkId | "", passport?: string }, { getState }) => {
  const feed = collectionAddress + ":" + chainId.toString();
  const response = await getMutedUsers(feed, wallet, passport);
  let mutedUsers = [];
  if (response.status === 200) {

    let responseData = await response.json();
    const state = getState() as RootState;
    const AllUsersData = state.AppUser.AllUsersData;

    if(responseData && responseData.length > 0) {

      responseData.forEach(item => {

        const userData = AllUsersData.find(user => user?.wallet.toLowerCase() === item?.account.toLowerCase());
        if(userData) {
          mutedUsers.push({ ...item, ...userData });
        }

      });

    }
  } 
  else {
    LogToConsoleError("getCollectionSocialAccess", response?.status?.toString(), response?.statusText, null);
    if(response.status === 500)
    {
      const res = await response.json();
      if(res && res.error)
      {
        LogToConsoleError(res.error);
      }
    }
  }

  return { mutedUsers: mutedUsers };
  
});

export const CreateUser = createAsyncThunk("appuser/createUser", async ({ networkID, provider, address, userInfo, wallet, userName, userStatus, userAvatarURI  }: IUserCreateBaseAsyncThunk, { dispatch }): Promise<any> => {

  //upload user avatar URI to IPFS and get IPFS URL
  let selectedAvatarURI = "";
  if (userAvatarURI != '') {
    await uploadUserData(userAvatarURI, wallet).then(async (ipfsURI: any) => {
      //LogToLoot8Console(ipfsURI);
      selectedAvatarURI = ipfsURI;
    });
  }

  let name = userName != "" ? userName : userInfo.name;
  let avatarURI = selectedAvatarURI != '' ? selectedAvatarURI : userInfo.profileImage;

    const data = GetCreateUserMessage(name, avatarURI, "");

    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].Dispatcher,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider
      }
      LogToConsole(msg);
      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdUserID = GetUserCreatedLogData(res.payload?.eventLogs);
        if (createdUserID === 0) {
          LogToConsoleError('Error user creation...');
        }
      }
    }

    //Store user status to IPNS & local storage
    dispatch(setUserStatus({ networkID, provider, address, wallet, newStatus: userStatus }));

});

export const CreateInviteCodes = createAsyncThunk("AppUser/CreateInviteCodes",  async ({ networkID, provider, address, wallet, inviteCodes }: any, { dispatch, }): Promise<any> => {
  
  const encryptedInviteCodes = inviteCodes.map(encodedCode =>GetEncodedHash(encodedCode));

  const data = GetAddInviteCodeDataMessage(encryptedInviteCodes);
  if(data) {  
    let msg: IMessageMetaData = {
        to: addresses[networkID].Onboarder,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider
      }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const socialAccountVerifyCode = createAsyncThunk("AppUser/socialAccountVerifyCode",  async ({ networkID, provider, address, wallet, code, userInfo }: any, { dispatch, getState }): Promise<any> => {
  
  const state = getState() as RootState;
  const response = await verifySocialLogin(wallet, code);
  LogToConsole("response",response?.txHash)
  if(response?.txHash) {
    await dispatch(loadUserDetail({ networkID, provider, address, userInfo, wallet }));
    return VERIFICATION_STATUS.SUCCESS;
  }

  return VERIFICATION_STATUS.FAILED;
});

export const getThirdPartyVerifiedURL = async ({
  networkID,
  provider,
  address
}: {
  networkID
  provider
  address
}): Promise<any> => {
  let thirdPartyURL = []

  try {
    const userFactory = User__factory.connect(
      addresses[networkID].User,
      provider
    )

    thirdPartyURL = await userFactory.getThirdPartyVerifiedProfileUrl(address)
  } catch (e) {
    LogToConsoleError(
      "getThirdPartyVerifiedURL | coudn't load collection owner",
      e.name,
      e.message,
      e.stack
    )
  }
  return thirdPartyURL
}

export const setUserName = createAsyncThunk("AppUser/setUserName", async ({ networkID, provider, wallet, address, newUserName }: IUserSetUserNamesBaseAsyncThunk, { dispatch, getState }): Promise<any> => {
  const data = GetUserNameMessage(newUserName);
  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].User,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    await dispatch(SendMetaTX(msg));
  }

  const state = getState() as RootState;
  let userData = { ...state.AppUser.UserData, name: newUserName };

  const signature = await wallet.signMessage(newUserName);
  
  return {
    UserData: userData
  }
});

export const setUserAvatar = createAsyncThunk("appuser/setAvatar", async ({ networkID, provider, wallet, address, newAvatarUri }: IUserSetAvatarBaseAsyncThunk, { dispatch, getState }): Promise<any> => {
  //post new avatar URI to IPFS
  const ipfsURI = await uploadUserData(newAvatarUri, wallet);
  //LogToLoot8Console(ipfsURI);
  if (ipfsURI) {
    //update new IPFS Avatar URI [CID] in contract
    const avatarData = GetUserAvatarMessage(ipfsURI);
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].User,
        wallet: wallet,
        data: avatarData,
        networkID: networkID,
        provider: provider
      }
      await dispatch(SendMetaTX(msg));
    }

    const state = getState() as RootState;
    let userData = { ...state.AppUser.UserData, avatarURI: newAvatarUri };

    return {
      UserData: userData
    }
  }
  // });
});

export const getUserStatus = createAsyncThunk("appuser/getUserStatus", async ({ networkID, provider, address, userAttributesData }: { networkID, provider, address, userAttributesData }, { getState }): Promise<any> => {
  let userStoredStatus = await getData('@myStatus');
  if (userStoredStatus) return { status: userStoredStatus };
  try {
    if (userAttributesData.userAttributes && userAttributesData.userAttributes?.wallet != '') {
      const userJsonData = await getUserJSONdata(userAttributesData?.userAttributes?.wallet);
      if(userJsonData && userJsonData?.status) {
        userStoredStatus = userJsonData?.status ?? "";
      }
    }
  } catch (error) {
    LogToConsole("getUserStatus", error.name, error.message, error.stack);
  }
  return {
    status: userStoredStatus ?? ""
  };
});

export const setUserStatus = createAsyncThunk("appuser/setStatus", async ({ networkID, provider, wallet, address, newStatus }: IUserSetStatusBaseAsyncThunk, { dispatch, getState }): Promise<any> => {
  const signature = await wallet.signMessage(newStatus);

  await storeData('@myStatus', newStatus);

  const state = getState() as RootState;
  let userData = { ...state.AppUser.UserData, status: newStatus };

  uploadUserStatusIPNS(wallet, newStatus);

  return {
    UserData: userData
  }
});

export const uploadUserStatusIPNS = async (userWallet: Wallet, status: string) => {

  const filename = 'status.txt';
  const data = Buffer.from(status, "utf-8");
  const canonicalizedData = getBufferForStatusAndAvatarSigning(data, filename);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);


  const postStatusURL = IPNS_UPLOAD_LAMBDA_URL + 'user/' + userWallet.address + '/secure-upload/raw/' + filename + '/'
    + lambda_signature + '/' + masterSignature;

  fetch(postStatusURL, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: status
  }).catch((err) => {
    LogToConsoleError('Upload User Status Error');
    console.error(err);
  });
}


export interface IAppUserSliceData {
  readonly UserData?: IUser;
  readonly loading: boolean;
  readonly AllUsersData: IUser[];
  readonly MutedUsers : any[];
  readonly SocialAccessLoading: boolean;
  readonly MailStatus: Number;
  readonly InvitesSent: Number;
  readonly maxInvites: Number;
  readonly socialAccountVerifiedCodeLoading: boolean;
  readonly isNewUser: boolean;
}

const initialState: IAppUserSliceData = {
  UserData: null,
  loading: false,
  AllUsersData: [],
  MutedUsers: null,
  SocialAccessLoading: false,
  MailStatus: 0,
  InvitesSent: 0,
  maxInvites: 0,
  socialAccountVerifiedCodeLoading: false,
  isNewUser: false
};

const AppUserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateUserDataURI(state, action) {
      state.UserData.dataURI = action.payload.dataURI;
    },
    setAllUserData(state, action) {
      state.AllUsersData = action.payload.AllUsersData
    },
    setMailStatus(state, action) {
      state.MailStatus = action.payload;
    },
    setUserData(state, action) {
      state.UserData = action.payload;
    },
    setInvitesSent(state, action) {
      state.InvitesSent = action.payload;
    },
    setMaxInvites(state, action) {
      state.maxInvites = action.payload;
    },
    setUserOnboarded(state, action) {
      if(state.UserData) {
        state.UserData.isOnboarded = action.payload;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadUserDetail.pending, (state: { loading: boolean; }) => {
        state.loading = true;
      })

      .addCase(loadUserDetail.fulfilled, (state, action) => {
        // state.UserData = action.payload;
        state.loading = false;
      })

      .addCase(loadUserDetail.rejected, (state: { loading: boolean; }, { error }: any) => {
        state.loading = false;
        LogToConsoleError("loadUserDetail", error.name, error.message, error.stack);
      })

      .addCase(CreateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getCollectionSocialAccess.fulfilled, (state, action) => {
        state.SocialAccessLoading = false;
        state.MutedUsers = action.payload.mutedUsers;
      })
      .addCase(getCollectionSocialAccess.pending, (state, ) => {
        state.SocialAccessLoading = true;
      })
      .addCase(getCollectionSocialAccess.rejected, (state, { error }: any) => {
        state.SocialAccessLoading = false;
        LogToConsoleError("getCollectionSocialAccess", error.name, error.message, error.stack);
      })
      .addCase(socialAccountVerifyCode.fulfilled, (state, action) => {
        state.socialAccountVerifiedCodeLoading = false;        
      })
      .addCase(socialAccountVerifyCode.pending, (state, ) => {
        state.socialAccountVerifiedCodeLoading = true;
      })
      .addCase(socialAccountVerifyCode.rejected, (state, { error }: any) => {
        state.socialAccountVerifiedCodeLoading = false;
        LogToConsoleError("socialAccountVerified", error.name, error.message, error.stack);
      })
      .addCase(CreateUserDetail.pending, (state) => {
        state.isNewUser = false;
      })
      .addCase(CreateUserDetail.fulfilled, (state) => {
        state.loading = false;
        state.isNewUser = true;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        if (state.UserData) { 
          state.UserData.status = action.payload?.status;
        }
        state.loading = false;
      })
  },
});

export const AppUserSliceReducer = AppUserSlice.reducer;

const baseInfo = (state: RootState) => state.AppUser;

export const { fetchAppSuccess, setAllUserData, setMailStatus, setUserData, setInvitesSent, setMaxInvites, updateUserDataURI, setUserOnboarded } = AppUserSlice.actions;

export const getUserState = createSelector(baseInfo, User => User);