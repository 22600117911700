import React from 'react';
import { Box, BoxProps, Paper, PaperProps, Typography, styled } from "@mui/material";
import backgroundImage from "./../../assets/images/background/backgroundImage.png"
import loot8Loader from './../../assets/loot8Loader.json'

import { CircularProgress } from '@mui/material';
import Lottie from 'lottie-react';

const StyledModal = styled(Paper)<PaperProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  position: 'absolute',
  width: 'fill-available',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 0 10px rgba(255, 255, 255, 0.3)',
  
  zIndex: 9999999,

}));

const Content = styled(Box)<BoxProps>(({theme}) => ({
  // backgroundColor: theme.palette.background.paper,
  // boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  outline: 'none',
  position: 'relative',
  minWidth: '25vw',
  '@media (max-width: 961px)': {
    maxWidth: '100%',
    },
  maxWidth: '30vw',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'

}));

const LoaderContainer = styled(Box)<BoxProps>(({theme}) => ({

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(2)
}));
export const LoaderModal = ({ open, message, children = null }) => {

  return (
    <StyledModal >
      <Content>
      <LoaderContainer>
        <Lottie animationData={loot8Loader} style={{height:'30vh',width:"40vw",position:"absolute"}}/>
        </LoaderContainer>
        <Typography mt={'1rem'} fontFamily={'Poppins-Medium'} fontSize={'1.25rem'} textAlign={'center'}>Please hang on...</Typography>
        <Typography fontFamily={'Poppins-Medium'} fontSize={'1.15rem'} textAlign={'center'} >{message}</Typography>
        {
          children
        }
      </Content>
    </StyledModal>
  );
};


