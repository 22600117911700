import '../Profile.scss'
import profile_placeHolder from '../../../assets/svg/profile_placeholder.svg'
import { Avatar } from '@mui/material'
import { ProfilePicUpload } from './ProfilePicUpload'

export type ProfileImageProps = {
    source?:string,
    profilePicUpload: (f) => void
}

export const ProfileImage = (props: ProfileImageProps) => {
    
    return (
        <div className='d-flex justify-content-center'>
            <div className='position-relative d-flex justify-content-center w-25 '>
                <Avatar className='profile-image' src={props?.source ? props?.source : profile_placeHolder} />               
                <ProfilePicUpload 
                    className={""} 
                    disabled={false} 
                    required={true} 
                    fileTypes={"image/jpg, image/jpeg, image/png"}
                    fileChangeHandler={props?.profilePicUpload}
                />
            </div>
        </div>
    )
}