import { TextField, Typography, outlinedInputClasses } from '@mui/material';
import React, { useState } from 'react';
import { defaultTheme } from '../../../styles';

export const NameTextInput = ({ name,maxLength=null,nameChangeHandler, required, disabled, label, placeholder="Enter a name" }) => {

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    nameChangeHandler(event);
    setError(value.trim().length > 45);
  };

  return (
    <div className='mt-2'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {label}
        </Typography>
        { required &&  
          <Typography marginBottom={-1} variant="caption" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
            *REQUIRED
          </Typography>
        }
      </div>
      <TextField
        value={name}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        FormHelperTextProps={{
          sx: {
            fontFamily: 'Poppins',
   
            color: '#ff6060',
            fontSize: '0.9rem'
          }
        }}
        helperText={!disabled && '*Max 45 characters allowed'}
        required={required}
        disabled={disabled}
        inputProps={{
          maxLength: 45,
        }}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: "#F0ECEC",
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          mt: 1
        }}

      />
      {maxLength ?  
      <Typography display={'flex'} marginBottom={-2} justifyContent={'flex-end'} fontSize={10}>{name?.length}/{maxLength}</Typography> : <></>}
    </div>
  );
}

