import { FormLabel, styled } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { MouseEventHandler } from "react";
import uploadIcon from "../../../../assets/images/icons/uplaodImag.png"
import { IMAGE_REGEX, IMAGE_UPLOAD_LIMIT, IMAGE_UPLOAD_SIZE, THUMBNAIL_UPLOAD_SIZE, VIDEO_REGEX } from "../../../../constants";
import { useAlert } from "../../../../hooks/Alert";
import { CatalogTypes } from "../../../../enums/catalog.enum";


const ColorButton = styled(FormLabel)<ButtonProps>(({ theme }) => ({
  fontFamily: "Poppins-SemiBold",
  padding: '0.25rem',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: '6px',
}));
type imageType = "thumbnail"|"optimised"|"original"

interface FileUploadButtonProps {
  children?: any;
  className: string;
  catalogType?:string,
  required: boolean;
  disabled: boolean;
  imageType?:imageType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fileTypes: string;
  fileChangeHandler: (event: any,isThumbnail?:imageType)=> void;
}
export const FileUploader = ({className,catalogType, required=true,imageType, disabled, onClick, fileTypes, fileChangeHandler }: FileUploadButtonProps) => {
  
 const { setShowToastError } = useAlert();

  const filehandler = (event) => {
    const { files } = event.target;
    if(files && files[0]) {
      if(catalogType && (catalogType == CatalogTypes.EXPASSES || catalogType==CatalogTypes.COLLECTIBLES || catalogType == CatalogTypes.EVENT)){
        if(imageType=='thumbnail' && files[0]?.type=="image/gif"){
          setShowToastError({ open:true, message: "Thumbnail must be png or jpeg"});
          return 
        }
        if((imageType=='thumbnail' && files[0].size>THUMBNAIL_UPLOAD_SIZE) || 
        (imageType=='optimised' && files[0].size>IMAGE_UPLOAD_SIZE) || (imageType=='original' &&  files[0].size>IMAGE_UPLOAD_LIMIT)){

          setShowToastError({ open:true, message: `Max file size allowed is ${imageType=='optimised' ? '2' : imageType=='thumbnail' ? '1' : '30'}  MB`});
          return;
        }
      }else if(files[0].size>IMAGE_UPLOAD_LIMIT){
        setShowToastError({ open:true, message: `Max file size allowed is 100 MB`});
        return;
      }
   
      if(!IMAGE_REGEX.test(files[0].type.toString()) && !VIDEO_REGEX.test(files[0].type.toString())) {
          setShowToastError({ open:true, message: "Invalid file type"});
          return;
      }
      fileChangeHandler(event,imageType);
    }
  }

  return (
  <div>
    <ColorButton  variant="contained" size="small"  className={className}>
    <img src={uploadIcon} style={{width:'22px',height:'22px'}}/>
    {/* <EditTwoToneIcon sx={{ color: defaultTheme.darkColor }} /> */}
    <div className="imageCardText p-2">
      <span>Drop your image here or <span className="browse" >browse</span> </span>
      <input
        accept={fileTypes}
        type="file"
        onChange={filehandler}
        required={required}
        style={{ opacity: 0, width: 1 }}
        disabled={disabled}
        onClick={(event) => { event.currentTarget.value = null }}
      />
    </div>
   
    </ColorButton>
  </div>
  );
}