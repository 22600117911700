import React from 'react'
import "./WhitePattern.scss"
import { Link } from '@mui/material';
interface Props {
    children: React.ReactNode;
  }
const WhitePattern = ({ children } : Props ) => {
    return (
            <div className="background-container">
                {children}
                <div className='bottomTextContainer'>
                    <Link target='_blank' href='https://loot8.io/privacy.html'>
                    Privacy Policy</Link> &nbsp;|&nbsp;<Link href='https://loot8.io/tos.html' target='_blank'> Terms & Conditions</Link>
                </div>
            </div>
        
    )
}

export default WhitePattern
