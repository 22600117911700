import { Box, IconButton, TextField, Typography, outlinedInputClasses } from '@mui/material';
import React, { useState } from 'react';
import { defaultTheme } from '../../../styles';
import { LightTooltip } from '../../../views/CollectionDetails/views/FormContainer.tsx/CollectionFormContainer';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Remove } from '@mui/icons-material';

type BasicTextInputProps = {
  value:string
  label:string
  valueChangeHandler:(item:any)=>void,
  required:boolean
  disabled:boolean
  placeholder:string
  onClickField?:(e)=>void
  maxLength?:number|null, 
  pattern?:null|any,
  infoText?:string,
  icon?:string,
  id?:string
  handleRemove?:()=>void
}

export const BasicTextInput = (props:BasicTextInputProps) => {

  const { value, label,icon,id, valueChangeHandler,handleRemove, required, disabled, placeholder, maxLength=null, pattern = null } = props;

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    valueChangeHandler(event);
    setError(maxLength && value.trim().length > maxLength);
  };

  return (
    <div className='mt-2'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {icon ?  <img  src={icon} alt="" style={{ width: "25px", height: "25px",marginRight:'3px' }} /> : <></>}
        <Typography variant="body2" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
          {label}
        </Typography>
        {handleRemove ? 
        <IconButton onClick={handleRemove}>
              <Remove sx={{ backgroundColor: '#BD0909', borderRadius: '50%', color: '#ffffff', fontSize: '1rem' }} />
          </IconButton> : <></>}
        {props?.infoText ? 
        <LightTooltip  title={props?.infoText} placement="bottom" arrow>
                <InfoRoundedIcon sx={{ fontSize: '1rem',color:defaultTheme.yellowColor,marginLeft:1}} />
            </LightTooltip> : <></>}
        </Box>
        { required &&  
          <Typography variant="caption" marginBottom={-1} color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
            *required
          </Typography>
        }
      </div>
      <TextField
        id={id??""}
        value={value}
        onClick={props?.onClickField}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        FormHelperTextProps={{
          sx: {
            fontFamily: 'Poppins',
            color: '#ff6060',
            fontSize: '0.8rem',
            marginLeft: 0
          }
        }}
        required={required}
        disabled={disabled}
        inputProps={{
          maxLength: maxLength || 1000,
          pattern: pattern
        }}
        InputLabelProps={{
          sx: {
            color: defaultTheme.placeHolder,
          }
        }}
        InputProps={{
          sx: {
            backgroundColor: defaultTheme.newFieldColor,
            color: defaultTheme.buttonDarkTextColor,
            fontFamily: 'Poppins-Medium',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: 'none',
            },
            [`& .${outlinedInputClasses.disabled}`]: {
              WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
              cursor: 'not-allowed'
            }
          },
          notched: false
        }}
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          mt: 1
        }}
        

      />
      {maxLength ?  
      <Typography display={'flex'} marginBottom={-2} justifyContent={'flex-end'} fontSize={10}>{value?.length}/{maxLength}</Typography> : <></>}
    </div>
  );
}

