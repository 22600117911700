import { Button, InputAdornment, TextField, TextFieldProps, Typography, outlinedInputClasses } from "@mui/material"
import { defaultTheme } from "../../../styles"
import './EditTextInput.scss'
import { useEffect, useState } from "react"

type Props = {
icon?:React.ReactNode
required?:boolean
label?:string
enableUpdate?: boolean
onUpdate?: () => void
closeIcon?:React.ReactNode
normalTextField?: boolean
minLength?:number
maxLength?: number
value?: string
}&TextFieldProps

export const EditTextInput = (props:Props) => {
  
  const [errorFlag, setErrorFlag] = useState(false);
  const [countInputTextLength, setCountInputTextLength] = useState(props?.value ? props?.value?.length : 0);

  const onChangeHandler = (val) => {
      props?.onChange(val);
      setErrorFlag(val ? false : true)
  }

  useEffect(() => {
    setCountInputTextLength(props?.value ? props?.value?.length : 0);
  },[props?.value]);

  return(
    <div className="mt-2 mb-2">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body2" color={'black'} fontFamily={'Poppins-Medium'} >
          {props?.label}
        </Typography>
        { props?.required &&  
          <Typography variant="caption" color={'required'} fontFamily={'Poppins'}>
            *required
          </Typography>
        }
      </div>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div style={props?.enableUpdate ? { width:'75%' } :{ width:'100%'}}>
          <TextField
          // fullWidth = {props?.enableUpdate ? false : true}
          size="small"
          style={{ width:'100%', margin:0}}
          className="material-input"
          placeholder={props?.placeholder}
          value={props?.value}
          onChange={(text) => {
            if(props?.maxLength && props?.minLength) {              
              if (text?.target?.value?.length === 0 ||
                (text?.target?.value.trim().length > 0
                  && text?.target?.value?.trim().length < props?.minLength)) {
                  onChangeHandler(text?.target?.value)                
                  setCountInputTextLength(text?.target.value.length);
              } else if (text
                && text?.target?.value?.length >= props?.minLength
                && text?.target?.value?.length <= props?.maxLength
                && text?.target?.value?.trim().length > 0) {
                  onChangeHandler(text?.target?.value)                     
                  setCountInputTextLength(text?.target.value.length);
              }
            } else if(props?.maxLength) {
              if (text && text?.target?.value?.length <= props?.maxLength) {
                onChangeHandler(text?.target?.value)                     
                setCountInputTextLength(text?.target.value.length);
              } else if (text?.target?.value?.length === 0) {
                onChangeHandler(text?.target?.value)                     
                setCountInputTextLength(text?.target.value.length);
              }
            } else {
              onChangeHandler(text?.target?.value)
            }
          }}
          disabled={props?.normalTextField ? false : props?.enableUpdate ? false : true}
          required={props?.required}
          InputLabelProps={{
            sx: {
              color: defaultTheme.placeHolder,
            }
          }}
          FormHelperTextProps={{
            sx: {
              fontFamily: 'Poppins',
     
              color: '#ff6060',
              fontSize: '0.9rem'
            }
          }}
          InputProps={{
            sx: {
              backgroundColor: "#F0ECEC",
              color: defaultTheme.buttonDarkTextColor,
              fontFamily: 'Poppins-Medium',
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                border: 'none',
              },
              [`& .${outlinedInputClasses.disabled}`]: {
                WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
                cursor: 'not-allowed'
              }
            },
            notched: false,
              ...props?.icon ? {
              endAdornment: <InputAdornment position="start">
                  {
                    props?.enableUpdate ?                  
                    props?.closeIcon
                  :            
                    props?.icon
                  }
              </InputAdornment>} : null,
            }}
          error={errorFlag}
          sx={{
            mt: 1
          }}
          variant="outlined"
          />
          {
            props?.maxLength &&
          <div style={{display:"flex", flexDirection:"column", alignItems:'flex-end', fontFamily:'Poppins', fontSize:12}}>
            {countInputTextLength}/{props?.maxLength}
          </div>
          }
        </div>
        {
          props?.enableUpdate && 
          <Button className={'mt-3'} disabled={props?.value ? false : true} onClick={() =>{ props?.onUpdate()}} style={{color: defaultTheme.darkColor, backgroundColor: defaultTheme.yellowColor, width:'20%', height:40 }} >Update</Button>
        }
      </div>
    </div>
    )

}